import { Serializable, jsonProperty } from 'ts-serializable';

import { DateModel } from 'modules/base/models/DateModel';

import { PollAnswerModel } from './PollAnswerModel';

export class PollQuestionModel extends Serializable {
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(Number, null) poll_id: number | null = null;
  @jsonProperty(String, null) title: string | null = null;
  @jsonProperty(Number, null) order: number | null = null;
  @jsonProperty(Boolean) is_media: boolean = false;
  @jsonProperty(Boolean) has_many_answers: boolean = false;
  @jsonProperty(DateModel, null) created_at: DateModel | null = null;
  @jsonProperty(DateModel, null) updated_at: DateModel | null = null;
  @jsonProperty([PollAnswerModel], null) answers: PollAnswerModel[] | null = null;
}
