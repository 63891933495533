import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { IPollTemplate } from 'modules/polls/interfaces/PollsInterfaces';
import { PollRouteActionTypes } from 'modules/polls/types/PollsTypes';
import { pollsRoutes } from 'routes/routes';
import { customColors } from 'styles/colors';

interface IPollTemplateCardProps {
  data: IPollTemplate;
}

export const PollTemplateCard: React.FC<IPollTemplateCardProps> = ({ data }) => {
  const { classes } = useStyles();

  const path: string = RouteHelper.makePath(pollsRoutes.PollConstructorScreen.path, {
    action: PollRouteActionTypes.create,
    templateId: data.id,
    type: data.type,
  });

  // Renders
  return (
    <>
      <Link to={path} className={classes.card}>
        <div className={classes.staticContent}>
          <Typography color={customColors.black} variant="h4Medium">
            {data.title}
          </Typography>
        </div>
        <div className={classes.hoverContent}>
          <div className={classes.viewBtn}>
            <Typography variant="h4Medium" component="span">
              Посмотреть
            </Typography>
            <i className="custom-icon-eye" />
          </div>
        </div>
      </Link>
    </>
  );
};

const useStyles = makeStyles<void, 'staticContent' | 'hoverContent'>()((theme, props, classes) => ({
  card: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 231,
    borderRadius: 10,
    border: `1.5px solid ${theme.palette.customColors.grayDivider}`,
    padding: theme.spacing(2),
    // maxWidth: '221px',
    maxWidth: '219.8px',
    overflow: 'hidden',
    '&:hover': {
      [`& .${classes.staticContent}`]: {
        opacity: 0,
        visibility: 'hidden',
      },
      [`& .${classes.hoverContent}`]: {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  staticContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create(['opacity', 'visibility']),
  },
  hoverContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.customColors.hoverMainGradient,
    transition: theme.transitions.create(['opacity', 'visibility']),
    opacity: 0,
    cursor: 'pointer',
    visibility: 'hidden',
  },
  viewBtn: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.customColors.white,
    fontSize: 14,
    '& span': {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      color: theme.palette.customColors.white,
    },
  },
}));
