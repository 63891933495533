import { Serializable, jsonProperty } from 'ts-serializable';

export class PollVoteBaseModel extends Serializable {
  @jsonProperty(Boolean) is_completed: boolean = false;
  @jsonProperty(Number, null) total: number | null = null;
  @jsonProperty(Number, null) current: number | null = null;
  @jsonProperty(Number, null) percentage: number | null = null;
  @jsonProperty(Number, null) round: number | null = null;
  @jsonProperty(Number, null) total_rounds: number | null = null;
}
