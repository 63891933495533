import { Dto } from 'base/Dto';

import { CheckUserAccessTokenDto } from './dto/auth/CheckUserAccessTokenDto';
import { ForgotPasswordDto } from './dto/auth/ForgotPasswordDto';
import { LoginDto } from './dto/auth/LoginDto';
import { NewPasswordDto } from './dto/auth/NewPasswordDto';
import { RegisterDto } from './dto/auth/RegisterDto';
import { ForgotPasswordForm } from './forms/auth/ForgotPasswordForm';
import { LoginForm } from './forms/auth/LoginForm';
import { NewPasswordForm } from './forms/auth/NewPasswordForm';
import { RegisterForm } from './forms/auth/RegisterForm';

export class AuthDataSource {
  public prepareNewPasswordDto = (form: NewPasswordForm): NewPasswordDto => {
    const preDto = {
      password: form.password,
      password_confirmation: form.passwordConfirm,
    };

    return Dto.populate(NewPasswordDto, preDto);
  };

  public prepareRegisterDto = (form: RegisterForm) => {
    return Dto.populate(RegisterDto, { ...form, name: form.fullname });
  };

  public prepareLoginDto = (form: LoginForm) => {
    return Dto.populate(LoginDto, { ...form, rememberMe: form.needRemember });
  };

  public prepareForgotPasswordDto = (form: ForgotPasswordForm) => {
    return Dto.populate(ForgotPasswordDto, { ...form });
  };

  public prepareCheckUserAccessTokenDto = (token: string): CheckUserAccessTokenDto => {
    return Dto.populate(CheckUserAccessTokenDto, { token });
  };
}
