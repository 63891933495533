import { observer } from 'mobx-react-lite';
import { useStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QIconButton } from 'components/UI/Buttons/QIconButton';
import { PollsListFormFields } from 'modules/polls/forms/PollsListForm/PollsListForm';
import { PollsViewType } from 'modules/polls/types/PollsTypes';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';

interface IPollsViewTypePanelProps {}

export const PollsViewTypePanel: React.FC<IPollsViewTypePanelProps> = observer(() => {
  const { pollsStore } = useRootStore();
  const { cx } = useStyles();

  // Handlers
  const handleChooseViewType = (type: PollsViewType) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (type === PollsViewType.table) {
      pollsStore.pollsListForm.setValue(TableFormFields.sortValue, null);
    }

    pollsStore.pollsListFormSetValueAndGetList(PollsListFormFields.viewType, type);
  };

  // Renders
  return (
    <>
      <QIconButton
        color="secondary"
        size="small"
        className={cx({ active: pollsStore.pollsListForm.viewType === PollsViewType.grid })}
        onClick={handleChooseViewType(PollsViewType.grid)}
      >
        <i className="custom-icon-element" />
      </QIconButton>
      <QIconButton
        size="small"
        color="secondary"
        sx={{ mr: 1 }}
        className={cx({ active: pollsStore.pollsListForm.viewType === PollsViewType.table })}
        onClick={handleChooseViewType(PollsViewType.table)}
      >
        <i className="custom-icon-row-vertical" />
      </QIconButton>
    </>
  );
});
