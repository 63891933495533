import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { QCheckbox } from 'components/UI/CheckBoxes/QCheckbox';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { PollConstructorBlockFieldFormFields } from 'modules/polls/forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';

interface IConstructorCheckboxFieldProps {
  fieldName: `${PollCreateAndEditFormFields.blocks}.${number}.${PollConstructorBlockFormFields.fields}.${number}`;
  actionButtons: ReactNode;
}

export const ConstructorCheckboxField: React.FC<IConstructorCheckboxFieldProps> = props => {
  const { fieldName, actionButtons } = props;
  const { classes } = useStyles();
  const { control } = useFormContext<PollCreateAndEditForm>();

  return (
    <div className={classes.fieldWrap}>
      <QCheckbox disabled />
      <QOutlinedTextFieldWithController
        fullWidth
        placeholder="Введите текст"
        name={`${fieldName}.${PollConstructorBlockFieldFormFields.text}`}
        hookFormProps={{ control }}
      />
      {actionButtons}
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  fieldWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
