import { TableHead, TableCell, TableBody, Box, Table } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QTableHeadCellWithSortLabel } from 'components/UI/Tables/QTableHeadCellWithSortLabel';
import { QTableRow } from 'components/UI/Tables/QTableRow';
import { reportAnswersTableFields } from 'lists/reports/reportAnswersTableFields';
import { useTableStyles } from 'styles/tableStyles';

import { ReportDetailTableBodyElements } from './ReportDetailTableBodyElements';

interface IReportDetailTableListProps {}

export const ReportDetailTableList: React.FC<IReportDetailTableListProps> = observer(() => {
  const { reportsStore } = useRootStore();
  const { tableClasses } = useTableStyles();

  // Handlers
  const handleSort = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    reportsStore.reportAnswersListFormSetSortValueAndGetList(currentSort);
  };

  // Renders
  return (
    <Box sx={{ pb: 3.75, position: 'relative' }}>
      {reportsStore.reportAnswersListLoading && (
        <div className={tableClasses.loaderWrap}>
          <Loader isAbsolute />
        </div>
      )}
      <Table sx={{ borderSpacing: '0 24px' }}>
        <TableHead>
          <QTableRow variant="transparent">
            {reportAnswersTableFields.map((field, index) => (
              <QTableHeadCellWithSortLabel
                key={index}
                field={field}
                sortValue={reportsStore.reportAnswersListForm.sortValue}
                {...field.settings}
                onHandleSort={handleSort}
              />
            ))}
            <TableCell align="right" />
          </QTableRow>
        </TableHead>
        <TableBody className={tableClasses.tableRelative}>
          {reportsStore.reportAnswersList?.map(reportAnswer => (
            <QTableRow key={reportAnswer.answer?.id} className={tableClasses.tableRowFilledVariant}>
              {reportAnswersTableFields.map(field => (
                <ReportDetailTableBodyElements key={field.column} data={reportAnswer} elementId={field.column} />
              ))}
            </QTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
});
