import { PASSWORD_REGEX } from 'consts/globalConsts';
import * as yup from 'yup';

import { ProfileChangePasswordFormFields } from '../forms/ProfileChangePasswordForm';

export const profileChangePasswordFormScheme = yup.object({
  [ProfileChangePasswordFormFields.currentPassword]: yup.string().required('Пожалуйста, введите пароль'),
  [ProfileChangePasswordFormFields.newPassword]: yup
    .string()
    .required('Введите пароль')
    .matches(PASSWORD_REGEX, 'Пароль должен содержать 6 символов'),
  [ProfileChangePasswordFormFields.newPasswordConfirm]: yup
    .string()
    .required('Введите пароль')
    .oneOf([yup.ref(ProfileChangePasswordFormFields.newPassword)], 'Пароли не совпадают'),
});
