import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { CommonHelper } from 'helpers/CommonHelper';
import { useObjectUrls } from 'hooks/useObjectUrls';
import { FileHelper } from 'modules/file/helpers/FileHelper';
import { ILocalFileWithFileUrl } from 'modules/file/interfaces/FileInterfaces';
import { PollConstructorBlockFieldFormFields } from 'modules/polls/forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { customColors } from 'styles/colors';

import { AttachMediaModal } from './AttachMediaModal';

interface IAttachFileProps {
  fieldName: `${PollCreateAndEditFormFields.blocks}.${number}.${PollConstructorBlockFormFields.fields}.${number}`;
  disabled?: boolean;
  wrapProps?: BoxProps;
}

// TODO: Нужно добавить модалку для прикрепления файла, после того как будет готово медиатека
export const AttachFile: React.FC<IAttachFileProps> = observer(props => {
  const { wrapProps, disabled, fieldName } = props;
  const { fileStore } = useRootStore();
  const { classes, cx } = useStyles();

  const [loading, setLoading] = useState(false);
  const [attachMediaModal, setAttachMediaModal] = useState(false);
  const { getObjectUrl, removeObjectUrl } = useObjectUrls();

  const { control } = useFormContext<PollCreateAndEditForm>();
  const { field: imageController } = useController({
    control,
    name: `${fieldName}.${PollConstructorBlockFieldFormFields.image}`,
  });

  const fileUrl = FileHelper.getCurrentFileUrl(
    getObjectUrl(imageController.value?.localFile),
    imageController.value?.fileUrl,
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[] | null) => {
      if (imageController.value?.localFile) {
        removeObjectUrl(imageController.value.localFile);
      }

      if (!acceptedFiles?.length) {
        return;
      }

      setLoading(true);

      fileStore.uploadFile(acceptedFiles[0], {
        onHandleSuccess: fileUrl => {
          if (fileUrl.length) {
            const fileObject: ILocalFileWithFileUrl = {
              localFile: imageController.value?.localFile ?? null,
              fileUrl,
            };
            imageController.onChange(fileObject);
          }
        },
        onHandleFinally: () => {
          setLoading(false);
        },
      });
    },
    [imageController.value],
  );

  const fileDropzone = useDropzone({ onDrop, noClick: false, noKeyboard: true, multiple: false });

  const handleClickInputRootProps = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) {
      return;
    }

    if (imageController.value?.fileUrl) {
      return;
    }

    fileDropzone.open();

    // if (!imageController.value?.localFile) {
    //   handleOpenModal();
    // }
  };

  const handleClearFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (imageController.value?.fileUrl) {
      const fileObject: ILocalFileWithFileUrl = { localFile: null, fileUrl: null };

      removeObjectUrl(imageController.value.localFile);
      imageController.onChange(fileObject);
    }
  };

  // const handleOpenModal = () => {
  //   setAttachMediaModal(true);
  // };

  const handleCloseAttachModal = () => {
    setAttachMediaModal(false);
  };

  const renderFileUploadContent = () => {
    return (
      <>
        <input
          disabled={disabled}
          type="file"
          name={`${fieldName}.${PollConstructorBlockFieldFormFields.image}`}
          className={classes.fileInput}
          {...fileDropzone.getInputProps()}
        />
        <div className={classes.iconWrap}>
          <i className="custom-icon-import-curve" />
        </div>
        <Typography variant="h4Regular" sx={{ mb: 0.5 }}>
          <span className={classes.accent}>Открыть медиатеку</span> <br /> или перетащите изображение сюда
        </Typography>
        <Typography variant="h5Regular" color={customColors.gray}>
          JPG, PNG или GIF максимальный размер 3 MБ
        </Typography>
      </>
    );
  };

  const renderUploadedFile = () => {
    if (!fileUrl?.length) {
      return null;
    }

    return (
      <div className={classes.imgWrap}>
        <img src={fileUrl} alt="" />
        <IconButton className={classes.clearBtn} onClick={handleClearFile}>
          <i className="custom-icon-add" />
        </IconButton>
      </div>
    );
  };

  return (
    <>
      <Box
        {...wrapProps}
        className={cx(classes.wrap, wrapProps?.className, {
          hasFileUpload: !CommonHelper.hasLength(fileUrl),
          isActive: fileDropzone.isDragActive,
        })}
        {...fileDropzone.getRootProps()}
        onClick={handleClickInputRootProps}
      >
        {loading && <Loader isAbsolute />}
        {CommonHelper.hasLength(fileUrl) ? renderUploadedFile() : renderFileUploadContent()}
      </Box>
      <AttachMediaModal open={attachMediaModal} onHandleCloseModal={handleCloseAttachModal} />
    </>
  );
});

const useStyles = makeStyles<void, 'imgWrap' | 'clearBtn'>()((theme, props, classes) => ({
  wrap: {
    position: 'relative',
    maxWidth: 210,
    width: '100%',
    textAlign: 'center',
    borderRadius: 10,
    padding: theme.spacing(5, 2),
    margin: theme.spacing(0, 'auto', 2),
    border: `1px solid ${theme.palette.customColors.darkGrey}`,
    minHeight: 217,
    overflow: 'hidden',
    '&:hover': {
      [`& .${classes.imgWrap}`]: {
        '&:after': {
          opacity: 1,
          visibility: 'visible',
        },
      },
      [`& .${classes.clearBtn}`]: {
        opacity: 1,
        visibility: 'visible',
      },
    },
    '&.isActive': {
      borderColor: theme.palette.customColors.purple,
    },
    '&.hasFileUpload': {
      cursor: 'pointer',
    },
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 28,
    marginBottom: theme.spacing(1),
  },
  accent: {
    fontWeight: 700,
    color: theme.palette.customColors.purple,
  },
  fileInput: {
    display: 'none',
  },
  imgWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '101%',
      height: '101%',
      zIndex: 0,
      opacity: 0,
      visibility: 'hidden',
      background: 'rgba(0, 0, 0, 54%)',
      transition: theme.transitions.create(['opacity', 'visibility']),
      transform: 'translate(-50%, -50%)',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  clearBtn: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    padding: 0,
    color: theme.palette.customColors.white,
    opacity: 0,
    visibility: 'hidden',
    transition: theme.transitions.create(['opacity', 'visibility']),
    zIndex: 2,
    '& > i': {
      transform: 'rotate(-45deg)',
    },
  },
}));
