import { Controller } from 'react-hook-form';

import { IQOutlinedTextFieldWithControllerProps } from 'interfaces/CommonComponentsInterfaces';

import { QOutlinedTextField } from './QOutlinedTextField';

export const QOutlinedTextFieldWithController: React.FC<IQOutlinedTextFieldWithControllerProps> = props => {
  const { errors, name, hookFormProps, ...rest } = props;

  return (
    <Controller
      name={name}
      control={hookFormProps.control}
      render={({ field, formState }) => (
        <QOutlinedTextField
          errors={errors?.length ? errors : (formState.errors[name]?.message as string)}
          {...rest}
          {...field}
          onBlur={rest.onBlur ? rest.onBlur : field.onBlur}
        />
      )}
    />
  );
};
