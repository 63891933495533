import { Avatar } from '@mui/material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { makeStyles } from 'tss-react/mui';

import { TextButton } from './Buttons/TextButton';
import { Loader } from './Loader';

interface IUploadLogoProps {
  img?: string | null;
  loading?: boolean;
  iconSize?: number;
  iconClassName?: string;
  disabled?: boolean;
  radiusValue?: number;
  size?: number;
  multiple?: boolean;
  clearIcon?: boolean;
  onHandleClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDrop: DropzoneOptions['onDrop'];
}

export const UploadLogo: React.FC<IUploadLogoProps> = props => {
  const { iconClassName, loading, img, disabled = false, multiple = false, clearIcon, onHandleClear, onDrop } = props;
  const { classes, cx } = useStyles(props);

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple,
    noClick: true,
    noKeyboard: true,
  });

  const renderClearStack = () => {
    return (
      <TextButton className={cx(classes.button, { active: isDragActive })} disabled={disabled} onClick={onHandleClear}>
        {loading && <Loader isAbsolute />}
        {img && (
          <div className={classes.imgWrap}>
            <Avatar className={classes.img} src={img} alt="" />
          </div>
        )}
        {!img && (
          <div className={cx(classes.icon, classes.iconWrap)}>
            <i className={iconClassName ?? 'custom-icon-add-photo'} />
          </div>
        )}
        <div className={cx(classes.icon, classes.hoverIconWrap)}>
          <i className="custom-icon-add" />
        </div>
      </TextButton>
    );
  };

  const renderUploadStack = () => {
    return (
      <TextButton
        {...getRootProps()}
        onClick={open}
        className={cx(classes.button, { active: isDragActive })}
        disabled={disabled}
      >
        {loading && <Loader isAbsolute />}
        {img && (
          <>
            <div className={classes.imgWrap}>
              <Avatar className={classes.img} src={img} alt="" />
            </div>
            <div className={cx(classes.icon, classes.hoverIconWrap)}>
              <i className="custom-icon-add" />
            </div>
          </>
        )}
        {!img && (
          <>
            <div className={cx(classes.icon, classes.iconWrap)}>
              <i className={iconClassName ?? 'custom-icon-user'} />
            </div>
            <div className={cx(classes.icon, classes.hoverIconWrap)}>
              <i className="custom-icon-camera-plus" />
            </div>
          </>
        )}
      </TextButton>
    );
  };

  // Renders
  return (
    <>
      {!disabled && (
        <input
          type="file"
          name="fileInput"
          className={classes.fileInput}
          onChange={open}
          {...getInputProps()}
          disabled={disabled}
        />
      )}

      {clearIcon ? renderClearStack() : renderUploadStack()}
    </>
  );
};

const useStyles = makeStyles<IUploadLogoProps, 'hoverIconWrap' | 'iconWrap' | 'imgWrap'>()((theme, props, clasess) => {
  const { size, radiusValue, iconSize } = props;

  return {
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: size ? size : 80,
      height: size ? size : 80,
      backgroundColor: theme.palette.customColors.gray,
      borderRadius: radiusValue ? radiusValue : '50%',
      overflow: 'hidden',
      '&:hover:not(.active)': {
        backgroundColor: theme.palette.customColors.gray,
        [`& .${clasess.hoverIconWrap}`]: {
          opacity: 1,
        },
        [`& .${clasess.iconWrap}`]: {
          opacity: 0,
        },
        [`& .${clasess.imgWrap}`]: {
          '&:after': {
            opacity: 1,
          },
        },
      },
      '&.active': {
        [`& .${clasess.hoverIconWrap}`]: {
          opacity: '1 !important',
        },
        [`& .${clasess.iconWrap}`]: {
          opacity: '0 !important',
        },
      },
    },
    icon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      transition: theme.transitions.create('opacity', { duration: 150 }),
    },
    hoverIconWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: iconSize ?? 32.5,
      height: iconSize ?? 32.5,
      color: theme.palette.customColors.white,
      '& > .custom-icon-add': {
        transform: 'rotate(45deg)',
      },
      '& > i': {
        fontSize: iconSize ?? 32.5,
      },
    },
    iconWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.customColors.white,
      width: iconSize ?? 36,
      height: iconSize ?? 36,
      opacity: 1,
      '& > i': {
        fontSize: iconSize ?? 36,
      },
    },
    fileInput: {
      display: 'none',
    },
    imgWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.3)',
        opacity: 0,
        transition: theme.transitions.create('opacity', { duration: 150 }),
      },
    },
  };
});
