import { makeAutoObservable, makeObservable, observable, runInAction } from 'mobx';

import { Notification } from 'base/ui/Notification';
import { authNotificationMessages } from 'messages/auth';
import { usersNotificationMessages } from 'messages/user';

import { UsersService } from './UsersService';
import { HelpMailForm } from './forms/HelpMailForm';
import { ProfileChangePasswordForm } from './forms/ProfileChangePasswordForm';
import { ProfileForm } from './forms/ProfileForm';
import { UserModel } from './models/UserModel';

export class UsersStore {
  loading: boolean = false;
  userFindLoading: boolean = false;
  editUserLoading: boolean = false;
  sendVerifyLoading: boolean = false;
  changeThePasswordWithTheCurrentLoading: boolean = false;
  changeUserStatusLoading: boolean = false;
  sendMailLoading: boolean = false;

  userInfo: UserModel | null = null;
  findedUser: UserModel | null = null;

  private usersService: UsersService;

  constructor() {
    makeAutoObservable(this);
    this.usersService = new UsersService();
  }

  getCurrentUserInfo = () => {
    this.setLoading(true);

    this.usersService
      .getCurrentUserInfo()
      .then(userInfo => {
        runInAction(() => {
          this.userInfo = makeObservable(userInfo, {
            active: observable,
            email_verified_at: observable,
          });
          this.userInfo = userInfo;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  editUser = (form: ProfileForm) => {
    this.setEditUserLoading(true);

    this.usersService
      .editUser(form)
      .then(userInfo => {
        runInAction(() => {
          if (userInfo) {
            this.userInfo = this.getUserModel({
              ...userInfo,
              active: !!this.userInfo?.active,
              // is_verified: !!this.userInfo?.is_verified,
            });
          }
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setEditUserLoading(false);
      });
  };

  sendVerify = (onHandleSuccess?: () => void): void => {
    this.setSendVerifyLoading(true);

    this.usersService
      .sendVerify()
      .then(() => {
        onHandleSuccess?.();
      })
      .catch((error: any) => {})
      .finally(() => this.setSendVerifyLoading(false));
  };

  changeThePasswordWithTheCurrent = (form: ProfileChangePasswordForm, onHandleSuccess?: () => void) => {
    this.setChangeThePasswordWithTheCurrentLoading(true);

    this.usersService
      .changeThePasswordWithTheCurrent(form)
      .then(() => {
        Notification.showSuccess(authNotificationMessages.changeThePasswordWithTheCurrent.success);
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setChangeThePasswordWithTheCurrentLoading(false);
      });
  };

  changeUserStatus = (onHandleSuccess?: () => void) => {
    this.setChangeUserStatusLoading(true);

    this.usersService
      .changeUserStatus()
      .then(userModel => {
        if (this.userInfo) {
          this.userInfo.active = userModel.active;
        }

        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setChangeUserStatusLoading(false);
      });
  };

  sendHelpMail = (form: HelpMailForm, onHandleSuccess?: () => void) => {
    this.setSendMailLoading(true);

    this.usersService
      .sendHelpMail(form)
      .then(() => {
        Notification.showSuccess(usersNotificationMessages.helpMail.success);
        onHandleSuccess?.();
      })
      .catch(() => {
        Notification.showSuccess(usersNotificationMessages.helpMail.error);
      })
      .finally(() => {
        this.setSendMailLoading(false);
      });
  };

  // Getters
  getProfileBaseForm = (): ProfileForm | null => {
    return this.usersService.getProfileBaseForm(this.userInfo);
  };

  getUserModel = (json: any): UserModel => {
    return this.usersService.getUserModel(json);
  };

  // Loadings
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setUserFindLoading = (value: boolean) => {
    this.userFindLoading = value;
  };

  setEditUserLoading = (value: boolean) => {
    this.editUserLoading = value;
  };

  setSendVerifyLoading = (value: boolean): void => {
    this.sendVerifyLoading = value;
  };

  setChangeThePasswordWithTheCurrentLoading = (value: boolean): void => {
    this.changeThePasswordWithTheCurrentLoading = value;
  };

  setChangeUserStatusLoading = (value: boolean): void => {
    this.changeUserStatusLoading = value;
  };

  setSendMailLoading = (value: boolean): void => {
    this.sendMailLoading = value;
  };
}
