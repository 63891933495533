import { IPollTableField } from 'modules/polls/interfaces/PollsInterfaces';
import { PollsTableColumns } from 'modules/polls/types/PollsTypes';

export const pollsTableFields: IPollTableField[] = [
  { column: PollsTableColumns.name, title: 'Опрос', isOrderable: true, settings: { align: 'left' } },
  { column: PollsTableColumns.created_at, title: 'Создан', isOrderable: true, settings: { align: 'center' } },
  { column: PollsTableColumns.questions, title: 'Вопросы', isOrderable: true, settings: { align: 'center' } },
  { column: PollsTableColumns.status, title: 'Статус', isOrderable: true, settings: { align: 'center' } },
  { column: PollsTableColumns.completed, title: 'Завершено', isOrderable: false, settings: { align: 'center' } },
  { column: PollsTableColumns.passability, title: '% Проходимости', isOrderable: true, settings: { align: 'center' } },
];
