import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BoxProps, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { PasswordTextField } from 'components/UI/TextFields/PasswordTextField';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { LoginFormFields } from 'modules/auth/forms/auth/LoginForm';
import { RegisterForm, RegisterFormFields } from 'modules/auth/forms/auth/RegisterForm';
import { registerFormScheme } from 'modules/auth/schemes/registerFormScheme';
import { authRoutes } from 'routes/routes';

import { VerifyEmailModal } from '../../../../components/Common/VerifyEmailModal';

interface IRegisterFormComponentProps extends BoxProps {}

export const RegisterFormComponent: React.FC<IRegisterFormComponentProps> = observer(props => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const registerForm = useForm<RegisterForm>({
    mode: 'all',
    resolver: yupResolver(registerFormScheme),
    defaultValues: RegisterForm.create(),
  });

  // Handlers
  const handleSubmit = (form: RegisterForm) => {
    authStore.register(form, () => {
      handleOpenModal();
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    handleResetFormAndNavigate();
  };

  const handleResetFormAndNavigate = () => {
    if (authStore.accountModel) {
      navigate(authRoutes.LoginScreen.path, {
        state: { form: RegisterForm.create(registerForm.getValues()), accountModel: authStore.accountModel },
      });
    }

    registerForm.reset(RegisterForm.create());
  };

  // Renders
  return (
    <Box sx={{ mx: 'auto', maxWidth: '315px' }}>
      <Box component="form" {...props} onSubmit={registerForm.handleSubmit(handleSubmit)}>
        <Box sx={{ mb: 1.5 }}>
          <QOutlinedTextFieldWithController
            label="ФИО"
            placeholder="Введите ФИО"
            name={RegisterFormFields.fullname}
            hookFormProps={{ control: registerForm.control }}
          />
        </Box>
        <Box sx={{ mb: 1.5 }}>
          <QOutlinedTextFieldWithController
            label="Электронная почта"
            placeholder="Введите электронную почту"
            name={RegisterFormFields.email}
            hookFormProps={{ control: registerForm.control }}
          />
        </Box>
        <Box sx={{ mb: 2.5 }}>
          <PasswordTextField
            name={LoginFormFields.password}
            label="Пароль"
            placeholder="Придумайте пароль"
            autoComplete="current-password"
            errors={authStore.errorMessages?.password}
            hookFormProps={{ control: registerForm.control }}
          />
        </Box>
        <Button fullWidth type="submit" variant="contained" color="primary">
          Создать аккаунт
        </Button>
      </Box>
      <VerifyEmailModal open={openModal} onHandleClose={handleCloseModal} />
    </Box>
  );
});
