import { MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { QIconButton } from 'components/UI/Buttons/QIconButton';
import { QMenu } from 'components/UI/QMenu/QMenu';
import { IQMenuProps } from 'interfaces/CommonComponentsInterfaces';
import { pollItemActionMenuList } from 'lists/polls/pollItemActionMenuList';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { PollModel } from 'modules/polls/models/PollModel';
import {
  PollActionMenuTypes,
  PollRouteActionTypes,
  PollTemplateTypes,
  PollTypes,
} from 'modules/polls/types/PollsTypes';
import { pollsRoutes, routes } from 'routes/routes';

interface IPollActionMenuProps {
  pollInfo: PollModel | null;
  menuProps?: Partial<IQMenuProps>;
}

export const PollActionMenu: React.FC<IPollActionMenuProps> = observer(props => {
  const { pollInfo, menuProps } = props;
  const { pollsStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pollsList = pollsStore.getPollActionTypesList(pollItemActionMenuList);

  // Handlers
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
    pollsStore.setPollLocalInfo(pollInfo);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChooseActionType = (actionType: PollActionMenuTypes) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    const pollId: number = pollsStore.pollLocalInfo?.id ?? 0;
    const path = RouteHelper.makePath(pollsRoutes.PollConstructorScreen.path, {
      action: PollRouteActionTypes.edit,
      templateId: PollTemplateTypes.textPoll,
      type: pollInfo?.template ?? PollTypes.default,
      pollId,
    });

    switch (actionType) {
      case PollActionMenuTypes.startPoll:
        pollsStore.setPollStatusModalOpen(true);
        break;
      case PollActionMenuTypes.stopPoll:
        pollsStore.setPollStatusModalOpen(true);
        break;
      case PollActionMenuTypes.edit:
        navigate(path);
        break;
      case PollActionMenuTypes.report:
        navigate(RouteHelper.makePath(routes.ReportDetailScreen.path, { reportId: pollId }));
        break;
      case PollActionMenuTypes.duplicate:
        handleDuplicate();
        break;
      case PollActionMenuTypes.rename:
        console.log('rename');
        break;
      case PollActionMenuTypes.delete:
        pollsStore.setDeletePollModalOpen(true);
        break;
    }

    handleCloseMenu();
  };

  const handleDuplicate = () => {
    if (pollsStore.pollLocalInfo?.id) {
      pollsStore.replicatePollById(pollsStore.pollLocalInfo.id, () => {
        pollsStore.getOrganizationPolls();
      });
    }
  };

  // Renders
  return (
    <>
      <QIconButton size="small" color="inherit" onClick={handleOpenMenu}>
        <i className="custom-icon-more" />
      </QIconButton>
      <QMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseMenu}
        variant="menu"
        className={classes.menu}
        {...menuProps}
      >
        {pollsList.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
            onClick={handleChooseActionType(item.id)}
            disabled={pollsStore.getPollActionTypesListState(item, roleMatrix)}
          >
            {item.name}
          </MenuItem>
        ))}
      </QMenu>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  menu: {
    minWidth: 115,
  },
}));
