import { BaseForm } from 'base/BaseForm';
import { ILocalFileWithFileUrl } from 'modules/file/interfaces/FileInterfaces';

export enum PollConstructorBlockImageFieldFormFields {
  text = 'text',
  image = 'image',
}

export class PollConstructorBlockImageFieldForm extends BaseForm {
  text: string = '';
  image: ILocalFileWithFileUrl | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
