import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { customColors } from 'styles/colors';

import { AuthNavigation } from './components/AuthNavigation';
import { LoginWrapper } from './components/LoginWrapper';
import { ForgotPasswordFormComponent } from './components/forgotPassword/ForgotPasswordFormComponent';

interface IForgotPasswordScreenProps {}

export const ForgotPasswordScreen: React.FC<IForgotPasswordScreenProps> = observer(() => {
  const { authStore } = useRootStore();

  return (
    <LoginWrapper>
      {authStore.loading && <Loader isAbsolute minHeight="100vh" />}
      <AuthNavigation sx={{ mb: 2.5 }} />
      <Box sx={{ mb: 4.25 }}>
        <Typography textAlign="center" variant="h3Regular" color={customColors.black}>
          Пожалуйста, заполните ниже данные
        </Typography>
      </Box>

      <ForgotPasswordFormComponent />
    </LoginWrapper>
  );
});
