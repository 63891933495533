import { BaseForm } from 'base/BaseForm';

import { PollConstructorBlockFieldsTypes } from '../types/PollsTypes';
import { PollBlockSettingsForm } from './PollBlockSettingsForm';
import { PollConstructorBlockFieldForm } from './PollConstructorBlockFieldForm';
import { PollConstructorBlockImageFieldForm } from './PollConstructorBlockImageFieldForm';

export enum PollConstructorBlockFormFields {
  title = 'title',
  settings = 'settings',
  fields = 'fields',
  images = 'images',
}

export class PollConstructorBlockForm extends BaseForm {
  title: string = '';
  settings: PollBlockSettingsForm | null = PollBlockSettingsForm.create();
  order: number = 0;
  fields: PollConstructorBlockFieldForm[] = [
    PollConstructorBlockFieldForm.create({ type: PollConstructorBlockFieldsTypes.checkbox }),
    PollConstructorBlockFieldForm.create({ type: PollConstructorBlockFieldsTypes.checkbox }),
    PollConstructorBlockFieldForm.create({ type: PollConstructorBlockFieldsTypes.checkbox }),
    PollConstructorBlockFieldForm.create({ type: PollConstructorBlockFieldsTypes.checkbox }),
  ];
  images: PollConstructorBlockImageFieldForm[] = [
    PollConstructorBlockImageFieldForm.create(),
    PollConstructorBlockImageFieldForm.create(),
    PollConstructorBlockImageFieldForm.create(),
    PollConstructorBlockImageFieldForm.create(),
    PollConstructorBlockImageFieldForm.create(),
    PollConstructorBlockImageFieldForm.create(),
  ];

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
