import { Box, Divider, Button, Theme, buttonClasses, BoxProps, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';

import { authRoutes } from 'routes/routes';
import { useCommonStyles } from 'styles/commonStyles';

interface IAuthNavigationProps extends BoxProps {}

export const AuthNavigation: React.FC<IAuthNavigationProps> = props => {
  const { commonClasses, cx } = useCommonStyles();

  // Renders
  return (
    <Box {...props}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: 14, mb: 2.5 }}>
        <NavLink
          className={({ isActive }) => cx('router-link', { [commonClasses.navLinkActive]: isActive })}
          to={authRoutes.LoginScreen.path}
        >
          <StyledButton variant="text">
            <Typography component="span" variant="h2Bold">
              Вход
            </Typography>
          </StyledButton>
        </NavLink>
        <NavLink
          className={({ isActive }) => cx('router-link', { [commonClasses.navLinkActive]: isActive })}
          to={authRoutes.RegisterScreen.path}
        >
          <StyledButton variant="text">
            <Typography component="span" variant="h2Bold">
              Регистрация
            </Typography>
          </StyledButton>
        </NavLink>
      </Box>
      <Divider />
    </Box>
  );
};

const StyledButton = withStyles(Button, (theme: Theme) => ({
  root: {
    [`&.${buttonClasses.text}`]: {
      padding: 0,
      width: 'auto',
      minWidth: '100%',
      color: theme.palette.customColors.purple,
    },
  },
}));
