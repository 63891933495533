import { alpha } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QLinearProgress } from 'components/UI/QLinearProgress';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { IPollCreateAndEditScreensParams } from 'modules/polls/interfaces/PollsInterfaces';
import { PollModel } from 'modules/polls/models/PollModel';
import { useGetPollCreateAndEditParams } from 'modulesHooks/polls/useGetPollCreateAndEditParams';
import { routes } from 'routes/routes';

import { PollPreviewTopLine } from './PollPreviewTopLine';
import { PollPreviewDefaultViewType } from './PollPreviewViewTypes/PollDefaultViewType/PollPreviewDefaultViewType';

interface IPollPreviewContentProps {}

export const PollPreviewContent: React.FC<IPollPreviewContentProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();

  const navigate = useNavigate();

  const { reset } = useFormContext<PollCreateAndEditForm>();
  const params = useParams<IPollCreateAndEditScreensParams>();
  const pollCreateAndEditParams = useGetPollCreateAndEditParams();

  // Effects
  useEffect(() => {
    if (
      PollsHelper.isPollEditScreen(params) &&
      pollCreateAndEditParams.pollId?.length &&
      !pollsStore.currentGettedPollInfo &&
      organizationsStore.selectedOrganization?.id
    ) {
      pollsStore.getPollById(
        pollCreateAndEditParams.pollId,
        organizationsStore.selectedOrganization.id,
        handleSuccessGetPoll,
        handleErrorGetPoll,
      );
    }
  }, [pollsStore.currentGettedPollInfo]);

  // Handlers
  const handleSuccessGetPoll = (pollModel: PollModel) => {
    const form = pollsStore.getPollCreateAndEditForm(pollModel);

    reset(form);
  };

  const handleErrorGetPoll = () => {
    navigate(routes.PollTemplatesScreen.path);
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.innerWrap}>
        <QLinearProgress wrapProps={{ sx: { mb: 2 } }} color="secondary" value={0} withLabel />
        <PollPreviewTopLine sx={{ mb: 3 }} />

        <PollPreviewDefaultViewType />
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    padding: theme.spacing(0, 3, 3),
  },
  innerWrap: {
    background: alpha(theme.palette.customColors.black, 0.8),
    borderRadius: 10,
    minHeight: 'calc(100vh - 109px)',
    padding: theme.spacing(2, 2),
  },
}));
