import { BaseForm } from 'base/BaseForm';

export enum ForgotPasswordFormFields {
  email = 'email',
}

export class ForgotPasswordForm extends BaseForm {
  email: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
