import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { OrganizationRoles } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';
import { customColors } from 'styles/colors';

interface IOwnerTransferConfirmModalProps extends IQModalProps {
  currentMember: UserModel;
  currentRole: OrganizationRoles | null;
  onHandleCloseModal: () => void;
  onHandleCancel: () => void;
}

export const OwnerTransferConfirmModal: React.FC<IOwnerTransferConfirmModalProps> = observer(props => {
  const { currentMember, currentRole, onHandleCancel, onHandleCloseModal, ...rest } = props;
  const { organizationsStore } = useRootStore();
  const currentRoleId = organizationsStore.rolesHashMap?.[currentRole!]?.id ?? 0;

  // Handlers
  const handleTransferOwner = () => {
    if (organizationsStore.selectedOrganization?.id && currentMember.id && currentRole) {
      organizationsStore.transferOwner(
        organizationsStore.selectedOrganization.id,
        currentMember.id,
        currentRoleId,
        () => {
          handleCloseModal();
          organizationsStore.getOrganizationMembers();
          organizationsStore.getMembershipOrganizations('OwnerTransferConfirmModal');
        },
      );
    }
  };

  const handleCloseModal = () => {
    onHandleCloseModal();
  };

  // Renders
  return (
    <QModal {...rest} onClose={handleCloseModal} modalMaxWidthValue={620}>
      {organizationsStore.transferOwnerLoading && <Loader isAbsolute />}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Вы уверены что хотите передать роль «Администратор» другому пользователю?
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Внимание! После передачи прав Вы утратите возможность управлять организацией.
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="error" sx={{ mb: 2 }} onClick={handleTransferOwner}>
            Да, уверен
          </Button>
          <Button fullWidth variant="outlined" color="primary" sx={{ mb: 2 }} onClick={onHandleCancel}>
            Нет
          </Button>
        </Box>
      </Box>
    </QModal>
  );
});
