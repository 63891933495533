import { iconButtonClasses, outlinedInputClasses } from '@mui/material';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { StylesHelper } from 'helpers/StylesHelper';
import { PollSearchForm, PollSearchFormFields } from 'modules/polls/forms/PollSearchForm';
import { PollsListFormFields } from 'modules/polls/forms/PollsListForm/PollsListForm';

interface ISearchFormProps {}

export const SearchForm: React.FC<ISearchFormProps> = observer(() => {
  const { pollsStore } = useRootStore();
  const { classes } = useStyles();

  const searchForm = useForm<PollSearchForm>({
    mode: 'onChange',
    defaultValues: PollSearchForm.create(),
  });

  // Effects
  useEffect(() => {
    const subscription = searchForm.watch(handleWatchSearchForm);
    return () => subscription.unsubscribe();
  }, []);

  // Handlers
  const handleInputChange = useCallback(
    debounce((value: string) => {
      handleResetPagination();
      pollsStore.pollsListFormSetValueAndGetList(PollsListFormFields.search, value);
    }, 1000),
    [],
  );

  const handleResetPagination = () => {
    pollsStore.pollsListForm.setValues({ offset: 0, page: 1 });
  };

  const handleWatchSearchForm = (value: any, { name }: any) => {
    switch (name) {
      case PollSearchFormFields.search:
        handleInputChange(value.search);
        break;
    }
  };

  const handleSubmit = (data: PollSearchForm) => {
    handleResetPagination();
    pollsStore.pollsListFormSetValueAndGetList(PollsListFormFields.search, data.search);
    handleInputChange.cancel();
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    searchForm.handleSubmit(handleSubmit)(e);
  };

  // Renders
  return (
    <>
      <form name="search-form" onSubmit={handleSubmitForm} autoComplete="off">
        <QOutlinedTextFieldWithController
          fullWidth
          name={PollSearchFormFields.search}
          className={classes.searchTextField}
          startIconProps={{ type: 'submit', iconClassName: 'custom-icon-search' }}
          placeholder="Введите имя пользователя или название опроса"
          hookFormProps={{ control: searchForm.control }}
          autoComplete="new-password"
        />
      </form>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  searchTextField: {
    [`& .${outlinedInputClasses.input}`]: {
      padding: StylesHelper.importantStyle(theme.spacing('12.5px', 2, '12.5px', 0)),
    },
    [`& .${iconButtonClasses.root}`]: {
      padding: StylesHelper.importantStyle(theme.spacing(2.125, 2)),
      fontSize: 14,
    },
  },
}));
