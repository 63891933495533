import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { PollTypes } from 'modules/polls/types/PollsTypes';

import { PollDefaultType } from './Types/PollDefaultType';
import { PollDoubleElimination } from './Types/PollDoubleElimination';
import { PollOneToOne } from './Types/PollOneToOne';
import { PollSingleElimination } from './Types/PollSingleElimination';

interface IPollsTypesContentProps {}

export const PollsTypesContent: React.FC<IPollsTypesContentProps> = () => {
  const params = useParams<{ type?: PollTypes; pollId?: string }>();

  const PollsTypesContent: Record<PollTypes, ReactNode> = useMemo(
    () => ({
      default: <PollDefaultType />,
      double_elimination: <PollDoubleElimination />,
      one_to_one: <PollOneToOne />,
      single_elimination: <PollSingleElimination />,
    }),
    [],
  );

  return <>{params?.type ? PollsTypesContent?.[params.type] ?? null : null}</>;
};
