import { PASSWORD_REGEX } from 'consts/globalConsts';
import * as yup from 'yup';

import { RegisterFormFields } from '../forms/auth/RegisterForm';

export const registerFormScheme = yup.object({
  [RegisterFormFields.fullname]: yup
    .string()
    .required('Пожалуйста, введите ФИО')
    .min(2, 'ФИО должно содержать как минимум 2 символа'),
  [RegisterFormFields.email]: yup
    .string()
    .email('Электронная почта указана неверно')
    .required('Пожалуйста, введите электронную почту'),
  [RegisterFormFields.password]: yup
    .string()
    .required('Пожалуйста, введите пароль')
    .matches(PASSWORD_REGEX, 'Пароль должен содержать 6 символов'),
});
