import { Serializable, jsonProperty } from 'ts-serializable';

import { PollQuestionAnswerDto } from './PollQuestionAnswerDto';

export class PollQuestionDto extends Serializable {
  @jsonProperty(String, null) title: string | null = null;
  @jsonProperty(Number, null) order: number | null = null;
  @jsonProperty(Boolean, undefined) is_media?: boolean;
  @jsonProperty(Boolean, undefined) has_many_answers?: boolean;
  @jsonProperty([PollQuestionAnswerDto], null) answers: PollQuestionAnswerDto[] | null = null;
}
