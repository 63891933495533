import { makeAutoObservable, runInAction, set } from 'mobx';

import { FormFieldSetter, LoadingState } from 'base/types/BaseTypes';
import { Notification } from 'base/ui/Notification';
import { organizationNotificationMessages } from 'messages/organization';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';
import { UserInOrganizationModel } from 'modules/users/models/UserInOrganizationModel';
import { UserModel } from 'modules/users/models/UserModel';

import { OrganizationsService } from './OrganizationsService';
import { InviteUserInOrganizationForm } from './forms/InviteUserInOrganizationForm';
import { MembersListForm, MembersListFormFields } from './forms/MembersListForm';
import { OrganizationCreateForm } from './forms/OrganizationCreateForm';
import { OrganizationModel } from './models/OrganizationModel';
import { RoleModel } from './models/RoleModel';
import { OrganizationCreateModalContextKeys } from './types/OrganizationTypes';

export class OrganizationsStore {
  loading: boolean = false;
  ownershipOrganizationsLoading: boolean = false;
  membershipOrganizationsLoading: boolean = false;
  organizationCreateLoading: boolean = false;
  organizationMembersListLoading: boolean = false;
  organizationMembersListLoadingState: LoadingState = LoadingState.notLoaded;
  transferOwnerLoading: boolean = false;
  kickMemberLoading: boolean = false;
  inviteUserInOrganizationLoading: boolean = false;
  joinOrganizationByLinkLoading: boolean = false;
  rolesListLoading: boolean = false;
  currentUserInOrganizationRoleLoading: boolean = false;
  generateInviteLinkLoading: boolean = false;

  organizationCreateModal: Partial<Record<OrganizationCreateModalContextKeys, boolean>> = {};
  transferOwnerModal: Partial<Record<string, boolean>> = {};
  memberKickModal: Partial<Record<string, boolean>> = {};

  ownershipOrganizations: OrganizationModel[] | null = null;

  membershipOrganizations: OrganizationModel[] | null = null;
  selectedOrganization: OrganizationModel | null = null;
  createdOrganization: OrganizationModel | null = null;

  rolesList: RoleModel[] | null = null;

  membersListForm: MembersListForm = MembersListForm.createFormObservable();
  organizationMembersList: UserModel[] | null = null;
  currentMember: UserModel | null = null;
  currentUserInOrganizationRole: UserInOrganizationModel | null = null;

  membersInvitationModal: boolean = false;

  private organizationsService: OrganizationsService;

  constructor() {
    makeAutoObservable(this);
    this.organizationsService = new OrganizationsService();
  }

  get rolesHashMap() {
    return this.organizationsService.getRolesHashMap(this.rolesList);
  }

  getOwnershipOrganizations = (onHandleSuccess?: (organizations: OrganizationModel[]) => void) => {
    this.setOwnershipOrganizationsLoading(true);

    this.organizationsService
      .getOwnershipOrganizations()
      .then(organizations => {
        runInAction(() => {
          this.ownershipOrganizations = organizations;
          onHandleSuccess?.(organizations);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setOwnershipOrganizationsLoading(false);
      });
  };

  getMembershipOrganizations = (page: string, onHandleSuccess?: (organizations: OrganizationModel[]) => void) => {
    this.setMembershipOrganizationsLoading(true);
    console.log(page);

    this.organizationsService
      .getMembershipOrganizations()
      .then(organizations => {
        runInAction(() => {
          if (organizations.length) {
            this.membershipOrganizations = organizations;
            onHandleSuccess?.(organizations);
          }
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setMembershipOrganizationsLoading(false);
      });
  };

  createOrganization = (form: OrganizationCreateForm, onHandleSuccess?: () => void) => {
    this.setOrganizationCreateLoading(true);

    this.organizationsService
      .createOrganization(form)
      .then(organizationModel => {
        runInAction(() => {
          if (this.membershipOrganizations?.length) {
            this.membershipOrganizations.push(organizationModel);
          } else {
            this.membershipOrganizations = [organizationModel];
          }

          Notification.showSuccess(organizationNotificationMessages.createOrganization.success);
          onHandleSuccess?.();
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setOrganizationCreateLoading(false);
      });
  };

  getOrganizationMembers = () => {
    this.setOrganizationMembersListLoading(true);
    this.setOrganizationMembersListLoadingState(LoadingState.loading);

    this.organizationsService
      .getOrganizationMembers(this.membersListForm)
      .then(({ items, paginationMeta }) => {
        runInAction(() => {
          this.organizationMembersList = items;
          this.membersListForm.setValues(paginationMeta);
          this.setOrganizationMembersListLoadingState(LoadingState.loadingFinished);
        });
      })
      .catch(() => {
        this.setOrganizationMembersListLoadingState(LoadingState.error);
      })
      .finally(() => {
        this.setOrganizationMembersListLoading(false);
      });
  };

  kickMember = (organizationId: number, userId: number, onHandleSuccess?: () => void) => {
    this.setKickMemberLoading(true);

    this.organizationsService
      .kickMember(organizationId, userId)
      .then(() => {
        runInAction(() => {
          onHandleSuccess?.();
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setKickMemberLoading(false);
      });
  };

  joinOrganizationByLink = (inviteLink: string, onHandleSuccess?: () => void, onHandleError?: () => void) => {
    this.setJoinOrganizationByLinkLoading(true);

    this.organizationsService
      .joinOrganizationByLink(inviteLink)
      .then(() => {
        Notification.showSuccess(organizationNotificationMessages.joinOrganizationByLink.success);
        onHandleSuccess?.();
      })
      .catch(() => {
        onHandleError?.();
      })
      .finally(() => {
        this.setJoinOrganizationByLinkLoading(false);
      });
  };

  inviteUserInOrganization = (form: InviteUserInOrganizationForm, onHandleSuccess?: () => void) => {
    this.setInviteUserInOrganizationLoading(true);

    this.organizationsService
      .inviteUserInOrganization(form)
      .then(() => {
        Notification.showSuccess(organizationNotificationMessages.inviteUserInOrganization.success);
        onHandleSuccess?.();
      })
      .catch(() => {
        Notification.showError(organizationNotificationMessages.inviteUserInOrganization.error);
      })
      .finally(() => {
        this.setInviteUserInOrganizationLoading(false);
      });
  };

  generateInviteLink = (organizationId: number, onHandleSuccess?: (url: string) => void) => {
    this.setGenerateInviteLinkLoading(true);

    this.organizationsService
      .generateInviteLink(organizationId)
      .then(url => {
        onHandleSuccess?.(url);
      })
      .catch(() => {})
      .finally(() => {
        this.setGenerateInviteLinkLoading(false);
      });
  };

  getOrganizationRolesList = () => {
    this.setRolesListLoading(true);

    this.organizationsService
      .getOrganizationRolesList()
      .then(rolesList => {
        runInAction(() => {
          this.rolesList = rolesList;
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setRolesListLoading(false);
      });
  };

  changeRole = (organizationId: number, userId: number, roleId: number, onHandleSuccess?: () => void) => {
    this.setRolesListLoading(true);

    this.organizationsService
      .changeRole(organizationId, userId, roleId)
      .then(() => {
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setRolesListLoading(false);
      });
  };

  transferOwner = (organizationId: number, userId: number, roleId: number, onHandleSuccess?: () => void) => {
    this.setTransferOwnerLoading(true);

    this.organizationsService
      .changeRole(organizationId, userId, roleId)
      .then(() => {
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setTransferOwnerLoading(false);
      });
  };

  getCurrentUserInOrganizationRole = (organizationId: number, onHandleSuccess?: () => void) => {
    this.setCurrentUserInOrganizationRoleLoading(true);

    this.organizationsService
      .getCurrentUserInOrganizationRole(organizationId)
      .then(currentUserInOrganization => {
        runInAction(() => {
          this.currentUserInOrganizationRole = currentUserInOrganization;
          onHandleSuccess?.();
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setCurrentUserInOrganizationRoleLoading(false);
      });
  };

  membersListFormSetValueAndGetList: FormFieldSetter<
    `${MembersListFormFields}` | `${TableFormFields}`,
    MembersListForm
  > = (key, value) => {
    this.membersListForm.setValue(key, value);
    this.getOrganizationMembers();
  };

  membersListFormSetSortValueAndGetList = (currentOrderBy: string, withClear?: boolean) => {
    const sortValue = this.organizationsService.getSortValue(currentOrderBy, this.membersListForm.sortValue, withClear);
    this.membersListFormSetValueAndGetList(TableFormFields.sortValue, sortValue);
  };

  // Getters
  getLocalJoinLink = () => {
    return this.organizationsService.getLocalJoinLink();
  };

  saveJoinLinkInLocal = (inviteLink: string) => {
    Notification.showSuccess(organizationNotificationMessages.saveJoinLink.success);
    this.organizationsService.saveJoinLinkInLocal(inviteLink);
  };

  removeLocalJoinLink = () => {
    this.organizationsService.removeLocalJoinLink();
  };

  // Setters
  setSelectedOrganization = (value: OrganizationModel | null) => {
    this.selectedOrganization = value;
  };

  setCurrentMember = (currentMember: UserModel | null) => {
    this.currentMember = currentMember;
  };

  setOrganizationCreateModal = (key: OrganizationCreateModalContextKeys, value: boolean) => {
    set(this.organizationCreateModal, key, value);
  };

  setTransferOwnerModal = (key: number, value: boolean) => {
    set(this.transferOwnerModal, key, value);
  };

  setMemberKickModal = (key: number, value: boolean) => {
    this.memberKickModal = { ...this.memberKickModal, [key]: value };
  };

  setMembersInvitationModal = (value: boolean) => {
    this.membersInvitationModal = value;
  };

  // Loadings
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setOwnershipOrganizationsLoading = (value: boolean) => {
    this.ownershipOrganizationsLoading = value;
  };

  setMembershipOrganizationsLoading = (value: boolean) => {
    this.membershipOrganizationsLoading = value;
  };

  setOrganizationCreateLoading = (value: boolean) => {
    this.organizationCreateLoading = value;
  };

  setOrganizationMembersListLoading = (value: boolean) => {
    this.organizationMembersListLoading = value;
  };

  setOrganizationMembersListLoadingState = (value: LoadingState) => {
    this.organizationMembersListLoadingState = value;
  };

  setTransferOwnerLoading = (value: boolean) => {
    this.transferOwnerLoading = value;
  };

  setKickMemberLoading = (value: boolean) => {
    this.kickMemberLoading = value;
  };

  setInviteUserInOrganizationLoading = (value: boolean) => {
    this.inviteUserInOrganizationLoading = value;
  };

  setJoinOrganizationByLinkLoading = (value: boolean) => {
    this.joinOrganizationByLinkLoading = value;
  };

  setRolesListLoading = (value: boolean) => {
    this.rolesListLoading = value;
  };

  setCurrentUserInOrganizationRoleLoading = (value: boolean) => {
    this.currentUserInOrganizationRoleLoading = value;
  };

  setGenerateInviteLinkLoading = (value: boolean) => {
    this.generateInviteLinkLoading = value;
  };
}
