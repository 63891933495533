import React from 'react';

import { AuthStore } from 'modules/auth/AuthStore';
import { BaseStore } from 'modules/base/BaseStore';
import { FileStore } from 'modules/file/FileStore';
import { MediaLibraryStore } from 'modules/mediaLibrary/MediaLibraryStore';
import { NotificationsStore } from 'modules/notifications/NotificationsStore';
import { OrganizationsStore } from 'modules/organizations/OrganizationsStore';
import { PollsStore } from 'modules/polls/PollsStore';
import { ReportsStore } from 'modules/reports/ReportsStore';
import { SidebarStore } from 'modules/sidebar/SidebarStore';
import { UsersStore } from 'modules/users/UsersStore';

class RootStore {
  baseStore: BaseStore;
  fileStore: FileStore;
  authStore: AuthStore;
  usersStore: UsersStore;
  pollsStore: PollsStore;
  sidebarStore: SidebarStore;
  reportsStore: ReportsStore;
  mediaLibraryStore: MediaLibraryStore;
  organizationsStore: OrganizationsStore;
  notificationsStore: NotificationsStore;

  constructor() {
    this.baseStore = new BaseStore();
    this.fileStore = new FileStore();
    this.authStore = new AuthStore();
    this.usersStore = new UsersStore();
    this.pollsStore = new PollsStore();
    this.sidebarStore = new SidebarStore();
    this.reportsStore = new ReportsStore();
    this.mediaLibraryStore = new MediaLibraryStore();
    this.organizationsStore = new OrganizationsStore();
    this.notificationsStore = new NotificationsStore();
  }
}

export const rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);
