import { modelFactory } from 'base/ModelFactory';

import { AuthApiRepository } from './AuthApiRepository';
import { AuthDataSource } from './AuthDataSource';
import { ForgotPasswordForm } from './forms/auth/ForgotPasswordForm';
import { LoginForm } from './forms/auth/LoginForm';
import { NewPasswordForm } from './forms/auth/NewPasswordForm';
import { RegisterForm } from './forms/auth/RegisterForm';
import { SuccessAuth } from './models/SuccessAuth';

export class AuthService {
  private authApi: AuthApiRepository;
  private authDataSource: AuthDataSource;

  constructor() {
    this.authApi = new AuthApiRepository();
    this.authDataSource = new AuthDataSource();
  }

  register = async (form: RegisterForm): Promise<void> => {
    const registerDto = this.authDataSource.prepareRegisterDto(form);

    return this.authApi.register(registerDto);
  };

  login = async (form: LoginForm): Promise<SuccessAuth> => {
    const loginDto = this.authDataSource.prepareLoginDto(form);
    const { data } = await this.authApi.login(loginDto);

    return modelFactory.create<SuccessAuth>(SuccessAuth, data.data);
  };

  logout = (): Promise<void> => {
    return this.authApi.logout();
  };

  verifyAccount = async (id: string, hash: string): Promise<SuccessAuth> => {
    const { data } = await this.authApi.verifyAccount(id, hash);

    return modelFactory.create<SuccessAuth>(SuccessAuth, data.data);
  };

  forgotPassword = async (form: ForgotPasswordForm): Promise<void> => {
    const forgotPasswordDto = this.authDataSource.prepareForgotPasswordDto(form);

    return this.authApi.forgotPassword(forgotPasswordDto);
  };

  setNewPassword = async (id: string, hash: string, form: NewPasswordForm): Promise<void> => {
    const newPasswordDto = this.authDataSource.prepareNewPasswordDto(form);

    return this.authApi.setNewPassword(id, hash, newPasswordDto);
  };

  checkUserAccessToken = async (token: string): Promise<boolean> => {
    const checkUserAccessTokenDto = this.authDataSource.prepareCheckUserAccessTokenDto(token);
    const { data } = await this.authApi.checkUserAccessToken(checkUserAccessTokenDto);

    return data.data;
  };
}
