import { Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { IQSwitchWithControllerProps } from 'interfaces/CommonComponentsInterfaces';

import { QSwitch } from './QSwitch';

export const QSwitchWithController: React.FC<IQSwitchWithControllerProps> = props => {
  const { name, hookFormProps, containerClassName, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.switchContainer, containerClassName)}>
      <Controller
        name={name}
        control={hookFormProps.control}
        render={({ field }) => (
          <QSwitch checked={field.value} onChange={e => field.onChange(e.target.checked)} {...rest} />
        )}
      />
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
