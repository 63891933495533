import { BaseForm } from 'base/BaseForm';

export enum NewPasswordFormFields {
  password = 'password',
  passwordConfirm = 'passwordConfirm',
}

export class NewPasswordForm extends BaseForm {
  password: string = '';
  passwordConfirm: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
