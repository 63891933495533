import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { PasswordTextField } from 'components/UI/TextFields/PasswordTextField';
import {
  ProfileChangePasswordForm,
  ProfileChangePasswordFormFields,
} from 'modules/users/forms/ProfileChangePasswordForm';
import { profileChangePasswordFormScheme } from 'modules/users/schemes/profileChangePasswordFormScheme';

interface IProfilePasswordFormComponentProps {}

export const ProfilePasswordFormComponent: React.FC<IProfilePasswordFormComponentProps> = observer(() => {
  const { usersStore } = useRootStore();

  const profileChangePasswordForm = useForm<ProfileChangePasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(profileChangePasswordFormScheme),
    defaultValues: ProfileChangePasswordForm.create(),
  });

  // Handlers
  const handleSubmit = (form: ProfileChangePasswordForm) => {
    usersStore.changeThePasswordWithTheCurrent(form, () => {
      profileChangePasswordForm.reset(ProfileChangePasswordForm.create());
    });
  };

  // Renders
  return (
    <form onSubmit={profileChangePasswordForm.handleSubmit(handleSubmit)}>
      <PasswordTextField
        fullWidth
        label="Текущий пароль"
        sx={{ mb: 1.5 }}
        placeholder="Введите текущий пароль"
        autoComplete="current-password"
        name={ProfileChangePasswordFormFields.currentPassword}
        hookFormProps={{ control: profileChangePasswordForm.control }}
      />
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 1.5, mb: 2 }}>
        <PasswordTextField
          fullWidth
          label="Новый пароль"
          placeholder="Введите новый пароль пароль"
          autoComplete="new-password"
          name={ProfileChangePasswordFormFields.newPassword}
          hookFormProps={{ control: profileChangePasswordForm.control }}
        />
        <PasswordTextField
          fullWidth
          label="Повторите пароль"
          placeholder="Повторите пароль"
          autoComplete="off"
          name={ProfileChangePasswordFormFields.newPasswordConfirm}
          hookFormProps={{ control: profileChangePasswordForm.control }}
        />
      </Box>
      <Button fullWidth variant="contained" color="primary" type="submit">
        Изменить пароль
      </Button>
    </form>
  );
});
