import { CommonContainer } from 'components/Common/CommonContainer';

import { MembersContent } from './components/MembersContent';
import { MembersTopLine } from './components/MembersTopLine';

interface IMembersScreenProps {}

export const MembersScreen: React.FC<IMembersScreenProps> = () => {
  return (
    <CommonContainer sx={{ pt: 8.75, pb: 5, pl: 9 }}>
      <MembersTopLine />

      <MembersContent />
    </CommonContainer>
  );
};
