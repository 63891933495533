import { RouteHelper } from 'base/routes/helpers/RouteHelper';

import { BaseApiRepository } from './BaseApiRepository';
import { getHeaderVisualTypeMatrix } from './consts/consts';
import { HeaderVisualType } from './types/BaseTypes';

export class BaseService {
  private baseApi: BaseApiRepository;

  constructor() {
    this.baseApi = new BaseApiRepository();
  }

  getValidHeaderVisualType = (pathname: string): HeaderVisualType => {
    const entries = Object.entries(getHeaderVisualTypeMatrix());

    for (let i = 0; i < entries.length; i++) {
      const [key, values] = entries[i];
      const findedPath = values.some(item => RouteHelper.matchPathWithOptional(item, pathname));

      // Тут находим роут который равен текущему pathname и возвращаем текущий key если ничего не найдется то по дефолту возвращаем main
      if (findedPath) {
        return key as HeaderVisualType;
      }
    }

    return HeaderVisualType.main;
  };

  abortRequest = () => {
    return this.baseApi.abortRequest();
  };
}
