import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { pollContextsViewMatrix } from 'modules/polls/consts/consts';
import { PollTypes } from 'modules/polls/types/PollsTypes';
import { customColors } from 'styles/colors';

interface IBatchImageUploadModalProps extends Omit<IQModalProps, 'onClose'> {
  imagesCount: number;
  uploadedFilesListCount: number;
  onHandleGoBack: () => void;
  onHandleUploadFile: () => void;
  onHandleSetImagesCount: (imagesCount: number) => void;
}

export const BatchImageUploadModal: React.FC<IBatchImageUploadModalProps> = props => {
  const { imagesCount, uploadedFilesListCount, onHandleGoBack, onHandleUploadFile, onHandleSetImagesCount, ...rest } =
    props;
  const { classes, cx } = useStyles();
  const params = useParams<{ type?: PollTypes }>();
  const currentContextImagesCountSetting = pollContextsViewMatrix?.[params?.type!]?.imagesCount;

  // Effects
  useEffect(() => {
    if (params.type) {
      const validImagesCountsList = Object.entries(currentContextImagesCountSetting).find(([key, value]) => value);
      if (validImagesCountsList?.[0]?.length) {
        onHandleSetImagesCount(Number(validImagesCountsList[0]));
      }
    }

    return () => {
      onHandleSetImagesCount(0);
    };
  }, [params?.type]);

  // Handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onHandleSetImagesCount(Number(value));
  };

  // Renders
  return (
    <QModal {...rest} modalMaxWidthValue={610}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 4 }}>
          Выберите вариант отображения картинок: {uploadedFilesListCount}
        </Typography>

        <div className={classes.radioGroup}>
          <RadioGroup value={String(imagesCount)} onChange={handleChange}>
            <div className={classes.row}>
              <div className={classes.item}>
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  disabled={!currentContextImagesCountSetting?.[2]}
                  label={
                    <div className={cx(classes.iconsWrap, classes.twoElements)}>
                      <div className={classes.iconWrap}>
                        <i className="custom-icon-gallery" />
                      </div>
                      <div className={classes.iconWrap}>
                        <i className="custom-icon-gallery" />
                      </div>
                    </div>
                  }
                />
              </div>
              <div className={classes.item}>
                <FormControlLabel
                  value="4"
                  control={<Radio color="primary" />}
                  disabled={!currentContextImagesCountSetting?.[4]}
                  label={
                    <>
                      <div className={cx(classes.iconsWrap, classes.twoElements)}>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                      </div>
                      <div className={cx(classes.iconsWrap, classes.twoElements)}>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
              <div className={classes.item}>
                <FormControlLabel
                  value="6"
                  control={<Radio color="primary" />}
                  disabled={!currentContextImagesCountSetting?.[6]}
                  label={
                    <>
                      <div className={cx(classes.iconsWrap, classes.twoElements)}>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                      </div>
                      <div className={cx(classes.iconsWrap, classes.twoElements)}>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                        <div className={classes.iconWrap}>
                          <i className="custom-icon-gallery" />
                        </div>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          </RadioGroup>
        </div>

        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mb: 2 }}
            onClick={onHandleUploadFile}
            disabled={imagesCount === 0}
          >
            Готово
          </Button>
          <Button fullWidth variant="outlined" color="primary" onClick={onHandleGoBack}>
            Вернуться назад
          </Button>
        </Box>
      </Box>
    </QModal>
  );
};

const useStyles = makeStyles()(theme => ({
  row: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  item: {},
  iconsWrap: {},
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.customColors.black,
    padding: theme.spacing(0.625),
    '& i': {
      fontSize: 26,
    },
  },
  twoElements: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
