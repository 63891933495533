import { Button, alpha } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QLinearProgress } from 'components/UI/QLinearProgress';
import { CommonHelper } from 'helpers/CommonHelper';
import {
  PollOthersTemplatesAnswerForm,
  PollOthersTemplatesAnswerFormFields,
} from 'modules/polls/forms/PollOthersTemplatesAnswerForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';

interface IPollDetailOthersTemplateTopLineProps {}

export const PollDetailOthersTemplateTopLine: React.FC<IPollDetailOthersTemplateTopLineProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();
  const params = useParams<{ pollId?: string }>();
  const pollId = params.pollId ?? null;

  const pollOthersTemplatesAnswerForm = useFormContext<PollOthersTemplatesAnswerForm>();
  const { field: answerIdController } = useController({
    name: PollOthersTemplatesAnswerFormFields.answerId,
    control: pollOthersTemplatesAnswerForm.control,
  });
  const isBeforeTheLastRound = PollsHelper.isBeforeTheLastRound(pollsStore.pollVoteModel);
  const isFirstRound = PollsHelper.isFirstRound(pollsStore.pollVoteModel);

  // Effects
  useEffect(() => {
    if (pollsStore.pollVoteModel?.is_completed && !pollsStore.pollSuccessfullyModalOpen) {
      pollsStore.setPollSuccessfullyModalOpen(true);
    }
  }, [pollsStore.pollVoteModel, pollsStore.pollSuccessfullyModalOpen]);

  // Handlers
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    pollOthersTemplatesAnswerForm.handleSubmit(handleSubmitForm)();
  };

  const handleSubmitForm = (form: PollOthersTemplatesAnswerForm) => {
    if (!pollId || !organizationsStore.selectedOrganization?.id) {
      return;
    }

    pollsStore.setAnswerTheQuestionOtherTemplates(
      pollId,
      organizationsStore.selectedOrganization.id,
      form,
      handleGetNextQuestions,
    );
  };

  const handleRevert = () => {
    if (!pollId) {
      return;
    }

    pollsStore.revertPrevQuestion(pollId);
  };

  const handleGetNextQuestions = () => {
    pollOthersTemplatesAnswerForm.reset(PollOthersTemplatesAnswerForm.create());
  };

  // Renders
  return (
    <div className={classes.topLineWrap}>
      <div className={classes.topLineInner}>
        {pollsStore.currentGettedPollInfo?.with_progress_bar && (
          <QLinearProgress color="secondary" value={pollsStore.pollVoteModel?.percentage ?? 0} withLabel />
        )}
        <div className={classes.buttonsWrap}>
          {!isFirstRound && (
            <Button variant="contained" color="secondary" onClick={handleRevert} disabled={isFirstRound}>
              Назад
            </Button>
          )}
          {!pollsStore.pollVoteModel?.is_completed && (
            <Button
              sx={{ ml: 'auto' }}
              variant="contained"
              color="primary"
              disabled={!CommonHelper.isNotNullOrUndefined(answerIdController.value)}
              onClick={handleSubmit}
            >
              {isBeforeTheLastRound ? 'Завершить опрос' : 'Далее'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  topLineWrap: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: 'calc(100% + 64px)',
    zIndex: 3,
    background: theme.palette.customColors.white,
    marginLeft: theme.spacing(-4),
  },
  topLineInner: {
    padding: theme.spacing(4, 4, 0),
    position: 'relative',
    background: alpha(theme.palette.customColors.black, 0.8),
  },
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(1.5),
  },
}));
