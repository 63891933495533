import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { NotificationButton } from 'components/UI/NotificationButton';
import { UserPanel } from 'widgets/UserPanel/UserPanel';

import { NotificationsPopup } from './NotificationsPopup';

interface IHeaderRightPanelProps {}

export const HeaderRightPanel: React.FC<IHeaderRightPanelProps> = () => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Handlers
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Renders
  return (
    <div className={classes.wrap}>
      <div className={classes.notificationsWrap}>
        <NotificationButton count={0} onClick={handleOpenMenu} />
      </div>
      <UserPanel />
      <NotificationsPopup anchorEl={anchorEl} handleClose={handleCloseMenu} />
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  notificationsWrap: {
    marginRight: theme.spacing(1),
  },
}));
