import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { QPagination } from 'components/UI/QPagination';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';
import { routes } from 'routes/routes';

import { MembersTable } from './MembersTable/MembersTable';

interface IMembersContentProps {}

export const MembersContent: React.FC<IMembersContentProps> = observer(() => {
  const { organizationsStore } = useRootStore();

  const navigate = useNavigate();
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  // Effects
  useEffect(() => {
    organizationsStore.getOrganizationRolesList();
  }, []);

  useEffect(() => {
    if (!organizationsStore.currentUserInOrganizationRole) {
      return;
    }

    if (!roleMatrix?.viewMembers) {
      navigate(routes.MainScreen.path);
    }
  }, [organizationsStore.currentUserInOrganizationRole]);

  // Handlers
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    organizationsStore.membersListFormSetValueAndGetList(TableFormFields.page, page);
  };

  // Renders
  return (
    <>
      <Box sx={{ mb: 3.75 }}>
        <MembersTable />
      </Box>

      {organizationsStore.membersListForm.showPagination && (
        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 5 }}>
          <QPagination
            color="primary"
            defaultPage={1}
            page={organizationsStore.membersListForm.page}
            count={organizationsStore.membersListForm.pageCount}
            onChange={handleChangePage}
          />
        </Box>
      )}
    </>
  );
});
