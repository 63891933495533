import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { customColors } from 'styles/colors';

interface IReportDeleteModalProps {}

export const ReportDeleteModal: React.FC<IReportDeleteModalProps> = observer(props => {
  const { reportsStore } = useRootStore();
  const { ...rest } = props;

  // Handlers
  const handleDeleteReports = () => {
    reportsStore.deleteReports(() => {
      handleCloseModal();
      reportsStore.setDeletedReportsSelection({});
      reportsStore.getPollReports();
    });
  };

  const handleCloseModal = () => {
    reportsStore.setDeleteReportsModalOpen(false);
  };

  // Renders
  return (
    <QModal {...rest} open={reportsStore.deleteReportsModalOpen} onClose={handleCloseModal} modalMaxWidthValue={610}>
      {reportsStore.deleteReportsLoading && <Loader isAbsolute />}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Удалить отчеты? ({reportsStore.deletedReportsSelectionList.length})
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Удаленные отчеты восстановлению не подлежат. <br /> Вы уверены, что хотите удалить отчеты?
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="error" sx={{ mb: 2 }} onClick={handleDeleteReports}>
            Да, удалить
          </Button>
          <Button fullWidth variant="outlined" color="primary" sx={{ mb: 2 }} onClick={handleCloseModal}>
            Нет, не надо
          </Button>
        </Box>
      </Box>
    </QModal>
  );
});
