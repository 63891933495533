import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { UserModel } from 'modules/users/models/UserModel';
import { customColors } from 'styles/colors';

interface IMemberKickConfirmModalProps extends IQModalProps {
  currentMember: UserModel;
  onHandleCloseModal: () => void;
}

export const MemberKickConfirmModal: React.FC<IMemberKickConfirmModalProps> = observer(props => {
  const { currentMember, onHandleCloseModal, ...rest } = props;
  const { organizationsStore } = useRootStore();

  // Handlers
  const handleKickMember = () => {
    if (organizationsStore.selectedOrganization?.id && currentMember.id) {
      organizationsStore.kickMember(organizationsStore.selectedOrganization.id, currentMember.id, () => {
        handleCloseModal();
        organizationsStore.getOrganizationMembers();
      });
    }
  };

  const handleCloseModal = () => {
    onHandleCloseModal();
  };

  // Renders
  return (
    <QModal {...rest} onClose={handleCloseModal} modalMaxWidthValue={620}>
      {organizationsStore.kickMemberLoading && <Loader isAbsolute />}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Исключение пользователя из организации
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Вы уверены что хотите исключить данного пользователя с текущей организации?
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="error" sx={{ mb: 2 }} onClick={handleKickMember}>
            Да, исключить
          </Button>
          <Button fullWidth variant="outlined" color="primary" sx={{ mb: 2 }} onClick={handleCloseModal}>
            Нет
          </Button>
        </Box>
      </Box>
    </QModal>
  );
});
