import { makeStyles } from 'tss-react/mui';

interface IStatusWithLabelProps {
  label: string;
  color?: React.CSSProperties['color'];
}

export const StatusWithLabel: React.FC<IStatusWithLabelProps> = props => {
  const { color, label } = props;
  const { classes } = useStyles({ color });

  return (
    <div className={classes.statusWrap}>
      <div className={classes.statusIcon} />
      <span>{label}</span>
    </div>
  );
};

const useStyles = makeStyles<Pick<IStatusWithLabelProps, 'color'>>()((theme, props) => {
  return {
    statusWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    statusIcon: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      transition: theme.transitions.create('background-color'),
      marginRight: theme.spacing(0.625),
      backgroundColor: props.color,
    },
  };
});
