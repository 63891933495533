import { CommonContainer } from 'components/Common/CommonContainer';

import { ReportsContainer } from './components/reports/ReportsContainer';

interface IReportsScreenProps {}

export const ReportsScreen: React.FC<IReportsScreenProps> = () => {
  // Renders
  return (
    <CommonContainer sx={{ pt: 6.5, pr: 3.75 }}>
      <ReportsContainer />
    </CommonContainer>
  );
};
