import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { CommonContainer } from 'components/Common/CommonContainer';

import { MainEmptyContent } from './components/MainEmptyContent';
import { PollsContainer } from './components/PollsContainer';

export const MainScreen: React.FC = observer(() => {
  const { organizationsStore } = useRootStore();

  if (!organizationsStore.membershipOrganizations?.length) {
    return <MainEmptyContent />;
  }

  return (
    <CommonContainer sx={{ pt: 5.5 }}>
      <PollsContainer />
    </CommonContainer>
  );
});
