import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { authRoutes, routes } from 'routes/routes';

interface IAccountVerifyScreenProps {}

export const AccountVerifyScreen: React.FC<IAccountVerifyScreenProps> = observer(() => {
  const { authStore, usersStore } = useRootStore();
  const params = useParams<{ id: string; hash: string }>();
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    if (params.id && params.hash) {
      authStore.verifyAccount(params.id, params.hash, handleNavigate, handleNavigate);
    } else {
      handleNavigate();
    }
  }, [params]);

  // Handlers
  const handleNavigate = () => {
    if (authStore.isAuth) {
      navigate(routes.MainScreen.path);
      usersStore.getCurrentUserInfo();
    } else {
      navigate(authRoutes.LoginScreen.path);
    }
  };

  // Renders
  return <Loader isAbsolute minHeight="100vh" />;
});
