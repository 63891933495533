import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { LoaderButton } from 'components/UI/LoaderButton';

import { ProfileDeleteModal } from './ProfileDeleteModal';
import { ProfileDeleteSuccessModal } from './ProfileDeleteSuccessModal';

interface IProfileTopLineProps {}

export const ProfileTopLine: React.FC<IProfileTopLineProps> = observer(() => {
  const { usersStore } = useRootStore();

  const [profileDeleteModal, setProfileDeleteModal] = useState(false);
  const [profileDeleteSuccessModal, setProfileDeleteSuccessModal] = useState(false);

  // Handlers
  const handleOpenProfileDeleteModal = () => {
    setProfileDeleteModal(true);
  };

  const handleCloseProfileDeleteModal = () => {
    setProfileDeleteModal(false);
  };

  const handleOpenProfileDeleteSuccessModal = () => {
    setProfileDeleteSuccessModal(true);
  };

  const handleCloseProfileDeleteSuccessModal = () => {
    setProfileDeleteSuccessModal(false);
  };

  const handleRetrieveAccount = () => {
    usersStore.changeUserStatus();
  };

  // Renders
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant="h1Bold" sx={{ mb: 3 }}>
        Профиль
      </Typography>

      {usersStore.userInfo?.active ? (
        <LoaderButton
          color="error"
          variant="outlined"
          loading={usersStore.changeUserStatusLoading}
          onClick={handleOpenProfileDeleteModal}
        >
          Удалить аккаунт
        </LoaderButton>
      ) : (
        <LoaderButton
          color="primary"
          variant="contained"
          loading={usersStore.changeUserStatusLoading}
          onClick={handleRetrieveAccount}
        >
          Восстановить аккаунт
        </LoaderButton>
      )}

      <ProfileDeleteModal
        open={profileDeleteModal}
        onHandleCloseModal={handleCloseProfileDeleteModal}
        onHandleSuccessDeleteAccount={handleOpenProfileDeleteSuccessModal}
      />
      <ProfileDeleteSuccessModal
        open={profileDeleteSuccessModal}
        onHandleCloseModal={handleCloseProfileDeleteSuccessModal}
      />
    </Box>
  );
});
