import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CommonHelper } from 'helpers/CommonHelper';
import { PollConstructorBlockForm } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollBlockTypes } from 'modules/polls/types/PollsTypes';

import { PollPreviewFieldsTypes } from './PollPreviewFieldsTypes';
import { PollPreviewImagesTypes } from './PollPreviewImagesTypes';

interface IPollPreviewBlockItemProps {
  data: PollConstructorBlockForm;
  index: number;
}

export const PollPreviewBlockItem: React.FC<IPollPreviewBlockItemProps> = props => {
  const { data, index } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.block}>
      {data.settings?.titleVisible && (
        <Typography component="div" variant="h1Medium" sx={{ mb: 3 }}>
          {data.title}
        </Typography>
      )}

      {data.settings?.blockType === PollBlockTypes.text && (
        <div className={classes.fieldsWrap}>
          {CommonHelper.hasLength(data.fields) &&
            PollsHelper.getNotEmptyFields(data.fields).map((field, fieldIndex) => (
              <PollPreviewFieldsTypes key={fieldIndex} data={field} fieldType={field.type} />
            ))}
        </div>
      )}
      {data.settings?.blockType === PollBlockTypes.media && <PollPreviewImagesTypes index={index} blockForm={data} />}
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  block: {
    // maxWidth: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(0, 'auto'),
    '&:not(:last-child)': {
      margin: theme.spacing(0, 'auto', 8),
    },
  },
  fieldsWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
