import { Serializable, jsonProperty } from 'ts-serializable';

import { ReportPollModel } from './ReportPollModel';

export class ReportModel extends Serializable {
  @jsonProperty(ReportPollModel, null) poll: ReportPollModel | null = null;
  @jsonProperty(Number, null) users: number | null = null;
  @jsonProperty(Number, null) users_pass_poll: number | null = null;
  @jsonProperty(Number, null) users_ignore_poll: number | null = null;
}
