export class ArrayHelper {
  static splitArrayIntoSubarrays(arr: string[], subarraySize: number): string[][] {
    const subarrays: string[][] = [];

    for (let i = 0; i < arr.length; i += subarraySize) {
      const subarray = arr.slice(i, i + subarraySize);
      subarrays.push(subarray);
    }

    return subarrays;
  }
}
