import { Table, TableHead, TableCell, TableBody } from '@mui/material';

import { SortValue } from 'base/modules/sort/models/SortValue';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { QPagination } from 'components/UI/QPagination';
import { QTableHeadCellWithSortLabel } from 'components/UI/Tables/QTableHeadCellWithSortLabel';
import { QTableRow } from 'components/UI/Tables/QTableRow';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { IMemberModalTableField } from 'modules/organizations/interfaces/OrganizationsInterfaces';
import { UserModel } from 'modules/users/models/UserModel';
import { useTableStyles } from 'styles/tableStyles';

import { MembersTableBodyElements } from './MembersTableBodyElements';

interface IMembersModalProps extends Omit<IQModalProps, 'onClose'> {
  sortValue: SortValue | null;
  tableLoading?: boolean;
  membersList: UserModel[] | null;
  tableFields: IMemberModalTableField[];
  paginationProps: {
    showPagination: boolean;
    page: number;
    pageCount: number;
    onHandleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  };
  onHandleClose: () => void;
  onHandleSort: (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => void;
}

export const MembersModal: React.FC<IMembersModalProps> = props => {
  const { sortValue, tableFields, tableLoading, membersList, onHandleSort, onHandleClose, paginationProps, ...rest } =
    props;
  const { tableClasses } = useTableStyles();

  // Handlers
  const handleCloseModal = () => {
    onHandleClose();
  };

  // Renders
  return (
    <>
      <QModal modalMaxWidthValue={810} {...rest} onClose={handleCloseModal}>
        {tableLoading && <Loader isAbsolute />}
        <Table sx={{ borderSpacing: '0 16px' }}>
          <TableHead>
            <QTableRow variant="transparent">
              {tableFields.map((field, index) => (
                <QTableHeadCellWithSortLabel
                  key={index}
                  field={field}
                  sortValue={sortValue}
                  {...field.settings}
                  onHandleSort={onHandleSort}
                />
              ))}
              <TableCell align="right" />
            </QTableRow>
          </TableHead>
          <TableBody className={tableClasses.tableRelative}>
            {membersList?.map(member => (
              <QTableRow key={member.id} variant="transparent" className={tableClasses.talbeRowWithBorder}>
                {tableFields.map(field => (
                  <MembersTableBodyElements key={field.column} member={member} elementId={field.column} />
                ))}
              </QTableRow>
            ))}
          </TableBody>
        </Table>

        {paginationProps.showPagination && (
          <QPagination
            color="primary"
            defaultPage={1}
            page={paginationProps.page}
            count={paginationProps.pageCount}
            onChange={paginationProps.onHandleChangePage}
          />
        )}
      </QModal>
    </>
  );
};
