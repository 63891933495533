import { Theme, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IFieldInfoProps {
  title: ReactNode;
}

export const FieldInfo: React.FC<IFieldInfoProps> = props => {
  const { title } = props;
  const { classes } = useStyles();

  if (!title) {
    return <></>;
  }

  return (
    <Tooltip arrow placement="top" title={title}>
      <div className={classes.fieldInfoWrap}>
        <i className="custom-icon-support" />
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  fieldInfoWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    marginLeft: theme.spacing(1),
    color: theme.palette.customColors.gray,
    transition: theme.transitions.create('color'),
    '& > i': {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
    },
    '&:hover': {
      color: theme.palette.customColors.gray,
    },
  },
}));
