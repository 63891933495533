import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QPagination } from 'components/UI/QPagination';

import { NotificationItem } from './NotificationItem';

export const NotificationsList = observer(() => {
  const { notificationsStore } = useRootStore();

  //  Effects
  useEffect(() => {
    notificationsStore.getNotificationsList();
  }, []);

  // Handlers
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    notificationsStore.setPage(page);
  };

  // Renders
  if (notificationsStore.notificationsListLoading) {
    return <Loader />;
  }

  return (
    <>
      {notificationsStore.notificationsList?.map((notification, index) => (
        <NotificationItem
          key={index}
          title={notification.title}
          message={notification.message}
          withLink={notification.withLink}
        />
      ))}
      {notificationsStore.showPagination && (
        <Box sx={{ mt: 3.5 }}>
          <QPagination
            defaultPage={1}
            onChange={handleChangePage}
            page={notificationsStore.page}
            count={notificationsStore.pageCount}
          />
        </Box>
      )}
    </>
  );
});
