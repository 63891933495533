import { FormControlLabel, Switch, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IQSwitchProps } from 'interfaces/CommonComponentsInterfaces';

export const QSwitch: React.FC<IQSwitchProps> = forwardRef((props, ref) => {
  const { label, labelProps, labelClassName, containerClassName, name, ...rest } = props;
  const { classes, cx } = useStyles();

  // Renders
  return (
    <div className={cx(containerClassName, classes.switchContainer)}>
      <FormControlLabel
        control={<Switch ref={ref} inputProps={{ name }} disableRipple {...rest} />}
        label={
          <Typography variant="h4Medium" className={cx(classes.label, labelClassName)} {...labelProps}>
            {label}
          </Typography>
        }
      />
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  label: {
    userSelect: 'none',
    marginLeft: theme.spacing(1),
  },
}));
