import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { UploadLogo } from 'components/UI/UploadLogo';
import { useObjectUrls } from 'hooks/useObjectUrls';
import { FileHelper } from 'modules/file/helpers/FileHelper';
import { ProfileForm, ProfileFormFields } from 'modules/users/forms/ProfileForm';

interface IProfileAvatarProps {}

export const ProfileAvatar: React.FC<IProfileAvatarProps> = observer(() => {
  const { control } = useFormContext<ProfileForm>();
  const { field: avatar } = useController({ name: `${ProfileFormFields.avatar}`, control });
  const { getObjectUrl, removeObjectUrl } = useObjectUrls();
  const fileUrl = FileHelper.getCurrentFileUrl(getObjectUrl(avatar.value?.localFile), avatar.value?.fileUrl);

  // Handlers
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        const file: any = acceptedFiles[0];
        avatar.onChange({ ...avatar.value, localFile: file });
      }
    },
    [avatar.value?.localFile],
  );

  const handleClear = () => {
    removeObjectUrl(avatar.value?.localFile);
    avatar.onChange(null);
  };

  // Renders
  return (
    <UploadLogo
      img={fileUrl}
      clearIcon={!!fileUrl}
      iconClassName="custom-icon-user"
      onDrop={handleDrop}
      onHandleClear={handleClear}
    />
  );
});
