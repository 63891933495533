import { Box, Button, Typography } from '@mui/material';

import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { customColors } from 'styles/colors';

interface IVerifyEmailModalProps extends Omit<IQModalProps, 'onClose'> {
  onHandleClose?: () => void;
}

export const VerifyEmailModal: React.FC<IVerifyEmailModalProps> = props => {
  const { onHandleClose, ...rest } = props;

  // Handlers

  const handleCloseModal = () => {
    onHandleClose?.();
  };

  // Renders
  return (
    <QModal {...rest} modalMaxWidthValue={610} onClose={handleCloseModal}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Подтвердите регистрацию
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          На Вашу электронную почту отправлено сообщение, содержащее ссылку подтверждение.
        </Typography>
        <Box sx={{ mx: 'auto', mb: 2, maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleCloseModal}>
            Ок, понятно
          </Button>
        </Box>
        <Typography variant="h4Medium">
          Если письмо не пришло в течение 15 минут, проверьте папку «Спам». Если же письма нет, попробуйте ещё раз.
          Возможно, Вы ошиблись при вводе адреса.
        </Typography>
      </Box>
    </QModal>
  );
};
