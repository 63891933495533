import { action, computed, makeObservable } from 'mobx';

import { TableForm } from 'modules/tables/forms/TableForm/TableForm';

import ReportsService from '../ReportsService';

export enum UsersNotCompletedPollListFormFields {
  organizationId = 'organizationId',
}

export class UsersNotCompletedPollListForm extends TableForm {
  limit: number = ReportsService.USERS_NOT_COMPLETED_LIST_LIMIT;
  organizationId: number | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createFormObservable() {
    const context = new this();
    context.createTableFormObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(UsersNotCompletedPollListFormFields),
      pageCount: computed,
      showPagination: computed,
      resetForm: action,
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.limit);
  }

  get showPagination() {
    return this.total > this.limit;
  }

  resetForm = () => {
    this.total = 0;
    this.limit = ReportsService.USERS_NOT_COMPLETED_LIST_LIMIT;
    this.offset = 0;
    this.page = 1;
    this.sortValue = null;
  };
}
