import { makeStyles } from 'tss-react/mui';

import { PollMatchDefaultTemplateModel } from 'modules/polls/models/PollMatchDefaultTemplateModel';
import { PollQuestionModel } from 'modules/polls/models/PollQuestionModel';

import { PollTextBlockCheckbox } from './PollTextBlockType/PollTextBlockCheckbox';
import { PollTextBlockRadioGroup } from './PollTextBlockType/PollTextBlockRadioGroup';

interface IPollTextBlockTypeProps {
  question: PollQuestionModel;
  pollMatchDefaultTemplateModel: PollMatchDefaultTemplateModel;
}

export const PollTextBlockType: React.FC<IPollTextBlockTypeProps> = props => {
  const { question, pollMatchDefaultTemplateModel } = props;
  const { classes } = useStyles();

  // Renders
  const renderCheckboxes = () => {
    return pollMatchDefaultTemplateModel.answers?.map((answer, index) => (
      <PollTextBlockCheckbox key={answer.id} question={question} answer={answer} index={index} />
    ));
  };

  const renderRadioGroup = () => {
    return (
      <PollTextBlockRadioGroup pollMatchDefaultTemplateModel={pollMatchDefaultTemplateModel} question={question} />
    );
  };

  return (
    <div className={classes.answersWrap}>{question?.has_many_answers ? renderCheckboxes() : renderRadioGroup()}</div>
  );
};

const useStyles = makeStyles()(theme => ({
  answersWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
