import { Table, TableHead, TableCell, TableBody } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { Loader } from 'components/UI/Loader';
import { QTableHeadCellWithSortLabel } from 'components/UI/Tables/QTableHeadCellWithSortLabel';
import { QTableRow } from 'components/UI/Tables/QTableRow';
import { membersModalTableFields } from 'lists/organizations/membersModalTableFields';
import { OrganizationRoles } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';
import { useCommonStyles } from 'styles/commonStyles';
import { useTableStyles } from 'styles/tableStyles';

import { MemberEditModal } from './MemberEditModal';
import { MembersTableBodyElements } from './MembersTableBodyElements';

interface IMembersTableProps {}

export const MembersTable: React.FC<IMembersTableProps> = observer(() => {
  const { organizationsStore, usersStore } = useRootStore();

  const isOrganizationAdmin = organizationsStore.selectedOrganization?.owner?.id === usersStore.userInfo?.id;

  const { tableClasses } = useTableStyles();
  const { commonClasses } = useCommonStyles();

  // Effects
  useEffect(() => {
    organizationsStore.getOrganizationRolesList();
  }, []);

  // Handlers
  const handleSort = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    organizationsStore.membersListFormSetSortValueAndGetList(currentSort);
  };

  const handleEditMember = (member: UserModel) => (e: React.MouseEvent<HTMLButtonElement>) => {
    organizationsStore.setCurrentMember(member);
  };

  const handleCloseModal = () => {
    organizationsStore.setCurrentMember(null);
  };

  return (
    <>
      <Table sx={{ borderSpacing: '0 16px' }}>
        <TableHead>
          <QTableRow variant="transparent">
            {membersModalTableFields.map((field, index) => (
              <QTableHeadCellWithSortLabel
                key={index}
                field={field}
                sortValue={organizationsStore.membersListForm.sortValue}
                {...field.settings}
                onHandleSort={handleSort}
              />
            ))}
            <TableCell align="right" />
          </QTableRow>
        </TableHead>
        <TableBody className={tableClasses.tableRelative}>
          {organizationsStore.organizationMembersListLoading && (
            <QTableRow variant="transparent" className={tableClasses.loaderRow}>
              <TableCell>
                <Loader isAbsolute />
              </TableCell>
            </QTableRow>
          )}

          {organizationsStore.organizationMembersList?.map(organizationMember => (
            <QTableRow key={organizationMember.id} variant="transparent" className={tableClasses.talbeRowWithBorder}>
              {membersModalTableFields.map(field => (
                <MembersTableBodyElements key={field.column} member={organizationMember} elementId={field.column} />
              ))}
              <TableCell>
                <TextButton
                  color="primary"
                  disabled={!isOrganizationAdmin || organizationMember?.role?.name === OrganizationRoles.admin}
                  className={commonClasses.bolder}
                  onClick={handleEditMember(organizationMember)}
                >
                  Редактировать
                </TextButton>
              </TableCell>
            </QTableRow>
          ))}
        </TableBody>
      </Table>

      <MemberEditModal
        open={!!organizationsStore.currentMember}
        currentMember={organizationsStore.currentMember}
        onHandleCloseModal={handleCloseModal}
      />
    </>
  );
});
