import { Menu, Theme } from '@mui/material';
import React from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

import { IQMenuProps } from 'interfaces/CommonComponentsInterfaces';

export const QMenu: React.FC<IQMenuProps> = props => {
  const { menuClassName, maxWidth, children, onClose, ...rest } = props;

  const { classes, cx } = useStyles();

  // Handlers
  const handleClose = (e: any, r: any) => {
    e.stopPropagation();

    if (onClose) {
      onClose(e, r);
    }
  };

  const handleClickContainer = (e: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
  };

  return (
    <StyledMenu
      elevation={0}
      PaperProps={{
        style: { maxWidth, width: maxWidth ? '100%' : undefined },
        className: cx(classes.menu, { menuClassName: !!menuClassName }),
        onClick: handleClickContainer,
      }}
      variant="selectedMenu"
      {...rest}
      anchorOrigin={
        props.anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
      transformOrigin={
        props.transformOrigin || {
          vertical: 'top',
          horizontal: 'right',
        }
      }
      onClose={handleClose}
    >
      {children}
    </StyledMenu>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  menu: {
    minWidth: 200,
  },
}));

const StyledMenu = withStyles(Menu, theme => ({
  paper: {
    background: theme.palette.customColors.white,
    border: `0.5px solid ${theme.palette.customColors.grayDivider}`,
    boxShadow: '0px 4px 4px rgba(169, 169, 169, 0.12)',
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
}));
