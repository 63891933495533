import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';

interface INotificationButtonProps {
  count: number | string | null;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const NotificationButton: React.FC<INotificationButtonProps> = ({ count, onClick }) => {
  const { classes } = useStyles();

  // Handlers
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(e);
  };

  // Renders
  return (
    <div>
      <IconButton onClick={handleOnClick} classes={{ root: classes.iconBtn }}>
        <i className="custom-icon-notification" />
        {!!count && <span className={classes.count}>50</span>}
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  iconBtn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    color: theme.palette.customColors.black,
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 9,
    right: 9,
    fontWeight: 500,
    fontSize: 8,
    lineHeight: '12px',
    color: theme.palette.customColors.white,
    backgroundColor: theme.palette.customColors.purple,
    minWidth: 12,
    height: 12,
    borderRadius: '50%',
    padding: 1,
  },
}));
