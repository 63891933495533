import { Box } from '@mui/material';

import { NotificationsHeader } from './components/NotificationsHeader/NotificationsHeader';
import { NotificationsList } from './components/NotificationsList/NotificationsList';
import { NotificationsSettings } from './components/NotificationsSettings/NotificationsSettings';

interface INotificationsScreenProps {}

export const NotificationsScreen: React.FC<INotificationsScreenProps> = () => {
  return (
    <Box sx={{ pt: 11.25, pr: 11.25, pl: 11.25 }}>
      <NotificationsHeader />
      <NotificationsList />
      <NotificationsSettings />
    </Box>
  );
};
