import { Box, BoxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';

interface IPollTopLineProps extends BoxProps {}

export const PollTopLine: React.FC<IPollTopLineProps> = observer(props => {
  const { pollsStore } = useRootStore();
  const { classes } = useStyles();

  return (
    <Box className={classes.wrap} {...props}>
      <Typography sx={{ mb: 2 }} variant="h1Bold">
        {pollsStore.currentGettedPollInfo?.name}
      </Typography>
      {pollsStore.currentGettedPollInfo?.description && (
        <Typography variant="h2Medium">{pollsStore.currentGettedPollInfo.description}</Typography>
      )}
    </Box>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    color: theme.palette.customColors.white,
    textAlign: 'center',
    paddingTop: theme.spacing(4),
  },
}));
