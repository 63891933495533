import { Button, menuClasses } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Controller, ControllerRenderProps, useController, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { QMenu } from 'components/UI/QMenu/QMenu';
import { QSwitch } from 'components/UI/Switches/QSwitch';
import { IQMenuProps } from 'interfaces/CommonComponentsInterfaces';
import { pollContextsViewMatrix } from 'modules/polls/consts/consts';
import { PollBlockSettingsFormFields } from 'modules/polls/forms/PollBlockSettingsForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollBlockTypes, PollTypes } from 'modules/polls/types/PollsTypes';

import { ImagesCountSettings } from './ImagesCountSettings';

interface IConstructorFieldSettingsPanelProps {
  index: number;
  menuProps?: Partial<IQMenuProps>;
}

type ManyAnswersControllerFieldType = ControllerRenderProps<
  PollCreateAndEditForm,
  `${PollCreateAndEditFormFields.blocks}.${number}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.manyAnswers}`
>;

export const ConstructorFieldSettingsPanel: React.FC<IConstructorFieldSettingsPanelProps> = observer(props => {
  const { index, menuProps } = props;
  const { classes } = useStyles();
  const params = useParams<{ type?: PollTypes }>();

  const { control } = useFormContext<PollCreateAndEditForm>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { field: blockType } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.blockType}`,
    control,
  });
  const currentContextManyAnswers = pollContextsViewMatrix?.[params?.type!]?.manyAnswers;

  // Handlers
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeManyAnswers =
    (field: ManyAnswersControllerFieldType, manyAnswers: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (manyAnswers) {
        field.onChange(checked);
      } else {
        field.onChange(!checked);
      }
    };

  // Renders
  return (
    <>
      <Button
        color="info"
        variant="contained"
        endIcon={<i className="custom-icon-settings-2" />}
        onClick={handleOpenMenu}
      >
        Настройки
      </Button>
      <QMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseMenu}
        variant="menu"
        className={classes.menu}
        {...menuProps}
      >
        <Controller
          name={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.titleVisible}`}
          control={control}
          render={({ field }) => (
            <QSwitch
              checked={field.value}
              color="primary"
              label="Заголовок"
              containerClassName={classes.switchItemContainer}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.manyAnswers}`}
          control={control}
          render={({ field }) => (
            <QSwitch
              checked={!field.value}
              color="primary"
              label="Выбор одного из списка"
              containerClassName={classes.switchItemContainer}
              onChange={handleChangeManyAnswers(field, false)}
              disabled={!currentContextManyAnswers}
            />
          )}
        />
        <Controller
          name={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.manyAnswers}`}
          control={control}
          render={({ field }) => (
            <QSwitch
              checked={field.value}
              color="primary"
              label="Выбор нескольких из списка"
              containerClassName={classes.switchItemContainer}
              onChange={handleChangeManyAnswers(field, true)}
              disabled={!currentContextManyAnswers}
            />
          )}
        />

        {blockType.value === PollBlockTypes.media && <ImagesCountSettings index={index} />}
      </QMenu>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  menu: {
    [`& .${menuClasses.list}`]: {
      minWidth: 210,
      maxWidth: 210,
      padding: theme.spacing(2),
    },
  },
  switchItemContainer: {
    minHeight: 30,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));
