import { MenuList, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { routes } from 'routes/routes';
import { useCommonStyles } from 'styles/commonStyles';

interface IMainSideBarProps {}

export const MainSideBar: React.FC<IMainSideBarProps> = observer(() => {
  const { authStore, organizationsStore } = useRootStore();
  const { classes, cx } = useStyles();
  const { commonClasses } = useCommonStyles();

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const hasAccessViewMembers = roleMatrix?.viewMembers;
  const hasAccessReports = roleMatrix?.pollResults;

  // Handers
  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    authStore.logout();
  };

  const handleDisableMembersNavLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasAccessViewMembers) {
      e.preventDefault();
    }
  };

  const handleDisableReportsNavLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasAccessReports) {
      e.preventDefault();
    }
  };

  // Renders
  return (
    <div className={classes.wrap}>
      <MenuList>
        <li className={classes.menuItem}>
          <NavLink
            className={({ isActive }) =>
              cx('router-link', commonClasses.aic, { [commonClasses.navLinkActive]: isActive })
            }
            to={routes.ProfileScreen.path}
          >
            <TextButton
              color="secondary"
              className={commonClasses.textButtonIcon16}
              startIcon={<i className="custom-icon-user" />}
            >
              <Typography variant="h2Medium" component="span">
                Профиль
              </Typography>
            </TextButton>
          </NavLink>
        </li>
        <li className={classes.menuItem}>
          <NavLink
            className={({ isActive }) =>
              cx('router-link', commonClasses.aic, {
                [commonClasses.navLinkActive]: isActive,
                [commonClasses.cursorDisable]: !hasAccessViewMembers,
              })
            }
            to={routes.MembersScreen.path}
            onClick={handleDisableMembersNavLink}
          >
            <TextButton
              color="secondary"
              className={commonClasses.textButtonIcon16}
              startIcon={<i className="custom-icon-profile-user" />}
              disabled={!hasAccessViewMembers}
            >
              <Typography variant="h2Medium" component="span">
                Участники
              </Typography>
            </TextButton>
          </NavLink>
        </li>
        <li className={classes.menuItem}>
          <NavLink
            to={routes.NotificationsScreen.path}
            className={({ isActive }) =>
              cx('router-link', commonClasses.aic, commonClasses.cursorDisable, {
                [commonClasses.navLinkActive]: isActive,
              })
            }
          >
            <TextButton
              color="secondary"
              className={commonClasses.textButtonIcon16}
              startIcon={<i className="custom-icon-notification" />}
            >
              <Typography variant="h2Medium" component="span">
                Уведомления
              </Typography>
            </TextButton>
          </NavLink>
        </li>
        <li className={classes.menuItem}>
          <NavLink
            to={routes.ReportsScreen.path}
            className={({ isActive }) =>
              cx('router-link', commonClasses.aic, {
                [commonClasses.navLinkActive]: isActive,
                [commonClasses.cursorDisable]: !hasAccessReports,
              })
            }
            onClick={handleDisableReportsNavLink}
          >
            <TextButton
              color="secondary"
              className={commonClasses.textButtonIcon16}
              startIcon={<i className="custom-icon-chart" />}
              disabled={!hasAccessReports}
            >
              <Typography variant="h2Medium" component="span">
                Отчетность
              </Typography>
            </TextButton>
          </NavLink>
        </li>
        <li className={classes.menuItem}>
          <NavLink
            className={({ isActive }) =>
              cx('router-link', commonClasses.aic, { [commonClasses.navLinkActive]: isActive })
            }
            to={routes.SupportScreen.path}
          >
            <TextButton
              color="secondary"
              className={commonClasses.textButtonIcon16}
              startIcon={<i className=" custom-icon-messagequestion" />}
            >
              <Typography variant="h2Medium" component="span">
                Помощь
              </Typography>
            </TextButton>
          </NavLink>
        </li>
      </MenuList>

      <div>
        <TextButton
          color="secondary"
          className={commonClasses.textButtonIcon16}
          startIcon={<i className="custom-icon-logoutcurve" />}
          onClick={handleLogout}
        >
          <Typography variant="h2Medium" component="span">
            Выйти
          </Typography>
        </TextButton>
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    paddingTop: 110,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  menuItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));
