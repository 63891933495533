import { Box, BoxProps } from '@mui/material';

interface ICommonContainerProps extends BoxProps {}

export const CommonContainer: React.FC<ICommonContainerProps> = ({ children, sx, ...rest }) => {
  return (
    <Box sx={{ pl: 11.25, pr: 7.25, height: '100%', ...sx }} {...rest}>
      {children}
    </Box>
  );
};
