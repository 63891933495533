import { PollDetailContent } from './components/pollDetail/PollDetailContent';

interface IPollDetailScreenProps {}

export const PollDetailScreen: React.FC<IPollDetailScreenProps> = () => {
  return (
    <>
      <PollDetailContent />
    </>
  );
};
