import { SelectChangeEvent, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { QSelectOutlined } from 'components/UI/Selects/QSelectOutlined';
import { IQSelectOutlinedProps } from 'interfaces/CommonComponentsInterfaces';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { OrganizationRoles } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';

import { OwnerTransferConfirmModal } from './components/OwnerTransferConfirmModal';

interface IMembersRoleChangeSelectProps {
  currentMember: UserModel | null;
  selectProps?: IQSelectOutlinedProps;
}

export const MembersRoleChangeSelect: React.FC<IMembersRoleChangeSelectProps> = observer(props => {
  const { currentMember, selectProps } = props;
  const { organizationsStore, usersStore } = useRootStore();

  const prevRole = useRef<OrganizationRoles | null>(null);
  const [currentRole, setCurrentRole] = useState<OrganizationRoles | null>(null);

  const currentMemberId: number = currentMember?.id ?? 0;
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const isCurrentSelectedOrganizationAdmin =
    organizationsStore.selectedOrganization?.owner?.id === usersStore.userInfo?.id;
  const isCurrentMemberAdmin = currentMember?.role?.name === OrganizationRoles.admin;
  const filteredRolesList = OrganizationHelper.getFilteredRoles(
    organizationsStore.rolesList,
    currentRole,
    isCurrentSelectedOrganizationAdmin,
  );

  // Effects
  useEffect(() => {
    if (currentMember?.role?.name) {
      setCurrentRole(currentMember.role.name);
    }
  }, [currentMember, organizationsStore.selectedOrganization]);

  // Handlers
  const handleDisableRoleChangeSelect = () => {
    if (roleMatrix?.changeRoleToAdmin && isCurrentSelectedOrganizationAdmin && !isCurrentMemberAdmin) {
      return false;
    }

    if (
      roleMatrix?.changeTheRole &&
      !isCurrentMemberAdmin &&
      currentMember?.role?.name === OrganizationRoles.poll_participant
    ) {
      return false;
    }

    return true;
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const value: OrganizationRoles = event.target.value;
    const currentRoleId = organizationsStore.rolesHashMap?.[value!]?.id ?? 0;

    if (!organizationsStore.selectedOrganization?.id || !currentMemberId) {
      return;
    }

    if (value === OrganizationRoles.admin) {
      handleOpenTransferOwnerModal();
      setCurrentRole(prevState => {
        prevRole.current = prevState;

        return value;
      });

      return;
    }

    organizationsStore.changeRole(organizationsStore.selectedOrganization.id, currentMemberId, currentRoleId, () => {
      organizationsStore.getMembershipOrganizations('MembersRoleChangeSelect widget');
    });
    setCurrentRole(value);
  };

  const handleOpenTransferOwnerModal = () => {
    organizationsStore.setTransferOwnerModal(currentMemberId, true);
  };

  const handleCloseTransferOwnerModal = () => {
    organizationsStore.setTransferOwnerModal(currentMemberId, false);
  };

  const handleCancelTransforOwner = () => {
    setCurrentRole(prevRole.current);
    handleCloseTransferOwnerModal();
  };

  // Renders
  return (
    <>
      <QSelectOutlined
        value={currentRole ?? ''}
        formControlProps={{ sx: { minWidth: 160 } }}
        disabled={handleDisableRoleChangeSelect()}
        onChange={handleChange}
        {...selectProps}
      >
        {filteredRolesList?.map(
          role =>
            role.name && (
              <MenuItem key={role.name} value={role.name}>
                {role.title}
              </MenuItem>
            ),
        )}
      </QSelectOutlined>

      <OwnerTransferConfirmModal
        currentMember={currentMember}
        currentRole={currentRole}
        open={!!organizationsStore.transferOwnerModal?.[currentMemberId]}
        onHandleCancel={handleCancelTransforOwner}
        onHandleCloseModal={handleCloseTransferOwnerModal}
      />
    </>
  );
});
