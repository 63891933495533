import { BaseForm } from 'base/BaseForm';
import { ILocalFileWithFileUrl } from 'modules/file/interfaces/FileInterfaces';

import { ProfileGenderTypes } from '../types/UsersTypes';

export enum ProfileFormFields {
  name = 'name',
  gender = 'gender',
  birthday = 'birthday',
  city = 'city',
  avatar = 'avatar',
}

export class ProfileForm extends BaseForm {
  name: string = '';
  gender: ProfileGenderTypes = ProfileGenderTypes.female;
  birthday: Date | null = null;
  city: string = '';
  avatar: ILocalFileWithFileUrl | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
