import { FormLabel, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';

interface IPollNameFormComponentProps {}

export const PollNameFormComponent: React.FC<IPollNameFormComponentProps> = () => {
  const { classes } = useStyles();
  const { control } = useFormContext<PollCreateAndEditForm>();

  return (
    <div className={classes.nameWrap}>
      <FormLabel className={classes.label} color="secondary">
        <Typography variant="h1Medium" component="span" sx={{ mr: 1 }}>
          Название опроса <span className={classes.requiredIcon}>*</span>
        </Typography>
      </FormLabel>
      <QOutlinedTextFieldWithController
        placeholder="Введите название опроса"
        name={PollCreateAndEditFormFields.name}
        hookFormProps={{ control: control }}
      />
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  nameWrap: {
    marginBottom: theme.spacing(3),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  requiredIcon: {
    color: theme.palette.customColors.red,
  },
}));
