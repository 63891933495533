import { TableCell } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { QCheckbox } from 'components/UI/CheckBoxes/QCheckbox';
import { MembersListFormFields } from 'modules/organizations/forms/MembersListForm';
import { ReportModel } from 'modules/reports/models/ReportModel';
import { ReportsTableColumns } from 'modules/reports/types/ReportsTypes';

import cardImg from 'assets/img/temp/card-img.png';

interface IReportsTableBodyElementsProps {
  data: ReportModel;
  elementId: ReportsTableColumns;
}

export const ReportsTableBodyElements: React.FC<IReportsTableBodyElementsProps> = observer(props => {
  const { data, elementId } = props;
  const { reportsStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();

  // Handlers
  const handleGetPollUsers = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!organizationsStore.selectedOrganization?.id) {
      return;
    }

    organizationsStore.membersListFormSetValueAndGetList(
      MembersListFormFields.organizationId,
      organizationsStore.selectedOrganization.id,
    );
    reportsStore.setOpenUsersModal(true);
  };

  const handleGetIgnorePollUsers = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    reportsStore.setCurrentReport(data);
    reportsStore.setOpenUsersNotCompletedListFormModal(true);
    reportsStore.getUsersNotCompletedPolls();
  };

  const handleSelectItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!data.poll?.id) {
      return;
    }

    reportsStore.toggleDeletedReportSelection(data.poll.id, e.target.checked);
  };

  const handleStopPropagation = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();
  };

  // Renders
  // TODO: Есть баг с отображением стейта чекбоксов, при покидании страницы стейт сохраняется но при этом текущий стейт не применяется к чекбоксам
  const elements = useMemo<Record<ReportsTableColumns, ReactNode>>(() => {
    return {
      id: (
        <TableCell align="left">
          <div className={classes.checkboxWrap}>
            <QCheckbox
              value={!!reportsStore.deletedReportsSelection?.[data.poll?.id ?? '']}
              onClick={handleStopPropagation}
              onChange={handleSelectItem}
            />
          </div>
          {data.poll?.id}
        </TableCell>
      ),
      background: (
        <TableCell align="left">
          <div className={classes.content}>
            <div className={classes.imgWrap}>
              <img src={data.poll?.background ?? cardImg} alt="" />
            </div>
          </div>
        </TableCell>
      ),
      name: <TableCell align="left">{data.poll?.name}</TableCell>,
      users: (
        <TableCell align="center">
          <TextButton className={classes.link} color="secondary" onClick={handleGetPollUsers}>
            {data.users} чел.
          </TextButton>
        </TableCell>
      ),
      created_at: <TableCell align="center">{data.poll?.created_at?.formatted}</TableCell>,
      users_ignore_poll: (
        <TableCell align="center">
          <TextButton className={classes.link} color="secondary" onClick={handleGetIgnorePollUsers}>
            {data.users_ignore_poll} чел.
          </TextButton>
        </TableCell>
      ),
    };
  }, [data, reportsStore.deletedReportsSelection]);

  return <>{elements?.[elementId] ? elements?.[elementId] : null}</>;
});

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 315,
  },
  imgWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 53,
    height: 53,
    overflow: 'hidden',
    borderRadius: 3,
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(0deg, rgba(57, 57, 57, 0.4), rgba(57, 57, 57, 0.4))',
      zIndex: -1,
    },
  },
  link: {
    textDecoration: 'underline',
  },
  checkboxWrap: {
    marginLeft: theme.spacing(-5.75),
    height: 0,
  },
}));
