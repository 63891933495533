import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { QPagination } from 'components/UI/QPagination';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';

interface IPollsPaginationProps {}

export const PollsPagination: React.FC<IPollsPaginationProps> = observer(() => {
  const { pollsStore } = useRootStore();

  // Handlers
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    pollsStore.pollsListFormSetValueAndGetList(TableFormFields.page, page);
  };

  // Renders
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pb: 5 }}>
      <QPagination
        color="primary"
        defaultPage={1}
        page={pollsStore.pollsListForm.page}
        count={pollsStore.pollsListForm.pageCount}
        onChange={handleChangePage}
      />
    </Box>
  );
});
