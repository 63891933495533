import { IPollActionMenuItem } from 'modules/polls/interfaces/PollsInterfaces';
import { PollActionMenuTypes } from 'modules/polls/types/PollsTypes';

export const pollItemActionMenuList: IPollActionMenuItem[] = [
  { id: PollActionMenuTypes.startPoll, name: 'Запустить опрос' },
  { id: PollActionMenuTypes.stopPoll, name: 'Остановить опрос' },
  { id: PollActionMenuTypes.edit, name: 'Редактировать' },
  { id: PollActionMenuTypes.report, name: 'Отчет' },
  { id: PollActionMenuTypes.duplicate, name: 'Дублировать' },
  { id: PollActionMenuTypes.rename, name: 'Переименовать' },
  { id: PollActionMenuTypes.delete, name: 'Удалить' },
];
