import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { QModal } from 'components/UI/QModal';
import { QPagination } from 'components/UI/QPagination';
import { CommonHelper } from 'helpers/CommonHelper';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { MembersListFormFields } from 'modules/organizations/forms/MembersListForm';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';

import { MembersTable } from './components/MembersTable';

interface IMembersModalWidgetProps extends Partial<IQModalProps> {}

export const MembersModalWidget: React.FC<IMembersModalWidgetProps> = observer(props => {
  const { baseStore, organizationsStore } = useRootStore();
  const { open, ...rest } = props;
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  // Effects
  useEffect(() => {
    if (
      !organizationsStore.organizationMembersList?.length &&
      organizationsStore.selectedOrganization?.id &&
      roleMatrix?.viewMembers
    ) {
      organizationsStore.membersListFormSetValueAndGetList(
        MembersListFormFields.organizationId,
        organizationsStore.selectedOrganization.id,
      );
    }
  }, [
    organizationsStore.organizationMembersList,
    organizationsStore.selectedOrganization,
    organizationsStore.currentUserInOrganizationRole,
  ]);

  // Handlers
  const handleClose = () => {
    baseStore.setMembersModalOpen(false);
  };

  // Handlers
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    organizationsStore.membersListFormSetValueAndGetList(TableFormFields.page, page);
  };

  const handleOpenInviteUserModal = () => {
    organizationsStore.setMembersInvitationModal(true);
  };

  // TODO: Потом нужно изменить ширину модалки на 930 и пофиксить таблицу как в дизайне
  return (
    <QModal open={baseStore.membersModalOpen} onClose={handleClose} {...rest} modalMaxWidthValue={1200}>
      <Box sx={{ mb: 0.75 }}>
        <MembersTable />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {organizationsStore.membersListForm.showPagination && (
          <QPagination
            color="primary"
            defaultPage={1}
            page={organizationsStore.membersListForm.page}
            count={organizationsStore.membersListForm.pageCount}
            onChange={handleChangePage}
          />
        )}

        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          color="primary"
          disabled={
            !CommonHelper.hasLength(organizationsStore.ownershipOrganizations) ||
            !roleMatrix?.invitationToTheOrganization
          }
          onClick={handleOpenInviteUserModal}
        >
          Добавить участника
        </Button>
      </Box>
    </QModal>
  );
});
