import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollTypes } from 'modules/polls/types/PollsTypes';

import { BatchImageProgressModal } from './BatchImageUpload/BatchImageProgressModal';
import { BatchImageUploadButton } from './BatchImageUpload/BatchImageUploadButton';
import { BatchImageUploadCancelConfirmationModal } from './BatchImageUpload/BatchImageUploadCancelConfirmationModal';
import { BatchImageUploadModal } from './BatchImageUpload/BatchImageUploadModal';
import { ConstructorBlocksList } from './ConstructorBlocks/ConstructorBlocksList';
import { PollDescriptionFormComponent } from './ConstructorFormComponent/PollDescriptionFormComponent';
import { PollNameFormComponent } from './ConstructorFormComponent/PollNameFormComponent';

interface IConstructorFormComponentProps {}

export const ConstructorFormComponent: React.FC<IConstructorFormComponentProps> = observer(() => {
  const { classes } = useStyles();
  const { pollsStore, baseStore, fileStore } = useRootStore();
  const params = useParams<{ type?: PollTypes }>();

  const { control, handleSubmit } = useFormContext<PollCreateAndEditForm>();
  const blocksList = useFieldArray({ name: `${PollCreateAndEditFormFields.blocks}`, control });

  const [openBatchImageUploadModal, setOpenBatchImageUploadModal] = useState(false);
  const [openBatchImageProgressModal, setOpenBatchImageProgressModal] = useState(false);
  const [cancelUploadImageModal, setCancelUploadImageModal] = useState(false);
  const [imagesCount, setImagesCount] = useState(0);
  const [uploadedFilesList, setUploadedFilesList] = useState<string[] | null>(null);

  // Handlers
  const handleOpenBatchImageUploadModal = () => {
    setOpenBatchImageUploadModal(true);
  };

  const handleCloseBatchImageUploadModal = () => {
    setOpenBatchImageUploadModal(false);
    setUploadedFilesList(null);
  };

  const handleOpenBatchImageProgressModal = () => {
    setOpenBatchImageProgressModal(true);
  };

  const handleCloseBatchImageProgressModal = () => {
    setOpenBatchImageProgressModal(false);
  };

  const handleUploadFile = () => {
    if (!params?.type?.length || !uploadedFilesList?.length) {
      return;
    }

    const blocksListArray = pollsStore.getPollContructorBlocksForm(params.type, uploadedFilesList, imagesCount);
    // TODO: Не нашел другого решение кроме submit, если обращаться к blockList.fields[0].title то title оказывается пустым
    handleSubmit(values => {
      if (PollsHelper.isFormBlocksEmpty(values.blocks)) {
        blocksList.replace(blocksListArray);
      } else {
        blocksList.append(blocksListArray);
      }

      handleCloseBatchImageUploadModal();
    })();
  };

  const handleSuccessUploadFiles = (filesList: string[]) => {
    setUploadedFilesList(filesList);
    handleCloseBatchImageProgressModal();
    handleOpenBatchImageUploadModal();
  };

  const handleErrorUploadFiles = () => {
    setUploadedFilesList(null);
    handleCloseBatchImageProgressModal();
    handleCloseBatchImageUploadModal();
  };

  const handleSetImagesCount = (imagesCount: number) => {
    setImagesCount(imagesCount);
  };

  const handleUploadAction = () => {
    handleOpenBatchImageProgressModal();
  };

  const handleCancelUpload = () => {
    handleOpenCancelUploadImageModal();
  };

  const handleOpenCancelUploadImageModal = () => {
    setCancelUploadImageModal(true);
  };

  const handleCloseCancelUploadImageModal = () => {
    setCancelUploadImageModal(false);
  };

  const handleCancelItUploading = () => {
    handleCloseCancelUploadImageModal();

    handleCloseBatchImageUploadModal();

    if (fileStore.loading) {
      handleCloseBatchImageProgressModal();
      baseStore.abortRequest();
      return;
    }
  };

  const handleContinueUploading = () => {
    handleCloseCancelUploadImageModal();
  };

  const handleGoBack = () => {
    handleOpenCancelUploadImageModal();
  };

  // Renders
  return (
    <>
      <div className={classes.topLineWrap}>
        <PollNameFormComponent />
        <Box sx={{ mb: 3 }}>
          <PollDescriptionFormComponent />
        </Box>
        <div className={classes.uploadBtnWrap}>
          <BatchImageUploadButton
            blocksListFieldArray={blocksList}
            onHandleUploadAction={handleUploadAction}
            onHandleErrorUploadFiles={handleErrorUploadFiles}
            onHandleSuccessUploadFiles={handleSuccessUploadFiles}
          />
        </div>
      </div>

      <div className={classes.blocksList}>
        <ConstructorBlocksList blocksListFieldArray={blocksList} />
      </div>

      <div className={classes.btnWrap}>
        <Button fullWidth sx={{ maxWidth: 260 }} type="submit" variant="contained" color="secondary">
          Сохранить
        </Button>
      </div>

      <BatchImageProgressModal open={openBatchImageProgressModal} onHandleCancelUpload={handleCancelUpload} />
      <BatchImageUploadCancelConfirmationModal
        open={cancelUploadImageModal}
        onHandleCancelIt={handleCancelItUploading}
        onHandleContinueUploading={handleContinueUploading}
      />

      <BatchImageUploadModal
        open={openBatchImageUploadModal}
        imagesCount={imagesCount}
        uploadedFilesListCount={uploadedFilesList?.length ?? 0}
        onHandleGoBack={handleGoBack}
        onHandleUploadFile={handleUploadFile}
        onHandleSetImagesCount={handleSetImagesCount}
      />
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  blocksList: {
    marginBottom: theme.spacing(15),
  },
  topLineWrap: {
    width: '100%',
    maxWidth: 712,
    margin: theme.spacing(0, 'auto', 4),
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadBtnWrap: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 320,
    margin: '0 auto',
  },
}));
