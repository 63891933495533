import { Serializable, jsonProperty } from 'ts-serializable';

import { DateModel } from 'modules/base/models/DateModel';
import { RoleModel } from 'modules/organizations/models/RoleModel';

import { ProfileGenderTypes } from '../types/UsersTypes';

export class UserModel extends Serializable {
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(String, null) name: string | null = null;
  @jsonProperty(String, null) email: string | null = null;
  @jsonProperty(DateModel, null) email_verified_at: DateModel | null = null;
  @jsonProperty(DateModel, null) birthday: DateModel | null = null;
  @jsonProperty(String, null) gender: ProfileGenderTypes | null = null;
  @jsonProperty(String, null) avatar: string | null = null;
  @jsonProperty(Boolean) active: boolean = false;
  @jsonProperty(DateModel, null) created_at: DateModel | null = null;
  @jsonProperty(String, null) city: string | null = null;
  @jsonProperty(DateModel, null) updated_at: DateModel | null = null;
  @jsonProperty(RoleModel, null) role: RoleModel | null = null;
}
