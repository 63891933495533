import { PaginationModel } from 'base/modules/pagination/models/PaginationModel';
import { ResponseWithPagination } from 'base/modules/pagination/types/PaginationTypes';
import { SortService } from 'base/modules/sort/SortService';
import { SortValue } from 'base/modules/sort/models/SortValue';
import { ParamsGenerationHelper } from 'helpers/ParamsGenerationHelper';
import { UserInOrganizationModel } from 'modules/users/models/UserInOrganizationModel';
import { UserModel } from 'modules/users/models/UserModel';

import { OrganizationsDataSource } from './OrganizationsDataSource';
import { OrganizationsFactory } from './OrganizationsFactory';
import { MEMBERS_SHORT_DISPLAY_DATE_FORMAT } from './consts/consts';
import { CreateInviteInOrganizationForm } from './forms/CreateInviteInOrganizationForm';
import { InviteUserInOrganizationForm } from './forms/InviteUserInOrganizationForm';
import { MembersListForm } from './forms/MembersListForm';
import { OrganizationCreateForm } from './forms/OrganizationCreateForm';
import { InviteInOrganizationModel } from './models/InviteInOrganizationModel';
import { OrganizationModel } from './models/OrganizationModel';
import { RoleModel } from './models/RoleModel';
import { OrganizationsApiRepository } from './repositories/OrganizationsApiRepository';
import { OrganizationsLocalRepository } from './repositories/OrganizationsLocalRepository';
import { OrganizationRoles } from './types/OrganizationTypes';

export class OrganizationsService {
  static readonly MEMBERS_LIST_LIMIT: number = 10;

  private organizationsApi: OrganizationsApiRepository;
  private organizationsLocalApi: OrganizationsLocalRepository;
  private organizationsFactory: OrganizationsFactory;
  private sortService: SortService;
  private organizationsDataSource: OrganizationsDataSource;

  constructor() {
    this.organizationsApi = new OrganizationsApiRepository();
    this.organizationsLocalApi = new OrganizationsLocalRepository();
    this.organizationsFactory = new OrganizationsFactory();
    this.sortService = new SortService();
    this.organizationsDataSource = new OrganizationsDataSource();
  }

  createOrganization = async (form: OrganizationCreateForm): Promise<OrganizationModel> => {
    const organizationCreateDto = this.organizationsDataSource.prepareOrganizationCreateFormDto(form);
    const { data } = await this.organizationsApi.createOrganization(organizationCreateDto);

    return this.organizationsFactory.createOrganizationModel(data.data);
  };

  deleteOrganization = async (organizationId: number): Promise<void> => {
    return this.organizationsApi.deleteOrganization(organizationId);
  };

  getOrganizationMembers = async (form: MembersListForm): ResponseWithPagination<UserModel[]> => {
    const dto = this.organizationsDataSource.prepareMembersListFormDto(form);
    const params = ParamsGenerationHelper.getSerializedParams(dto);

    const { data } = await this.organizationsApi.getOrganizationMembers(form.organizationId ?? 0, params);

    const list = this.organizationsFactory.createUsersList(data.data.items, MEMBERS_SHORT_DISPLAY_DATE_FORMAT);
    const paginationMeta = this.organizationsFactory.create<PaginationModel>(PaginationModel, data.data.paginationMeta);

    return { items: list, paginationMeta };
  };

  kickMember = async (organizationId: number, userId: number): Promise<OrganizationModel> => {
    const kickMemberDto = this.organizationsDataSource.prepareKickMemberDto(userId);
    const { data } = await this.organizationsApi.kickMember(organizationId, kickMemberDto);

    return this.organizationsFactory.createOrganizationModel(data.data);
  };

  getMembershipOrganizations = async (): Promise<OrganizationModel[]> => {
    const { data } = await this.organizationsApi.getMembershipOrganizations();

    return this.organizationsFactory.createOrganizationModelList(data.data);
  };

  getOwnershipOrganizations = async (): Promise<OrganizationModel[]> => {
    const { data } = await this.organizationsApi.getOwnershipOrganizations();

    return this.organizationsFactory.createOrganizationModelList(data.data);
  };

  createInviteInOrganization = async (
    organizationId: number,
    form: CreateInviteInOrganizationForm,
  ): Promise<InviteInOrganizationModel> => {
    const dto = this.organizationsDataSource.prepareCreateInviteOrganizationDto(form);
    const { data } = await this.organizationsApi.createInviteInOrganization(organizationId, dto);

    return this.organizationsFactory.create<InviteInOrganizationModel>(InviteInOrganizationModel, data.data);
  };

  joinOrganizationByLink = async (inviteLink: string): Promise<void> => {
    const joinOrganizationDto = this.organizationsDataSource.prepareJoinOrganizationDto(inviteLink);

    return this.organizationsApi.joinOrganizationByLink(joinOrganizationDto);
  };

  inviteUserInOrganization = async (form: InviteUserInOrganizationForm): Promise<void> => {
    const inviteUserInOrganizationDto = this.organizationsDataSource.prepareInviteUserInOrganizationDto(form);
    const choosenOrganizationId = Number(form.choosenOrganization);

    return this.organizationsApi.inviteUserInOrganization(choosenOrganizationId, inviteUserInOrganizationDto);
  };

  generateInviteLink = async (organizationId: number): Promise<string> => {
    const { data } = await this.organizationsApi.generateInviteLink(organizationId);

    return data.data?.url ?? '';
  };

  getOrganizationRolesList = async (): Promise<RoleModel[]> => {
    const { data } = await this.organizationsApi.getOrganizationRolesList();

    return this.organizationsFactory.createList<RoleModel>(RoleModel, data.data);
  };

  changeRole = async (organizationId: number, userId: number, roleId: number): Promise<void> => {
    const changeRoleDto = this.organizationsDataSource.prepareChangeRoleDto(userId, roleId);

    return this.organizationsApi.changeRole(organizationId, changeRoleDto);
  };

  getCurrentUserInOrganizationRole = async (organizationId: number): Promise<UserInOrganizationModel> => {
    const { data } = await this.organizationsApi.getCurrentUserInOrganizationRole(organizationId);

    return this.organizationsFactory.createUsersInOrganizationsModel(data.data);
  };

  getLocalJoinLink = (): string | null => {
    return this.organizationsLocalApi.get();
  };

  saveJoinLinkInLocal = (inviteLink: string): void => {
    this.organizationsLocalApi.set(inviteLink);
  };

  removeLocalJoinLink = (): void => {
    this.organizationsLocalApi.remove();
  };

  // Getters
  getSortValue = (currentOrderBy: string, sortValue: SortValue | null, withClear?: boolean): SortValue => {
    return this.sortService.getSortValue(currentOrderBy, sortValue, withClear);
  };

  getRolesHashMap = (rolesList: RoleModel[] | null) => {
    const rolesHashMap = {} as Record<OrganizationRoles, RoleModel>;

    if (!rolesList?.length) {
      return rolesHashMap;
    }

    for (let i = 0; i < rolesList.length; i++) {
      const role = rolesList[i];

      if (role.name) {
        rolesHashMap[role.name] = role;
      }
    }

    return rolesHashMap;
  };
}
