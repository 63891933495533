import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { customColors } from 'styles/colors';

interface IProfileDeleteModalProps extends IQModalProps {
  onHandleCloseModal: () => void;
  onHandleSuccessDeleteAccount?: () => void;
}

export const ProfileDeleteModal: React.FC<IProfileDeleteModalProps> = observer(props => {
  const { usersStore } = useRootStore();
  const { onHandleSuccessDeleteAccount, onHandleCloseModal, ...rest } = props;

  // Handlers
  const handleDeleteAccount = () => {
    usersStore.changeUserStatus(() => {
      onHandleCloseModal();
      onHandleSuccessDeleteAccount?.();
    });
  };

  const handleCloseModal = () => {
    onHandleCloseModal();
  };

  // Renders
  return (
    <QModal {...rest} onClose={handleCloseModal} modalMaxWidthValue={610}>
      {usersStore.changeUserStatusLoading && <Loader isAbsolute />}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Удалить аккаунт
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Вы уверены, что хотите удалить аккаунт? Все данные будут безвозвратно удалены.
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="error" sx={{ mb: 2 }} onClick={handleDeleteAccount}>
            Да, удалить
          </Button>
          <Button fullWidth variant="outlined" color="primary" sx={{ mb: 2 }} onClick={handleCloseModal}>
            Нет, не надо
          </Button>
        </Box>
      </Box>
    </QModal>
  );
});
