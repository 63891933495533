import { NotificationMessages } from 'types/CommonTypes';

export enum AuthNotificationMessagesKeys {
  accountVerify = 'accountVerify',
  retrieveAccount = 'retrieveAccount',
  changeThePasswordWithTheCurrent = 'changeThePasswordWithTheCurrent',
}

export const authNotificationMessages: NotificationMessages<AuthNotificationMessagesKeys> = {
  accountVerify: {
    success: 'Почта успешно подтверждена!',
    error: 'Что-то пошло не так',
  },
  retrieveAccount: {
    success: 'Аккаунт успешно восстановлен!',
    error: 'Что-то пошло не так',
  },
  changeThePasswordWithTheCurrent: {
    success: 'Пароль успешно изменен!',
    error: 'Что-то пошло не так',
  },
};
