import { IconButton, TextFieldProps, Theme, iconButtonClasses } from '@mui/material';
import { pickersDayClasses, pickersYearClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ru from 'date-fns/locale/ru';
import { useCallback } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

import { QOutlinedTextField } from './TextFields/QOutlinedTextField';

interface IQDatePickerProps extends DatePickerProps<any> {}

export const QDatePicker: React.FC<IQDatePickerProps> = props => {
  const { classes, cx } = useStyles();

  // Renders
  const renderTextField = useCallback(
    (textFieldProps: TextFieldProps) => <QOutlinedTextField {...(textFieldProps as any)} />,
    [],
  );
  const renderOpenPickerIcon = useCallback(
    (props: any) => <i {...props} className={cx('custom-icon-calendar', props.className)} />,
    [],
  );
  const renderOpenPickerButton = useCallback((props: any) => <StyledIconButton {...props} />, []);

  return (
    <div>
      <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
        <DatePicker
          slotProps={{
            layout: { className: classes.pickersLayout },
            day: { classes: { selected: classes.selectedDay } },
          }}
          slots={{
            textField: renderTextField,
            openPickerIcon: renderOpenPickerIcon,
            openPickerButton: renderOpenPickerButton,
          }}
          format="dd.MM.yyyy"
          {...props}
        />
      </LocalizationProvider>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  selectedDay: {
    [`&.${pickersDayClasses.selected}`]: {
      color: theme.palette.customColors.white,
    },
  },
  pickersLayout: {
    [`& .${pickersYearClasses.yearButton}`]: {
      [`&.${pickersYearClasses.selected}`]: {
        color: theme.palette.customColors.white,
      },
    },
  },
}));

const StyledIconButton = withStyles(IconButton, (theme: Theme) => ({
  root: {
    [`&.${iconButtonClasses.root}`]: {
      padding: '10px',
      marginRight: 0,
    },
  },
}));
