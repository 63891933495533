import * as yup from 'yup';

import { InviteUserInOrganizationFormFields } from '../forms/InviteUserInOrganizationForm';

export const inviteUserInOrganizationFormScheme = yup.object({
  [InviteUserInOrganizationFormFields.choosenOrganization]: yup.string().required('Выберите организацию'),
  [InviteUserInOrganizationFormFields.userEmail]: yup
    .string()
    .email('Почта указана неверно')
    .required('Введите электронную почту'),
});
