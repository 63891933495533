import { Table, TableHead, TableCell, TableBody } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QTableHeadCellWithSortLabel } from 'components/UI/Tables/QTableHeadCellWithSortLabel';
import { QTableRow } from 'components/UI/Tables/QTableRow';
import { membersTableFields } from 'lists/organizations/membersTableFields';
import { MembersListFormFields } from 'modules/organizations/forms/MembersListForm';
import { useTableStyles } from 'styles/tableStyles';

import { MembersTableBodyElements } from './MembersTableBodyElements';

interface IMembersTableProps {}

export const MembersTable: React.FC<IMembersTableProps> = observer(() => {
  const { organizationsStore } = useRootStore();

  const { tableClasses } = useTableStyles();

  // Effects
  useEffect(() => {
    // Когда в другой странице получили организацию
    if (organizationsStore.membershipOrganizations?.length) {
      if (organizationsStore.selectedOrganization?.id) {
        organizationsStore.membersListFormSetValueAndGetList(
          MembersListFormFields.organizationId,
          organizationsStore.selectedOrganization.id,
        );
      }

      return;
    }
  }, [organizationsStore.membershipOrganizations, organizationsStore.selectedOrganization]);

  useEffect(() => {
    // Получаем организации когда открывают по ссылке страницу
    if (organizationsStore.membershipOrganizations?.length) {
      return;
    }

    organizationsStore.getMembershipOrganizations('MembersTable', organizations => {
      const defaultOrganization = organizationsStore.selectedOrganization
        ? organizationsStore.selectedOrganization
        : organizations?.[0] ?? null;

      organizationsStore.setSelectedOrganization(defaultOrganization);

      if (defaultOrganization.id) {
        organizationsStore.membersListFormSetValueAndGetList(
          MembersListFormFields.organizationId,
          defaultOrganization.id,
        );
      }
    });
  }, [organizationsStore.membershipOrganizations, organizationsStore.selectedOrganization]);

  // Handlers
  const handleSort = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    organizationsStore.membersListFormSetSortValueAndGetList(currentSort);
  };

  return (
    <>
      <Table sx={{ borderSpacing: '0 16px' }}>
        <TableHead>
          <QTableRow variant="transparent">
            {membersTableFields.map((field, index) => (
              <QTableHeadCellWithSortLabel
                key={index}
                field={field}
                sortValue={organizationsStore.membersListForm.sortValue}
                {...field.settings}
                onHandleSort={handleSort}
              />
            ))}
            <TableCell align="right" />
          </QTableRow>
        </TableHead>
        <TableBody className={tableClasses.tableRelative}>
          {organizationsStore.organizationMembersListLoading && (
            <QTableRow variant="transparent" className={tableClasses.loaderRow}>
              <TableCell>
                <Loader isAbsolute />
              </TableCell>
            </QTableRow>
          )}

          {organizationsStore.organizationMembersList?.map(organizationMember => (
            <QTableRow key={organizationMember.id} variant="transparent" className={tableClasses.talbeRowWithBorder}>
              {membersTableFields.map(field => (
                <MembersTableBodyElements key={field.column} data={organizationMember} elementId={field.column} />
              ))}
            </QTableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
});
