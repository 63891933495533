import { RoutesType } from 'base/routes/types/RouteTypes';
import { PollsChildScreenKeys } from 'modules/polls/types/PollsTypes';
import { AccountVerifyScreen } from 'screens/auth/AccountVerifyScreen';
import { ForgotPasswordScreen } from 'screens/auth/ForgotPasswordScreen';
import { ForgotPasswordSuccessScreen } from 'screens/auth/ForgotPasswordSuccessScreen';
import { LoginScreen } from 'screens/auth/LoginScreen';
import { NewPasswordScreen } from 'screens/auth/NewPasswordScreen';
import { RegisterScreen } from 'screens/auth/RegisterScreen';
import { FAQScreen } from 'screens/faq/FAQScreen';
import { JoinOrganizationScreen } from 'screens/main/JoinOrganizationScreen';
import { MainScreen } from 'screens/main/MainScreen';
import { MembersScreen } from 'screens/members/MembersScreen';
import { NotFoundScreen } from 'screens/not-found/NotFoundScreen';
import { NotificationsScreen } from 'screens/notifications/NotificationsScreen';
import { PollCreateAndEditScreen } from 'screens/poll/PollCreateAndEditScreen';
import { PollDetailScreen } from 'screens/poll/PollDetailScreen';
import { PollTemplatesScreen } from 'screens/poll/PollTemplatesScreen';
import { PollConstructorScreen } from 'screens/poll/pollChildScreens/PollConstructorScreen';
import { PollDesignScreen } from 'screens/poll/pollChildScreens/PollDesignScreen';
import { PollPreviewScreen } from 'screens/poll/pollChildScreens/PollPreviewScreen';
import { PollSettingsScreen } from 'screens/poll/pollChildScreens/PollSettingsScreen';
import { ProfileScreen } from 'screens/profile/ProfileScreen';
import { ReportDetailScreen } from 'screens/reports/ReportDetailScreen';
import { ReportsScreen } from 'screens/reports/ReportsScreen';
import { SupportScreen } from 'screens/support/SupportScreen';
import { UiComponents } from 'screens/ui-components/UiComponents';

const screens = {
  MainScreen,
  MembersScreen,
  NotificationsScreen,
  SupportScreen,
  ProfileScreen,
  UiComponents,
  FAQScreen,
  AccountVerifyScreen,
  JoinOrganizationScreen,
  PollTemplatesScreen,
  PollCreateAndEditScreen,
  PollDetailScreen,
  ReportsScreen,
  ReportDetailScreen,
  NotFoundScreen,
};

const pollsScreens = {
  PollConstructorScreen,
  PollDesignScreen,
  PollSettingsScreen,
  PollPreviewScreen,
  NotFoundScreen,
};

const authScreens = {
  LoginScreen,
  RegisterScreen,
  ForgotPasswordScreen,
  ForgotPasswordSuccessScreen,
  AccountVerifyScreen,
  NewPasswordScreen,
  JoinOrganizationScreen,
  NotFoundScreen,
};

type RoutesKeys = keyof typeof screens;
export const routes: RoutesType<RoutesKeys> = {
  MainScreen: {
    path: '/',
    title: 'Главная',
    element: <MainScreen />,
    // credentials: [roles.director],
  },
  MembersScreen: {
    path: '/members',
    title: 'Участники',
    element: <MembersScreen />,
  },
  SupportScreen: {
    path: '/support',
    title: 'Помощь',
    element: <SupportScreen />,
  },
  NotificationsScreen: {
    path: '/notifications',
    title: 'Уведомления',
    element: <NotificationsScreen />,
  },
  ProfileScreen: {
    path: '/profile',
    title: 'Профиль',
    element: <ProfileScreen />,
  },
  FAQScreen: {
    path: '/faq',
    title: 'Часто задаваемые вопросы',
    element: <FAQScreen />,
  },
  UiComponents: {
    path: '/ui-components',
    title: 'UI компоненты',
    element: <UiComponents />,
  },
  AccountVerifyScreen: {
    path: '/auth/verify/:id/:hash',
    title: 'Подтверждение почты',
    element: <AccountVerifyScreen />,
  },
  PollTemplatesScreen: {
    path: '/poll/templates',
    title: 'Создание опроса',
    element: <PollTemplatesScreen />,
  },
  PollCreateAndEditScreen: {
    path: '/poll/:action',
    title: 'Создание опроса',
    element: <PollCreateAndEditScreen />,
    hasNestedRoutes: true,
  },
  PollDetailScreen: {
    path: '/poll/detail/:pollId/:type',
    title: 'Опрос',
    element: <PollDetailScreen />,
    hasNestedRoutes: true,
  },
  JoinOrganizationScreen: {
    path: '/organization/join/:hash',
    title: 'Присоединение к организации',
    element: <JoinOrganizationScreen />,
  },
  ReportsScreen: {
    path: '/reports',
    title: 'Отчетность',
    element: <ReportsScreen />,
  },
  ReportDetailScreen: {
    path: '/reports/:reportId',
    title: 'Отчетность',
    element: <ReportDetailScreen />,
  },
  NotFoundScreen: {
    path: '/not-found',
    title: '404 Страница не найдена',
    element: <NotFoundScreen />,
  },
};

type PollsRouteKeys = keyof typeof pollsScreens;
export const pollsRoutes: RoutesType<PollsRouteKeys> = {
  PollConstructorScreen: {
    path: `${routes.PollCreateAndEditScreen.path}/:templateId/:type/${PollsChildScreenKeys.constructor}/:pollId?`,
    title: 'Конструктор опроса',
    element: <PollConstructorScreen />,
  },
  PollDesignScreen: {
    path: `${routes.PollCreateAndEditScreen.path}/:templateId/:type/${PollsChildScreenKeys.design}/:pollId?`,
    title: 'Дизайн опроса',
    element: <PollDesignScreen />,
  },
  PollSettingsScreen: {
    path: `${routes.PollCreateAndEditScreen.path}/:templateId/:type/${PollsChildScreenKeys.settings}/:pollId?`,
    title: 'Настройки опроса',
    element: <PollSettingsScreen />,
  },
  PollPreviewScreen: {
    path: `${routes.PollCreateAndEditScreen.path}/:templateId/:type/${PollsChildScreenKeys.preview}/:pollId?`,
    title: 'Предпросмотр опроса',
    element: <PollPreviewScreen />,
  },
  NotFoundScreen: {
    path: '/not-found',
    title: '404 Страница не найдена',
    element: <NotFoundScreen />,
  },
};

// Auth Routes
type AuthRouteKeys = keyof typeof authScreens;
export const authRoutes: RoutesType<AuthRouteKeys> = {
  LoginScreen: {
    path: '/auth/login',
    title: 'Вход',
    element: <LoginScreen />,
  },
  RegisterScreen: {
    path: '/auth/register',
    title: 'Регистрация',
    element: <RegisterScreen />,
  },
  ForgotPasswordScreen: {
    path: '/auth/forgot-password',
    title: 'Забыл пароль',
    element: <ForgotPasswordScreen />,
  },
  ForgotPasswordSuccessScreen: {
    path: '/auth/forgot-password-success',
    title: 'Восстановление пароля',
    element: <ForgotPasswordSuccessScreen />,
  },
  AccountVerifyScreen: {
    path: '/auth/verify/:id/:hash',
    title: 'Подтверждение почты',
    element: <AccountVerifyScreen />,
  },
  NewPasswordScreen: {
    path: '/auth/new-password/:id/:hash',
    title: 'Восстановление пароля',
    element: <NewPasswordScreen />,
  },
  JoinOrganizationScreen: {
    path: '/organization/join/:hash',
    title: 'Присоединение к организации',
    element: <JoinOrganizationScreen />,
  },
  NotFoundScreen: {
    path: '/not-found',
    title: '404 Страница не найдена',
    element: <NotFoundScreen />,
  },
};
