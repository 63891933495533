import { Box, BoxProps, LinearProgress, LinearProgressProps, Typography, TypographyProps, alpha } from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';

import { NumberHelper } from 'helpers/NumberHelper';
import { customColors } from 'styles/colors';

interface IQLinearProgressProps extends LinearProgressProps {
  wrapProps?: BoxProps;
  withLabel?: boolean;
  labelProps?: TypographyProps;
}

export const QLinearProgress: React.FC<IQLinearProgressProps> = props => {
  const { withLabel, labelProps, wrapProps, ...rest } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.wrap} {...wrapProps}>
      <StyledLinearProgress variant="determinate" {...rest} />
      {withLabel && (
        <Typography
          className={classes.label}
          color={customColors.white}
          variant="h4Bold"
          {...labelProps}
        >{`${NumberHelper.round(props.value)}%`}</Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles()(theme => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: theme.spacing(0.5),
  },
}));

const StyledLinearProgress = withStyles(LinearProgress, theme => ({
  root: {
    height: 12,
    width: '100%',
  },
  determinate: {
    borderRadius: 10,
  },
  barColorSecondary: {
    backgroundColor: theme.palette.customColors.activeGreen,
  },
  colorSecondary: {
    backgroundColor: alpha(theme.palette.customColors.grayDivider, 0.36),
  },
}));
