import { Serializable, jsonProperty } from 'ts-serializable';

import { PollsService } from '../PollsService';
import { PollsFilters } from '../types/PollsTypes';

export class PollsListFormDto extends Serializable {
  @jsonProperty(Number, null) organization_id: number | null = null;
  @jsonProperty(Number, null) limit: number | null = PollsService.POLLS_GRID_TYPE_LIST_LIMIT;
  @jsonProperty(Number, null) offset: number | null = 1;
  @jsonProperty(String, null) filter: PollsFilters | null = null;
  @jsonProperty(String, null) orderBy: string | null = null;
  @jsonProperty(String, null) search: string | null = null;
}
