import { Serializable, jsonProperty } from 'ts-serializable';

import ReportsService from '../ReportsService';

export class ReportsListFormDto extends Serializable {
  @jsonProperty(Number, null) limit: number | null = ReportsService.REPORTS_LIST_LIMIT;
  @jsonProperty(Number, null) offset: number | null = 0;
  @jsonProperty(Number, null) organization_id: number | null = null;
  @jsonProperty(String, null) orderBy: string | null = null;
  @jsonProperty(String, null) search: string | null = null;
}
