import { Avatar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';

import { UserMenu } from './UserMenu';

interface IUserPanelProps {}

export const UserPanel: React.FC<IUserPanelProps> = observer(() => {
  const { usersStore } = useRootStore();
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Handlers
  const handleOpenUserMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  // Renders
  return (
    <div className={classes.wrap}>
      <div className={classes.avatarWrap}>
        <Avatar src={usersStore.userInfo?.avatar ?? ''} />
      </div>

      <TextButton
        color="secondary"
        endIcon={<i className="custom-icon-triangle-owal-down" />}
        className={cx(classes.btn, { active: !!anchorEl })}
        onClick={handleOpenUserMenu}
      >
        {usersStore.userInfo?.name}
      </TextButton>

      <UserMenu open={!!anchorEl} anchorEl={anchorEl} onHandleClose={handleCloseUserMenu} />
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  avatarWrap: {
    marginRight: theme.spacing(1.25),
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    '& i': {
      transition: theme.transitions.create('transform'),
    },
    '&.active': {
      '& i': {
        transform: 'rotate(-180deg)',
      },
    },
  },
}));
