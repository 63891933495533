import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { configure } from 'mobx';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
// import * as Sentry from '@sentry/browser';
import { BrowserRouter } from 'react-router-dom';
import 'reflect-metadata';

import { Loader } from 'components/UI/Loader';
import { theme } from 'styles/muiTheme';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

import 'assets/icomoon/style.scss';

// StrictMode для Mobx
configure({ enforceActions: 'observed' });

// Sentry для продакшена, отлавливает ошибки приложения, включается перед публикацией
// if (process.env.NODE_ENV === 'production') {
// Sentry.init(appConfig.sentrySettings);
// }

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Loader isFixed minHeight="100vh" />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  </StyledEngineProvider>,
);

// Помогает при верстке, после можно отключить, так как при интеграции с апи может помешать
// Warning при разработке появляется из-за этого
if (module.hot) {
  module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
