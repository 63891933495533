import { ObjectToFormDataHelper } from 'helpers/ObjectToFormDataHelper';

import { UsersApiRepository } from './UsersApiRepository';
import { UsersDataSource } from './UsersDataSource';
import { UsersFactory } from './UsersFactory';
import { HelpMailForm } from './forms/HelpMailForm';
import { ProfileChangePasswordForm } from './forms/ProfileChangePasswordForm';
import { ProfileForm } from './forms/ProfileForm';
import { UserModel } from './models/UserModel';

export class UsersService {
  private usersApi: UsersApiRepository;
  private usersFactory: UsersFactory;
  private usersDataSource: UsersDataSource;

  constructor() {
    this.usersApi = new UsersApiRepository();
    this.usersFactory = new UsersFactory();
    this.usersDataSource = new UsersDataSource();
  }

  getCurrentUserInfo = async (): Promise<UserModel> => {
    const { data } = await this.usersApi.getCurrentUserInfo();

    return this.usersFactory.createUserModel(data.data);
  };

  editUser = async (form: ProfileForm) => {
    const profileFormDto = await this.usersDataSource.prepareProfileFormDto(form);
    const { data } = await this.usersApi.editUser(profileFormDto);

    return this.usersFactory.createUserModel(data.data);
  };

  sendVerify = (): Promise<void> => {
    return this.usersApi.sendVerify();
  };

  changeThePasswordWithTheCurrent = async (form: ProfileChangePasswordForm): Promise<void> => {
    const profileChangePasswordFormDto = this.usersDataSource.prepareProfileChangePasswordFormDto(form);

    return this.usersApi.changeThePasswordWithTheCurrent(profileChangePasswordFormDto);
  };

  changeUserStatus = async (): Promise<UserModel> => {
    const { data } = await this.usersApi.changeUserStatus();

    return this.usersFactory.createUserModel(data.data);
  };

  sendHelpMail = async (form: HelpMailForm): Promise<void> => {
    const formData = ObjectToFormDataHelper.getObjectPropertiesToFormdata(form);

    return this.usersApi.sendHelpMail(formData);
  };

  // Getters
  getProfileBaseForm = (userInfo: UserModel | null): ProfileForm | null => {
    return this.usersDataSource.getProfileBaseForm(userInfo);
  };

  getUserModel = (json: any): UserModel => {
    return this.usersFactory.createUserModel(json);
  };
}
