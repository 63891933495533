import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { CommonHelper } from 'helpers/CommonHelper';
import { PollMatchDefaultTemplateModel } from 'modules/polls/models/PollMatchDefaultTemplateModel';
import { PollQuestionModel } from 'modules/polls/models/PollQuestionModel';

import { PollItemImage } from './PollMediaBlockType/PollItemImage';

interface IPollMediaBlockTypeProps {
  question: PollQuestionModel;
  pollMatchDefaultTemplateModel: PollMatchDefaultTemplateModel;
}

export const PollMediaBlockType: React.FC<IPollMediaBlockTypeProps> = props => {
  const { question } = props;

  const [hasChoosenItem, setHasChoosenItem] = useState(false);

  const imagesLength = question?.answers?.length ?? 0;
  const { classes, cx } = useStyles({ imagesLength });

  // Handlers
  const handleSetChoosenItem = (hasChoosenItem: boolean) => {
    setHasChoosenItem(hasChoosenItem);
  };

  // Renders
  return (
    <div className={cx(classes.imagesWrap, { hasChoosenItem })}>
      {CommonHelper.hasLength(question?.answers) &&
        question?.answers?.map((answer, index) => (
          <PollItemImage
            key={answer.id}
            index={index}
            className={classes.item}
            imagesLength={imagesLength}
            currentQuestion={question}
            answer={answer}
            innerClassName={classes.itemInner}
            onHandleSetChoosenItem={handleSetChoosenItem}
          />
        ))}
    </div>
  );
};

const useStyles = makeStyles<{ imagesLength: number }, 'item' | 'itemInner'>()((theme, props, classes) => ({
  imagesWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    gap: theme.spacing(4),
    maxWidth: props.imagesLength > 4 ? 1200 : '100%',
    margin: '0 auto',
    '&.hasChoosenItem': {
      [`& .${classes.item}:not(.active) .${classes.itemInner}`]: {
        '&:before': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
  },
  item: {},
  itemInner: {},
}));
