import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { QMenu } from 'components/UI/QMenu/QMenu';
import { IQMenuProps } from 'interfaces/CommonComponentsInterfaces';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { routes } from 'routes/routes';
import { customColors } from 'styles/colors';
import { useCommonStyles } from 'styles/commonStyles';

interface IUserMenuProps extends Omit<IQMenuProps, 'onClose'> {
  onHandleClose: () => void;
}

export const UserMenu: React.FC<IUserMenuProps> = observer(({ onHandleClose, ...rest }) => {
  const { authStore, usersStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();
  const { commonClasses } = useCommonStyles();
  const navigate = useNavigate();

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  // Handlers
  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    authStore.logout(() => {
      handleCloseMenu();
    });
  };

  const handleCloseMenu = () => {
    onHandleClose();
  };

  const handleNavigate = (path: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    navigate(path);
    handleCloseMenu();
  };

  // Renders
  return (
    <QMenu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      variant="menu"
      MenuListProps={{ classes: { padding: classes.menuList } }}
      {...rest}
      onClose={handleCloseMenu}
    >
      {usersStore.userInfo?.email && (
        <li className={classes.menuItem}>
          <Typography variant="h4Regular" color={customColors.gray}>
            {usersStore.userInfo?.email}
          </Typography>
        </li>
      )}
      <li className={classes.menuItem}>
        <TextButton
          color="secondary"
          className={commonClasses.textButtonIcon13}
          startIcon={<i className="custom-icon-user" />}
          onClick={handleNavigate(routes.ProfileScreen.path)}
        >
          <Typography variant="h4Medium" component="span">
            Профиль
          </Typography>
        </TextButton>
      </li>
      <li className={classes.menuItem}>
        <TextButton
          color="secondary"
          className={commonClasses.textButtonIcon13}
          startIcon={<i className="custom-icon-profile-user" />}
          onClick={handleNavigate(routes.MembersScreen.path)}
          disabled={!roleMatrix?.viewMembers}
        >
          <Typography variant="h4Medium" component="span">
            Участники
          </Typography>
        </TextButton>
      </li>
      <li className={classes.menuItem}>
        <TextButton
          color="secondary"
          className={commonClasses.textButtonIcon13}
          startIcon={<i className=" custom-icon-messagequestion" />}
          onClick={handleNavigate(routes.SupportScreen.path)}
        >
          <Typography variant="h4Medium" component="span">
            Помощь
          </Typography>
        </TextButton>
      </li>
      <li className={classes.menuItem}>
        <TextButton
          color="secondary"
          className={commonClasses.textButtonIcon13}
          startIcon={<i className="custom-icon-logoutcurve" />}
          onClick={handleLogout}
        >
          <Typography variant="h4Medium" component="span">
            Выйти
          </Typography>
        </TextButton>
      </li>
    </QMenu>
  );
});

const useStyles = makeStyles()(theme => ({
  menuList: {
    padding: theme.spacing(1.25, 0),
  },
  menuItem: {
    padding: theme.spacing(0, 2.5),
    display: 'flex',
    alignItems: 'center',
    minHeight: 'auto',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));
