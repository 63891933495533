import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { OrganizationCreateModalContextKeys } from 'modules/organizations/types/OrganizationTypes';
import { OrganizationCreateModal } from 'widgets/OrganizationCreateModal/OrganizationCreateModal';

import { ReactComponent as MainScreenEmptyIllustration } from 'assets/img/icons/main-screen-empty-illustration.svg';

interface IMainEmptyContentProps {}

export const MainEmptyContent: React.FC<IMainEmptyContentProps> = observer(() => {
  const { organizationsStore } = useRootStore();
  const { classes } = useStyles();

  // Handlers
  const handleOpenOrganizationCreateModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    organizationsStore.setOrganizationCreateModal(OrganizationCreateModalContextKeys.mainEmptyContent, true);
  };

  // Renders
  return (
    <div className={classes.wrap}>
      {organizationsStore.membershipOrganizationsLoading && <Loader isAbsolute minHeight="calc(100vh - 85px)" />}
      <div className={classes.iconWrap}>
        <MainScreenEmptyIllustration />
      </div>
      <div className={classes.bottomLine}>
        <Typography variant="h1Bold" sx={{ mb: 1.5 }}>
          Здесь пока нет организации
        </Typography>
        <Typography variant="h2Medium" sx={{ mb: 2 }}>
          Создайте новую организацию или перейдите по ссылке-приглашению
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpenOrganizationCreateModal}>
          Создать организацию
        </Button>
      </div>

      <OrganizationCreateModal modalContextKey={OrganizationCreateModalContextKeys.mainEmptyContent} />
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(9, 0),
  },
  iconWrap: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  bottomLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(9),
  },
}));
