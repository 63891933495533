import { Serializable, jsonProperty } from 'ts-serializable';

import { DateModel } from 'modules/base/models/DateModel';

export class PollAnswerModel extends Serializable {
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(Number, null) poll_question_id: number | null = null;
  @jsonProperty(String, null) text: string | null = null;
  @jsonProperty(String, null) image: string | null = null;
  @jsonProperty(Number, null) order: number | null = null;
  @jsonProperty(DateModel, null) created_at: DateModel | null = null;
  @jsonProperty(DateModel, null) updated_at: DateModel | null = null;
}
