import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QMenu } from 'components/UI/QMenu/QMenu';
import { QMenuSelectedItem } from 'components/UI/QMenu/QMenuSelectedItem';
import { CommonHelper } from 'helpers/CommonHelper';
import { reportAnswersFiltersList } from 'lists/reports/reportAnswersFiltersList';
import { ReportAnswersListFormFields } from 'modules/reports/forms/ReportAnswersListForm';
import { ReportDetailFilters } from 'modules/reports/types/ReportsTypes';

interface IReportAnswersFilterMenuProps {}

export const ReportAnswersFilterMenu: React.FC<IReportAnswersFilterMenuProps> = observer(() => {
  const { reportsStore } = useRootStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { classes } = useStyles();

  // Handlers
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChooseActionType = (actionType: ReportDetailFilters | null) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    reportsStore.reportAnswersListForm.setValues({ offset: 0, page: 1 });
    reportsStore.reportAnswersListFormSetValueAndGetList(ReportAnswersListFormFields.filter, actionType);
    handleCloseMenu();
  };

  // Renders
  return (
    <>
      <Button
        variant="contained"
        color="info"
        sx={{ mr: 2 }}
        endIcon={<i className="custom-icon-filter" />}
        onClick={handleOpenMenu}
      >
        Фильтр
      </Button>
      <QMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseMenu}
        variant="selectedMenu"
        className={classes.menu}
      >
        <QMenuSelectedItem
          value=""
          selected={CommonHelper.isNullOrUndefined(reportsStore.reportAnswersListForm.filter)}
          onClick={handleChooseActionType(null)}
        >
          Отображать все
        </QMenuSelectedItem>
        {reportAnswersFiltersList.map(item => (
          <QMenuSelectedItem
            key={item.id}
            value={item.id ?? ''}
            selected={item.id === reportsStore.reportAnswersListForm.filter}
            onClick={handleChooseActionType(item.id)}
          >
            {item.name}
          </QMenuSelectedItem>
        ))}
      </QMenu>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  menu: {
    minWidth: 115,
  },
}));
