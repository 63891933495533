export enum PollsFilters {
  completed = 'completed',
  active = 'active',
}

export enum PollStatuses {
  active = 'active',
  completed = 'completed',
  disable = 'disable',
}

export enum PollsViewType {
  table = 'table',
  grid = 'grid',
}

export enum PollsActionTypes {
  delete = 'delete',
}

export enum PollsListOrderByList {
  createdAtASC = 'created_at',
  createdAtDESC = '-created_at',
}

export enum PollActionMenuTypes {
  startPoll = 'startPoll',
  stopPoll = 'stopPoll',
  edit = 'edit',
  report = 'report',
  duplicate = 'duplicate',
  rename = 'rename',
  delete = 'delete',
}

export enum PollsTableColumns {
  name = 'name',
  created_at = 'created_at',
  questions = 'questions',
  status = 'status',
  completed = 'completed',
  passability = 'passability',
}

export enum PollTemplateTypes {
  textPoll = 'text-poll',
}

export enum PollTypes {
  singleElimination = 'single_elimination',
  doubleElimination = 'double_elimination',
  oneToOne = 'one_to_one',
  default = 'default',
}

export enum PollBlockTypes {
  text = 'text',
  media = 'media',
}

export enum PollConstructorBlockFieldsTypes {
  radio = 'radio',
  checkbox = 'checkbox',
  imageWithCheckbox = 'imageWithCheckbox',
  imageWithRadio = 'imageWithRadio',
}

export enum PollRouteActionTypes {
  create = 'create',
  edit = 'edit',
}

export enum PollsChildScreenKeys {
  constructor = 'constructor',
  design = 'design',
  settings = 'settings',
  preview = 'preview',
}
