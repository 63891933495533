import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { PollsViewType } from 'modules/polls/types/PollsTypes';
import { routes } from 'routes/routes';
import { useCommonStyles } from 'styles/commonStyles';

import { PollsFilterMenu } from './PollsFilterMenu';
import { PollsMembers } from './PollsMembers';
import { PollsSortPanel } from './PollsSortPanel';
import { PollsViewTypePanel } from './PollsViewTypePanel';

interface IPollsTopPanelProps {}

export const PollsTopPanel: React.FC<IPollsTopPanelProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();
  const { commonClasses, cx } = useCommonStyles();

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const hasAccessPollCreate = roleMatrix?.pollCreate;

  // Handlers
  const handleDisable = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasAccessPollCreate) {
      e.preventDefault();
    }
  };

  // Renders
  return (
    <div className={classes.wrap}>
      <div className={classes.leftCol}>
        <div className={classes.leftPanel}>
          <PollsViewTypePanel />
          <Typography sx={{ mr: 0.5 }} variant="h1">
            Мои опросы
          </Typography>
          {/* <PollsMoreMenu /> */}
        </div>

        <PollsMembers />
      </div>
      <div className={classes.rightCol}>
        {pollsStore.pollsListForm.viewType === PollsViewType.grid && <PollsSortPanel />}
        <PollsFilterMenu />
        <Link
          className={cx({ [commonClasses.cursorDisable]: !hasAccessPollCreate })}
          to={routes.PollTemplatesScreen.path}
          onClick={handleDisable}
        >
          <Button variant="contained" color="primary" disabled={!hasAccessPollCreate}>
            Новый опрос
          </Button>
        </Link>
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 76,
  },
  leftCol: {},
  leftPanel: {
    display: 'flex',
    alignItems: 'center',
  },
  rightCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));
