import { Serializable, jsonProperty } from 'ts-serializable';

import ReportsService from '../ReportsService';

export class ReportAnswersListFormDto extends Serializable {
  @jsonProperty(Number, null) limit: number | null = ReportsService.REPORTS_LIST_LIMIT;
  @jsonProperty(Number, null) offset: number | null = 0;
  @jsonProperty(String, null) orderBy: string | null = null;
  @jsonProperty(String, null) filter: string | null = null;
  @jsonProperty(String, null) poll_id: string | null = null;
}
