import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { routes } from 'routes/routes';
import { customColors } from 'styles/colors';

interface IProfileDeleteSuccessModalProps extends IQModalProps {
  onHandleCloseModal: () => void;
}

export const ProfileDeleteSuccessModal: React.FC<IProfileDeleteSuccessModalProps> = props => {
  const { onHandleCloseModal, ...rest } = props;
  const navigate = useNavigate();

  // Handlers
  const handleNavigateMain = () => {
    handleCloseModal();
    navigate(routes.MainScreen.path);
  };

  const handleCloseModal = () => {
    onHandleCloseModal();
  };

  // Renders
  return (
    <QModal {...rest} onClose={handleCloseModal} modalMaxWidthValue={610}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Аккаунт удален!
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Ваш аккаунт успешно удален.
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '240px' }}>
          <Button fullWidth variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleNavigateMain}>
            Вернуться на главную
          </Button>
        </Box>
      </Box>
    </QModal>
  );
};
