import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { CommonHelper } from 'helpers/CommonHelper';
import { PollConstructorBlockForm } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';

interface IPollPreviewImagesTypesProps {
  blockForm: PollConstructorBlockForm;
  index: number;
}

export const PollPreviewImagesTypes: React.FC<IPollPreviewImagesTypesProps> = props => {
  const { blockForm, index } = props;
  const { getValues } = useFormContext<PollCreateAndEditForm>();

  const imagesCount = getValues().blocks[index].settings?.imagesCount ?? 4;
  const images = blockForm.images
    .slice(0, imagesCount)
    .filter(item => CommonHelper.isNotNullOrUndefined(item.image?.fileUrl));
  const imagesLength = images.length ?? 2;

  const { classes, cx } = useStyles({ imagesLength });

  // Renders
  if (!CommonHelper.hasLength(blockForm.images)) {
    return <></>;
  }

  return (
    <div className={classes.imagesWrap}>
      {CommonHelper.hasLength(blockForm.images) &&
        images.map((item, index) => {
          if (imagesLength === 2 && CommonHelper.isNotNullOrUndefined(item.image?.fileUrl)) {
            return (
              <div key={index} className={cx(classes.imageItem, classes.imageItemMd)}>
                {CommonHelper.isNotNullOrUndefined(item.image?.fileUrl) && (
                  <img src={item.image?.fileUrl ?? ''} alt="" />
                )}
                {CommonHelper.hasLength(item.text) && (
                  <div className={classes.bottomTitle}>
                    <Typography component="span" variant="h3Bold" className={classes.imageText}>
                      {item.text}
                    </Typography>
                  </div>
                )}
              </div>
            );
          }

          if (imagesLength === 4 && CommonHelper.isNotNullOrUndefined(item.image?.fileUrl)) {
            return (
              <div key={index} className={cx(classes.imageItem, classes.imageItemMd)}>
                <img src={item.image?.fileUrl ?? ''} alt="" />
                {CommonHelper.hasLength(item.text) && (
                  <div className={classes.bottomTitle}>
                    <Typography component="span" variant="h3Bold" className={classes.imageText}>
                      {item.text}
                    </Typography>
                  </div>
                )}
              </div>
            );
          }

          return CommonHelper.isNotNullOrUndefined(item.image?.fileUrl) ? (
            <div key={index} className={cx(classes.imageItem, classes.imageItemLg)}>
              {CommonHelper.isNotNullOrUndefined(item.image?.fileUrl) && <img src={item.image?.fileUrl ?? ''} alt="" />}
              {CommonHelper.hasLength(item.text) && (
                <div className={classes.bottomTitle}>
                  <Typography component="span" variant="h3Bold" className={classes.imageText}>
                    {item.text}
                  </Typography>
                </div>
              )}
            </div>
          ) : null;
        })}
    </div>
  );
};

const useStyles = makeStyles<{ imagesLength: number }>()((theme, props) => ({
  imagesWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    gap: theme.spacing(4),
    maxWidth: props.imagesLength > 4 ? 1200 : '100%',
  },
  imageItem: {
    position: 'relative',
    width: '100%',
    borderRadius: 10,
    border: `1px solid ${theme.palette.customColors.darkGrey}`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  bottomTitle: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    padding: theme.spacing(1.5),
    background: theme.palette.customColors.black,
    borderRadius: 10,
    transform: 'translate(-50%)',
    maxWidth: '100%',
  },
  imageText: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  imageItemMd: {
    maxWidth: 325,
    height: 420,
  },
  imageItemLg: {
    maxWidth: 366,
    height: 470,
  },
}));
