import { Box, BoxProps, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { CommonHelper } from 'helpers/CommonHelper';
import { getPollsChildrendScreens } from 'modules/polls/consts/consts';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollModel } from 'modules/polls/models/PollModel';
import { PollRouteActionTypes } from 'modules/polls/types/PollsTypes';
import { useGetPollCreateAndEditParams } from 'modulesHooks/polls/useGetPollCreateAndEditParams';
import { pollsRoutes } from 'routes/routes';

interface IPollPreviewTopLineProps extends BoxProps {}

export const PollPreviewTopLine: React.FC<IPollPreviewTopLineProps> = observer(props => {
  const { sx, ...rest } = props;
  const { organizationsStore, pollsStore } = useRootStore();

  const params = useParams();
  const navigate = useNavigate();
  const routeSegmentsList = params['*']?.split('/');
  const currentScreen = PollsHelper.getCurrentScreenSlug(routeSegmentsList);
  const pollsChildrendScreens = getPollsChildrendScreens();
  const hasOrganizationId = CommonHelper.isNotNullOrUndefined(organizationsStore.selectedOrganization?.id);

  const { handleSubmit, reset } = useFormContext<PollCreateAndEditForm>();
  const pollCreateAndEditParams = useGetPollCreateAndEditParams();

  const handleGoBack = () => {
    navigate(RouteHelper.makePath(pollsRoutes.PollConstructorScreen.path, pollCreateAndEditParams));
  };

  const handlePublish = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleSubmit(handleSubmitForm)();
  };

  const handleSubmitForm = (form: PollCreateAndEditForm) => {
    if (
      hasOrganizationId &&
      pollCreateAndEditParams.action === PollRouteActionTypes.edit &&
      pollCreateAndEditParams.pollId.length
    ) {
      pollsStore.editPoll(
        organizationsStore.selectedOrganization!.id!,
        pollCreateAndEditParams.pollId,
        form,
        (pollModel: PollModel) => {
          handleUpdateForm(pollModel);
          if (CommonHelper.isNotNullOrUndefined(pollModel.id)) {
            pollsStore.startPollById(pollModel.id!, organizationsStore.selectedOrganization!.id!);
          }
        },
      );
      return;
    }

    if (hasOrganizationId && pollCreateAndEditParams.action === PollRouteActionTypes.create) {
      pollsStore.createPoll(organizationsStore.selectedOrganization!.id!, form, (pollModel: PollModel) => {
        const hasData =
          currentScreen?.length &&
          pollsChildrendScreens?.[currentScreen] &&
          CommonHelper.isNotNullOrUndefined(pollModel.id);

        if (hasData) {
          const path = RouteHelper.makePath(pollsChildrendScreens[currentScreen], {
            ...pollCreateAndEditParams,
            action: PollRouteActionTypes.edit,
            pollId: pollModel.id,
          });

          navigate(path);
        }

        handleUpdateForm(pollModel);

        pollsStore.startPollById(pollModel.id!, organizationsStore.selectedOrganization!.id!);
      });

      return;
    }
  };

  const handleUpdateForm = (pollModel: PollModel) => {
    const form = pollsStore.getPollCreateAndEditForm(pollModel);
    reset(form);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...sx }} {...rest}>
      <Button variant="contained" color="secondary" onClick={handleGoBack}>
        Вернуться в конструктор
      </Button>
      <Button variant="contained" color="primary" onClick={handlePublish}>
        Опубликовать
      </Button>
    </Box>
  );
});
