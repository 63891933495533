import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { CommonHelper } from 'helpers/CommonHelper';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { MembersInvitationModal } from 'widgets/MembersInvitationModal/MembersInvitationModal';

interface IMembersTopLineProps {}

export const MembersTopLine: React.FC<IMembersTopLineProps> = observer(() => {
  const { organizationsStore } = useRootStore();
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  // Handlers
  const handleOpenMembersInvitationModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    organizationsStore.setMembersInvitationModal(true);
  };

  // Renders
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: 2 }}>
        <Typography variant="h1">Участники</Typography>

        <Button
          color="primary"
          variant="contained"
          disabled={
            !CommonHelper.hasLength(organizationsStore.ownershipOrganizations) ||
            !roleMatrix?.invitationToTheOrganization
          }
          onClick={handleOpenMembersInvitationModal}
        >
          Пригласить
        </Button>
      </Box>

      <MembersInvitationModal />
    </>
  );
});
