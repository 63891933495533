import { BaseFactory } from 'modules/base/BaseFactory';
import { DateModel } from 'modules/base/models/DateModel';
import { OrganizationModel } from 'modules/organizations/models/OrganizationModel';
import { UserModel } from 'modules/users/models/UserModel';

import { POLLS_DISPLAY_DATE_LONG_FORMAT } from './consts/consts';
import { PollAnswerModel } from './models/PollAnswerModel';
import { PollModel } from './models/PollModel';
import { PollQuestionModel } from './models/PollQuestionModel';

export class PollsFactory extends BaseFactory {
  createPollsList = (items: any[]): PollModel[] => {
    return items?.length ? items.map(this.createPollModel) : [];
  };

  createPollModel = (json: any): PollModel => {
    const pollModelData: Record<string, unknown> = {};

    if (json?.created_at && !(json.created_at instanceof DateModel)) {
      pollModelData.created_at = this.createDateModel(json.created_at, POLLS_DISPLAY_DATE_LONG_FORMAT);
    }

    if (json?.updated_at && !(json.updated_at instanceof DateModel)) {
      pollModelData.updated_at = this.createDateModel(json.updated_at, POLLS_DISPLAY_DATE_LONG_FORMAT);
    }

    if (json?.creator && !(json.creator instanceof UserModel)) {
      pollModelData.creator = this.createUserModel(json.creator, POLLS_DISPLAY_DATE_LONG_FORMAT);
    }

    if (json?.organization && !(json.organization instanceof OrganizationModel)) {
      pollModelData.organization = this.createOrganizationModel(json.organization, POLLS_DISPLAY_DATE_LONG_FORMAT);
    }

    pollModelData.questions = this.createPollQuestionsList(json?.questions);

    return this.create<PollModel>(PollModel, { ...json, ...pollModelData });
  };

  createPollQuestionsList = (items: any[]): PollQuestionModel[] => {
    return items?.length ? items.map(this.createPollQuestionModel) : [];
  };

  createPollQuestionModel = (json: any): PollQuestionModel => {
    const questionModelData: Record<string, unknown> = {};

    if (json?.created_at && !(json.created_at instanceof DateModel)) {
      questionModelData.created_at = this.createDateModel(json.created_at);
    }

    if (json?.updated_at && !(json.updated_at instanceof DateModel)) {
      questionModelData.updated_at = this.createDateModel(json.updated_at);
    }

    questionModelData.answers = this.createPollAnswerModelList(json?.answers);

    return this.create<PollQuestionModel>(PollQuestionModel, { ...json, ...questionModelData });
  };

  createPollAnswerModelList = (items: any[]): PollAnswerModel[] => {
    return items?.length ? items.map(this.createPollAnswerModel) : [];
  };

  createPollAnswerModel = (json: any): PollAnswerModel => {
    const pollAnswerModelData: Record<string, unknown> = {};

    if (json?.created_at && !(json.created_at instanceof DateModel)) {
      pollAnswerModelData.created_at = this.createDateModel(json.created_at);
    }

    if (json?.updated_at && !(json.updated_at instanceof DateModel)) {
      pollAnswerModelData.updated_at = this.createDateModel(json.updated_at);
    }

    return this.create<PollAnswerModel>(PollAnswerModel, { ...json, ...pollAnswerModelData });
  };
}
