import { TableCellProps, BoxProps, TableCell, Box, Typography } from '@mui/material';

import { SortValue } from 'base/modules/sort/models/SortValue';
import { customColors } from 'styles/colors';

import { QTableSortLabel } from './QTableSortLabel';

interface IQTableHeadCellWithSortLabelProps extends TableCellProps {
  title?: string;
  field: any | null | undefined;
  sortValue: SortValue | null;
  tableCellSettings?: BoxProps;
  onHandleSort: (column: string) => (e: React.MouseEvent<HTMLElement>) => void;
}

export const QTableHeadCellWithSortLabel: React.FC<IQTableHeadCellWithSortLabelProps> = props => {
  const { title, field, sortValue, tableCellSettings, onHandleSort, ...rest } = props;

  return (
    <TableCell {...rest}>
      <Box {...tableCellSettings}>
        {field?.isOrderable ? (
          <QTableSortLabel
            sortValue={sortValue}
            column={field?.column as string}
            onClick={onHandleSort(field?.column as string)}
          >
            <Box sx={{ mr: 0.625 }}>
              <Typography variant="h5Medium" component="div" color={customColors.darkGrey}>
                {title ? title : field?.title}
              </Typography>
            </Box>
          </QTableSortLabel>
        ) : (
          <Typography variant="h5Medium" component="div" color={customColors.darkGrey}>
            {title ? title : field?.title}
          </Typography>
        )}
      </Box>
    </TableCell>
  );
};
