import { OutlinedInput, outlinedInputClasses, OutlinedInputProps } from '@mui/material';
import { forwardRef } from 'react';
import { withStyles } from 'tss-react/mui';

interface IImageTitleTextFieldProps extends OutlinedInputProps {}

export const ImageTitleTextField: React.FC<IImageTitleTextFieldProps> = forwardRef((props, ref) => {
  return <StyledTextField fullWidth color="secondary" ref={ref} {...props} />;
});

const StyledTextField = withStyles(OutlinedInput, theme => ({
  root: {
    backgroundColor: 'transparent',
    fontWeight: 500,
    border: `1px solid ${theme.palette.customColors.darkGrey}`,
    borderRadius: 10,
    [`&:focus:not(.${outlinedInputClasses.disabled})`]: {
      outline: 'none',
    },
    [`&.${outlinedInputClasses.focused}:not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.error})`]: {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: 'transparent',
        borderWidth: '0 !important',
      },
    },
  },
  notchedOutline: {
    borderWidth: '0 !important',
  },
}));
