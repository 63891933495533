import { Collapse, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TextButton } from 'components/UI/Buttons/TextButton';
import { QSwitchWithController } from 'components/UI/Switches/QSwitchWithController';
import { pollContextsViewMatrix } from 'modules/polls/consts/consts';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollSettingsFormFields } from 'modules/polls/forms/PollSettingsForm';
import { PollTypes } from 'modules/polls/types/PollsTypes';

interface IPollTypeSettingComponentProps {}

export const PollTypeSettingComponent: React.FC<IPollTypeSettingComponentProps> = props => {
  const { control } = useFormContext<PollCreateAndEditForm>();
  const { classes, cx } = useStyles();
  const params = useParams<{ type?: PollTypes }>();

  const [openContent, setOpenContent] = useState(true);
  const currentContextShuffleSetting = pollContextsViewMatrix?.[params.type!]?.shuffle;

  // Handlers
  const handleToggleContent = () => {
    setOpenContent(!openContent);
  };

  // Renders
  return (
    <>
      <TextButton
        color="secondary"
        className={cx(classes.btn, { isActive: openContent })}
        endIcon={<i className="custom-icon-triangle-owal-down" />}
        onClick={handleToggleContent}
      >
        <Typography variant="h3Bold" component="span">
          Тип опроса
        </Typography>
      </TextButton>
      <Collapse in={openContent}>
        <div className={classes.innerCollapse}>
          <QSwitchWithController
            label="Случайный порядок"
            labelProps={{ variant: 'h3Regular' }}
            containerClassName={classes.switchContainer}
            name={`${PollCreateAndEditFormFields.settings}.${PollSettingsFormFields.shuffle}`}
            hookFormProps={{ control }}
            disabled={!currentContextShuffleSetting}
          />
        </div>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  innerCollapse: {
    paddingLeft: theme.spacing(5),
    marginTop: theme.spacing(1.5),
  },
  switchContainer: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
  btn: {
    '& i': {
      transition: theme.transitions.create('transform'),
    },
    '&.isActive': {
      '& i': {
        transform: 'rotate(-180deg)',
      },
    },
  },
}));
