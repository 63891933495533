import { jsonProperty, Serializable } from 'ts-serializable';

import { ProfileGenderTypes } from '../types/UsersTypes';

export class ProfileFormDto extends Serializable {
  @jsonProperty(String, null) name: string | null = null;
  @jsonProperty(String, null) gender: ProfileGenderTypes | null = null;
  @jsonProperty(String, null) birthday: string | null = null;
  @jsonProperty(String, null) avatar: string | null = null;
}
