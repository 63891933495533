import { IReportTableField } from 'modules/reports/interfaces/ReportsInterfaces';
import { ReportsTableColumns } from 'modules/reports/types/ReportsTypes';

export const reportsTableFields: IReportTableField[] = [
  { column: ReportsTableColumns.id, title: '№', isOrderable: false, settings: { align: 'left' } },
  { column: ReportsTableColumns.background, title: 'Картинка', isOrderable: false, settings: { align: 'left' } },
  { column: ReportsTableColumns.name, title: 'Наименование опроса', isOrderable: true, settings: { align: 'left' } },
  { column: ReportsTableColumns.users, title: 'Участники', isOrderable: true, settings: { align: 'center' } },
  { column: ReportsTableColumns.createdAt, title: 'Дата и время', isOrderable: true, settings: { align: 'center' } },
  {
    column: ReportsTableColumns.usersIgnorePoll,
    title: 'Не прошли опрос',
    isOrderable: true,
    settings: { align: 'center' },
  },
];
