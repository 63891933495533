import { Button, buttonClasses } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { CommonHelper } from 'helpers/CommonHelper';
import { getPollsChildrendScreens } from 'modules/polls/consts/consts';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollModel } from 'modules/polls/models/PollModel';
import { PollRouteActionTypes } from 'modules/polls/types/PollsTypes';
import { useGetPollCreateAndEditParams } from 'modulesHooks/polls/useGetPollCreateAndEditParams';
import { pollsRoutes, routes } from 'routes/routes';

interface IPollBreadcrumbsProps {}

export const PollBreadcrumbs: React.FC<IPollBreadcrumbsProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();
  const { classes, cx } = useStyles();

  const { reset, handleSubmit } = useFormContext<PollCreateAndEditForm>();

  const params = useParams();
  const pollCreateAndEditParams = useGetPollCreateAndEditParams();
  const navigate = useNavigate();
  const routeSegmentsList = params['*']?.split('/');
  const currentScreen = PollsHelper.getCurrentScreenSlug(routeSegmentsList);
  const pollsChildrendScreens = getPollsChildrendScreens();
  const hasOrganizationId = CommonHelper.isNotNullOrUndefined(organizationsStore.selectedOrganization?.id);

  useEffect(() => {
    if (
      PollsHelper.isPollEditScreen(params) &&
      pollCreateAndEditParams.pollId?.length &&
      !pollsStore.currentGettedPollInfo &&
      organizationsStore.selectedOrganization?.id
    ) {
      pollsStore.getPollById(
        pollCreateAndEditParams.pollId,
        organizationsStore.selectedOrganization.id,
        handleUpdateForm,
        handleErrorGetPoll,
      );
    }
  }, [pollsStore.currentGettedPollInfo, organizationsStore.selectedOrganization]);

  // Handlers
  const handleUpdateForm = (pollModel: PollModel) => {
    const form = pollsStore.getPollCreateAndEditForm(pollModel);
    reset(form);
  };

  const handleErrorGetPoll = () => {
    navigate(routes.PollTemplatesScreen.path);
  };

  const makePollBreadcrumbsPath = useCallback(
    (path: string) => RouteHelper.makePath(path, pollCreateAndEditParams),
    [params],
  );

  const handlePublish = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleSubmit(handleSubmitForm)();
  };

  const handleSubmitForm = (form: PollCreateAndEditForm) => {
    if (
      hasOrganizationId &&
      pollCreateAndEditParams.action === PollRouteActionTypes.edit &&
      pollCreateAndEditParams.pollId.length
    ) {
      pollsStore.editPoll(
        organizationsStore.selectedOrganization!.id!,
        pollCreateAndEditParams.pollId,
        form,
        (pollModel: PollModel) => {
          pollsStore.startPollById(pollModel.id ?? 0, organizationsStore.selectedOrganization!.id!);
          handleUpdateForm(pollModel);
        },
      );
    }

    if (hasOrganizationId && pollCreateAndEditParams.action === PollRouteActionTypes.create) {
      pollsStore.createPoll(organizationsStore.selectedOrganization!.id!, form, (pollModel: PollModel) => {
        const hasData =
          currentScreen?.length &&
          pollsChildrendScreens?.[currentScreen] &&
          CommonHelper.isNotNullOrUndefined(pollModel.id);

        if (hasData) {
          const path = RouteHelper.makePath(pollsChildrendScreens[currentScreen], {
            ...pollCreateAndEditParams,
            action: PollRouteActionTypes.edit,
            pollId: pollModel.id,
          });

          navigate(path);
        }

        handleUpdateForm(pollModel);
        pollsStore.startPollById(pollModel.id ?? 0, organizationsStore.selectedOrganization!.id!);
      });

      return;
    }
  };

  const handleClickNavLink = (disabled: boolean) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  // Renders
  const renderNavLink = (pathName: string, title: string, disabled: boolean = false) => {
    return (
      <NavLink
        to={makePollBreadcrumbsPath(pathName)}
        className={({ isActive }) => cx('router-link', classes.link, { disabled, [classes.linkActive]: isActive })}
        onClick={handleClickNavLink(disabled)}
      >
        <TextButton className={classes.textBtn} disabled={disabled} color="secondary">
          {title}
        </TextButton>
      </NavLink>
    );
  };

  return (
    <div className={classes.breadcrumbsWrap}>
      <div className={classes.btnWrap}>
        {renderNavLink(pollsRoutes.PollConstructorScreen.path, 'Конструктор')}
        {renderNavLink(pollsRoutes.PollDesignScreen.path, 'Дизайн', true)}
        {renderNavLink(pollsRoutes.PollSettingsScreen.path, 'Настройки')}
        {renderNavLink(pollsRoutes.PollPreviewScreen.path, 'Предпросмотр')}
      </div>

      <Button variant="contained" color="primary" onClick={handlePublish}>
        Опубликовать
      </Button>
    </div>
  );
});

const useStyles = makeStyles<void, 'textBtn'>()((theme, _, classes) => ({
  breadcrumbsWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3.75),
    background: theme.palette.customColors.lightBlue,
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(8.5),
  },
  textBtn: {
    background: 'transparent',
    padding: theme.spacing(1.375, 1.875),
    border: '1px solid transparent',
    borderRadius: 8,
    transition: theme.transitions.create('all'),
  },
  link: {
    '&.disabled': {
      cursor: 'default',
    },
  },
  linkActive: {
    [`& .${buttonClasses.root}.${buttonClasses.text}`]: {
      color: theme.palette.customColors.purple900,
      background: theme.palette.customColors.purple100,
      borderColor: theme.palette.customColors.purple300,
      '&:hover': {
        color: theme.palette.customColors.purple900,
        background: theme.palette.customColors.purple100,
        borderColor: theme.palette.customColors.purple300,
      },
    },
  },
}));
