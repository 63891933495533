import { BaseForm } from 'base/BaseForm';

export enum NotificationsSettingsFormFields {
  enablePopupNotifications = 'enablePopupNotifications',
  enableEmailDeliveryNotifications = 'enableEmailDeliveryNotifications',
}

export class NotificationsSettingsForm extends BaseForm {
  enablePopupNotifications: boolean = false;
  enableEmailDeliveryNotifications: boolean = false;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
