import { NotificationMessages } from 'types/CommonTypes';

export enum PollsNotificationMessages {
  deletePoll = 'deletePoll',
  pollStatusStarted = 'pollStatusStarted',
  pollStatusEnded = 'pollStatusEnded',
  pollsBlockFieldsMaxLength = 'pollsBlockFieldsMaxLength',
  pollCreate = 'pollCreate',
  duplicatePoll = 'duplicatePoll',
  pollEdit = 'pollEdit',
  navigatePollDetail = 'navigatePollDetail',
  pollPassed = 'pollPassed',
  pollCompletionAccess = 'pollCompletionAccess',
}

export const pollsNotificationMessages: NotificationMessages<PollsNotificationMessages> = {
  deletePoll: { success: 'Опрос успешно удален!', error: 'Что-то пошло не так' },
  pollStatusStarted: { success: 'Опрос запущен! ', error: 'Что-то пошло не так' },
  pollStatusEnded: { success: 'Опрос остановлен!', error: 'Что-то пошло не так' },
  pollsBlockFieldsMaxLength: { warning: 'Максимальное количество полей не должно превышать более 10 элементов!' },
  pollCreate: { success: 'Опрос успешно создан!', error: 'Что-то пошло не так' },
  duplicatePoll: { success: 'Опрос успешно продублирован!', error: 'Что-то пошло не так' },
  pollEdit: { success: 'Опрос успешно сохранен!', error: 'Что-то пошло не так' },
  navigatePollDetail: {
    warning: 'Внимание! Чтобы начать проходить созданный опрос, нажмите на кнопку "Запустить опрос"',
  },
  pollPassed: { warning: 'Вы уже прошли этот опрос!' },
  pollCompletionAccess: { error: 'У вас нет доступа пройти этот опрос!' },
};
