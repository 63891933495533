export enum OrganizationCreateModalContextKeys {
  header = 'header',
  mainEmptyContent = 'mainEmptyContent',
}

export enum MembersTableColumns {
  full_name = 'full_name',
  email = 'email',
  created_at = 'created_at',
  status = 'status',
  role = 'role',
}

export enum MembersModalTableColumns {
  full_name = 'full_name',
  email = 'email',
  role = 'role',
  status = 'status',
}

export enum OrganizationRoles {
  admin = 'admin',
  poll_master = 'poll_master',
  poll_participant = 'poll_participant',
}

export type RoleMatrixItem = {
  pollCreate: boolean;
  pollEdit: boolean;
  pollDelete: boolean;
  pollCompletion: boolean;
  pollResults: boolean;
  invitationToTheOrganization: boolean;
  deleteFromTheOrganization: boolean;
  changeTheRole: boolean;
  changeRoleToAdmin: boolean;
  viewMembers: boolean;
};

export type RoleMatrix = Readonly<Record<OrganizationRoles, Readonly<RoleMatrixItem>>>;
