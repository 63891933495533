import { Box, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { CommonHelper } from 'helpers/CommonHelper';
import { PollsListFormFields } from 'modules/polls/forms/PollsListForm/PollsListForm';

import { PollChangeStatusConfirmModal } from './Modals/PollChangeStatusConfirmModal';
import { PollDeleteModal } from './Modals/PollDeleteModal';
import { PollsContent } from './PollsContent/PollsContent';
import { PollsEmptyContent } from './PollsContent/PollsEmptyContent';
import { PollsPagination } from './PollsContent/PollsPagination';
import { PollsTopPanel } from './PollsTopPanel/PollsTopPanel';
import { SearchForm } from './SearchForm';

interface IPollsContainerProps {}

export const PollsContainer: React.FC<IPollsContainerProps> = observer(() => {
  const { organizationsStore, pollsStore } = useRootStore();

  // Effects
  useEffect(() => {
    if (CommonHelper.isNotNullOrUndefined(organizationsStore.selectedOrganization?.id)) {
      pollsStore.pollsListFormSetValueAndGetList(
        PollsListFormFields.organizationId,
        organizationsStore.selectedOrganization!.id,
      );
    }
  }, [organizationsStore.selectedOrganization]);

  // useEffect(() => {
  useEffect(() => {
    organizationsStore.getOrganizationRolesList();
  }, []);

  // Renders
  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ mb: 5.75 }}>
        <SearchForm />
      </Box>

      <Box sx={{ mb: 2.75 }}>
        <PollsTopPanel />
      </Box>

      <Divider sx={{ mb: 5 }} />

      {CommonHelper.hasLength(pollsStore.organizationPollsList) ? <PollsContent /> : <PollsEmptyContent />}
      {pollsStore.pollsListForm.showPagination && <PollsPagination />}
      <PollDeleteModal />
      <PollChangeStatusConfirmModal />
    </Box>
  );
});
