import { Box } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { PollBlockSettingsFormFields } from 'modules/polls/forms/PollBlockSettingsForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollBlockTypes } from 'modules/polls/types/PollsTypes';

import { ConstructorFieldTitle } from './ConstructorFieldsCommon/ConstructorFieldTitle';
import { ConstructorFieldsList } from './ConstructorFieldsList';
import { ConstructorImagesList } from './ConstructorImagesList/ConstructorImagesList';

interface IConstructorFieldsProps {
  index: number;
}

export const ConstructorFields: React.FC<IConstructorFieldsProps> = props => {
  const { index } = props;

  const { classes } = useStyles();
  const { control } = useFormContext<PollCreateAndEditForm>();

  const { field: blockTypeController } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.blockType}`,
    control,
  });

  return (
    <div className={classes.fieldsWrap}>
      <Box sx={{ maxWidth: blockTypeController.value === PollBlockTypes.text ? 375 : 658, width: '100%' }}>
        <div className={classes.titleWrap}>
          <ConstructorFieldTitle index={index} />
        </div>

        {blockTypeController.value === PollBlockTypes.text && <ConstructorFieldsList index={index} />}
        {blockTypeController.value === PollBlockTypes.media && <ConstructorImagesList index={index} />}
      </Box>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  fieldsWrap: {
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(6.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleWrap: {
    marginBottom: theme.spacing(3),
  },
}));
