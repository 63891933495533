import { Dto } from 'base/Dto';
import { PaginationService } from 'base/modules/pagination/PaginationService';
import { SortService } from 'base/modules/sort/SortService';
import { CommonHelper } from 'helpers/CommonHelper';
import { FileService } from 'modules/file/FileService';

import { AnswerTheQuestionOtherTemplatesDto } from './dto/AnswerTheQuestionOtherTemplatesDto';
import { AnswerTheQuestionsDto } from './dto/AnswerTheQuestionsDto';
import { PollsCreateFormDto } from './dto/PollsCreateFormDto/PollsCreateFormDto';
import { PollsListFormDto } from './dto/PollsListFormDto';
import { PollBlockSettingsForm } from './forms/PollBlockSettingsForm';
import { PollConstructorBlockFieldForm } from './forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockForm } from './forms/PollConstructorBlockForm';
import { PollConstructorBlockImageFieldForm } from './forms/PollConstructorBlockImageFieldForm';
import { PollCreateAndEditForm } from './forms/PollCreateAndEditForm';
import { PollDesignSettingsForm } from './forms/PollDesignSettingsForm';
import { PollOthersTemplatesAnswerForm } from './forms/PollOthersTemplatesAnswerForm';
import { PollQuestionsForm } from './forms/PollQuestionsForm';
import { PollSettingsForm } from './forms/PollSettingsForm';
import { PollsListForm } from './forms/PollsListForm/PollsListForm';
import { PollsHelper } from './helpers/PollsHelper';
import { PollModel } from './models/PollModel';
import { PollQuestionModel } from './models/PollQuestionModel';
import { PollBlockTypes, PollConstructorBlockFieldsTypes, PollTypes } from './types/PollsTypes';

export class PollsDataSource {
  private paginationService: PaginationService;
  private sortService: SortService;
  private fileService: FileService;

  constructor() {
    this.paginationService = new PaginationService();
    this.sortService = new SortService();
    this.fileService = new FileService();
  }

  // Public methods
  public prepareAnswersTheQuestionsDto = (form: PollQuestionsForm): AnswerTheQuestionsDto[] => {
    const questionsResult: AnswerTheQuestionsDto[] = [];
    const questions = Object.entries(form.questions);

    for (let i = 0; i < questions.length; i++) {
      const [question_id, answersForm] = questions[i];
      const answers = answersForm.answers ?? [];

      // Проверяем answer если есть то это означает что answers будет undefined из-за этого делаем continue
      if (answersForm.answer?.length) {
        questionsResult.push(Dto.populate(AnswerTheQuestionsDto, { question_id, answers: [answersForm.answer] }));
        continue;
      }

      const validAnswers = answers.filter(answer => answer?.length) as string[];
      if (validAnswers.length) {
        questionsResult.push(Dto.populate(AnswerTheQuestionsDto, { question_id, answers: validAnswers }));
      }
    }

    return questionsResult;
  };

  public prepareOrganizationPollsDto = (form: PollsListForm): PollsListFormDto => {
    const paginationData = this.paginationService.getPaginationData(form.page, form.currentContextLimit);
    const sortParam = this.sortService.getSort(form?.sortValue ?? null);
    const preDto = {
      organization_id: form.organizationId,
      orderBy: sortParam.orderBy,
      search: form.search?.trim() ?? '',
    };

    return Dto.populate(PollsListFormDto, { ...form, ...preDto, ...paginationData });
  };

  public preparePollCreateFormDto = (organizationId: number, form: PollCreateAndEditForm): PollsCreateFormDto => {
    const dto = Dto.populate(PollsCreateFormDto, {
      ...form,
      organization_id: organizationId,
      background: form.design.background,
      shuffle: form.settings.shuffle,
      with_description: form.settings.descriptionVisible,
      with_progress_bar: form.settings.progressBar,
      one_by_one: form.settings.showOneBlock,
      with_notifications: form.settings.newRepliesNotify,
      questions: this.preparePollQuestionsListDto(form),
    });

    return dto;
  };

  public getPollCreateAndEditForm = (
    pollModel: PollModel,
    settedData?: Record<string, unknown>,
  ): PollCreateAndEditForm => {
    return PollCreateAndEditForm.create({
      ...pollModel,
      design: this.getPollDesignSettingsForm(pollModel),
      blocks: this.getPollConstructorBlockFormList(pollModel),
      settings: this.getPollSettingsForm(pollModel),
      ...settedData,
    });
  };

  public getPollCreateAndEditCurrentContextDefaultForm = (pollType: PollTypes): PollCreateAndEditForm => {
    switch (pollType) {
      case PollTypes.singleElimination:
      case PollTypes.doubleElimination:
      case PollTypes.oneToOne:
        return this.getWithoutDefaultTemplateDefaultForm(pollType);
      default:
        return PollCreateAndEditForm.create<PollCreateAndEditForm>();
    }
  };

  public getCurrentPollTemplateDefaultBlockForm = (pollType: PollTypes): PollConstructorBlockForm => {
    const blockForm = PollConstructorBlockForm.create<PollConstructorBlockForm>();

    switch (pollType) {
      case PollTypes.singleElimination:
      case PollTypes.doubleElimination:
      case PollTypes.oneToOne:
        blockForm.images = blockForm.images.slice(2);

        if (blockForm.settings) {
          blockForm.settings.blockType = PollBlockTypes.media;
          blockForm.settings.imagesCount = 2;
          blockForm.settings.manyAnswers = false;
        }
    }

    return blockForm;
  };

  prepareAnswerTheQuestionOtherTemplatesDto = (form: PollOthersTemplatesAnswerForm) => {
    return Dto.populate(AnswerTheQuestionOtherTemplatesDto, { answer_id: form.answerId });
  };

  // Private methods
  private getPollDesignSettingsForm = (pollModel: PollModel): PollDesignSettingsForm => {
    return PollDesignSettingsForm.create({ background: pollModel.background });
  };

  private getPollSettingsForm = (pollModel: PollModel): PollSettingsForm => {
    return PollSettingsForm.create({
      shuffle: pollModel.shuffle ?? false,
      descriptionVisible: pollModel.with_description ?? false,
      progressBar: pollModel.with_progress_bar ?? false,
      showOneBlock: pollModel.one_by_one ?? false,
      newRepliesNotify: pollModel.with_notifications,
    });
  };

  private getPollConstructorBlockForm = (
    pollQuestionModel: PollQuestionModel,
    index: number,
  ): PollConstructorBlockForm => {
    const blockType = pollQuestionModel.is_media ? PollBlockTypes.media : PollBlockTypes.text;
    const fields =
      blockType === PollBlockTypes.text
        ? this.getPollConstructorBlockFieldFormList(pollQuestionModel)
        : Array(4).fill(PollConstructorBlockFieldForm.create({ type: PollConstructorBlockFieldsTypes.checkbox }));
    const images =
      blockType === PollBlockTypes.media
        ? this.getPollConstructorBlockImageFieldFormList(pollQuestionModel)
        : Array(6).fill(PollConstructorBlockImageFieldForm.create());

    return PollConstructorBlockForm.create({
      ...pollQuestionModel,
      title: pollQuestionModel.title,
      settings: this.getPollBlockSettingsForm(pollQuestionModel),
      order: index,
      fields,
      images,
    });
  };

  private getPollConstructorBlockFieldFormList = (
    pollQuestionModel: PollQuestionModel,
  ): PollConstructorBlockFieldForm[] => {
    if (!pollQuestionModel.answers?.length) {
      return [];
    }

    const result: PollConstructorBlockFieldForm[] = [];

    for (let i = 0; i < pollQuestionModel.answers.length; i++) {
      const element = pollQuestionModel.answers[i];

      const pollConstructorBlockFieldForm = PollConstructorBlockFieldForm.create<PollConstructorBlockFieldForm>({
        ...element,
        type: PollsHelper.getValidFieldType(element, pollQuestionModel),
        image: this.fileService.createLocalFileWithFileUrl(element.image),
      });

      result.push(pollConstructorBlockFieldForm);
    }

    return result;
  };

  private getPollConstructorBlockImageFieldFormList = (
    pollQuestionModel: PollQuestionModel,
  ): PollConstructorBlockImageFieldForm[] => {
    if (!pollQuestionModel?.answers?.length) {
      return [];
    }

    const images = pollQuestionModel?.answers?.map(item =>
      PollConstructorBlockImageFieldForm.create<PollConstructorBlockImageFieldForm>({
        ...item,
        image: this.fileService.createLocalFileWithFileUrl(item.image),
      }),
    );

    const defaultFormList = [];
    for (let i = images.length; i < 6; i++) {
      defaultFormList.push(PollConstructorBlockImageFieldForm.create());
    }

    return [...images, ...defaultFormList];
  };

  private getPollConstructorBlockFormList = (pollModel: PollModel): PollConstructorBlockForm[] => {
    return pollModel.questions?.length ? pollModel.questions.map(this.getPollConstructorBlockForm) : [];
  };

  private getPollBlockSettingsForm = (pollQuestionModel: PollQuestionModel): PollBlockSettingsForm => {
    const imagesCount = pollQuestionModel.is_media ? pollQuestionModel.answers?.length : 4;

    return PollBlockSettingsForm.create({
      blockType: pollQuestionModel.is_media ? PollBlockTypes.media : PollBlockTypes.text,
      manyAnswers: pollQuestionModel.has_many_answers,
      imagesCount: imagesCount ?? 4,
      // TODO: Нужно добавить недостающие поля, titleVisible, background
    });
  };

  private preparePollQuestionsListDto = (form: PollCreateAndEditForm) => {
    return form.blocks.map((block, index) => {
      return {
        title: block.title,
        order: index,
        is_media: block.settings?.blockType === PollBlockTypes.media,
        has_many_answers: !!block.settings?.manyAnswers,
        answers: this.preparePollQuestionsAnswersListDto(block),
      };
    });
  };

  private preparePollQuestionsAnswersListDto = (
    pollConstructorBlockForm: PollConstructorBlockForm,
  ): { text: string | null; image: string | null; order: number | null }[] => {
    const blockType = pollConstructorBlockForm.settings?.blockType ?? PollBlockTypes.text;
    const imagesCount = pollConstructorBlockForm.settings?.imagesCount ?? 4;

    const media = pollConstructorBlockForm.images
      .map((item, index) => ({ order: index, text: item.text, image: item.image?.fileUrl ?? null }))
      .filter(item => CommonHelper.hasLength(item.image))
      .slice(0, imagesCount);

    const text = pollConstructorBlockForm.fields
      .map((item, index) => ({ order: index, text: item.text, image: item.image?.fileUrl ?? null }))
      .filter(item => CommonHelper.hasLength(item.text?.trim()));

    const blockTypesContent = { media, text };
    const currentBlockTypeContent = blockTypesContent?.[blockType] ?? [];

    return currentBlockTypeContent;
  };

  private getWithoutDefaultTemplateDefaultForm = (pollType: PollTypes): PollCreateAndEditForm => {
    const defaultForm = PollCreateAndEditForm.create<PollCreateAndEditForm>();

    defaultForm.settings.showOneBlock = true;
    defaultForm.settings.shuffle = true;
    defaultForm.template = pollType;

    defaultForm.blocks = defaultForm.blocks.map(block => {
      if (block.settings?.blockType) {
        block.settings.blockType = PollBlockTypes.media;
        block.settings.imagesCount = 2;
        block.settings.manyAnswers = false;
      }
      return block;
    });

    return defaultForm;
  };
}
