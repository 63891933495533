import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BoxProps, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { ForgotPasswordForm, ForgotPasswordFormFields } from 'modules/auth/forms/auth/ForgotPasswordForm';
import { forgotPasswordScheme } from 'modules/auth/schemes/forgotPasswordScheme';
import { authRoutes } from 'routes/routes';

interface IForgotPasswordFormComponentProps extends BoxProps {}

export const ForgotPasswordFormComponent: React.FC<IForgotPasswordFormComponentProps> = observer(props => {
  const { authStore } = useRootStore();

  const location = useLocation();
  const navigate = useNavigate();

  const forgotPasswordForm = useForm<ForgotPasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordScheme),
    defaultValues: ForgotPasswordForm.create(),
  });

  // Effects
  useEffect(() => {
    if (location.state?.email?.length) {
      forgotPasswordForm.reset(ForgotPasswordForm.create({ email: location.state.email }));
    }
  }, [location.state]);

  // Handlers
  const handleSubmit = (form: ForgotPasswordForm) => {
    authStore.forgotPassword(form, () => {
      navigate(authRoutes.ForgotPasswordSuccessScreen.path, { state: { email: form.email } });
    });
  };

  return (
    <Box sx={{ mx: 'auto', maxWidth: '315px' }}>
      <Box component="form" {...props} onSubmit={forgotPasswordForm.handleSubmit(handleSubmit)}>
        <Box sx={{ mb: 2.5 }}>
          <QOutlinedTextFieldWithController
            label="Электронная почта"
            placeholder="Введите электронную почту"
            name={ForgotPasswordFormFields.email}
            hookFormProps={{ control: forgotPasswordForm.control }}
          />
        </Box>

        <Button fullWidth type="submit" variant="contained" color="primary">
          Отправить ссылку
        </Button>
      </Box>
    </Box>
  );
});
