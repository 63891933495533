import { Typography, alpha } from '@mui/material';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { CommonHelper } from 'helpers/CommonHelper';
import {
  PollOthersTemplatesAnswerForm,
  PollOthersTemplatesAnswerFormFields,
} from 'modules/polls/forms/PollOthersTemplatesAnswerForm';
import { PollMatchOthersTemplateModel } from 'modules/polls/models/PollMatchOthersTemplateModel';

import { ReactComponent as HeartUnchecked } from 'assets/img/icons/fav-icon.svg';
import { ReactComponent as FavoriteBorderedIcon } from 'assets/img/icons/favorite-bordered-icon.svg';

interface IPollMatchItemProps extends React.HTMLAttributes<HTMLDivElement> {
  innerClassName: string;
  pollMatchModel: PollMatchOthersTemplateModel | null;
}

export const PollMatchItem: React.FC<IPollMatchItemProps> = props => {
  const { pollMatchModel, className, innerClassName, ...rest } = props;
  const { classes, cx } = useStyles();
  const { control } = useFormContext<PollOthersTemplatesAnswerForm>();

  const answerId = pollMatchModel?.id ?? 0;
  const { field: answerController } = useController({
    control,
    name: `${PollOthersTemplatesAnswerFormFields.answerId}`,
  });

  const currentValue = answerController.value === answerId;

  // Handlers
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (answerController.value === answerId) {
      answerController.onChange(undefined);

      return;
    }

    answerController.onChange(answerId);
  };

  // Renders
  if (!CommonHelper.isNotNullOrUndefined(pollMatchModel?.image)) {
    return <></>;
  }

  return (
    <div
      {...rest}
      onClick={handleClick}
      className={cx(classes.imageItem, classes.imageItemMd, { active: currentValue }, className)}
    >
      <div className={cx(classes.innerWrap, innerClassName)}>
        <div className={classes.heartIconWrap}>
          <HeartUnchecked />
        </div>

        <div className={classes.favoriteBorderedIconBtn}>
          <FavoriteBorderedIcon />
        </div>

        <img className={classes.img} src={pollMatchModel?.image ?? ''} alt="" />
        {CommonHelper.hasLength(pollMatchModel?.text) && (
          <div className={classes.bottomTitle}>
            <Typography component="span" variant="h3Bold" className={classes.imageText}>
              {pollMatchModel?.text}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles<void, 'innerWrap' | 'imageItem' | 'heartIconWrap' | 'favoriteBorderedIconBtn'>()(
  (theme, _, classes) => ({
    imageItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      borderRadius: 10,
      border: `1px solid ${theme.palette.customColors.darkGrey}`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      zIndex: 1,
      cursor: 'pointer',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        background: theme.palette.customColors.gradientAquamarine,
        zIndex: -1,
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        transition: theme.transitions.create(['opacity', 'visibility']),
      },
      '&.active': {
        '&:after': {
          opacity: 1,
          visibility: 'visible',
        },
        [`& .${classes.innerWrap}`]: {
          width: 'calc(100% - 6px)',
          height: 'calc(100% - 6px)',
        },
        [`& .${classes.heartIconWrap}`]: {
          opacity: 1,
          visibility: 'visible',
        },
      },
      '&:hover:not(.active)': {
        [`& .${classes.innerWrap}`]: {
          '&:before': {
            opacity: 1,
            visibility: 'visible',
          },
          [`& .${classes.favoriteBorderedIconBtn}`]: {
            opacity: 1,
            visibility: 'visible',
          },
        },
      },
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    innerWrap: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      borderRadius: 10,
      transition: theme.transitions.create(['width', 'height']),
      willChange: 'width, height',
      zIndex: 1,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: alpha(theme.palette.customColors.simpleBlack, 0.5),
        zIndex: 0,
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        transition: theme.transitions.create(['opacity', 'visibility']),
      },
    },
    heartIconWrap: {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1,
      opacity: 0,
      visibility: 'hidden',
      transition: theme.transitions.create(['opacity', 'visibility']),
      willChange: 'opacity, visibility',
    },
    favoriteBorderedIconBtn: {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 2,
      opacity: 0,
      visibility: 'hidden',
      transition: theme.transitions.create(['opacity', 'visibility']),
      willChange: 'opacity, visibility',
    },
    bottomTitle: {
      position: 'absolute',
      left: '50%',
      bottom: 0,
      width: '100%',
      height: 'auto',
      textAlign: 'center',
      padding: theme.spacing(1.5),
      background: theme.palette.customColors.black,
      borderRadius: 10,
      transform: 'translate(-50%)',
      maxWidth: '100%',
      color: theme.palette.customColors.white,
    },
    imageText: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    imageItemMd: {
      maxWidth: 325,
      height: 420,
    },
  }),
);
