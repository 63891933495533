import { buttonClasses } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { routes } from 'routes/routes';
import { ChooseOrganization } from 'widgets/ChooseOrganization/ChooseOrganization';

interface IHeaderPageInProps {}

export const HeaderPageIn: React.FC<IHeaderPageInProps> = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const matchPath = RouteHelper.matchPath(routes.ReportDetailScreen.path, location.pathname);

  // Handlers
  const handleGoBack = () => {
    navigate(routes.MainScreen.path);
  };

  // Renders
  return (
    <>
      {!matchPath && <ChooseOrganization />}
      <TextButton
        className={classes.btn}
        color="primary"
        startIcon={<i className="custom-icon-triangle-owal-down" />}
        onClick={handleGoBack}
      >
        Вернуться на главную
      </TextButton>
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  btn: {
    marginLeft: 'auto',
    [`& .${buttonClasses.startIcon}`]: {
      '& i': {
        fontSize: 13,
        transform: 'rotate(90deg)',
      },
    },
  },
}));
