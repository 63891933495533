import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { QSwitchWithController } from 'components/UI/Switches/QSwitchWithController';
import { NotificationsSettingsFormFields } from 'modules/notifications/forms/NotificationsSettingsForm';

interface INotificationsOptionsProps {}

export const NotificationsOptions: React.FC<INotificationsOptionsProps> = observer(() => {
  const { control } = useFormContext();
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h2Medium">Настройки</Typography>
      <Box sx={{ mt: 1.5 }}>
        <QSwitchWithController
          name={NotificationsSettingsFormFields.enablePopupNotifications}
          hookFormProps={{ control }}
          label="Всплывающие уведомления"
          labelProps={{ variant: 'h3Regular' }}
        />
      </Box>
      <Box sx={{ mt: 1.5 }}>
        <QSwitchWithController
          name={NotificationsSettingsFormFields.enableEmailDeliveryNotifications}
          hookFormProps={{ control }}
          label="Отправка уведомлений на электронную почту"
          labelProps={{ variant: 'h3Regular' }}
        />
      </Box>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(4.375),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5, 0, 2.5, 2.5),
    borderRadius: '10px',
    background: theme.palette.customColors.lightBlue,
  },
}));
