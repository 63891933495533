import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

import { AbstractApiRepository } from 'base/api/AbstractApiRepository';

export class FileApiRepository extends AbstractApiRepository {
  uploadFile = (data: FormData) => {
    return this.apiClient.post({ url: '/image', data });
  };

  uploadFiles = (data: FormData, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
    return this.apiClient.post({
      url: '/images',
      data,
      config: { signal: this.apiClient.abortController.signal, onUploadProgress } as AxiosRequestConfig,
    });
  };
}
