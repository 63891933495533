import { appConfig } from 'appConfig';

import { OrganizationRoles, RoleMatrix } from '../types/OrganizationTypes';

export const MEMBERS_DISPLAY_DATE_FORMAT: string = 'dd.MM.yyyy HH:mm:ss';
export const MEMBERS_SHORT_DISPLAY_DATE_FORMAT: string = 'dd.MM.yyyy';
export const ORGANIZATION_JOIN_LINK: string = `_${appConfig.projectName}JoinLink`;

export const ROLE_MATRIX: RoleMatrix = {
  [OrganizationRoles.admin]: {
    pollCreate: true,
    pollEdit: true,
    pollDelete: true,
    pollCompletion: true,
    pollResults: true,
    invitationToTheOrganization: true,
    deleteFromTheOrganization: true,
    changeTheRole: true,
    changeRoleToAdmin: true,
    viewMembers: true,
  },
  [OrganizationRoles.poll_master]: {
    pollCreate: true,
    pollEdit: true,
    pollDelete: true,
    pollCompletion: true,
    pollResults: true,
    invitationToTheOrganization: true,
    deleteFromTheOrganization: true,
    changeTheRole: true,
    changeRoleToAdmin: false,
    viewMembers: true,
  },
  [OrganizationRoles.poll_participant]: {
    pollCreate: false,
    pollEdit: false,
    pollDelete: false,
    pollCompletion: true,
    pollResults: false,
    invitationToTheOrganization: false,
    deleteFromTheOrganization: false,
    changeTheRole: false,
    changeRoleToAdmin: false,
    viewMembers: false,
  },
};
