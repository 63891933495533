import { Theme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Logo } from 'components/UI/Logo';
import { routes } from 'routes/routes';

import { SidebarTypesComponent } from './components/SidebarTypesComponent';

interface ISidebarProps {}

export const Sidebar: React.FC<ISidebarProps> = observer(() => {
  const { sidebarStore } = useRootStore();
  const { classes } = useStyles();

  // Renders
  return (
    <aside className={classes.sidebar}>
      <Logo linkProps={{ to: routes.MainScreen.path }} />

      <SidebarTypesComponent type={sidebarStore.sidebarType} />
    </aside>
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gridArea: 'sidebar',
    padding: theme.spacing(3.75, 3.75, 5),
    background: theme.palette.customColors.lightBlue,
  },
}));
