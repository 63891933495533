import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { PollMatchDefaultTemplateModel } from 'modules/polls/models/PollMatchDefaultTemplateModel';
import { PollQuestionModel } from 'modules/polls/models/PollQuestionModel';
import { PollBlockTypes } from 'modules/polls/types/PollsTypes';

import { PollMediaBlockType } from './PollMediaBlockType';
import { PollTextBlockType } from './PollTextBlockType';

interface IPollBlockTypeItemProps {
  question: PollQuestionModel | null;
  pollMatchDefaultTemplateModel: PollMatchDefaultTemplateModel | null;
}

export const PollBlockTypeItem: React.FC<IPollBlockTypeItemProps> = props => {
  const { question, pollMatchDefaultTemplateModel } = props;

  const blockType = question?.is_media ? PollBlockTypes.media : PollBlockTypes.text;
  const isMediaBlockType = blockType === PollBlockTypes.media;
  const { classes } = useStyles({ isMediaBlockType });

  // Renders
  if (!question || !pollMatchDefaultTemplateModel) {
    return null;
  }

  return (
    <div className={classes.question}>
      <Typography className={classes.title} variant="h1Medium">
        {pollMatchDefaultTemplateModel.title}
      </Typography>
      {blockType === PollBlockTypes.text && (
        <PollTextBlockType pollMatchDefaultTemplateModel={pollMatchDefaultTemplateModel} question={question} />
      )}
      {blockType === PollBlockTypes.media && (
        <PollMediaBlockType pollMatchDefaultTemplateModel={pollMatchDefaultTemplateModel} question={question} />
      )}
    </div>
  );
};

const useStyles = makeStyles<{ isMediaBlockType: boolean }>()((theme, props) => ({
  title: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  question: {
    color: theme.palette.customColors.white,
    '&:first-of-type': {
      padding: theme.spacing(0, 0, 6),
    },
    '&:not(:first-of-type)': {
      padding: theme.spacing(6, 0),
    },
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.customColors.black}`,
    },
  },
}));
