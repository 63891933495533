import { BaseForm } from 'base/BaseForm';

import { PollAnswersForm } from './PollAnswersForm';

export enum PollQuestionsFormFields {
  questions = 'questions',
}

export class PollQuestionsForm extends BaseForm {
  questions: Record<string, PollAnswersForm> = {};

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
