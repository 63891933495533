import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { PollsViewType } from 'modules/polls/types/PollsTypes';

import { PollsGridList } from './PollsGrid/PollsGridList';
import { PollsTableList } from './PollsTable/PollsTableList';

interface IPollsContentProps {}

export const PollsContent: React.FC<IPollsContentProps> = observer(() => {
  const { pollsStore } = useRootStore();

  const CurrentViewType = useMemo<Record<PollsViewType, ReactNode>>(
    () => ({ grid: <PollsGridList />, table: <PollsTableList /> }),
    [],
  );

  return <>{CurrentViewType?.[pollsStore.pollsListForm.viewType] ?? null}</>;
});
