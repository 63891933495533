import { UseFieldArrayReturn } from 'react-hook-form';

import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';

import { ConstructorBlock } from './ConstructorBlock';

interface IConstructorBlocksListProps {
  blocksListFieldArray: UseFieldArrayReturn<PollCreateAndEditForm, 'blocks', 'id'>;
}

export const ConstructorBlocksList: React.FC<IConstructorBlocksListProps> = props => {
  const { blocksListFieldArray } = props;

  return (
    <>
      {blocksListFieldArray.fields.map((item, index) => (
        <ConstructorBlock key={item.id} id={item.id} index={index} blocksListFieldArray={blocksListFieldArray} />
      ))}
    </>
  );
};
