import { Serializable, jsonProperty } from 'ts-serializable';

import { DateModel } from 'modules/base/models/DateModel';
import { PollTypes } from 'modules/polls/types/PollsTypes';

export class ReportPollModel extends Serializable {
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(String, null) name: string | null = null;
  @jsonProperty(String, null) description: string | null = null;
  @jsonProperty(Number, null) organization_id: number | null = null;
  @jsonProperty(String, null) creator_id: string | null = null;
  @jsonProperty(String, null) template: PollTypes | null = null;
  @jsonProperty(String, null) background: string | null = null;
  @jsonProperty(Boolean, null) shuffle: boolean | null = null;
  @jsonProperty(Boolean, null) with_description: boolean | null = null;
  @jsonProperty(Boolean, null) with_progress_bar: boolean | null = null;
  @jsonProperty(Boolean, null) one_by_one: boolean | null = null;
  @jsonProperty(Boolean, null) with_notifications: boolean | null = null;
  @jsonProperty(Boolean, null) is_active: boolean | null = null;
  @jsonProperty(Boolean, null) is_completed: boolean | null = null;
  @jsonProperty(Boolean, null) is_published: boolean | null = null;
  @jsonProperty(DateModel) created_at: DateModel | null = null;
  @jsonProperty(DateModel) updated_at: DateModel | null = null;
}
