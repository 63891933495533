import { Box, Typography, Button, IconButton, Switch } from '@mui/material';

import { QOutlinedTextField } from 'components/UI/TextFields/QOutlinedTextField';

interface IUiComponentsProps {}

export const UiComponents: React.FC<IUiComponentsProps> = () => {
  return (
    <Box p={4}>
      <Box mb={4}>
        <Typography variant="h1">UI Components</Typography>
      </Box>
      <Box>
        <Box mr={2}>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={5} width="100%" gap={2}>
            <Box>
              <Button variant="outlined" color="primary">
                Outlined Primary
              </Button>
            </Box>
            <Box>
              <Button variant="outlined" color="primary" disabled>
                Outlined Primary Disabled
              </Button>
            </Box>
            <Box>
              <Button variant="outlined" color="primary" className="active">
                Outlined Primary Active
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={5} width="100%" gap={2}>
            <Box>
              <Button variant="contained" color="primary">
                Contained Primary
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="primary" disabled>
                Contained Primary Disabled
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={5} width="100%" gap={2}>
            <Box>
              <Button variant="contained" color="secondary">
                Contained Secondary
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="secondary" disabled>
                Contained Secondary Disabled
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={5} width="100%" gap={2}>
            <Box>
              <Button variant="contained" color="info">
                Contained Info
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="info" disabled>
                Contained Info Disabled
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={5} width="100%" gap={2}>
            <Box>
              <Button variant="contained" color="error">
                Contained Error
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="error" disabled>
                Contained Error Disabled
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={5} width="100%" gap={2}>
            <Box>
              <IconButton color="primary">
                <i className="custom-icon-add" />
              </IconButton>
            </Box>
            <Box>
              <IconButton color="primary" disabled>
                <i className="custom-icon-add" />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={5} width="100%" gap={2}>
            <Box>
              <Switch color="primary" />
            </Box>
            <Box>
              <Switch checked color="primary" disabled />
            </Box>
            <Box>
              <Switch color="primary" disabled />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flexWrap="wrap" mb={5} width="100%" maxWidth="30%" gap={2}>
            <Box>
              <QOutlinedTextField label="Default" placeholder="Элвис Пресли" />
            </Box>
            <Box>
              <QOutlinedTextField
                label="Error"
                placeholder="Элвис Пресли"
                error
                errors="Неверно указан логин или пароль"
              />
            </Box>
            <Box>
              <QOutlinedTextField label="Error disabled" placeholder="Элвис Пресли" error disabled />
            </Box>
            <Box>
              <QOutlinedTextField label="Disabled" placeholder="Элвис Пресли" disabled />
            </Box>
            <Box>
              <QOutlinedTextField
                label="With start icon"
                placeholder="Элвис Пресли"
                startIconProps={{ iconClassName: 'custom-icon-eye' }}
              />
            </Box>
            <Box>
              <QOutlinedTextField
                label="With end icon"
                placeholder="Элвис Пресли"
                endIconProps={{ iconClassName: 'custom-icon-edit2' }}
              />
            </Box>
            <Box>
              <QOutlinedTextField
                label="With both icons"
                placeholder="Элвис Пресли"
                startIconProps={{ iconClassName: 'custom-icon-eye' }}
                endIconProps={{ iconClassName: 'custom-icon-edit2' }}
              />
            </Box>
            <Box>
              <QOutlinedTextField
                label="With both icons error disabled"
                placeholder="Элвис Пресли"
                startIconProps={{ iconClassName: 'custom-icon-eye' }}
                endIconProps={{ iconClassName: 'custom-icon-edit2' }}
                disabled
                error
              />
            </Box>
            <Box>
              <QOutlinedTextField
                label="With both icons disabled"
                placeholder="Элвис Пресли"
                startIconProps={{ iconClassName: 'custom-icon-eye' }}
                endIconProps={{ iconClassName: 'custom-icon-edit2' }}
                disabled
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
