import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { MembersListFormFields } from 'modules/organizations/forms/MembersListForm';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { MembersInvitationModal } from 'widgets/MembersInvitationModal/MembersInvitationModal';
import { MembersModalWidget } from 'widgets/MembersModalWidget/MembersModalWidget';

interface IPollsMembersProps {}

export const PollsMembers: React.FC<IPollsMembersProps> = observer(() => {
  const { baseStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  useEffect(() => {
    if (organizationsStore.selectedOrganization?.id && roleMatrix?.viewMembers) {
      organizationsStore.membersListFormSetValueAndGetList(
        MembersListFormFields.organizationId,
        organizationsStore.selectedOrganization.id,
      );
    }
  }, [organizationsStore.selectedOrganization, organizationsStore.currentUserInOrganizationRole]);

  // Handlers
  const handleOpenMembersModal = () => {
    baseStore.setMembersModalOpen(true);
  };

  // Renders
  return (
    <>
      {roleMatrix?.viewMembers && (
        <div className={classes.members}>
          <TextButton color="customColorsGray" onClick={handleOpenMembersModal}>
            {organizationsStore.membersListForm.total} участников
          </TextButton>
        </div>
      )}
      <MembersModalWidget />
      <MembersInvitationModal />
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  members: {
    paddingLeft: theme.spacing(8.5),
  },
}));
