import { Box, Typography, formHelperTextClasses, outlinedInputClasses } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { VerifyEmailModal } from 'components/Common/VerifyEmailModal';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { Loader } from 'components/UI/Loader';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { CommonHelper } from 'helpers/CommonHelper';
import { ProfileEmailForm, ProfileEmailFormFields } from 'modules/users/forms/ProfileEmailForm';
import { customColors } from 'styles/colors';
import { useCommonStyles } from 'styles/commonStyles';

interface IProfileEmailFormComponentProps {}

export const ProfileEmailFormComponent: React.FC<IProfileEmailFormComponentProps> = observer(() => {
  const { usersStore } = useRootStore();
  const { classes } = useStyles();
  const { commonClasses } = useCommonStyles();

  const [openVerifyEmailModal, setOpenVerifyEmailModal] = useState(false);
  const userIsVerified: boolean = CommonHelper.isNotNullOrUndefined(usersStore.userInfo?.email_verified_at);

  const profileEmailForm = useForm<ProfileEmailForm>({
    mode: 'onChange',
    defaultValues: ProfileEmailForm.create(),
  });

  // Effects
  useEffect(() => {
    profileEmailForm.reset(ProfileEmailForm.create({ email: usersStore.userInfo?.email ?? '' }));
  }, [usersStore.userInfo]);

  // Handlers
  const handleVerifyEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    usersStore.sendVerify(() => {
      handleOpenVerifyEmailModal();
    });
  };

  const handleOpenVerifyEmailModal = () => {
    setOpenVerifyEmailModal(true);
  };

  const handleCloseModal = () => {
    setOpenVerifyEmailModal(false);
  };

  // Renders
  return (
    <>
      {usersStore.sendVerifyLoading && <Loader isAbsolute />}
      <QOutlinedTextFieldWithController
        label="Почта"
        className={classes.input}
        name={ProfileEmailFormFields.email}
        hookFormProps={{ control: profileEmailForm.control }}
        // endIconProps={{ iconClassName: 'custom-icon-edit2' }}
        sx={{ mb: userIsVerified ? 2 : undefined }}
        InputProps={{ readOnly: true }}
      />
      {!userIsVerified && (
        <Box sx={{ mt: 1, mb: 2.25, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5Regular" color={customColors.red}>
            Необходимо подтвердить почту
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
            <TextButton className={commonClasses.textUnderLine} color="primary" onClick={handleVerifyEmail}>
              Подтвердить регистрацию
            </TextButton>
          </Box>
        </Box>
      )}

      <VerifyEmailModal open={openVerifyEmailModal} onHandleClose={handleCloseModal} />
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  input: {
    position: 'relative',
    [`& .${outlinedInputClasses.error}`]: {
      [`& ~ .${formHelperTextClasses.root}`]: {
        position: 'absolute',
        left: 0,
        bottom: theme.spacing(-1.875),
        transform: 'translateY(100%)',
      },
    },
  },
}));
