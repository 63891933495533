import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { customColors } from 'styles/colors';

interface IPollDeleteModalProps {}

export const PollDeleteModal: React.FC<IPollDeleteModalProps> = observer(props => {
  const { pollsStore } = useRootStore();
  const { ...rest } = props;

  // Handlers
  const handleDeletePoll = () => {
    if (pollsStore.pollLocalInfo?.id) {
      pollsStore.deletePoll(pollsStore.pollLocalInfo.id, () => {
        handleCloseModal();
        pollsStore.getOrganizationPolls();
      });
    }
  };

  const handleCloseModal = () => {
    pollsStore.setDeletePollModalOpen(false);
    pollsStore.setPollLocalInfo(null);
  };

  // Renders
  return (
    <QModal {...rest} open={pollsStore.deletePollModalOpen} onClose={handleCloseModal} modalMaxWidthValue={610}>
      {pollsStore.deletePollLoading && <Loader isAbsolute />}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Удалить опрос
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Удаленные опросы восстановлению не подлежат. <br /> Вы уверены, что хотите удалить опрос?
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="error" sx={{ mb: 2 }} onClick={handleDeletePoll}>
            Да, удалить
          </Button>
          <Button fullWidth variant="outlined" color="primary" sx={{ mb: 2 }} onClick={handleCloseModal}>
            Нет, не надо
          </Button>
        </Box>
      </Box>
    </QModal>
  );
});
