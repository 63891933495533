import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { CommonHelper } from 'helpers/CommonHelper';
import { getPollsChildrendScreens } from 'modules/polls/consts/consts';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollModel } from 'modules/polls/models/PollModel';
import { PollRouteActionTypes } from 'modules/polls/types/PollsTypes';
import { useGetPollCreateAndEditParams } from 'modulesHooks/polls/useGetPollCreateAndEditParams';

import { PollPreviewBlockItem } from './PollPreviewBlockItem';

interface IPollPreviewShowOneBlockContentProps {}

export const PollPreviewShowOneBlockContent: React.FC<IPollPreviewShowOneBlockContentProps> = observer(() => {
  const { organizationsStore, pollsStore } = useRootStore();
  const { getValues, handleSubmit, reset } = useFormContext<PollCreateAndEditForm>();
  const formValues = getValues();

  const { classes } = useStyles();

  const params = useParams();
  const navigate = useNavigate();
  const pollCreateAndEditParams = useGetPollCreateAndEditParams();
  const routeSegmentsList = params['*']?.split('/');
  const currentScreen = PollsHelper.getCurrentScreenSlug(routeSegmentsList);
  const pollsChildrendScreens = getPollsChildrendScreens();

  const [currentBlockIndex, setcurrentBlockIndex] = useState(0);

  const blocksMaxIndex = formValues.blocks.length - 1;
  const isLastBlock = currentBlockIndex >= blocksMaxIndex;
  const hasOrganizationId = CommonHelper.isNotNullOrUndefined(organizationsStore.selectedOrganization?.id);

  // Handlers
  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (currentBlockIndex > 0) {
      setcurrentBlockIndex(prev => prev - 1);
    }
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isLastBlock) {
      setcurrentBlockIndex(prev => prev + 1);
      return;
    }

    handleSubmit(handleSubmitForm)();
  };

  const handleSubmitForm = (form: PollCreateAndEditForm) => {
    if (
      hasOrganizationId &&
      pollCreateAndEditParams.action === PollRouteActionTypes.edit &&
      pollCreateAndEditParams.pollId.length
    ) {
      pollsStore.editPoll(
        organizationsStore.selectedOrganization!.id!,
        pollCreateAndEditParams.pollId,
        form,
        handleUpdateForm,
      );
      return;
    }

    if (hasOrganizationId && pollCreateAndEditParams.action === PollRouteActionTypes.create) {
      pollsStore.createPoll(organizationsStore.selectedOrganization!.id!, form, (pollModel: PollModel) => {
        if (
          currentScreen?.length &&
          pollsChildrendScreens?.[currentScreen] &&
          CommonHelper.isNotNullOrUndefined(pollModel.id)
        ) {
          const path = RouteHelper.makePath(pollsChildrendScreens[currentScreen], {
            ...pollCreateAndEditParams,
            action: PollRouteActionTypes.edit,
            pollId: pollModel.id,
          });

          handleUpdateForm(pollModel);
          navigate(path);
        }
      });

      return;
    }
  };

  const handleUpdateForm = (pollModel: PollModel) => {
    const form = pollsStore.getPollCreateAndEditForm(pollModel);
    reset(form);
  };

  // Renders
  if (!formValues.blocks?.[currentBlockIndex]) {
    return <></>;
  }

  return (
    <>
      <div className={classes.blockWrap}>
        <PollPreviewBlockItem index={currentBlockIndex} data={formValues.blocks?.[currentBlockIndex] ?? null} />
      </div>
      <div className={classes.buttonsWrap}>
        <>
          <Button fullWidth variant="contained" color="info" className={classes.btnInfo} onClick={handlePrev}>
            <Typography component="span" variant="h3Bold">
              Назад
            </Typography>
          </Button>
          <Button fullWidth variant="contained" color="secondary" className={classes.btnSecondary} onClick={handleNext}>
            <Typography component="span" variant="h3Bold">
              {isLastBlock ? 'Сохранить' : 'Далее'}
            </Typography>
          </Button>
        </>
      </div>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  blockWrap: {
    marginBottom: theme.spacing(4),
  },
  buttonsWrap: {
    maxWidth: 273,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: theme.spacing(1),
    margin: theme.spacing(0, 'auto'),
  },
  btnInfo: {
    padding: '7px 30px 8px',
  },
  btnSecondary: {
    padding: '8px 30px 9px',
  },
}));
