import { BaseFactory } from 'modules/base/BaseFactory';
import { DateModel } from 'modules/base/models/DateModel';

import { ReportAnswerDetailModel } from './models/ReportAnswerDetailModel';
import { ReportAnswerModel } from './models/ReportAnswerModel';
import { ReportModel } from './models/ReportModel';
import { ReportPollModel } from './models/ReportPollModel';

export class ReportsFactory extends BaseFactory {
  createReportModel = (json: any): ReportModel => {
    const reportModel: Record<string, unknown> = {};

    if (json?.poll) {
      reportModel.poll = this.createReportPollModel(json.poll);
    }

    return this.create<ReportModel>(ReportModel, { ...json, ...reportModel });
  };

  createReportModelList = (items: any[]) => {
    return items.length ? items.map(this.createReportModel) : [];
  };

  createReportPollModel = (json: any): ReportPollModel => {
    const reportPollModel: Record<string, unknown> = {};

    if (json?.created_at && !(json.created_at instanceof DateModel)) {
      reportPollModel.created_at = this.createDateModel(json.created_at);
    }

    if (json?.updated_at && !(json.updated_at instanceof DateModel)) {
      reportPollModel.updated_at = this.createDateModel(json.updated_at);
    }

    return this.create<ReportPollModel>(ReportPollModel, { ...json, ...reportPollModel });
  };

  createReportAnswerModel = (json: any): ReportAnswerModel => {
    const reportAnswerModel: Record<string, unknown> = {};

    if (json?.answer) {
      reportAnswerModel.answer = this.createReportAnswerDetailModel(json.answer);
    }

    return this.create<ReportAnswerModel>(ReportAnswerModel, { ...json, ...reportAnswerModel });
  };

  createReportAnswerModelList = (items: any[]): ReportAnswerModel[] => {
    return items.length ? items.map(this.createReportAnswerModel) : [];
  };

  createReportAnswerDetailModel = (json: any): ReportAnswerDetailModel => {
    const reportAnswerDetailModel: Record<string, unknown> = {};

    if (json?.created_at && !(json.created_at instanceof DateModel)) {
      reportAnswerDetailModel.created_at = this.createDateModel(json.created_at);
    }

    if (json?.updated_at && !(json.updated_at instanceof DateModel)) {
      reportAnswerDetailModel.updated_at = this.createDateModel(json.updated_at);
    }

    return this.create<ReportAnswerDetailModel>(ReportAnswerDetailModel, { ...json, ...reportAnswerDetailModel });
  };
}
