import { makeStyles } from 'tss-react/mui';

import { PollBaseLayout } from './components/PollBaseLayout';
import { ConstructorFormComponent } from './components/pollConstructor/ConstructorFormComponent';

interface IPollConstructorScreenProps {}

export const PollConstructorScreen: React.FC<IPollConstructorScreenProps> = () => {
  const { classes } = useStyles();

  return (
    <PollBaseLayout>
      <div className={classes.wrap}>
        <ConstructorFormComponent />
      </div>
    </PollBaseLayout>
  );
};

const useStyles = makeStyles()(theme => ({
  wrap: {
    padding: theme.spacing(5, 0, 8),
  },
}));
