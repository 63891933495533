import { Checkbox, FormControlLabel } from '@mui/material';
import { forwardRef } from 'react';

import { IQCheckboxProps } from 'interfaces/CommonComponentsInterfaces';

export const QCheckbox: React.FC<IQCheckboxProps> = forwardRef((props, ref) => {
  const { label, formControlLabelProps, ...rest } = props;

  return (
    <FormControlLabel
      control={<Checkbox ref={ref} color="primary" {...rest} />}
      label={label}
      {...formControlLabelProps}
    />
  );
});
