import { Box, BoxProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as LogoIcon } from 'assets/img/icons/auth-logo.svg';

interface ILoginWrapperProps extends BoxProps {}

export const LoginWrapper: React.FC<ILoginWrapperProps> = props => {
  const { className, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <Box {...rest} className={cx(classes.wrapper, className)}>
      <div className={classes.contentLeft}>{props.children}</div>
      <div className={classes.contentRight}>
        <div className={classes.logoWrap}>
          <LogoIcon />
        </div>
      </div>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100vh',
  },
  contentLeft: {
    position: 'relative',
    flexBasis: '40%',
    padding: theme.spacing('16.06vh', 2, 0),
    background: theme.palette.customColors.white,
  },
  contentRight: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    flexBasis: '60%',
    paddingTop: '25.52vh',
    background: theme.palette.customColors.gradientBg,
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '380px',
    maxWidth: '432px',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));
