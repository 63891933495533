import { Collapse, RadioGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TextButton } from 'components/UI/Buttons/TextButton';
import { QRadio } from 'components/UI/Radio/QRadio';
import { imagesCountSettingsList } from 'lists/polls/imagesCountSettingsList';
import { pollContextsViewMatrix } from 'modules/polls/consts/consts';
import { PollBlockSettingsFormFields } from 'modules/polls/forms/PollBlockSettingsForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollTypes } from 'modules/polls/types/PollsTypes';

interface IImagesCountSettingsProps {
  index: number;
}

export const ImagesCountSettings: React.FC<IImagesCountSettingsProps> = props => {
  const { index } = props;
  const { control } = useFormContext<PollCreateAndEditForm>();
  const { classes, cx } = useStyles();
  const params = useParams<{ type?: PollTypes }>();

  const [openContent, setOpenContent] = useState(true);

  const { field: imagesCount } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.imagesCount}`,
    control,
  });

  const currentContextImagesCountSetting = pollContextsViewMatrix?.[params.type!]?.imagesCount;

  // Handlers
  const handleToggleContent = () => {
    setOpenContent(!openContent);
  };

  const handleChangeRadioGroup = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    imagesCount.onChange(Number(value));
  };

  // Renders
  return (
    <>
      <TextButton
        color="secondary"
        className={cx(classes.btn, { isActive: openContent })}
        endIcon={<i className="custom-icon-triangle-owal-down" />}
        onClick={handleToggleContent}
      >
        <Typography variant="h4Medium" component="span">
          Количество картинок
        </Typography>
      </TextButton>
      <Collapse in={openContent}>
        <div className={classes.innerCollapse}>
          <RadioGroup value={imagesCount.value} onChange={handleChangeRadioGroup}>
            {imagesCountSettingsList?.map(item => (
              <div key={item.id} className={classes.radioWrap}>
                <QRadio
                  label={item.name}
                  labelTypographyProps={{
                    // color: !currentContextImagesCountSetting?.[item.count] ? Colors.grayDivider : undefined,
                    variant: 'h4Medium',
                  }}
                  value={item.count ?? ''}
                  disabled={!currentContextImagesCountSetting?.[item.count]}
                />
              </div>
            ))}
          </RadioGroup>
        </div>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  innerCollapse: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
  },
  radioWrap: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
  btn: {
    '& i': {
      transition: theme.transitions.create('transform'),
    },
    '&.isActive': {
      '& i': {
        transform: 'rotate(-180deg)',
      },
    },
  },
}));
