import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';

import { PollBlockTypeItem } from './PollBlockTypes/PollBlockTypeItem';

interface IPollQuestionsProps {}

export const PollQuestions: React.FC<IPollQuestionsProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();
  const params = useParams<{ pollId?: string }>();

  // Effects
  useEffect(() => {
    if (params.pollId && organizationsStore.selectedOrganization?.id) {
      pollsStore.getPollVoteByIdDefaultTemplate(params.pollId, organizationsStore.selectedOrganization.id);
    }
  }, [params.pollId, organizationsStore.selectedOrganization]);

  // Renders
  return (
    <div className={classes.questionsWrap}>
      {pollsStore.pollVoteDefatulTemplateModel?.next_match?.map(pollMatchDefaultTemplateModel => (
        <PollBlockTypeItem
          key={pollMatchDefaultTemplateModel.id}
          question={pollsStore.currentGettedPollQuestionsHashMap?.[pollMatchDefaultTemplateModel.id ?? ''] ?? null}
          pollMatchDefaultTemplateModel={pollMatchDefaultTemplateModel}
        />
      ))}
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  questionsWrap: {},
}));
