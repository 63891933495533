import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QMenu } from 'components/UI/QMenu/QMenu';
import { QMenuSelectedItem } from 'components/UI/QMenu/QMenuSelectedItem';
import { CommonHelper } from 'helpers/CommonHelper';
import { pollsFiltersList } from 'lists/polls/pollsFiltersList';
import { PollsListFormFields } from 'modules/polls/forms/PollsListForm/PollsListForm';
import { PollsFilters } from 'modules/polls/types/PollsTypes';

interface IPollsFilterMenuProps {}

export const PollsFilterMenu: React.FC<IPollsFilterMenuProps> = observer(() => {
  const { pollsStore } = useRootStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { classes } = useStyles();

  // Handlers
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChooseActionType = (actionType: PollsFilters | null) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    pollsStore.pollsListForm.setValues({ offset: 0, page: 1 });
    pollsStore.pollsListFormSetValueAndGetList(PollsListFormFields.filter, actionType);
    handleCloseMenu();
  };

  // Renders
  return (
    <>
      <Button
        variant="contained"
        color="info"
        sx={{ mr: 2 }}
        endIcon={<i className="custom-icon-filter" />}
        onClick={handleOpenMenu}
      >
        Фильтр
      </Button>
      <QMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseMenu}
        variant="selectedMenu"
        className={classes.menu}
      >
        <QMenuSelectedItem
          value=""
          selected={CommonHelper.isNullOrUndefined(pollsStore.pollsListForm.filter)}
          onClick={handleChooseActionType(null)}
        >
          Отображать все
        </QMenuSelectedItem>
        {pollsFiltersList.map(item => (
          <QMenuSelectedItem
            key={item.id}
            value={item.id ?? ''}
            selected={item.id === pollsStore.pollsListForm.filter}
            onClick={handleChooseActionType(item.id)}
          >
            {item.name}
          </QMenuSelectedItem>
        ))}
      </QMenu>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  menu: {
    minWidth: 115,
  },
}));
