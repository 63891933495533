import { AbstractApiRepository } from 'base/api/AbstractApiRepository';

import { AnswerTheQuestionOtherTemplatesDto } from '../dto/AnswerTheQuestionOtherTemplatesDto';
import { AnswerTheQuestionsDto } from '../dto/AnswerTheQuestionsDto';
import { PollsCreateFormDto } from '../dto/PollsCreateFormDto/PollsCreateFormDto';

export class PollsApiRepository extends AbstractApiRepository {
  createPoll = (data: PollsCreateFormDto) => {
    return this.apiClient.post({ url: `/polls`, data });
  };

  editPoll = (pollId: string, data: PollsCreateFormDto) => {
    return this.apiClient.put({ url: `/polls/${pollId}`, data });
  };

  getPollById = (pollId: string, params: string) => {
    return this.apiClient.get({ url: `/polls/${pollId}${params}` });
  };

  getPollVoteById = (pollId: string, params: string) => {
    return this.apiClient.get({ url: `/polls/vote/${pollId}${params}` });
  };

  setAnswersTheQuestions = (pollId: string, params: string, data: AnswerTheQuestionsDto[]) => {
    return this.apiClient.post({ url: `/polls/vote/${pollId}${params}`, data });
  };

  setAnswerTheQuestionOtherTemplates = (pollId: string, params: string, data: AnswerTheQuestionOtherTemplatesDto) => {
    return this.apiClient.post({ url: `/polls/vote/${pollId}${params}`, data });
  };

  revertPrevQuestion = (pollId: string) => {
    return this.apiClient.get({ url: `/polls/previous/${pollId}` });
  };

  getOrganizationPolls = (params: string) => {
    return this.apiClient.get({ url: `/polls${params}` });
  };

  deletePoll = (pollId: number) => {
    return this.apiClient.delete({ url: `/polls/${pollId}` });
  };

  getPollProgress = (pollId: number) => {
    return this.apiClient.get({ url: `/polls/${pollId}/progress` });
  };

  startPollById = (pollId: number, params: string) => {
    return this.apiClient.post({ url: `/polls/start/${pollId}${params}` });
  };

  stopPollById = (pollId: number, params: string) => {
    return this.apiClient.post({ url: `/polls/stop/${pollId}${params}` });
  };

  replicatePollById = (pollId: number) => {
    return this.apiClient.post({ url: `/polls/replicate/${pollId}` });
  };
}
