import { useController, useFormContext } from 'react-hook-form';

import { QDatePicker } from 'components/UI/QDatePicker';
import { ProfileFormFields } from 'modules/users/forms/ProfileForm';

interface IProfileFormDatePickerProps {}

export const ProfileFormDatePicker: React.FC<IProfileFormDatePickerProps> = () => {
  const { control } = useFormContext();
  const { field } = useController({ name: ProfileFormFields.birthday, control });

  const handleChange = (value: any) => {
    field.onChange(value);
  };

  return <QDatePicker value={field.value} onChange={handleChange} sx={{ mb: 2 }} />;
};
