import { TableHead, TableCell, TableBody, Box, Table } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { Notification } from 'base/ui/Notification';
import { Loader } from 'components/UI/Loader';
import { QTableHeadCellWithSortLabel } from 'components/UI/Tables/QTableHeadCellWithSortLabel';
import { QTableRow } from 'components/UI/Tables/QTableRow';
import { pollsTableFields } from 'lists/polls/pollsTableFields';
import { pollsNotificationMessages } from 'messages/polls';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { PollModel } from 'modules/polls/models/PollModel';
import { PollTypes } from 'modules/polls/types/PollsTypes';
import { routes } from 'routes/routes';
import { useTableStyles } from 'styles/tableStyles';

import { PollActionMenu } from '../PollActionMenu';
import { PollsTableBodyElements } from './PollsTableBodyElements';

interface IPollsTableListProps {}

export const PollsTableList: React.FC<IPollsTableListProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();

  const navigate = useNavigate();
  const { tableClasses, cx } = useTableStyles();

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const showMoreMenu =
    !!roleMatrix?.pollResults || !!roleMatrix?.pollCreate || !!roleMatrix?.pollEdit || !roleMatrix?.pollDelete;

  // Handlers
  const handleSort = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    pollsStore.pollsListFormSetSortValueAndGetList(currentSort);
  };

  const handleClickRow = (poll: PollModel) => (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const path = RouteHelper.makePath(routes.PollDetailScreen.path, {
      pollId: poll?.id ?? '',
      type: poll?.template ?? PollTypes.default,
    });

    if (!poll?.is_active) {
      Notification.showWarning(pollsNotificationMessages.navigatePollDetail.warning);
      return;
    }

    if (poll.is_completed) {
      Notification.showWarning(pollsNotificationMessages.pollPassed.warning);
      return;
    }

    navigate(path);

    pollsStore.setPollLocalInfo(poll);
  };

  // Renders
  return (
    <Box sx={{ pb: 5, position: 'relative' }}>
      {pollsStore.organizationPollsListLoading && (
        <div className={tableClasses.loaderWrap}>
          <Loader isAbsolute />
        </div>
      )}
      <Table sx={{ borderSpacing: '0 24px' }}>
        <TableHead>
          <QTableRow variant="transparent">
            {pollsTableFields.map((field, index) => (
              <QTableHeadCellWithSortLabel
                key={index}
                field={field}
                sortValue={pollsStore.pollsListForm.sortValue}
                {...field.settings}
                onHandleSort={handleSort}
              />
            ))}
            <TableCell align="right" />
          </QTableRow>
        </TableHead>
        <TableBody className={tableClasses.tableRelative}>
          {pollsStore.organizationPollsList?.map(poll => (
            <QTableRow
              key={poll.id}
              className={cx(tableClasses.tableRowFilledVariant, tableClasses.pointer)}
              onClick={handleClickRow(poll)}
            >
              {pollsTableFields.map(field => (
                <PollsTableBodyElements key={field.column} data={poll} elementId={field.column} />
              ))}

              <TableCell align="right">
                {showMoreMenu && (
                  <PollActionMenu
                    pollInfo={poll}
                    menuProps={{
                      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                      transformOrigin: { vertical: 'top', horizontal: 'right' },
                    }}
                  />
                )}
              </TableCell>
            </QTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
});
