import { TableSortLabelProps, TableSortLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { SortValue } from 'base/modules/sort/models/SortValue';
import { SortOrdersEnum } from 'base/modules/sort/types/SortTypes';

interface IQTableSortLabelProps extends TableSortLabelProps {
  column: string;
  sortValue: SortValue | null;
}

export const QTableSortLabel: React.FC<IQTableSortLabelProps> = props => {
  const { column, sortValue, ...rest } = props;
  const { classes } = useStyles();

  const isSameColumn = sortValue?.orderBy === column;

  // Renders
  const renderIcon = (props: { className: string }) => {
    return (
      <div {...props}>
        <i className="custom-icon-arrow-down" />
      </div>
    );
  };

  return (
    <TableSortLabel
      active={isSameColumn}
      IconComponent={renderIcon}
      classes={{ icon: classes.icon }}
      direction={isSameColumn ? sortValue?.order : SortOrdersEnum.asc}
      {...rest}
    />
  );
};
const useStyles = makeStyles()(theme => ({
  icon: {
    // fontSize: 10,
    // margin: 0,
  },
}));
