import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { OrganizationRoles } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';

import { MemberKickConfirmModal } from './MemberKickConfirmModal';

interface IMemberKickButtonProps {
  currentMember: UserModel;
}

export const MemberKickButton: React.FC<IMemberKickButtonProps> = observer(props => {
  const { organizationsStore, usersStore } = useRootStore();
  const { currentMember } = props;

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const [memberKickModalOpen, setMemberKickModalOpen] = useState(false);
  const isCurrentSelectedOrganizationAdmin =
    organizationsStore.selectedOrganization?.owner?.id === usersStore.userInfo?.id;
  const isCurrentMemberAdmin = currentMember?.role?.name === OrganizationRoles.admin;

  // Handlers
  const handleOpenMemberKickModal = () => {
    setMemberKickModalOpen(true);
  };

  const handleCloseModal = () => {
    setMemberKickModalOpen(false);
  };

  const handleDisabledDeleteBtn = () => {
    if (roleMatrix?.deleteFromTheOrganization && isCurrentSelectedOrganizationAdmin && !isCurrentMemberAdmin) {
      return false;
    }

    if (
      roleMatrix?.changeTheRole &&
      !isCurrentMemberAdmin &&
      currentMember.role?.name === OrganizationRoles.poll_participant
    ) {
      return false;
    }

    return true;
  };

  // Renders
  return (
    <>
      <IconButton disabled={handleDisabledDeleteBtn()} onClick={handleOpenMemberKickModal}>
        <i className="custom-icon-trushsquare" />
      </IconButton>
      <MemberKickConfirmModal
        open={memberKickModalOpen}
        currentMember={currentMember}
        onHandleCloseModal={handleCloseModal}
      />
    </>
  );
});
