import { BaseForm } from 'base/BaseForm';

import { PollTypes } from '../types/PollsTypes';
import { PollConstructorBlockForm } from './PollConstructorBlockForm';
import { PollDesignSettingsForm } from './PollDesignSettingsForm';
import { PollSettingsForm } from './PollSettingsForm';

export enum PollCreateAndEditFormFields {
  name = 'name',
  description = 'description',
  template = 'template',
  design = 'design',
  settings = 'settings',
  blocks = 'blocks',
}

export class PollCreateAndEditForm extends BaseForm {
  name: string = '';
  description: string = '';
  template: PollTypes = PollTypes.default;
  design: PollDesignSettingsForm = PollDesignSettingsForm.create();
  settings: PollSettingsForm = PollSettingsForm.create();
  blocks: PollConstructorBlockForm[] = [PollConstructorBlockForm.create()];

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
