import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { ProfileForm, ProfileFormFields } from 'modules/users/forms/ProfileForm';
import { profileFormScheme } from 'modules/users/schemes/profileFormScheme';

import { ProfileAvatar } from './ProfileAvatar';
import { ProfileEmailFormComponent } from './ProfileEmailFormComponent';
import { ProfileFormDatePicker } from './ProfileFormDatePicker';
import { ProfileGenderRadio } from './ProfileGenderRadio';
import { ProfilePasswordFormComponent } from './ProfilePasswordFormComponent';

interface IProfileFormComponentProps {}

export const ProfileFormComponent: React.FC<IProfileFormComponentProps> = observer(() => {
  const { usersStore } = useRootStore();
  const { classes } = useStyles();

  const profileBaseForm = useForm<ProfileForm>({
    mode: 'onChange',
    resolver: yupResolver(profileFormScheme),
    defaultValues: ProfileForm.create(),
  });

  // Effects
  useEffect(() => {
    const form = usersStore.getProfileBaseForm();

    if (form) {
      profileBaseForm.reset(form);
    }
  }, [usersStore.userInfo]);

  // Handlers
  const handleSubmitForm = () => {
    usersStore.editUser(profileBaseForm.getValues());
  };

  // Renders
  return (
    <div className={classes.wrap}>
      <div className={classes.innerWrap}>
        {usersStore.editUserLoading && <Loader isAbsolute />}
        <form onSubmit={profileBaseForm.handleSubmit(handleSubmitForm)}>
          <FormProvider {...profileBaseForm}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <ProfileAvatar />
            </Box>

            <Typography sx={{ mb: 1.5 }} variant="h2Medium">
              Личные данные
            </Typography>

            <QOutlinedTextFieldWithController
              sx={{ mb: 2 }}
              label="Имя"
              name={ProfileFormFields.name}
              hookFormProps={{ control: profileBaseForm.control }}
              endIconProps={{ iconClassName: 'custom-icon-edit2' }}
            />

            <Box sx={{ mb: 5 }}>
              <Controller
                name={ProfileFormFields.gender}
                control={profileBaseForm.control}
                render={({ field }) => <ProfileGenderRadio value={field.value} onChange={field.onChange} />}
              />

              <ProfileFormDatePicker />

              <Button fullWidth variant="contained" color="secondary" type="submit">
                Сохранить изменения
              </Button>
            </Box>
          </FormProvider>
        </form>

        <Typography sx={{ mb: 1.5 }} variant="h2Medium">
          Безопасность
        </Typography>

        <ProfileEmailFormComponent />
        <div className={classes.passwordWrap}>
          <ProfilePasswordFormComponent />
        </div>
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    position: 'relative',
    padding: theme.spacing(4),
    borderRadius: 10,
    background: theme.palette.customColors.lightBlue,
    flex: 1,
    gap: theme.spacing(5),
  },
  innerWrap: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 404,
    margin: '0 auto',
  },
  passwordWrap: {
    marginBottom: theme.spacing(4),
  },
}));
