import { ReactFCPropsWithChildren } from 'interfaces/CommonComponentsInterfaces';

import { PollBreadcrumbs } from './PollBreadcrumbs';

interface IPollBaseLayoutProps {}

export const PollBaseLayout: ReactFCPropsWithChildren<IPollBaseLayoutProps> = props => {
  return (
    <>
      <PollBreadcrumbs />
      {props.children}
    </>
  );
};
