import { makeAutoObservable } from 'mobx';

import { SidebarVisualTypesEnum } from './types/SidebarTypes';

export class SidebarStore {
  sidebarType: SidebarVisualTypesEnum = SidebarVisualTypesEnum.main;

  constructor() {
    makeAutoObservable(this);
  }

  setSidebarType = (type: SidebarVisualTypesEnum) => {
    this.sidebarType = type;
  };
}
