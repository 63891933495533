import { BaseForm } from 'base/BaseForm';

export enum HelpMailFormFields {
  name = 'name',
  email = 'email',
  text = 'text',
  attachment = 'attachment',
}

export class HelpMailForm extends BaseForm {
  name: string = '';
  email: string = '';
  text: string = '';
  attachment: File | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
