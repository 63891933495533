import { computed, makeObservable } from 'mobx';

import { TableForm } from 'modules/tables/forms/TableForm/TableForm';

import ReportsService from '../ReportsService';

export enum ReportsListFormFields {
  organizationId = 'organizationId',
  search = 'search',
}

export class ReportsListForm extends TableForm {
  limit: number = ReportsService.REPORTS_LIST_LIMIT;
  organizationId: number | null = null;
  search: string | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createFormObservable() {
    const context = new this();
    context.createTableFormObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(ReportsListFormFields),
      pageCount: computed,
      showPagination: computed,
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.limit);
  }

  get showPagination() {
    return this.total > this.limit;
  }
}
