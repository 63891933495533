import { IconButton, IconButtonProps } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { OrganizationRoles } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';

import { MemberKickConfirmModal } from './components/MemberKickConfirmModal';

interface IMemberKickButtonProps {
  currentMember: UserModel | null;
  btnProps?: IconButtonProps;
}

export const MemberKickButton: React.FC<IMemberKickButtonProps> = observer(props => {
  const { currentMember, btnProps } = props;
  const { organizationsStore, usersStore } = useRootStore();

  const currentMemberId: number = currentMember?.id ?? 0;
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const isCurrentSelectedOrganizationAdmin =
    organizationsStore.selectedOrganization?.owner?.id === usersStore.userInfo?.id;
  const isCurrentMemberAdmin = currentMember?.role?.name === OrganizationRoles.admin;

  // Handlers
  const handleOpenMemberKickModal = () => {
    organizationsStore.setMemberKickModal(currentMemberId, true);
  };

  const handleCloseModal = () => {
    organizationsStore.setMemberKickModal(currentMemberId, false);
  };

  const handleDisabledDeleteBtn = () => {
    if (roleMatrix?.deleteFromTheOrganization && isCurrentSelectedOrganizationAdmin && !isCurrentMemberAdmin) {
      return false;
    }

    if (
      roleMatrix?.changeTheRole &&
      !isCurrentMemberAdmin &&
      currentMember?.role?.name === OrganizationRoles.poll_participant
    ) {
      return false;
    }

    return true;
  };

  // Renders
  return (
    <>
      <IconButton disabled={handleDisabledDeleteBtn()} onClick={handleOpenMemberKickModal} {...btnProps}>
        <i className="custom-icon-trushsquare" />
      </IconButton>
      <MemberKickConfirmModal
        currentMember={currentMember}
        open={!!organizationsStore.memberKickModal?.[currentMemberId]}
        onHandleCloseModal={handleCloseModal}
      />
    </>
  );
});
