import IcomoonEOT from 'assets/icomoon/fonts/icomoon.eot';
import IcomoonSVG from 'assets/icomoon/fonts/icomoon.svg';
import IcomoonTTF from 'assets/icomoon/fonts/icomoon.ttf';
import IcomoonWOFF from 'assets/icomoon/fonts/icomoon.woff';

export const icomoonFont = {
  fontFamily: 'icomoon',
  fontWeight: 'normal',
  fontStyle: 'normal',
  src: `
    url(${IcomoonEOT}) format('embedded-opentype'),
    url(${IcomoonTTF}) format('truetype'),
    url(${IcomoonWOFF}) format('woff'),
    url(${IcomoonSVG}) format('svg')
  `,
};
