import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ReactFCPropsWithChildren } from 'interfaces/CommonComponentsInterfaces';

interface IBlockPaperProps {}

export const BlockPaper: ReactFCPropsWithChildren<IBlockPaperProps> = props => {
  const { classes } = useStyles();

  return <Paper className={classes.paper}>{props.children}</Paper>;
};

const useStyles = makeStyles()(theme => ({
  paper: {
    boxShadow: 'none',
    padding: theme.spacing(2.5),
    borderRadius: '10px',
    background: theme.palette.customColors.lightBlue,
  },
}));
