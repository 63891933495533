import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Notification } from 'base/ui/Notification';
import { pollsNotificationMessages } from 'messages/polls';
import { POLLS_BLOCK_FIELDS_MAX_LENGTH } from 'modules/polls/consts/consts';
import { PollBlockSettingsFormFields } from 'modules/polls/forms/PollBlockSettingsForm';
import { PollConstructorBlockFieldForm } from 'modules/polls/forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollConstructorBlockFieldsTypes } from 'modules/polls/types/PollsTypes';

import { ConstructorFieldTypes } from './ConstructorFieldTypes/ConstructorFieldTypes';

interface IConstructorFieldsListProps {
  index: number;
}

export const ConstructorFieldsList: React.FC<IConstructorFieldsListProps> = props => {
  const { index } = props;
  const { classes } = useStyles();

  const { control, getValues } = useFormContext<PollCreateAndEditForm>();

  const blockFieldsArray = useFieldArray({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.fields}`,
    control,
  });
  const { field: block } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}`,
    control,
  });
  const { field: manyAnswers } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.manyAnswers}`,
    control,
  });

  useEffect(() => {
    handleUpdate();
  }, [manyAnswers.value]);

  // Handlers
  const handleUpdate = () => {
    blockFieldsArray.replace(
      PollsHelper.сhangeTheTypeOfFieldsDependingOnTheSetting(block.value.fields, manyAnswers.value),
    );
  };

  const handleAddField = () => {
    if (blockFieldsArray.fields.length < POLLS_BLOCK_FIELDS_MAX_LENGTH) {
      const currentValidType = manyAnswers.value
        ? PollConstructorBlockFieldsTypes.checkbox
        : PollConstructorBlockFieldsTypes.radio;

      blockFieldsArray.append(PollConstructorBlockFieldForm.create({ type: currentValidType }));
    } else {
      Notification.showWarning(pollsNotificationMessages.pollsBlockFieldsMaxLength.warning);
    }
  };

  const handleDelete = (fieldIndex: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    blockFieldsArray.remove(fieldIndex);
  };

  const handleChangeTypeAtGallery = (fieldIndex: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    const currentField = getValues().blocks[index].fields[fieldIndex];

    const updatedForm = PollConstructorBlockFieldForm.create<PollConstructorBlockFieldForm>({
      ...currentField,
      type: PollsHelper.getValidPollConstructorBlockFieldsType(currentField.type),
    });

    blockFieldsArray.update(fieldIndex, updatedForm);
  };

  return (
    <>
      <div className={classes.list}>
        {blockFieldsArray.fields.map((item, fieldIndex) => (
          <ConstructorFieldTypes
            key={item.id}
            index={index}
            fieldIndex={fieldIndex}
            fieldType={item.type}
            onHandleDelete={handleDelete(fieldIndex)}
            onHandleChangeTypeAtGallery={handleChangeTypeAtGallery(fieldIndex)}
          />
        ))}
      </div>

      <Button
        color="info"
        variant="contained"
        sx={{ ml: '32px' }}
        endIcon={<i className="custom-icon-add" />}
        className={classes.btn}
        onClick={handleAddField}
      >
        <Typography variant="h4Regular">Добавить</Typography>
      </Button>
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  list: {
    marginBottom: theme.spacing(1),
  },
  btn: {
    padding: '3px 30px 3px',
  },
}));
