import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';

import { PollBlockSettingsFormFields } from 'modules/polls/forms/PollBlockSettingsForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';

import { AttachFileWithTitle } from './AttachFileWithTitle';

interface IConstructorImagesListProps {
  index: number;
}

export const ConstructorImagesList: React.FC<IConstructorImagesListProps> = observer(props => {
  const { index } = props;
  const { control } = useFormContext<PollCreateAndEditForm>();

  const blockImagesArray = useFieldArray({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.images}`,
    control,
  });

  const { field: imagesCountField } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.imagesCount}`,
    control,
  });

  return (
    <Grid container spacing={1}>
      {blockImagesArray.fields.slice(0, imagesCountField.value).map((item, fieldIndex) => (
        <Grid key={item.id} item xs={6}>
          <AttachFileWithTitle index={index} fieldIndex={fieldIndex} />
        </Grid>
      ))}
    </Grid>
  );
});
