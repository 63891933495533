import { Box, FormLabel, RadioGroup } from '@mui/material';

import { QRadio } from 'components/UI/Radio/QRadio';
import { profileGenderList } from 'lists/users/profileGenderList';

interface IProfileGenderRadioProps {
  value: string;
  onChange: (value: string) => void;
}

export const ProfileGenderRadio: React.FC<IProfileGenderRadioProps> = props => {
  const { value, onChange } = props;

  // Handlers
  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value);
  };

  // Renders
  return (
    <>
      <RadioGroup sx={{ mb: 2 }} value={value} onChange={handleChangeRadio}>
        <FormLabel>Пол</FormLabel>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {profileGenderList.map(item => (
            <QRadio key={item.id} label={item.name} formControlLabelProps={{ sx: { mr: 1 } }} value={item.id} />
          ))}
        </Box>
      </RadioGroup>
    </>
  );
};
