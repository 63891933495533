import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QModal } from 'components/UI/QModal';
import { routes } from 'routes/routes';
import { customColors } from 'styles/colors';

import { ReactComponent as PollPassedIllustration } from 'assets/img/icons/poll-passed-illustration.svg';

interface IPollSuccessfullyPassedModalProps {}

export const PollSuccessfullyPassedModal: React.FC<IPollSuccessfullyPassedModalProps> = observer(props => {
  const { ...rest } = props;

  const { pollsStore } = useRootStore();
  const { classes } = useStyles();
  const navigate = useNavigate();

  // Handlers
  const handleNavigateMain = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleCloseModal();
    handleNavigateMainScreen();
  };

  const handleCloseModal = () => {
    pollsStore.setPollSuccessfullyModalOpen(false);
    handleNavigateMainScreen();
  };

  const handleNavigateMainScreen = () => {
    navigate(routes.MainScreen.path);
  };

  // Renders
  return (
    <QModal {...rest} open={pollsStore.pollSuccessfullyModalOpen} onClose={handleCloseModal} modalMaxWidthValue={610}>
      <Typography variant="h1Bold" color={customColors.purple} className={classes.title}>
        Спасибо за участие в опросе!
      </Typography>
      <div className={classes.iconWrap}>
        <PollPassedIllustration />
      </div>
      <div className={classes.btnWrap}>
        <Button variant="contained" color="primary" onClick={handleNavigateMain}>
          Вернуться на главную
        </Button>
      </div>
    </QModal>
  );
});

const useStyles = makeStyles()(theme => ({
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(7),
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 430,
    height: 210,
    width: '100%',
    margin: theme.spacing(0, 'auto', 4),
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 330,
    margin: theme.spacing(0, 'auto'),
  },
}));
