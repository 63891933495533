import { CommonContainer } from 'components/Common/CommonContainer';

import { ProfileFormComponent } from './components/ProfileFormComponent/ProfileFormComponent';
import { ProfileTopLine } from './components/ProfileTopLine/ProfileTopLine';

interface IProfileScreenProps {}

export const ProfileScreen: React.FC<IProfileScreenProps> = () => {
  return (
    <CommonContainer
      sx={{
        pb: 5,
        pl: 9,
        pt: 8.75,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        height: 'calc(100vh - 85px)',
      }}
    >
      <ProfileTopLine />

      <ProfileFormComponent />
    </CommonContainer>
  );
};
