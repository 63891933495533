import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { QCheckbox } from 'components/UI/CheckBoxes/QCheckbox';
import { QOutlinedTextField } from 'components/UI/TextFields/QOutlinedTextField';
import { PollAnswersFormFields } from 'modules/polls/forms/PollAnswersForm';
import { PollQuestionsForm, PollQuestionsFormFields } from 'modules/polls/forms/PollQuestionsForm';
import { PollMatchDefaultTemplateAnswerModel } from 'modules/polls/models/PollMatchDefaultTemplateAnswerModel';
import { PollQuestionModel } from 'modules/polls/models/PollQuestionModel';

interface IPollTextBlockCheckboxProps {
  index: number;
  answer: PollMatchDefaultTemplateAnswerModel | null;
  question: PollQuestionModel | null;
}

export const PollTextBlockCheckbox: React.FC<IPollTextBlockCheckboxProps> = props => {
  const { answer, question, index } = props;
  const { control } = useFormContext<PollQuestionsForm>();
  const { classes } = useStyles();

  const answerId = String(answer?.id ?? 0);
  const questionId = question?.id ?? 0;

  const { field: answerController } = useController({
    control,
    name: `${PollQuestionsFormFields.questions}.${questionId}.${PollAnswersFormFields.answers}.${index}`,
  });

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      answerController.onChange(answerId);
    } else {
      answerController.onChange(null);
    }
  };

  return (
    <div className={classes.answerItem}>
      {!!answer?.image?.length && (
        <div className={classes.imgWrap}>
          <img src={answer?.image} alt="" />
        </div>
      )}
      <div className={classes.fieldWrap}>
        <QCheckbox color="secondary" checked={answerController.value === answerId} onChange={handleChangeCheckbox} />
        <QOutlinedTextField
          value={answer?.text ?? ''}
          InputProps={{ readOnly: true }}
          containerProps={{ sx: { width: 239 } }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  answerItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  imgWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 273,
    height: 273,
    overflow: 'hidden',
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  fieldWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
