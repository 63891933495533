import { pollsRoutes, routes } from 'routes/routes';

import { HeaderVisualType } from '../types/BaseTypes';

export const BASE_DISPLAY_DATE_FORMAT = 'dd.MM.yyyy HH:mm';
export const BASE_DISPLAY_DATE_SHORT_FORMAT = 'dd.MM.yyyy';

export const getHeaderVisualTypeMatrix = (): Record<HeaderVisualType, string[]> => ({
  [HeaderVisualType.main]: [routes.MainScreen.path],
  [HeaderVisualType.pageIn]: [
    routes.MembersScreen.path,
    routes.NotificationsScreen.path,
    routes.SupportScreen.path,
    routes.ProfileScreen.path,
    routes.FAQScreen.path,
    routes.ReportDetailScreen.path,
  ],
  [HeaderVisualType.poll]: [
    routes.PollTemplatesScreen.path,
    routes.PollDetailScreen.path,
    routes.PollCreateAndEditScreen.path,
    pollsRoutes.PollConstructorScreen.path,
    pollsRoutes.PollDesignScreen.path,
    pollsRoutes.PollSettingsScreen.path,
    pollsRoutes.PollPreviewScreen.path,
  ],
});
