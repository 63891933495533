import * as yup from 'yup';

import { ProfileFormFields } from '../forms/ProfileForm';

export const profileFormScheme = yup.object({
  [ProfileFormFields.name]: yup.string().required(),
  [ProfileFormFields.gender]: yup.string().required(),
  [ProfileFormFields.birthday]: yup.string().required(),
  [ProfileFormFields.avatar]: yup.object().nullable(),
});
