import { OtherPollsMatches } from '../Common/OtherPollsMatches';
import { PollTopLine } from '../Common/PollTopLine';

interface IPollSingleEliminationProps {}

export const PollSingleElimination: React.FC<IPollSingleEliminationProps> = () => {
  return (
    <>
      <PollTopLine sx={{ mb: 4 }} />
      <OtherPollsMatches />
    </>
  );
};
