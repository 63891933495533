import { AbstractApiRepository } from 'base/api/AbstractApiRepository';

import { ChangeRoleDto } from '../dto/ChangeRoleDto';
import { CreateInviteInOrganizationDto } from '../dto/CreateInviteInOrganizationDto';
import { InviteUserInOrganizationDto } from '../dto/InviteUserInOrganizationDto';
import { JoinOrganizationDto } from '../dto/JoinOrganizationDto';
import { KickMemberDto } from '../dto/KickMemberDto';
import { OrganizationCreateFormDto } from '../dto/OrganizationCreateFormDto';

export class OrganizationsApiRepository extends AbstractApiRepository {
  createOrganization = (data: OrganizationCreateFormDto) => {
    return this.apiClient.post({ url: '/organizations', data });
  };

  deleteOrganization = (organizationId: number) => {
    return this.apiClient.delete({ url: `/organization/${organizationId}` });
  };

  getOrganizationMembers = (organizationId: number, params: string) => {
    return this.apiClient.get({ url: `/organizations/${organizationId}/members${params}` });
  };

  kickMember = (organizationId: number, data: KickMemberDto) => {
    return this.apiClient.delete({ url: `/organizations/${organizationId}/remove-member`, data });
  };

  getMembershipOrganizations = () => {
    return this.apiClient.get({ url: '/organizations' });
  };

  getOwnershipOrganizations = () => {
    return this.apiClient.get({ url: '/organizations/user/own' });
  };

  createInviteInOrganization = (organizationId: number, data: CreateInviteInOrganizationDto) => {
    return this.apiClient.post({ url: `/organization/${organizationId}/link`, data });
  };

  joinOrganizationByLink = (data: JoinOrganizationDto) => {
    return this.apiClient.post({ url: '/organizations/accept/invitation', data });
  };

  inviteUserInOrganization = (organizationId: number, data: InviteUserInOrganizationDto) => {
    return this.apiClient.post({ url: `/organizations/${organizationId}/invite`, data });
  };

  generateInviteLink = (organizationId: number) => {
    return this.apiClient.get({ url: `/organizations/${organizationId}/invite/generate` });
  };

  getOrganizationRolesList = () => {
    return this.apiClient.get({ url: '/organizations/roles/all' });
  };

  changeRole = (organizationId: number, data: ChangeRoleDto) => {
    return this.apiClient.post({ url: `/organizations/${organizationId}/change-role`, data });
  };

  getCurrentUserInOrganizationRole = (organizationId: number) => {
    return this.apiClient.get({ url: `/organizations/${organizationId}/role` });
  };
}
