import { MenuItemProps, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IQMenuSelectedItemProps extends MenuItemProps {}

export const QMenuSelectedItem: React.FC<IQMenuSelectedItemProps> = props => {
  const { classes, cx } = useStyles();
  const { children, className, selected, ...rest } = props;

  return (
    <MenuItem {...rest} className={cx(classes.menuItem, className, { [classes.selected]: selected })}>
      {children}
      {selected && <i className="custom-icon-done" />}
    </MenuItem>
  );
};

const useStyles = makeStyles()(theme => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    '& i': {
      fontSize: 9,
      marginLeft: theme.spacing(1.5),
      color: 'inherit',
    },
  },
  selected: {
    color: theme.palette.customColors.purple,
    fontWeight: 700,
  },
}));
