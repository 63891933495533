import { IconButton, Typography } from '@mui/material';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from 'tss-react/mui';

import { CommonHelper } from 'helpers/CommonHelper';

interface IAttachFileProps {
  file: File | null;
  label: string;
  disabled?: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  onHandleClearFile?: () => void;
}

export const AttachFile: React.FC<IAttachFileProps> = props => {
  const { file, label, disabled = false, onDrop, onHandleClearFile } = props;
  const { classes, cx } = useStyles(props);

  const fileDropzone = useDropzone({ onDrop, noClick: true, noKeyboard: true, multiple: false, disabled });

  //Renders
  return (
    <div className={classes.wrap}>
      <div
        className={cx(classes.fileUploaderWrap, { active: CommonHelper.isNotNullOrUndefined(file) })}
        {...fileDropzone.getRootProps()}
        onClick={fileDropzone.open}
      >
        <input
          disabled={disabled}
          type="file"
          name="fileInput"
          className={classes.fileInput}
          onChange={fileDropzone.open}
          {...fileDropzone.getInputProps()}
        />
        <div className={classes.iconWrap}>
          <i className="custom-icon-paperclip" />
        </div>
        <Typography variant={CommonHelper.isNotNullOrUndefined(file) ? 'h3Regular' : 'h3Bold'}>
          {file ? file.name : label}
        </Typography>
      </div>
      {CommonHelper.isNotNullOrUndefined(file) && (
        <IconButton className={classes.clearFile} size="small" onClick={onHandleClearFile}>
          <i className="custom-icon-trushsquare" />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles<IAttachFileProps, 'clearFile'>()((theme, props, classes) => {
  const disabled = props.disabled;

  return {
    wrap: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        [`& .${classes.clearFile}`]: {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    fileUploaderWrap: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.customColors.darkGrey,
      cursor: !disabled ? 'pointer' : undefined,
      '&.active': {
        color: theme.palette.customColors.black,
      },
    },
    iconWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 21,
      marginRight: theme.spacing(1.5),
    },
    fileInput: {
      display: 'none',
    },
    clearFile: {
      cursor: !disabled ? 'pointer' : undefined,
      marginLeft: theme.spacing(1),
      opacity: 0,
      visibility: 'hidden',
      transition: theme.transitions.create(['opacity', 'visibility']),
    },
  };
});
