import { Dto } from 'base/Dto';
import { PaginationService } from 'base/modules/pagination/PaginationService';
import { SortService } from 'base/modules/sort/SortService';

import { ChangeRoleDto } from './dto/ChangeRoleDto';
import { CreateInviteInOrganizationDto } from './dto/CreateInviteInOrganizationDto';
import { InviteUserInOrganizationDto } from './dto/InviteUserInOrganizationDto';
import { JoinOrganizationDto } from './dto/JoinOrganizationDto';
import { KickMemberDto } from './dto/KickMemberDto';
import { MembersListFormDto } from './dto/MembersListFormDto';
import { OrganizationCreateFormDto } from './dto/OrganizationCreateFormDto';
import { CreateInviteInOrganizationForm } from './forms/CreateInviteInOrganizationForm';
import { InviteUserInOrganizationForm } from './forms/InviteUserInOrganizationForm';
import { MembersListForm } from './forms/MembersListForm';
import { OrganizationCreateForm } from './forms/OrganizationCreateForm';

export class OrganizationsDataSource {
  private paginationService: PaginationService;
  private sortService: SortService;

  constructor() {
    this.paginationService = new PaginationService();
    this.sortService = new SortService();
  }

  public prepareMembersListFormDto = (form: MembersListForm): MembersListFormDto => {
    const paginationData = this.paginationService.getPaginationData(form.page, form.limit);

    const sortParam = this.sortService.getSort(form?.sortValue ?? null);
    const preDto = {
      orderBy: sortParam.orderBy,
    };

    return Dto.populate(MembersListFormDto, { ...form, ...preDto, ...paginationData });
  };

  public prepareOrganizationCreateFormDto = (form: OrganizationCreateForm): OrganizationCreateFormDto => {
    return Dto.populate(OrganizationCreateFormDto, { name: form.organizationName });
  };

  public prepareKickMemberDto = (userId: number): KickMemberDto => {
    return Dto.populate(KickMemberDto, { user_id: userId });
  };

  public prepareCreateInviteOrganizationDto = (form: CreateInviteInOrganizationForm): CreateInviteInOrganizationDto => {
    return Dto.populate(CreateInviteInOrganizationDto, { max_available_joins: form.maxAvailableJoins });
  };

  public prepareInviteUserInOrganizationDto = (form: InviteUserInOrganizationForm): InviteUserInOrganizationDto => {
    return Dto.populate(InviteUserInOrganizationDto, { email: form.userEmail });
  };

  public prepareChangeRoleDto = (userId: number, roleId: number): ChangeRoleDto => {
    return Dto.populate(ChangeRoleDto, { user_id: userId, role_id: roleId });
  };

  public prepareJoinOrganizationDto = (inviteLink: string): JoinOrganizationDto => {
    return Dto.populate(JoinOrganizationDto, { hash: inviteLink });
  };
}
