import { IReportAnswerTableField } from 'modules/reports/interfaces/ReportsInterfaces';
import { ReportAnswersTableColumns } from 'modules/reports/types/ReportsTypes';

export const reportAnswersTableFields: IReportAnswerTableField[] = [
  { column: ReportAnswersTableColumns.id, title: '№', isOrderable: false, settings: { align: 'left' } },
  { column: ReportAnswersTableColumns.image, title: 'Картинка', isOrderable: false, settings: { align: 'left' } },
  { column: ReportAnswersTableColumns.text, title: 'Вариант ответа', isOrderable: false, settings: { align: 'left' } },
  { column: ReportAnswersTableColumns.score, title: 'Баллы', isOrderable: true, settings: { align: 'center' } },
  {
    column: ReportAnswersTableColumns.userAnswers,
    title: 'Кол-во голосов',
    isOrderable: true,
    settings: { align: 'center' },
  },
];
