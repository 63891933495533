import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { QMenu } from 'components/UI/QMenu/QMenu';
import { routes } from 'routes/routes';
import { customColors } from 'styles/colors';

interface NotificationsPopupProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

export const NotificationsPopup: React.FC<NotificationsPopupProps> = observer(({ handleClose, anchorEl }) => {
  const { notificationsStore } = useRootStore();
  const { classes } = useStyles();

  // Effects
  useEffect(() => {
    // notificationsStore.getNotificationsList();
  }, []);

  // Renders
  return (
    <QMenu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose} maxWidth={242}>
      <div className={classes.list}>
        {notificationsStore.notificationsList?.map(({ title, message }, index) => (
          <div className={classes.notification} key={index}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="h4Bold" color={customColors.purple}>
                {title}
              </Typography>
              <TextButton className={classes.closeBtn}>
                <i className="custom-icon-add" />
              </TextButton>
            </Box>
            <Box sx={{ mt: 0.5, overflow: 'hidden' }}>
              <Typography variant="h5Regular" className={classes.message}>
                {message}
              </Typography>
            </Box>
          </div>
        ))}
      </div>
      <div className={classes.footer}>
        <Link to={routes.NotificationsScreen.path}>
          <Typography variant="h4Bold" color={customColors.purple}>
            Посмотреть все
          </Typography>
        </Link>
      </div>
    </QMenu>
  );
});

const useStyles = makeStyles()(theme => ({
  list: { maxWidth: '100%', display: 'flex', flexDirection: 'column', gap: theme.spacing(1.25) },
  notification: {
    padding: theme.spacing(0, 2.5, 1),
    borderBottom: `1px solid ${theme.palette.customColors.grayDivider}`,
  },
  message: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  closeBtn: {
    marginTop: theme.spacing(0.65),
    alignItems: 'flex-start',
    fontSize: '11px',
    color: theme.palette.customColors.black,
    ':hover': {
      color: theme.palette.customColors.black,
    },
    '& > i': {
      transform: 'rotate(-45deg)',
    },
  },
  footer: {
    height: '33px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
    },
  },
}));
