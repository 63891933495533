import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { customColors } from 'styles/colors';

interface INotificationsHeaderProps {}

export const NotificationsHeader: React.FC<INotificationsHeaderProps> = observer(() => {
  const { notificationsStore } = useRootStore();
  const { classes } = useStyles();

  // Redners
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h1Bold">Уведомления</Typography>
        {notificationsStore.showEraseWarning && (
          <div className={classes.warning}>
            <Typography color={customColors.red} variant="h5Regular" component="span">
              Внимание! Срок хранения уведомлений: {notificationsStore.daysTillErase} дня
            </Typography>
          </div>
        )}
      </Box>
      <Button color="error" variant="contained">
        Удалить все
      </Button>
    </Box>
  );
});

const useStyles = makeStyles()(theme => ({
  warning: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.65),
    alignItems: 'center',
    display: 'flex',
  },
}));
