import { Box, FormLabel, IconButton, TextField, Theme, iconButtonClasses } from '@mui/material';
import { forwardRef, useState } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';

import { FieldInfo } from 'components/UI/FieldInfo';
import { QHelperText } from 'components/UI/QHelperText';
import { IQOutlinedTextFieldProps } from 'interfaces/CommonComponentsInterfaces';

export const QOutlinedTextField: React.FC<IQOutlinedTextFieldProps> = forwardRef((props, ref) => {
  const {
    label,
    fieldInfo,
    required,
    requiredIcon,
    labelProps,
    startIconProps,
    endIconProps,
    InputProps,
    errors,
    name,
    containerProps,
    ...rest
  } = props;

  const { classes, cx } = useStyles();
  const [focused, setFocused] = useState(false);

  // Handlers
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  // Renders
  const renderStartIcon = () => {
    if (!startIconProps) {
      return null;
    }

    const { iconClassName, ...startIconPropsRest } = startIconProps;

    return (
      <StyledIconButton color="inherit" disabled={rest.disabled} {...startIconPropsRest}>
        {!!iconClassName?.length && <i className={iconClassName} />}
      </StyledIconButton>
    );
  };

  const renderEndIcon = () => {
    if (!endIconProps) {
      return null;
    }

    const { iconClassName, ...endIconPropsRest } = endIconProps;

    return (
      <StyledIconButton color="inherit" disabled={rest.disabled} {...endIconPropsRest}>
        {!!iconClassName?.length && <i className={iconClassName} />}
      </StyledIconButton>
    );
  };

  const renderRequiredIcon = () => {
    if (!required) {
      return null;
    }

    return requiredIcon ? requiredIcon : '*';
  };

  return (
    <Box className={cx(classes.wrap, { fullWidth: rest.fullWidth })} {...containerProps}>
      {label && (
        <FormLabel color={focused ? 'primary' : 'secondary'} {...labelProps}>
          <div className={classes.labelInner}>
            {label} {renderRequiredIcon()} <FieldInfo title={fieldInfo} />
          </div>
        </FormLabel>
      )}
      <div className={classes.textFieldWrap}>
        <TextField
          fullWidth
          autoComplete="off"
          ref={ref}
          name={name}
          variant="outlined"
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={!!errors}
          helperText={errors?.length ? <QHelperText errors={errors} /> : undefined}
          InputProps={{
            startAdornment: renderStartIcon(),
            endAdornment: renderEndIcon(),
            ...InputProps,
          }}
          {...rest}
        />
      </div>
    </Box>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    textAlign: 'left',
    '&.fullWidth': {
      width: '100%',
    },
  },
  textFieldWrap: {
    position: 'relative',
  },
  labelInner: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledIconButton = withStyles(IconButton, (theme: Theme) => ({
  root: {
    [`&.${iconButtonClasses.root}`]: {
      padding: '10px',
    },
  },
}));
