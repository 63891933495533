import { PollQuestions } from '../Common/PollQuestions';
import { PollTopLine } from '../Common/PollTopLine';

interface IPollDefaultTypeProps {}

export const PollDefaultType: React.FC<IPollDefaultTypeProps> = () => {
  return (
    <>
      <PollTopLine sx={{ mb: 4 }} />
      <PollQuestions />
    </>
  );
};
