import { Serializable, jsonProperty } from 'ts-serializable';

import { PollTypes } from 'modules/polls/types/PollsTypes';

import { PollQuestionDto } from './PollQuestionDto';

export class PollsCreateFormDto extends Serializable {
  @jsonProperty(String, null) name: string | null = null;
  @jsonProperty(String, null) description: string | null = null;
  @jsonProperty(Number, String, null) organization_id: number | string | null = null;
  @jsonProperty(String, null) template: PollTypes | null = null;
  @jsonProperty(String, null) background: string | null = null;
  @jsonProperty(Boolean) shuffle: boolean = false;
  @jsonProperty(Boolean) with_description: boolean = false;
  @jsonProperty(Boolean) with_progress_bar: boolean = false;
  @jsonProperty(Boolean) one_by_one: boolean = false;
  @jsonProperty(Boolean) with_notifications: boolean = false;
  @jsonProperty([PollQuestionDto], null) questions: PollQuestionDto[] | null = null;
}
