import { StylesHelper } from 'helpers/StylesHelper';

export enum Colors {
  simpleBlack = '#000000',
  black = '#393939',
  darkGrey = '#858585',
  gray = '#C6C6C7',
  grayDivider = '#DEDEDE',
  hoverPurpleGradient = 'linear-gradient(180deg, #A8A7FF 0%, #6F6EC2 100%)',
  purple = '#9998F9',
  purpleDark = '#9796F0',
  purple100 = '#E0E0FD',
  purple300 = '#B8B7FB',
  purple900 = '#6362ED',
  red = '#FF4C57',
  pink = '#FF3F79',
  hoverMainGradient = 'linear-gradient(90deg, #8E8DFB 0%, #FF7497 100%)',
  mainGradient = 'linear-gradient(90deg, #9796F0 0%, #FFB3C6 100%)',
  activeGreen = '#AAE42E',
  whiteBlueGradient = 'linear-gradient(180deg, #FFFFFF 0%, #E6E6FC 100%)',
  lightBlue = '#FAF8FF',
  white = '#FFFFFF',
  gradientAquamarine = 'linear-gradient(90deg, #00F5A0 0%, #00D9F5 100%)',
  gradientOrange = 'linear-gradient(90deg, #FFE259 0%, #FFA751 100%)',
  gradientBg = 'linear-gradient(360deg, #9796F0 0%, #FFB3C6 100%)',
  gradientRed = 'linear-gradient(180deg, #FF4C57 0%, #DD242F 100%)',
}

type TColorsKeys = `${keyof typeof Colors}`;
type ExcludedGradientColors = Exclude<
  TColorsKeys,
  | 'hoverPurpleGradient'
  | 'hoverMainGradient'
  | 'mainGradient'
  | 'whiteBlueGradient'
  | 'gradientAquamarine'
  | 'gradientOrange'
  | 'gradientBg'
  | 'gradientRed'
>;

export type ColorsKeysMap = Record<ExcludedGradientColors, string>;

export const customColors: ColorsKeysMap = StylesHelper.getCustomColorsKeys();
