import { Link, LinkProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as LogoIcon } from 'assets/img/icons/logo-new.svg';

interface ILogoProps {
  linkProps: LinkProps;
}

export const Logo: React.FC<ILogoProps> = props => {
  const { classes } = useStyles();

  return (
    <Link {...props.linkProps}>
      <div className={classes.logoWrap}>
        <LogoIcon />
      </div>
    </Link>
  );
};

const useStyles = makeStyles()(theme => ({
  logoWrap: {
    width: 136,
    height: 51,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));
