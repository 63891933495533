import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { Notification } from 'base/ui/Notification';
import { pollsNotificationMessages } from 'messages/polls';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { PollModel } from 'modules/polls/models/PollModel';
import { PollTypes } from 'modules/polls/types/PollsTypes';
import { routes } from 'routes/routes';
import { customColors } from 'styles/colors';

import { PollActionMenu } from '../PollActionMenu';

interface IPollCardProps {
  data: PollModel | null;
}

export const PollCard: React.FC<IPollCardProps> = observer(props => {
  const { data } = props;
  const { organizationsStore } = useRootStore();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const showMoreMenu =
    !!roleMatrix?.pollResults || !!roleMatrix?.pollCreate || !!roleMatrix?.pollEdit || !!roleMatrix?.pollDelete;

  const path = RouteHelper.makePath(routes.PollDetailScreen.path, {
    pollId: data?.id ?? '',
    type: data?.template ?? PollTypes.default,
  });

  // Handlers
  const handleNavigate = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (!data?.is_active) {
      Notification.showWarning(pollsNotificationMessages.navigatePollDetail.warning);
      return;
    }

    if (data.is_completed) {
      Notification.showWarning(pollsNotificationMessages.pollPassed.warning);
      return;
    }

    if (!roleMatrix?.pollCompletion) {
      Notification.showError(pollsNotificationMessages.pollCompletionAccess.error);
      return;
    }

    navigate(path);
  };

  // Renders
  return (
    <div className={classes.card} onClick={handleNavigate}>
      <div className={classes.topLine}>
        <div className={cx(classes.status, { active: !!data?.is_active, disable: !data?.is_active })} />
        <div className={classes.imgWrap} />
        <div className={classes.nameWrap}>
          <Typography className={classes.cardText} variant="inherit" color={customColors.white}>
            {data?.name}
          </Typography>
        </div>
        <div className={classes.imgContentBottom}>
          <Typography variant="h4Bold">{/* {data?.progress?.completed}/{data?.progress?.all} */}</Typography>
          {showMoreMenu && <PollActionMenu pollInfo={data} />}
        </div>
      </div>
      <div className={classes.bottomLine}>
        <div className={classes.userWrap}>
          <div className={classes.iconWrap}>
            <i className="custom-icon-user" />
          </div>
          <Typography className={classes.name} variant="h5Regular">
            {data?.creator?.name}
          </Typography>
        </div>
        <div className={classes.dateWrap}>
          <Typography component="div" variant="h5Regular">
            {data?.created_at?.formattedShort}
          </Typography>
        </div>
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  card: {
    flexBasis: 'calc(16.666666% - 9px)',
    maxWidth: 'calc(16.666666% - 9px)',
    minHeight: 267,
    color: theme.palette.customColors.black,
    cursor: 'pointer',
    '&:hover, &:active': {
      color: theme.palette.customColors.black,
    },
  },
  status: {
    position: 'absolute',
    top: theme.spacing(1.25),
    left: theme.spacing(2),
    width: 8,
    height: 8,
    borderRadius: '50%',
    zIndex: 1,
    '&.active': {
      backgroundColor: theme.palette.customColors.activeGreen,
    },
    '&.disable': {
      backgroundColor: theme.palette.customColors.red,
    },
  },
  imgWrap: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translateX(-50%)',
    background: theme.palette.customColors.purpleDark,
    zIndex: -1,
  },
  // img: {
  //   width: '100%',
  //   height: '100%',
  //   objectFit: 'cover',
  // },
  topLine: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 225,
    borderRadius: '10px 10px 0px 0px',
    padding: theme.spacing(1.5, 2, 2.5),
    border: `1px solid ${theme.palette.customColors.grayDivider}`,
    overflow: 'hidden',
  },
  bottomLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 1.25),
    borderRadius: '0px 0px 10px 10px',
    border: `1px solid ${theme.palette.customColors.grayDivider}`,
    marginTop: '-1px',
    gap: theme.spacing(1),
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 13,
    marginRight: theme.spacing(1.25),
  },
  userWrap: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.customColors.black,
    flexBasis: '68%',
    maxWidth: '68%',
  },
  dateWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexBasis: '32%',
    maxWidth: '32%',
  },
  nameWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardText: {
    color: theme.palette.customColors.white,
    [theme.breakpoints.up('xxs')]: {
      ...theme.typography.h4Bold,
    },
    [theme.breakpoints.up('xl')]: {
      ...theme.typography.h3Bold,
    },
  },
  imgContentBottom: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.customColors.white,
    padding: theme.spacing(0, 2, 2.5),
  },
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    width: '100%',
  },
}));
