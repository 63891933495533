import { makeAutoObservable } from 'mobx';

import { BaseService } from './BaseService';
import { HeaderVisualType } from './types/BaseTypes';

export class BaseStore {
  loading: boolean = false;

  membersModalOpen: boolean = false;
  headerVisualType: HeaderVisualType = HeaderVisualType.main;

  private baseService: BaseService;

  constructor() {
    makeAutoObservable(this);
    this.baseService = new BaseService();
  }

  abortRequest = () => {
    return this.baseService.abortRequest();
  };

  // Getters
  getValidHeaderVisualType = (pathname: string) => {
    return this.baseService.getValidHeaderVisualType(pathname);
  };

  // Setters
  setMembersModalOpen = (value: boolean) => {
    this.membersModalOpen = value;
  };

  setHeaderVisualType = (value: HeaderVisualType) => {
    this.headerVisualType = value;
  };

  // Loadings
  setLoading = (value: boolean) => {
    this.loading = value;
  };
}
