import { BaseForm } from 'base/BaseForm';

export enum PollSearchFormFields {
  search = 'search',
}

export class PollSearchForm extends BaseForm {
  search: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
