import { Theme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { HeaderVisualType } from 'modules/base/types/BaseTypes';

import { CommonContainer } from '../CommonContainer';
import { HeaderMain } from './components/HeaderMain';
import { HeaderPageIn } from './components/HeaderPageIn';
import { PollsHeader } from './components/PollsHeader';

export const Header: React.FC = observer(() => {
  const { baseStore } = useRootStore();
  const { classes } = useStyles();
  const location = useLocation();

  const headerValidVisualType = baseStore.getValidHeaderVisualType(location.pathname);

  const HeaderTypesComponents: Record<HeaderVisualType, ReactNode> = useMemo(() => {
    return {
      main: <HeaderMain />,
      pageIn: <HeaderPageIn />,
      poll: <PollsHeader />,
    };
  }, []);

  // Renders
  return (
    <header className={classes.header}>
      <CommonContainer sx={{ py: 2, pr: 3.75 }} className={classes.headerInner}>
        {HeaderTypesComponents[headerValidVisualType] || null}
      </CommonContainer>
    </header>
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gridArea: 'header',
    background: theme.palette.customColors.white,
    boxShadow: '0px 4px 4px rgba(169, 169, 169, 0.12)',
    minHeight: 85,
    zIndex: 2,
  },
  headerInner: {
    position: 'absolute',
    left: 0,
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
