import { TableCell } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { StatusWithLabel } from 'components/UI/StatusWithLabel';
import { PollModel } from 'modules/polls/models/PollModel';
import { PollsTableColumns } from 'modules/polls/types/PollsTypes';

interface IPollsTableBodyElementsProps {
  data: PollModel;
  elementId: PollsTableColumns;
}

export const PollsTableBodyElements: React.FC<IPollsTableBodyElementsProps> = props => {
  const { data, elementId } = props;
  const { classes, theme } = useStyles();

  const renderCurrentStatus = () => {
    if (data.is_completed) {
      return <StatusWithLabel label="Завершен" color={theme.palette.customColors.darkGrey} />;
    }

    if (data.is_active) {
      return <StatusWithLabel label="Активен" color={theme.palette.customColors.activeGreen} />;
    }

    if (!data.is_active) {
      return <StatusWithLabel label="Неактивен" color={theme.palette.customColors.red} />;
    }
  };

  const elements = useMemo<Record<PollsTableColumns, ReactNode>>(() => {
    return {
      name: (
        <TableCell>
          <div className={classes.content}>
            <div className={classes.imgWrap} />
            <div className={classes.nameWrap}>{data.name}</div>
          </div>
        </TableCell>
      ),
      created_at: <TableCell align="center">{data.created_at?.formatted}</TableCell>,
      questions: <TableCell align="center">{data.questions?.length ?? 0}</TableCell>,
      status: (
        <TableCell align="center">
          <div className={classes.statusWrap}>{renderCurrentStatus()}</div>
        </TableCell>
      ),
      completed: <TableCell align="center">-</TableCell>,
      passability: <TableCell align="center">-</TableCell>,
    };
  }, [data]);

  return <>{elements?.[elementId] ? elements?.[elementId] : null}</>;
};

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 315,
  },
  nameWrap: {
    marginLeft: theme.spacing(1.5),
    maxWidth: 250,
  },
  imgWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 53,
    height: 53,
    overflow: 'hidden',
    borderRadius: 3,
    background: theme.palette.customColors.purpleDark,
    zIndex: 1,
  },
  statusWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
