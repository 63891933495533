import { Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { QPagination } from 'components/UI/QPagination';
import { membersModalTableFields } from 'lists/organizations/membersModalTableFields';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { ReportAnswersListFormFields } from 'modules/reports/forms/ReportAnswersListForm';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';
import { routes } from 'routes/routes';

import { MembersModal } from '../MembersModal/MembersModal';
import { ReportDetailTableList } from './ReportDetailTable/ReportDetailTableList';
import { ReportDetailTopLine } from './ReportDetailTopLine';

interface IReportDetailContainerProps {}

export const ReportDetailContainer: React.FC<IReportDetailContainerProps> = observer(() => {
  const { classes } = useStyles();
  const { reportsStore, organizationsStore } = useRootStore();
  const params = useParams<{ reportId: string }>();
  const navigate = useNavigate();
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  // Effects
  useEffect(() => {
    if (!organizationsStore.currentUserInOrganizationRole) {
      return;
    }

    if (!roleMatrix?.pollResults) {
      navigate(routes.MainScreen.path);
    }
  }, [organizationsStore.currentUserInOrganizationRole]);

  useEffect(() => {
    if (!params.reportId) {
      return;
    }

    reportsStore.reportAnswersListFormSetValueAndGetList(ReportAnswersListFormFields.pollId, params.reportId);
  }, []);

  // Handlers
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    reportsStore.reportAnswersListFormSetValueAndGetList(TableFormFields.page, page);
  };

  const handleChangePageUsersVoteList = (event: React.ChangeEvent<unknown>, page: number) => {
    reportsStore.usersVoteListFormSetValueAndGetList(TableFormFields.page, page);
  };

  const handleCloseUsersVoteListModal = () => {
    reportsStore.setOpenUsersVoteListModal(false);
    reportsStore.usersVoteListForm.resetForm();
  };

  const handleSort = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    reportsStore.usersVoteListFormSetSortValueAndGetList(currentSort);
  };
  // Renders
  return (
    <>
      <ReportDetailTopLine />
      <Divider className={classes.divider} />
      <ReportDetailTableList />
      {reportsStore.reportAnswersListForm.showPagination && (
        <div className={classes.paginationWrap}>
          <QPagination
            color="primary"
            defaultPage={1}
            page={reportsStore.reportAnswersListForm.page}
            count={reportsStore.reportAnswersListForm.pageCount}
            onChange={handleChangePage}
          />
        </div>
      )}

      <MembersModal
        open={reportsStore.openUsersVoteListModal}
        sortValue={reportsStore.usersVoteListForm.sortValue}
        tableLoading={reportsStore.usersVoteListLoading}
        membersList={reportsStore.usersVoteList}
        tableFields={membersModalTableFields}
        paginationProps={{
          showPagination: reportsStore.usersVoteListForm.showPagination,
          page: reportsStore.usersVoteListForm.page,
          pageCount: reportsStore.usersVoteListForm.pageCount,
          onHandleChangePage: handleChangePageUsersVoteList,
        }}
        onHandleClose={handleCloseUsersVoteListModal}
        onHandleSort={handleSort}
      />
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  divider: {
    marginBottom: theme.spacing(-1.5),
  },
  paginationWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(8),
  },
}));
