import { BaseForm } from 'base/BaseForm';

export enum InviteUserInOrganizationFormFields {
  choosenOrganization = 'choosenOrganization',
  userEmail = 'userEmail',
}

export class InviteUserInOrganizationForm extends BaseForm {
  choosenOrganization: string = '';
  userEmail: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
