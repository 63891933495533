import { makeAutoObservable, runInAction } from 'mobx';

import { NotificationsService } from './NotificationsService';
import { NotificationModel } from './models/NotificationModel';

export class NotificationsStore {
  notificationsListLoading: boolean = false;
  notificationsList: NotificationModel[] | null = null;
  showEraseWarning: boolean = true;
  daysTillErase: number = 3;
  page: number = 0;
  limit: number = 5;
  total: number = 0;

  private notificationsService: NotificationsService;

  constructor() {
    makeAutoObservable(this);
    this.notificationsService = new NotificationsService();
  }

  getNotificationsList = (onHandleSuccess?: (notifications: NotificationModel[]) => void) => {
    this.setNotificationsListLoading(true);

    this.notificationsService
      .getNotifications()
      .then(notifications => {
        runInAction(() => {
          this.notificationsList = notifications;
          this.total = notifications.length;
          onHandleSuccess?.(notifications);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setNotificationsListLoading(false);
      });
  };

  deleteNotifications = (onHandleSuccess?: () => void) => {
    this.setNotificationsListLoading(true);

    this.notificationsService
      .deleteNotifications()
      .then(() => {
        runInAction(() => {
          onHandleSuccess?.();
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setNotificationsListLoading(false);
      });
  };

  // Getters
  get pageCount() {
    return Math.ceil(this.total / this.limit);
  }

  get showPagination() {
    return this.total > this.limit;
  }

  // Setters
  setPage = (value: number) => {
    this.page = value;
  };

  // Loadings
  setNotificationsListLoading = (value: boolean) => {
    this.notificationsListLoading = value;
  };
}
