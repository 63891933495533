import { SelectChangeEvent, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { QSelectOutlined } from 'components/UI/Selects/QSelectOutlined';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { OrganizationRoles } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';

import { OwnerTransferConfirmModal } from './OwnerTransferConfirmModal';

interface IRoleChangeSelectProps {
  member: UserModel;
}

export const RoleChangeSelect: React.FC<IRoleChangeSelectProps> = observer(props => {
  const { member } = props;
  const { organizationsStore, usersStore } = useRootStore();

  const [transferOwnerModalOpen, setTransferOwnerModalOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState<OrganizationRoles | null>(null);
  const prevRole = useRef<OrganizationRoles | null>(null);

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);
  const isCurrentSelectedOrganizationAdmin =
    organizationsStore.selectedOrganization?.owner?.id === usersStore.userInfo?.id;
  const isCurrentMemberAdmin = member.role?.name === OrganizationRoles.admin;
  const filteredRolesList = OrganizationHelper.getFilteredRoles(
    organizationsStore.rolesList,
    currentRole,
    isCurrentSelectedOrganizationAdmin,
  );

  // Effects
  useEffect(() => {
    if (member.role?.name) {
      setCurrentRole(member.role.name);
    }
  }, [member]);

  // Handlers
  const handleDisableRoleChangeSelect = () => {
    if (roleMatrix?.changeRoleToAdmin && isCurrentSelectedOrganizationAdmin && !isCurrentMemberAdmin) {
      return false;
    }

    if (
      roleMatrix?.changeTheRole &&
      !isCurrentMemberAdmin &&
      member.role?.name === OrganizationRoles.poll_participant
    ) {
      return false;
    }

    return true;
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const value: OrganizationRoles = event.target.value;
    const currentRoleId = organizationsStore.rolesHashMap?.[value!]?.id ?? 0;

    if (!organizationsStore.selectedOrganization?.id || !member.id || !value) {
      return;
    }

    if (value === OrganizationRoles.admin) {
      handleOpenTransferOwnerModal();
      setCurrentRole(prevState => {
        prevRole.current = prevState;

        return value;
      });

      return;
    }

    organizationsStore.changeRole(organizationsStore.selectedOrganization.id, member.id, currentRoleId, () => {
      organizationsStore.getMembershipOrganizations('RoleChangeSelect members');
    });

    setCurrentRole(value);
  };

  const handleOpenTransferOwnerModal = () => {
    setTransferOwnerModalOpen(true);
  };

  const handleCloseTransferOwnerModal = () => {
    setTransferOwnerModalOpen(false);
  };

  const handleCancelTransforOwner = () => {
    setCurrentRole(prevRole.current);
    handleCloseTransferOwnerModal();
  };

  // Renders
  return (
    <>
      <QSelectOutlined
        value={currentRole ?? ''}
        disabled={handleDisableRoleChangeSelect()}
        formControlProps={{ sx: { minWidth: 160 } }}
        onChange={handleChange}
      >
        {filteredRolesList?.map(
          role =>
            role.name && (
              <MenuItem key={role.name} value={role.name}>
                {role.title}
              </MenuItem>
            ),
        )}
      </QSelectOutlined>

      <OwnerTransferConfirmModal
        currentMember={member}
        currentRole={currentRole}
        open={transferOwnerModalOpen}
        onHandleCancel={handleCancelTransforOwner}
        onHandleCloseModal={handleCloseTransferOwnerModal}
      />
    </>
  );
});
