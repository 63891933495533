import { NotificationsFactory } from './NotificationsFactory';
import { NotificationModel } from './models/NotificationModel';
import { NotificationsApiRepository } from './repositories/NotificationsApiRepository';

export class NotificationsService {
  private notificationsApi: NotificationsApiRepository;
  private notificationsFactory: NotificationsFactory;

  constructor() {
    this.notificationsApi = new NotificationsApiRepository();
    this.notificationsFactory = new NotificationsFactory();
  }

  getNotifications = async (): Promise<NotificationModel[]> => {
    const { data } = await this.notificationsApi.getNotifications();

    return this.notificationsFactory.createList(NotificationModel, data);
  };

  deleteNotifications = async (): Promise<void> => this.notificationsApi.deleteNotifications();
}
