import { Button, Typography, buttonClasses } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { Loader } from 'components/UI/Loader';
import { routes } from 'routes/routes';
import { useCommonStyles } from 'styles/commonStyles';

import { ReportAnswersFilterMenu } from './ReportAnswersFilterMenu';

interface IReportDetailTopLineProps {}

export const ReportDetailTopLine: React.FC<IReportDetailTopLineProps> = observer(() => {
  const { classes } = useStyles();
  const { commonClasses, cx } = useCommonStyles();
  const { reportsStore } = useRootStore();
  const params = useParams<{ reportId?: string }>();

  // Handlers
  const handleDownloadReport = () => {
    if (params?.reportId?.length) {
      reportsStore.exportReport(params.reportId);
    }
  };

  // Renders
  return (
    <div className={classes.wrap}>
      {reportsStore.exportReportLoading && <Loader isFixed />}
      <Link to={routes.ReportsScreen.path} className="router-link">
        <TextButton
          color="primary"
          className={cx(commonClasses.textButtonIcon13, classes.btn)}
          startIcon={<i className="custom-icon-triangle-owal-down" />}
        >
          <Typography variant="h4Regular">Вернуться назад</Typography>
        </TextButton>
      </Link>
      <div className={classes.filterWrap}>
        <Button
          className={classes.importBtn}
          variant="text"
          color="secondary"
          endIcon={<i className="custom-icon-import-curve" />}
          onClick={handleDownloadReport}
        >
          Скачать отчет
        </Button>
        <ReportAnswersFilterMenu />
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  filterWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  importBtn: {
    fontWeight: 700,
    [`& .${buttonClasses.endIcon} i`]: {
      fontSize: 19,
    },
  },
  btn: {
    [`& .${buttonClasses.startIcon}`]: {
      '& i': {
        transform: 'rotate(90deg)',
      },
    },
  },
}));
