import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { QPagination } from 'components/UI/QPagination';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';

interface IReportsPaginationProps {}

export const ReportsPagination: React.FC<IReportsPaginationProps> = observer(() => {
  const { reportsStore } = useRootStore();

  // Handlers
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    reportsStore.reportsListFormSetValueAndGetList(TableFormFields.page, page);
  };

  // Renders
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pb: 5 }}>
      <QPagination
        color="primary"
        defaultPage={1}
        page={reportsStore.reportsListForm.page}
        count={reportsStore.reportsListForm.pageCount}
        onChange={handleChangePage}
      />
    </Box>
  );
});
