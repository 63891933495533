import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';

import { PollCard } from './PollCard';

interface IPollsGridListProps {}

export const PollsGridList: React.FC<IPollsGridListProps> = observer(props => {
  const { pollsStore } = useRootStore();
  const { classes } = useStyles();

  return (
    <div className={classes.list}>
      {pollsStore.organizationPollsListLoading && <Loader isAbsolute />}
      {!!pollsStore.organizationPollsList?.length &&
        pollsStore.organizationPollsList?.map(item => <PollCard key={item.id} data={item} />)}
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  list: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(1.25),
    rowGap: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    // minHeight: 267 * 2,
  },
}));
