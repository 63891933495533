import { AbstractApiRepository } from 'base/api/AbstractApiRepository';

import { CheckUserAccessTokenDto } from './dto/auth/CheckUserAccessTokenDto';
import { ForgotPasswordDto } from './dto/auth/ForgotPasswordDto';
import { LoginDto } from './dto/auth/LoginDto';
import { NewPasswordDto } from './dto/auth/NewPasswordDto';
import { RegisterDto } from './dto/auth/RegisterDto';

export class AuthApiRepository extends AbstractApiRepository {
  register = (data: RegisterDto) => {
    return this.apiClient.post({ url: '/auth/register', data });
  };

  login = (data: LoginDto) => {
    return this.apiClient.post({ url: '/auth/login', data });
  };

  logout = () => {
    return this.apiClient.post({ url: '/auth/logout' });
  };

  verifyAccount = (id: string, hash: string) => {
    return this.apiClient.get({ url: `/auth/verify/${id}/${hash}` });
  };

  forgotPassword = (data: ForgotPasswordDto) => {
    return this.apiClient.post({ url: '/reset-password-request', data });
  };

  setNewPassword = (id: string, hash: string, data: NewPasswordDto) => {
    return this.apiClient.post({ url: `/reset-password/${id}/${hash}`, data });
  };

  checkUserAccessToken = (data: CheckUserAccessTokenDto) => {
    return this.apiClient.post({ url: '/user/check-token', data });
  };
}
