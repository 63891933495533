import { Serializable, jsonProperty } from 'ts-serializable';

import { PollMatchDefaultTemplateAnswerModel } from './PollMatchDefaultTemplateAnswerModel';

export class PollMatchDefaultTemplateModel extends Serializable {
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(String, null) title: string | null = null;
  @jsonProperty([PollMatchDefaultTemplateAnswerModel], null) answers: PollMatchDefaultTemplateAnswerModel[] | null =
    null;
}
