import { Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollSettingsFormFields } from 'modules/polls/forms/PollSettingsForm';

interface IPollDescriptionFormComponentProps {}

export const PollDescriptionFormComponent: React.FC<IPollDescriptionFormComponentProps> = () => {
  const { classes, cx } = useStyles();
  const { control } = useFormContext<PollCreateAndEditForm>();
  const { field: descriptionVisibleField } = useController({
    name: `${PollCreateAndEditFormFields.settings}.${PollSettingsFormFields.descriptionVisible}`,
    control,
  });

  // Handlers
  const handleHideDescription = (e: React.MouseEvent<HTMLButtonElement>) => {
    descriptionVisibleField.onChange(!descriptionVisibleField.value);
  };

  // Redners
  return (
    <div>
      <div className={classes.label}>
        <Typography variant="h1Medium" component="span" sx={{ mr: 1 }}>
          Описание
        </Typography>
        <Tooltip title={descriptionVisibleField.value ? 'Скрыть описание' : 'Раскрыть описание'} placement="top-start">
          <IconButton
            className={cx(classes.iconBtn, { active: descriptionVisibleField.value })}
            color="black"
            size="small"
            onClick={handleHideDescription}
          >
            <i className="custom-icon-add" />
          </IconButton>
        </Tooltip>
      </div>
      <Collapse in={descriptionVisibleField.value}>
        <div className={classes.textAreaWrap}>
          <QOutlinedTextFieldWithController
            multiline
            rows={7}
            placeholder="Введите описание для опроса"
            name={PollCreateAndEditFormFields.description}
            hookFormProps={{ control: control }}
          />
        </div>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  iconBtn: {
    '& i': {
      transition: theme.transitions.create('transform'),
    },
    '&.active': {
      '& i': {
        transform: 'rotate(45deg)',
      },
    },
  },
  textAreaWrap: {
    marginTop: theme.spacing(1),
  },
}));
