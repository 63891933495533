import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';

import { ReactComponent as PollEmptyIllustration } from 'assets/img/icons/poll-empty-illustration.svg';

interface IPollsEmptyContentProps {}

export const PollsEmptyContent: React.FC<IPollsEmptyContentProps> = observer(() => {
  const { pollsStore } = useRootStore();
  const { classes } = useStyles();

  return (
    <div className={classes.wrap}>
      {pollsStore.organizationPollsListLoading && <Loader isAbsolute />}
      <div className={classes.iconWrap}>
        <PollEmptyIllustration />
      </div>
      <Typography variant="h1Bold" sx={{ mb: 1.5 }}>
        Здесь пока нет опросов
      </Typography>
      <Typography variant="h2Medium">Создайте новый опрос или перенесите его из другой папки</Typography>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3.75),
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 365,
    marginBottom: theme.spacing(2.5),
  },
}));
