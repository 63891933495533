import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TextButton } from 'components/UI/Buttons/TextButton';
import { routes } from 'routes/routes';
import { useCommonStyles } from 'styles/commonStyles';

interface IFolderSidebarProps {}

export const FolderSidebar: React.FC<IFolderSidebarProps> = () => {
  const { classes, cx } = useStyles();
  const { commonClasses } = useCommonStyles();

  return (
    <div className={classes.wrap}>
      <div></div>
      <div>
        <NavLink
          className={({ isActive }) =>
            cx('router-link', commonClasses.aic, { [commonClasses.navLinkActive]: isActive })
          }
          to={routes.SupportScreen.path}
        >
          <TextButton
            color="secondary"
            className={commonClasses.textButtonIcon16}
            startIcon={<i className="custom-icon-messagequestion" />}
          >
            <Typography variant="h2Medium" component="span">
              FAQ
            </Typography>
          </TextButton>
        </NavLink>
      </div>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  wrap: {
    paddingTop: 96,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
}));
