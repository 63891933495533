import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { UseFieldArrayReturn } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';

interface IBatchImageUploadButtonProps {
  disabled?: boolean;
  blocksListFieldArray: UseFieldArrayReturn<PollCreateAndEditForm, 'blocks', 'id'>;
  onHandleUploadAction: () => void;
  onHandleErrorUploadFiles: () => void;
  onHandleSuccessUploadFiles: (filesList: string[]) => void;
}

export const BatchImageUploadButton: React.FC<IBatchImageUploadButtonProps> = observer(props => {
  const { disabled, onHandleErrorUploadFiles, onHandleUploadAction, onHandleSuccessUploadFiles } = props;
  const { classes } = useStyles();
  const { fileStore } = useRootStore();

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return;
    }

    fileStore.uploadFiles(acceptedFiles, {
      onHandleSuccess: onHandleSuccessUploadFiles,
      onHandleError: onHandleErrorUploadFiles,
    });

    onHandleUploadAction();
  }, []);

  const fileDropzone = useDropzone({ onDrop: handleDrop, noClick: false, noKeyboard: true, multiple: true });

  const handleClickInputRootProps = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    fileDropzone.open();
  };

  // Renders
  return (
    <div {...fileDropzone.getRootProps()} onClick={handleClickInputRootProps}>
      <input
        disabled={disabled}
        type="file"
        name="file"
        className={classes.fileInput}
        {...fileDropzone.getInputProps()}
      />
      <Button fullWidth type="button" variant="contained" color="secondary">
        Пакетная загрузка изображений
      </Button>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  fileInput: {
    display: 'none',
  },
}));
