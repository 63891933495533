import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QLinearProgress } from 'components/UI/QLinearProgress';
import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { customColors } from 'styles/colors';

interface IBatchImageProgressModalProps extends Omit<IQModalProps, 'onClose'> {
  onHandleCancelUpload: () => void;
}

export const BatchImageProgressModal: React.FC<IBatchImageProgressModalProps> = observer(props => {
  const { onHandleCancelUpload, ...rest } = props;
  const { fileStore } = useRootStore();

  // Renders
  return (
    <QModal {...rest} modalMaxWidthValue={610}>
      {fileStore.loading && fileStore.currentProgress === 100 && <Loader isAbsolute />}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 1 }}>
          Загрузка изображений
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Ожидайте завершения загрузки.
        </Typography>

        <QLinearProgress
          wrapProps={{ sx: { mb: 3, maxWidth: 384, mx: 'auto' } }}
          withLabel
          value={fileStore.currentProgress}
          labelProps={{ color: customColors.black }}
        />

        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="primary" onClick={onHandleCancelUpload}>
            Отменить
          </Button>
        </Box>
      </Box>
    </QModal>
  );
});
