import { IconButton, alpha } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { UseFieldArrayReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollTypes } from 'modules/polls/types/PollsTypes';

import { ConstructorFields } from '../ConstructorFields/ConstructorFields';
import { ConstructorBlockSettingsPanel } from '../widgets/ConstructorBlockSettingsPanel';
import { ConstructorFieldSettingsPanel } from '../widgets/ConstructorFieldSettingsPanel/ConstructorFieldSettingsPanel';
import { PollViewTypeChangePanel } from '../widgets/PollViewTypeChangePanel';

interface IConstructorBlockProps {
  id: string;
  index: number;
  blocksListFieldArray: UseFieldArrayReturn<PollCreateAndEditForm, 'blocks', 'id'>;
}

export const ConstructorBlock: React.FC<IConstructorBlockProps> = observer(props => {
  const params = useParams<{ type?: PollTypes }>();

  const { index, blocksListFieldArray } = props;
  const { classes } = useStyles();
  const { pollsStore } = useRootStore();

  // Handlers
  const handleAddNewBlock = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (params.type && PollsHelper.isValidPollTemplateType(params.type)) {
      const pollConstructorBlockForm = pollsStore.getCurrentPollTemplateDefaultBlockForm(params.type);
      blocksListFieldArray.insert(index + 1, pollConstructorBlockForm);
    }
  };

  // Renders
  return (
    <div className={classes.block}>
      <div className={classes.topPanel}>
        <PollViewTypeChangePanel index={index} />
        <div className={classes.panelsWrap}>
          <ConstructorBlockSettingsPanel index={index} blocksListFieldArray={blocksListFieldArray} />
          <ConstructorFieldSettingsPanel index={index} />
        </div>
      </div>

      <ConstructorFields index={index} />

      <IconButton color="primary" className={classes.createNewBlockBtn} onClick={handleAddNewBlock}>
        <i className="custom-icon-add" />
      </IconButton>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  block: {
    position: 'relative',
    padding: theme.spacing(2, 4),
    border: `1.5px dashed ${alpha(theme.palette.customColors.darkGrey, 0.4)}`,
    background: alpha(theme.palette.customColors.grayDivider, 0.4),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  topPanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  createNewBlockBtn: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 50%)',
    zIndex: 1,
  },
  panelsWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
