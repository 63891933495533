export enum ReportsTableColumns {
  id = 'id',
  background = 'background',
  name = 'name',
  users = 'users',
  createdAt = 'created_at',
  usersIgnorePoll = 'users_ignore_poll',
}

export enum ReportAnswersTableColumns {
  id = 'id',
  image = 'image',
  text = 'text',
  score = 'score',
  userAnswers = 'user_answers',
}

export enum ReportDetailFilters {
  top3 = 'top3',
  top5 = 'top5',
  top10 = 'top10',
  top20 = 'top20',
  top30 = 'top30',
}
