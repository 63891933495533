import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { QSwitchWithController } from 'components/UI/Switches/QSwitchWithController';
import { pollContextsViewMatrix } from 'modules/polls/consts/consts';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollSettingsFormFields } from 'modules/polls/forms/PollSettingsForm';
import { PollTypes } from 'modules/polls/types/PollsTypes';

import { BlockPaper } from './BlockPaper';
import { PollTypeSettingComponent } from './PollTypeSettingComponent';

interface IPollSettingsInterfaceBlockProps {}

export const PollSettingsInterfaceBlock: React.FC<IPollSettingsInterfaceBlockProps> = () => {
  const { control } = useFormContext<PollCreateAndEditForm>();
  const params = useParams<{ type?: PollTypes }>();
  const currentContextShowOneBlockSetting = pollContextsViewMatrix?.[params.type!]?.showOneBlock;

  return (
    <BlockPaper>
      <Box sx={{ mb: 1.5 }}>
        <PollTypeSettingComponent />
      </Box>
      <Box sx={{ mb: 1.5 }}>
        <QSwitchWithController
          label="Описание перед опросом"
          labelProps={{ variant: 'h3Regular' }}
          name={`${PollCreateAndEditFormFields.settings}.${PollSettingsFormFields.descriptionVisible}`}
          hookFormProps={{ control }}
        />
      </Box>
      <Box sx={{ mb: 1.5 }}>
        <QSwitchWithController
          label="Прогрессбар"
          labelProps={{ variant: 'h3Regular' }}
          name={`${PollCreateAndEditFormFields.settings}.${PollSettingsFormFields.progressBar}`}
          hookFormProps={{ control }}
        />
      </Box>

      <QSwitchWithController
        label="Вопросы по одному"
        labelProps={{ variant: 'h3Regular' }}
        name={`${PollCreateAndEditFormFields.settings}.${PollSettingsFormFields.showOneBlock}`}
        hookFormProps={{ control }}
        disabled={!currentContextShowOneBlockSetting}
      />
      <Typography variant="h5Regular" sx={{ mt: 0.5, maxWidth: '303px' }}>
        Пока пользователь не ответит на текущий вопрос, следующий отображаться не будет.
      </Typography>
    </BlockPaper>
  );
};
