import { IMemberTableField } from 'modules/organizations/interfaces/OrganizationsInterfaces';
import { MembersTableColumns } from 'modules/organizations/types/OrganizationTypes';

export const membersTableFields: IMemberTableField[] = [
  { column: MembersTableColumns.full_name, title: 'Имя пользователя', isOrderable: true },
  { column: MembersTableColumns.email, title: 'Почта', isOrderable: true },
  { column: MembersTableColumns.created_at, title: 'Дата и время регистрации', isOrderable: true },
  // { column: MembersTableColumns.status, title: 'Статус', isOrderable: true },
  { column: MembersTableColumns.role, title: 'Роль', isOrderable: true },
];
