import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { SortOrdersEnum } from 'base/modules/sort/types/SortTypes';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { PollsTableColumns } from 'modules/polls/types/PollsTypes';

interface IPollsSortPanelProps {}

export const PollsSortPanel: React.FC<IPollsSortPanelProps> = observer(() => {
  const { pollsStore } = useRootStore();
  const { classes, cx } = useStyles();

  // Effects
  useEffect(() => {
    pollsStore.setCurrentSortValue(PollsTableColumns.created_at, SortOrdersEnum.desc);
  }, []);

  // Handlers
  const handleSetDescOrderBy = () => {
    if (pollsStore.pollsListForm.sortValue?.order !== SortOrdersEnum.desc) {
      pollsStore.pollsListFormSetSortValueAndGetList(PollsTableColumns.created_at, false);
    }
  };

  const handleSetAscOrderBy = () => {
    if (pollsStore.pollsListForm.sortValue?.order !== SortOrdersEnum.asc) {
      pollsStore.pollsListFormSetSortValueAndGetList(PollsTableColumns.created_at, false);
    }
  };

  // Renders
  return (
    <div className={classes.sortTextWrap}>
      <Typography sx={{ mr: 1.5 }} variant="h3Medium">
        Сортировать по:
      </Typography>
      <TextButton
        sx={{ mr: 1.5 }}
        color="secondary"
        className={cx({ active: pollsStore.pollsListForm.sortValue?.order === SortOrdersEnum.desc })}
        onClick={handleSetDescOrderBy}
      >
        новые опросы
      </TextButton>
      <TextButton
        color="secondary"
        className={cx({ active: pollsStore.pollsListForm.sortValue?.order === SortOrdersEnum.asc })}
        onClick={handleSetAscOrderBy}
      >
        старые опросы
      </TextButton>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  sortTextWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}));
