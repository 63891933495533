import { Avatar, TableCell } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { MembersTableColumns } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';

import { MemberKickButton } from './MemberKickButton';
import { RoleChangeSelect } from './RoleChangeSelect';

interface IMembersTableBodyElementsProps {
  data: UserModel;
  elementId: MembersTableColumns;
}

export const MembersTableBodyElements: React.FC<IMembersTableBodyElementsProps> = props => {
  const { data, elementId } = props;
  const { classes } = useStyles();

  const elements = useMemo<Record<MembersTableColumns, ReactNode>>(() => {
    return {
      full_name: (
        <TableCell>
          <div className={classes.content}>
            <Avatar className={classes.avatar} src={data.avatar ?? undefined} />
            <div className={classes.nameWrap}>{data.name}</div>
          </div>
        </TableCell>
      ),
      email: <TableCell>{data.email}</TableCell>,
      created_at: <TableCell>{data.created_at?.formatted}</TableCell>,
      status: <TableCell>-</TableCell>,
      role: (
        <TableCell>
          <div className={classes.roleSelectWrap}>
            <RoleChangeSelect member={data} />

            <MemberKickButton currentMember={data} />
          </div>
        </TableCell>
      ),
    };
  }, [data]);

  return <>{elements?.[elementId] ? elements?.[elementId] : null}</>;
};

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 200,
  },
  nameWrap: {
    marginLeft: theme.spacing(1.5),
    maxWidth: 150,
  },
  avatar: {
    width: 26,
    height: 26,
    marginRight: theme.spacing(1),
  },
  roleSelectWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
