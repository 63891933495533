import { Serializable, jsonProperty } from 'ts-serializable';

import { DateModel } from 'modules/base/models/DateModel';
import { OrganizationModel } from 'modules/organizations/models/OrganizationModel';
import { UserModel } from 'modules/users/models/UserModel';

import { PollTypes } from '../types/PollsTypes';
import { PollQuestionModel } from './PollQuestionModel';

export class PollModel extends Serializable {
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(String, null) name: string | null = null;
  @jsonProperty(String, null) description: string | null = null;
  @jsonProperty(Number, null) organization_id: number | null = null;
  @jsonProperty(UserModel, null) creator: UserModel | null = null;
  @jsonProperty(OrganizationModel, null) organization: OrganizationModel | null = null;
  @jsonProperty(String, null) template: PollTypes | null = null;
  @jsonProperty(String, null) background: string | null = null;
  @jsonProperty(Boolean) shuffle: boolean = false;
  @jsonProperty(Boolean, null) is_votable: boolean = false;
  @jsonProperty(Boolean) with_description: boolean = false;
  @jsonProperty(Boolean) with_progress_bar: boolean = false;
  @jsonProperty(Boolean) one_by_one: boolean = false;
  @jsonProperty(Boolean) with_notifications: boolean = false;
  @jsonProperty(Boolean) is_active: boolean = false;
  @jsonProperty(Boolean) is_completed: boolean = false;
  @jsonProperty(Boolean) is_published: boolean = false;
  @jsonProperty(DateModel, null) created_at: DateModel | null = null;
  @jsonProperty(DateModel, null) updated_at: DateModel | null = null;
  @jsonProperty([PollQuestionModel], null) questions: PollQuestionModel[] | null = null;
}
