import { Dto } from 'base/Dto';
import { DateHelper } from 'helpers/DateHelper';
import { FileService } from 'modules/file/FileService';
import { ILocalFileWithFileUrl } from 'modules/file/interfaces/FileInterfaces';

import { USER_DATE_FORMAT } from './consts/consts';
import { ProfileChangePasswordFormDto } from './dto/ProfileChangePasswordFormDto';
import { ProfileFormDto } from './dto/ProfileFormDto';
import { ProfileChangePasswordForm } from './forms/ProfileChangePasswordForm';
import { ProfileForm } from './forms/ProfileForm';
import { UserModel } from './models/UserModel';

export class UsersDataSource {
  private fileService: FileService;

  constructor() {
    this.fileService = new FileService();
  }

  public prepareProfileChangePasswordFormDto = (form: ProfileChangePasswordForm): ProfileChangePasswordFormDto => {
    return Dto.populate(ProfileChangePasswordFormDto, {
      password: form.currentPassword,
      new_password: form.newPassword,
      new_password_confirmation: form.newPasswordConfirm,
    });
  };

  public prepareProfileFormDto = async (form: ProfileForm): Promise<ProfileFormDto> => {
    let fileToSaveUrl: string | null = form.avatar?.fileUrl ?? null;

    if (form?.avatar?.localFile) {
      const newFile = await this.fileService.tryUploadFile(form.avatar.localFile);
      fileToSaveUrl = newFile ? newFile : fileToSaveUrl;
    }

    const preDto = {
      full_name: form.name,
      birthday: DateHelper.formatDate(form.birthday, USER_DATE_FORMAT),
      avatar: fileToSaveUrl,
    };

    return Dto.populate(ProfileFormDto, { ...form, ...preDto });
  };

  public getProfileBaseForm = (userInfo: UserModel | null): ProfileForm | null => {
    if (userInfo) {
      const birthday = userInfo?.birthday?.date ?? null;
      const avatar: ILocalFileWithFileUrl = { fileUrl: userInfo.avatar, localFile: null };

      return ProfileForm.create({ ...userInfo, birthday, avatar });
    }

    return null;
  };
}
