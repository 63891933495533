import { Theme, outlinedInputClasses } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IQOutlinedTextFieldWithControllerProps } from 'interfaces/CommonComponentsInterfaces';

import { QOutlinedTextFieldWithController } from './QOutlinedTextFieldWithController';

interface IPasswordTextFieldProps extends IQOutlinedTextFieldWithControllerProps {}

export const PasswordTextField: React.FC<IPasswordTextFieldProps> = props => {
  const { classes } = useStyles();
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  // Handlers
  const handleToggleShowPassword = () => {
    setPasswordVisibility(prevValues => !prevValues);
  };

  return (
    <QOutlinedTextFieldWithController
      {...props}
      classes={{ root: classes.textFieldRoot }}
      type={passwordVisibility ? 'text' : 'password'}
      endIconProps={{
        classes: { root: classes.btn },
        iconClassName: passwordVisibility ? 'custom-icon-eye' : 'custom-icon-eyeslash',
        onClick: handleToggleShowPassword,
      }}
    />
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  textFieldRoot: {
    position: 'relative',
    [`& .${outlinedInputClasses.root}`]: {
      [`& .${outlinedInputClasses.input}`]: {
        paddingRight: theme.spacing(5),
      },
      '& $btn': {
        position: 'absolute',
        top: '50%',
        right: 0,
        fontSize: '17px',
        padding: '11.5px',
        transform: 'translateY(-50%)',
      },
    },
  },
  btn: {},
}));
