import { BaseForm } from 'base/BaseForm';

export enum PollDesignSettingsFormFields {
  background = 'background',
}

export class PollDesignSettingsForm extends BaseForm {
  background: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
