import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BoxProps, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { QCheckboxWithController } from 'components/UI/CheckBoxes/QCheckboxWithController';
import { PasswordTextField } from 'components/UI/TextFields/PasswordTextField';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { LoginForm, LoginFormFields } from 'modules/auth/forms/auth/LoginForm';
import { RegisterForm } from 'modules/auth/forms/auth/RegisterForm';
import { AccountModel } from 'modules/auth/models/AccountModel';
import { loginFormScheme } from 'modules/auth/schemes/loginFormScheme';
import { authRoutes, routes } from 'routes/routes';
import { useCommonStyles } from 'styles/commonStyles';

interface ILoginFormComponentProps extends BoxProps {}

export const LoginFormComponent: React.FC<ILoginFormComponentProps> = observer(props => {
  const { authStore, usersStore } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();

  const { classes, cx } = useStyles();
  const { commonClasses } = useCommonStyles();

  const loginForm = useForm<LoginForm>({
    mode: 'all',
    resolver: yupResolver(loginFormScheme),
    defaultValues: LoginForm.create(),
  });

  useEffect(() => {
    const state: { form: RegisterForm; accountModel: AccountModel | null } | undefined = location.state;

    if (state) {
      loginForm.reset(
        LoginForm.create({ email: state?.accountModel?.email ?? '', password: state.form.password ?? '' }),
      );
    }
  }, [location.state]);

  // Handlers
  const handleSubmit = (form: LoginForm) => {
    authStore.login(form, () => {
      navigate(routes.MainScreen.path);
      if (!usersStore.userInfo) {
        usersStore.getCurrentUserInfo();
      }
    });
  };

  return (
    <Box position="relative" mx="auto" maxWidth="315px">
      <Box component="form" {...props} onSubmit={loginForm.handleSubmit(handleSubmit)}>
        <Box sx={{ mb: 1.5 }}>
          <QOutlinedTextFieldWithController
            label="Email"
            placeholder="Введите ваш email"
            name={LoginFormFields.email}
            hookFormProps={{ control: loginForm.control }}
          />
        </Box>
        <Box sx={{ mb: 1.5 }}>
          <PasswordTextField
            name={LoginFormFields.password}
            label="Пароль"
            placeholder="Введите ваш пароль"
            autoComplete="current-password"
            errors={authStore.errorMessages?.password}
            hookFormProps={{ control: loginForm.control }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2.5 }}>
          <QCheckboxWithController
            label="Запомнить меня"
            name={LoginFormFields.needRemember}
            hookFormProps={{ control: loginForm.control }}
          />

          <Link className="router-link" to={authRoutes.ForgotPasswordScreen.path}>
            <TextButton className={cx(commonClasses.textUnderLine, classes.textBtn)} color="secondary">
              Забыл пароль?
            </TextButton>
          </Link>
        </Box>
        <Button fullWidth type="submit" variant="contained" color="primary">
          Войти
        </Button>
      </Box>
    </Box>
  );
});

const useStyles = makeStyles()(theme => ({
  textBtn: {
    color: theme.palette.customColors.purple,
    fontWeight: 700,
    '&:hover:not(.active)': {
      color: theme.palette.customColors.purple,
    },
  },
}));
