import axios, { AxiosProgressEvent } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';

import { Notification } from 'base/ui/Notification';
import { NumberHelper } from 'helpers/NumberHelper';
import { fileNotificationMessages } from 'messages/file';

import { FileService } from './FileService';

export class FileStore {
  loading: boolean = false;
  fileSettingsLoading: boolean = false;
  deleteFileLoading: boolean = false;

  fileData: string | null = null;
  fileList: string[] | null = null;
  currentProgress: number = 0;

  private fileService: FileService;

  constructor() {
    makeAutoObservable(this);
    this.fileService = new FileService();
  }

  uploadFile = async (
    file: File,
    actions?: {
      onHandleSuccess?: (fileUrl: string) => void;
      onHandleError?: () => void;
      onHandleFinally?: () => void;
    },
  ) => {
    this.setLoading(true);

    return this.fileService
      .uploadFile(file)
      .then(fileUrl => {
        runInAction(() => {
          this.fileData = fileUrl;
          if (fileUrl) {
            actions?.onHandleSuccess?.(fileUrl);
          }
        });

        return fileUrl;
      })
      .catch(e => {
        actions?.onHandleError?.();
        Notification.showError(fileNotificationMessages.uploadFile.error);
      })
      .finally(() => {
        this.setLoading(false);
        actions?.onHandleFinally?.();
      });
  };

  uploadFiles = async (
    files: File[],
    actions?: {
      onHandleSuccess?: (fileUrlList: string[]) => void;
      onHandleError?: () => void;
      onHandleFinally?: () => void;
      onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    },
  ) => {
    this.setLoading(true);

    this.fileService
      .uploadFiles(files, this.setUploadProgress)
      .then(fileList => {
        if (!fileList?.length) {
          actions?.onHandleError?.();
          this.setCurrentProgress(0);

          return;
        }

        if (fileList?.length) {
          this.setFileList(fileList);
          actions?.onHandleSuccess?.(fileList);
          this.setCurrentProgress(0);
        }
      })
      .catch(e => {
        actions?.onHandleError?.();
        this.setCurrentProgress(0);

        if (axios.isCancel(e)) {
          Notification.showError(fileNotificationMessages.cancelRequest.error);
          return;
        }

        Notification.showError(fileNotificationMessages.uploadFile.error);
      })
      .finally(() => {
        this.setLoading(false);
        actions?.onHandleFinally?.();
      });
  };

  // Setters
  setFileList = (fileList: string[] | null) => {
    this.fileList = fileList;
  };

  setUploadProgress = (progressEvent: AxiosProgressEvent) => {
    this.currentProgress = NumberHelper.getRoundedProgress(progressEvent.progress ?? 0);
  };

  setCurrentProgress = (value: number) => {
    this.currentProgress = value;
  };

  // Loadings
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setFileSettingsLoading = (value: boolean) => {
    this.fileSettingsLoading = value;
  };

  setDeleteFileLoading = (value: boolean) => {
    this.deleteFileLoading = value;
  };
}
