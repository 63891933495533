import { PASSWORD_REGEX } from 'consts/globalConsts';
import * as yup from 'yup';

import { NewPasswordFormFields } from '../forms/auth/NewPasswordForm';

export const newPasswordScheme = yup.object({
  [NewPasswordFormFields.password]: yup
    .string()
    .required('Пожалуйста, введите пароль')
    .matches(PASSWORD_REGEX, 'Пароль должен содержать 6 символов'),
  [NewPasswordFormFields.passwordConfirm]: yup
    .string()
    .required('Введите пароль')
    .oneOf([yup.ref(NewPasswordFormFields.password)], 'Пароли не совпадают'),
});
