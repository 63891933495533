import { Dialog, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';

export const QModal: React.FC<IQModalProps> = props => {
  const { children, className, modalClassName, modalMaxWidthValue, disableCloseBtn, onHandleCollapse, ...rest } = props;
  const { classes, cx } = useStyles({ modalMaxWidthValue });

  const handleClose = (e: any, r: any) => {
    e.stopPropagation();

    if (props.onClose) {
      props.onClose(e, r);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      scroll="body"
      disableRestoreFocus
      className={className}
      {...rest}
      PaperProps={{ ...rest.PaperProps, className: cx(classes.modal, modalClassName) }}
    >
      {!!onHandleCollapse && (
        <IconButton onClick={onHandleCollapse} className={cx(classes.collapseButton)}>
          <i className="custom-icon-collapse" />
        </IconButton>
      )}
      {!disableCloseBtn && (
        <IconButton onClick={e => handleClose(e, null)} className={cx(classes.closeButton, 'close-button')}>
          <i className="custom-icon-add" />
        </IconButton>
      )}

      {children}
    </Dialog>
  );
};

const useStyles = makeStyles<{ modalMaxWidthValue?: string | number }>()((theme, props) => {
  return {
    modal: {
      maxWidth: props.modalMaxWidthValue || 380,
      minHeight: 170,
      width: '100%',
      background: theme.palette.customColors.whiteBlueGradient,
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0),
      color: theme.palette.customColors.gray,
      zIndex: 1,
      '& i': {
        fontSize: 16,
        transform: 'rotate(45deg)',
        color: theme.palette.customColors.black,
      },
    },
    collapseButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0),
      color: theme.palette.customColors.gray,
      zIndex: 1,
      '& i': {
        fontSize: 16,
      },
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
  };
});
