import { IconButton } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollConstructorBlockFieldsTypes } from 'modules/polls/types/PollsTypes';

import { ConstructorCheckboxField } from './ConstructorCheckboxField';
import { ConstructorImageWithCheckboxField } from './ConstructorImageWithCheckboxField';
import { ConstructorImageWithRadioField } from './ConstructorImageWithRadioField';
import { ConstructorRadioField } from './ConstructorRadioField';

interface IConstructorFieldTypesProps {
  index: number;
  fieldIndex: number;
  fieldType: PollConstructorBlockFieldsTypes;
  onHandleDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onHandleChangeTypeAtGallery: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ConstructorFieldTypes: React.FC<IConstructorFieldTypesProps> = props => {
  const { fieldType, fieldIndex, index, onHandleDelete, onHandleChangeTypeAtGallery } = props;
  const { classes } = useStyles();

  // Renders
  const renderActionButtons = () => {
    return (
      <div className={classes.btnWrap}>
        <IconButton size="small" onClick={onHandleDelete}>
          <i className="custom-icon-trushsquare" />
        </IconButton>
        <IconButton size="small" onClick={onHandleChangeTypeAtGallery}>
          <i className="custom-icon-gallery" />
        </IconButton>
      </div>
    );
  };

  const FieldTypes: Record<PollConstructorBlockFieldsTypes, ReactNode> = useMemo(() => {
    return {
      radio: (
        <ConstructorRadioField
          fieldName={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.fields}.${fieldIndex}`}
          actionButtons={renderActionButtons()}
        />
      ),
      checkbox: (
        <ConstructorCheckboxField
          fieldName={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.fields}.${fieldIndex}`}
          actionButtons={renderActionButtons()}
        />
      ),
      imageWithRadio: (
        <ConstructorImageWithRadioField
          fieldName={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.fields}.${fieldIndex}`}
          actionButtons={renderActionButtons()}
        />
      ),
      imageWithCheckbox: (
        <ConstructorImageWithCheckboxField
          fieldName={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.fields}.${fieldIndex}`}
          actionButtons={renderActionButtons()}
        />
      ),
    };
  }, [fieldType, index, fieldIndex]);

  return <>{FieldTypes?.[fieldType] ? <div className={classes.item}>{FieldTypes[fieldType]}</div> : null}</>;
};

const useStyles = makeStyles()(theme => ({
  item: {
    // display: 'flex',
    // alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
