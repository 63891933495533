import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { AppRoutes } from 'base/routes/components/AppRoutes';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { Loader } from 'components/UI/Loader';
import { CommonHelper } from 'helpers/CommonHelper';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { getPollsChildrendScreens } from 'modules/polls/consts/consts';
import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollsHelper } from 'modules/polls/helpers/PollsHelper';
import { PollModel } from 'modules/polls/models/PollModel';
import { PollRouteActionTypes, PollTypes } from 'modules/polls/types/PollsTypes';
import { pollsRoutes, routes } from 'routes/routes';

interface IPollCreateAndEditScreenProps {}

export const PollCreateAndEditScreen: React.FC<IPollCreateAndEditScreenProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();

  const params = useParams<{ action?: PollRouteActionTypes; '*'?: string }>();
  const navigate = useNavigate();
  const routeSegments = params['*']?.split('/');
  const templateId = routeSegments?.[0] ?? null;
  const type = (routeSegments?.[1] ?? null) as PollTypes | null;
  const pollId = routeSegments?.[3] ?? null;
  const routeSegmentsList = params['*']?.split('/');
  const currentScreen = PollsHelper.getCurrentScreenSlug(routeSegmentsList);
  const pollsChildrendScreens = getPollsChildrendScreens();
  const hasOrganizationId = CommonHelper.isNotNullOrUndefined(organizationsStore.selectedOrganization?.id);
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  const pollCreateAndEditForm = useForm<PollCreateAndEditForm>({
    mode: 'onChange',
    defaultValues: PollCreateAndEditForm.create(),
  });

  useEffect(() => {
    if (!type) {
      return;
    }

    const currentContextForm = pollsStore.getPollCreateAndEditCurrentContextDefaultForm(type);

    pollCreateAndEditForm.reset(currentContextForm, { keepDirty: true });
  }, [type]);

  useEffect(() => {
    return () => {
      pollsStore.resetPollInfoAndForm();
    };
  }, []);

  useEffect(() => {
    if (!organizationsStore.currentUserInOrganizationRole) {
      return;
    }

    if (pollId?.length && !roleMatrix?.pollEdit) {
      navigate(routes.MainScreen.path);
      return;
    }

    if (!roleMatrix?.pollCreate && !pollId?.length) {
      navigate(routes.MainScreen.path);
    }
  }, [organizationsStore.currentUserInOrganizationRole, params]);

  // Handlers
  const handleSubmit = (form: PollCreateAndEditForm) => {
    if (pollsStore.pollName !== form.name) {
      pollsStore.setPollName(form.name);
    }

    if (hasOrganizationId && params.action === PollRouteActionTypes.edit && pollId?.length) {
      pollsStore.editPoll(organizationsStore.selectedOrganization!.id!, pollId, form, handleUpdateForm);
      return;
    }

    if (hasOrganizationId && params.action === PollRouteActionTypes.create) {
      pollsStore.createPoll(organizationsStore.selectedOrganization!.id!, form, handleSuccessPollCreate);
      return;
    }
  };

  const handleSuccessPollCreate = (pollModel: PollModel) => {
    const hasNotRouteData =
      !templateId?.length ||
      !type?.length ||
      CommonHelper.isNullOrUndefined(pollModel.id) ||
      !currentScreen?.length ||
      !pollsChildrendScreens?.[currentScreen];

    if (hasNotRouteData) {
      return;
    }

    const path = RouteHelper.makePath(pollsChildrendScreens[currentScreen], {
      templateId,
      type,
      action: PollRouteActionTypes.edit,
      pollId: pollModel.id,
    });

    handleUpdateForm(pollModel);
    navigate(path);
  };

  const handleUpdateForm = (pollModel: PollModel) => {
    const form = pollsStore.getPollCreateAndEditForm(pollModel);
    pollCreateAndEditForm.reset(form);
  };

  // Renders
  return (
    <>
      {pollsStore.loading && <Loader isAbsolute />}
      <form onSubmit={pollCreateAndEditForm.handleSubmit(handleSubmit)}>
        <FormProvider {...pollCreateAndEditForm}>
          <AppRoutes routes={pollsRoutes} nestedRoutesProps={{ parentPath: routes.PollCreateAndEditScreen.path }} />
        </FormProvider>
      </form>
    </>
  );
});
