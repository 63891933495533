import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { pollTemplatesList } from 'lists/polls/pollTemplatesList';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { routes } from 'routes/routes';

import { PollTemplateCard } from './components/pollTemplates/PollTemplateCard';

interface IPollTemplatesScreenProps {}

export const PollTemplatesScreen: React.FC<IPollTemplatesScreenProps> = observer(() => {
  const { classes } = useStyles();
  const { organizationsStore } = useRootStore();
  const navigate = useNavigate();

  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  // Effects
  useEffect(() => {
    if (!organizationsStore.currentUserInOrganizationRole) {
      return;
    }

    if (!roleMatrix?.pollCreate) {
      navigate(routes.MainScreen.path);
    }
  }, [organizationsStore.currentUserInOrganizationRole]);

  // Renders
  return (
    <div className={classes.container}>
      <div className={classes.topLineWrap}>
        <Typography variant="h2Medium" sx={{ textAlign: 'center', mb: 4 }}>
          Выберите шаблон и адаптируйте его под ваши цели, или нажмите «Создать с нуля», чтобы создать соверешенно новую
          форму опроса
        </Typography>
        <div className={classes.btnWrap}>
          <Button disabled variant="contained" color="primary" sx={{ mr: 6 }}>
            Создать с нуля
          </Button>
          <TextButton color="secondary">Шаблоны</TextButton>
        </div>
      </div>
      <div className={classes.content}>
        {pollTemplatesList.map((item, index) => (
          <PollTemplateCard key={index} data={item} />
        ))}
      </div>
    </div>
  );
});

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(3, 3.75, 5),
  },
  topLineWrap: {
    maxWidth: 800,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(0, 'auto', 5),
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    columnGap: theme.spacing(1.5),
    rowGap: theme.spacing(4),
  },
}));
