/* eslint-disable tss-unused-classes/unused-classes */
import { tableCellClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useTableStyles = () => {
  const { classes, ...rest } = useStyles();

  return { ...rest, tableClasses: classes };
};

const useStyles = makeStyles()(theme => ({
  table: {
    tableLayout: 'fixed',
  },
  tableRow: {
    cursor: 'pointer',
    transition: theme.transitions.create('background-color', { duration: 150 }),
  },
  tableRelative: {
    position: 'relative',
  },
  loaderWrap: {
    position: 'absolute',
    top: 70,
    left: 0,
    height: '100%',
    width: '100%',
  },
  loaderRow: {
    // position: 'absolute',
    // width: '100%',
    // height: '100%',
    // background: 'transparent',
    // borderSpacing: 0,
    // border: 0,
    '& > td': {
      padding: 0,
    },
  },
  tableRowFilledVariant: {
    [`& .${tableCellClasses.body}`]: {
      border: `1px solid transparent`,
      transition: theme.transitions.create('border-color'),
      '&:not(&:first-of-type)': {
        borderLeft: 0,
      },
      '&:not(&:last-of-type)': {
        borderRight: 0,
      },
      '&:first-of-type': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
      },
      '&:last-of-type': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    '&:hover': {
      [`& .${tableCellClasses.body}`]: {
        borderColor: theme.palette.customColors.purple,
      },
    },
  },
  talbeRowWithBorder: {
    [`& > td`]: {
      borderBottom: `1px solid ${theme.palette.customColors.grayDivider}`,
      paddingRight: theme.spacing(1),
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  transparentTableCell: {
    background: 'transparent',
  },
}));
