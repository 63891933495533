import { buttonClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

/* eslint-disable tss-unused-classes/unused-classes */
export const useCommonStyles = () => {
  const { classes, ...rest } = useStyles();

  return { ...rest, commonClasses: classes };
};

const useStyles = makeStyles()(theme => ({
  routerLink: {
    textDecoration: 'none',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  aic: {
    display: 'flex',
    alignItems: 'center',
  },
  textButtonIcon13: {
    [`& .${buttonClasses.endIcon}, .${buttonClasses.startIcon}`]: {
      '& i': {
        fontSize: 13,
      },
    },
  },
  textButtonIcon16: {
    [`& .${buttonClasses.endIcon}, .${buttonClasses.startIcon}`]: {
      '& i': {
        fontSize: 16,
      },
    },
  },
  navLinkActive: {
    [`& .${buttonClasses.root}.${buttonClasses.text}`]: {
      color: theme.palette.customColors.black,
    },
  },
  iconBtnIcon24: {
    fontSize: 24,
    padding: 4,
  },
  textUnderLine: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'currentcolor',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -1,
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: 'currentColor',
      width: '100%',
      height: 1,
    },
  },
  bolder: {
    fontWeight: 'bold',
  },
  cursorDisable: {
    cursor: 'default',
  },
}));
