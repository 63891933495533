import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { customColors } from 'styles/colors';

interface IPollChangeStatusConfirmModalProps {}

export const PollChangeStatusConfirmModal: React.FC<IPollChangeStatusConfirmModalProps> = observer(props => {
  const { pollsStore, organizationsStore } = useRootStore();
  const { ...rest } = props;

  const isPollActive: boolean = !!pollsStore.pollLocalInfo?.is_active;
  const contextActionTitle: string = isPollActive ? 'Остановить' : 'Запустить';

  // Handlers
  const handleChangeStatus = () => {
    if (!pollsStore.pollLocalInfo?.id || !organizationsStore.selectedOrganization?.id) {
      return;
    }

    if (isPollActive) {
      pollsStore.stopPollById(pollsStore.pollLocalInfo.id, organizationsStore.selectedOrganization.id, () => {
        handleCloseModal();
        pollsStore.getOrganizationPolls();
      });
    } else {
      pollsStore.startPollById(pollsStore.pollLocalInfo.id, organizationsStore.selectedOrganization.id, () => {
        handleCloseModal();
        pollsStore.getOrganizationPolls();
      });
    }
  };

  const handleCloseModal = () => {
    pollsStore.setPollStatusModalOpen(false);
    pollsStore.setPollLocalInfo(null);
  };

  // Renders
  return (
    <QModal {...rest} open={pollsStore.pollStatusModalOpen} onClose={handleCloseModal} modalMaxWidthValue={610}>
      {pollsStore.changePollStatusLoading && <Loader isAbsolute />}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          {contextActionTitle} опрос
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Вы уверены что хотите {contextActionTitle.toLowerCase()} опрос?
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleChangeStatus}>
            Да, {contextActionTitle.toLowerCase()}
          </Button>
          <Button fullWidth variant="outlined" color="primary" sx={{ mb: 2 }} onClick={handleCloseModal}>
            Нет, не надо
          </Button>
        </Box>
      </Box>
    </QModal>
  );
});
