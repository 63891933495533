import { TableRow, TableRowProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IQTableRowProps extends TableRowProps {
  variant?: 'filled' | 'transparent';
}

type VariantStyle<T> = Record<Required<IQTableRowProps>['variant'], T>;

export const QTableRow: React.FC<IQTableRowProps> = props => {
  const { variant = 'filled', className, ...rest } = props;
  const { classes, cx } = useStyles({ variant, ...rest });

  return <TableRow {...rest} className={cx(classes.row, className)} />;
};

const useStyles = makeStyles<IQTableRowProps>()((theme, props) => {
  const variantColors: VariantStyle<React.CSSProperties['background']> = {
    filled: theme.palette.customColors.lightBlue,
    transparent: 'transparent',
  };

  return {
    row: {
      background: variantColors?.[props.variant ?? 'filled'],
    },
  };
});
