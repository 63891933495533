import { useFormContext, Controller, useController } from 'react-hook-form';

import { PollBlockSettingsFormFields } from 'modules/polls/forms/PollBlockSettingsForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';

import { FieldTitleTextField } from './FieldTitleTextField';

interface IConstructorFieldTitleProps {
  index: number;
}

export const ConstructorFieldTitle: React.FC<IConstructorFieldTitleProps> = props => {
  const { index } = props;
  const { control } = useFormContext<PollCreateAndEditForm>();
  const { field: titleVisible } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.titleVisible}`,
    control,
  });

  return (
    <>
      {titleVisible.value && (
        <Controller
          control={control}
          render={({ field }) => <FieldTitleTextField {...field} fullWidth placeholder="Введите заголовок..." />}
          name={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.title}`}
        />
      )}
    </>
  );
};
