import { Button, Typography, menuClasses } from '@mui/material';
import { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TextButton } from 'components/UI/Buttons/TextButton';
import { QMenu } from 'components/UI/QMenu/QMenu';
import { IQMenuProps } from 'interfaces/CommonComponentsInterfaces';
import { pollContextsViewMatrix } from 'modules/polls/consts/consts';
import { PollBlockSettingsFormFields } from 'modules/polls/forms/PollBlockSettingsForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollBlockTypes, PollTypes } from 'modules/polls/types/PollsTypes';

interface IPollViewTypeChangePanelProps {
  index: number;
  menuProps?: Partial<IQMenuProps>;
}

export const PollViewTypeChangePanel: React.FC<IPollViewTypeChangePanelProps> = props => {
  const { index, menuProps } = props;
  const { classes, cx } = useStyles();
  const params = useParams<{ type?: PollTypes }>();

  const { control } = useFormContext<PollCreateAndEditForm>();
  const { field: blockTypeController } = useController({
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.settings}.${PollBlockSettingsFormFields.blockType}`,
    control,
  });
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [toggleMenu, setToggleMenu] = useState(false);
  const pollViewTypeChangePanelSettings = pollContextsViewMatrix?.[params.type!]?.pollViewTypeChangePanel;

  // Handlers
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setToggleMenu(true);
  };

  const handleCloseMenu = () => {
    setToggleMenu(false);
  };

  const handleChoosenItem = (blockType: PollBlockTypes) => (e: React.MouseEvent) => {
    handleCloseMenu();
    blockTypeController.onChange(blockType);
  };

  // Renders
  return (
    <>
      <Button
        variant="contained"
        color="info"
        endIcon={<i className="custom-icon-edit2" />}
        onClick={handleOpenMenu}
        ref={anchorEl}
      >
        Изменить опрос
      </Button>
      <QMenu
        open={!!toggleMenu}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseMenu}
        variant="menu"
        className={classes.menu}
        {...menuProps}
      >
        <li className={classes.menuItem}>
          <TextButton
            fullWidth
            disabled={!pollViewTypeChangePanelSettings.text}
            className={cx(classes.menuItemBtn, { active: blockTypeController.value === PollBlockTypes.text })}
            onClick={handleChoosenItem(PollBlockTypes.text)}
          >
            <i className="custom-icon-text" />
            <Typography variant="h4Regular" component="span">
              Текстовый опрос
            </Typography>
          </TextButton>
        </li>
        <li className={classes.menuItem}>
          <TextButton
            fullWidth
            disabled={!pollViewTypeChangePanelSettings.media}
            className={cx(classes.menuItemBtn, { active: blockTypeController.value === PollBlockTypes.media })}
            onClick={handleChoosenItem(PollBlockTypes.media)}
          >
            <i className="custom-icon-gallery" />
            <Typography variant="h4Regular" component="span">
              Медиа опрос
            </Typography>
          </TextButton>
        </li>
      </QMenu>
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  menu: {
    [`& .${menuClasses.paper}`]: {
      minWidth: 290,
    },
    [`& .${menuClasses.list}`]: {
      padding: theme.spacing(3, 2),
    },
  },
  menuItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  menuItemBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.625, 2, 0.625, 1),
    borderRadius: 4,
    transition: theme.transitions.create('background'),
    cursor: 'pointer',
    color: theme.palette.customColors.black,
    '&.active': {
      color: theme.palette.customColors.white,
      background: theme.palette.customColors.purple,
      '& i': {
        color: theme.palette.customColors.white,
      },
    },
    '& i': {
      fontSize: 15,
      marginRight: theme.spacing(1),
      color: theme.palette.customColors.purple,
      transiton: theme.transitions.create('color'),
    },
  },
}));
