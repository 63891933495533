import { BaseForm } from 'base/BaseForm';

export enum ProfileEmailFormFields {
  email = 'email',
}

export class ProfileEmailForm extends BaseForm {
  email: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
