import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';
import { SortValue } from 'base/modules/sort/models/SortValue';

export enum TableFormFields {
  total = 'total',
  limit = 'limit',
  offset = 'offset',
  page = 'page',
  sortValue = 'sortValue',
}

export class TableForm extends BaseForm {
  total: number = 0;
  limit: number = 12;
  offset: number = 0;
  page: number = 1;
  sortValue: SortValue | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  createTableFormObservable() {
    this.createObservable();
    // TODO: Потом нужно поправить типизацию
    return makeObservable(this, this.makeObservableValues(TableFormFields) as any);
  }
}
