import { makeAutoObservable } from 'mobx';

import { ErrorsService } from 'base/modules/errors/ErrorsService';
import { Notification } from 'base/ui/Notification';
import { authNotificationMessages } from 'messages/auth';

import { AuthService } from './AuthService';
import { ForgotPasswordForm } from './forms/auth/ForgotPasswordForm';
import { LoginForm } from './forms/auth/LoginForm';
import { NewPasswordForm } from './forms/auth/NewPasswordForm';
import { RegisterForm } from './forms/auth/RegisterForm';
import { AccountModel } from './models/AccountModel';
import { TokenService } from './modules/token/TokenService';

export class AuthStore {
  loading = false;
  errorMessages: any | null = null;

  isAuth = false;
  completeCheckAuth = false;

  accountModel: AccountModel | null = null;

  private authServices: AuthService;
  private tokenService: TokenService;
  private errorsService: ErrorsService;

  constructor() {
    makeAutoObservable(this);
    this.authServices = new AuthService();
    this.tokenService = new TokenService();
    this.errorsService = new ErrorsService();
  }

  register = (form: RegisterForm, onHandleSuccess?: () => void): void => {
    this.setLoading(true);

    this.authServices
      .register(form)
      .then(() => {
        onHandleSuccess?.();
      })
      .catch(error => {
        this.setErrors(this.errorsService.getErrors(error));
      })
      .finally(() => this.setLoading(false));
  };

  login = (form: LoginForm, callback?: () => void): void => {
    this.setLoading(true);

    this.authServices
      .login(form)
      .then(({ token }) => {
        if (token) {
          this.tokenService.saveToken(token);
          this.setIsAuth(true);
          callback?.();
        }
      })
      .catch(error => {
        this.setErrors(this.errorsService.getErrors(error));
      })
      .finally(() => this.setLoading(false));
  };

  logout = (onHandleSuccess?: () => void): void => {
    this.setLoading(true);

    this.authServices
      .logout()
      .then(() => {
        this.removeTokenUnlessAuthorized();
        onHandleSuccess?.();
      })
      .catch((error: any) => {})
      .finally(() => this.setLoading(false));
  };

  verifyAccount = (id: string, hash: string, onHandleSuccess?: () => void, onHandleError?: () => void): void => {
    this.setLoading(true);

    this.authServices
      .verifyAccount(id, hash)
      .then(({ token }) => {
        if (token) {
          this.tokenService.saveToken(token);
          this.setIsAuth(true);
        }

        onHandleSuccess?.();
        Notification.showSuccess(authNotificationMessages.accountVerify.success);
      })
      .catch((error: any) => {
        onHandleError?.();
        Notification.showError(authNotificationMessages.accountVerify.error);
      })
      .finally(() => this.setLoading(false));
  };

  forgotPassword = (form: ForgotPasswordForm, onHandleSuccess?: () => void) => {
    this.setLoading(true);

    this.authServices
      .forgotPassword(form)
      .then(() => {
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setNewPassword = (id: string, hash: string, form: NewPasswordForm, onHandleSuccess?: () => void) => {
    this.setLoading(true);

    this.authServices
      .setNewPassword(id, hash, form)
      .then(() => {
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  checkUserAccessToken = (accessToken?: string, onHandleSuccess?: () => void) => {
    const token = accessToken ? accessToken : this.tokenService.getToken();

    if (!token) {
      return;
    }

    this.tokenService.saveToken(token);

    this.authServices
      .checkUserAccessToken(token)
      .then(status => {
        if (!status) {
          this.removeTokenUnlessAuthorized();
        }

        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {});
  };

  checkAuth = (onSuccessAuth?: (accessToken: string) => void, onErrorAuth?: () => void): void => {
    const accessToken = this.tokenService.getToken();

    if (accessToken) {
      this.tokenService.saveToken(accessToken);
      this.isAuth = true;
      this.completeCheckAuth = true;

      onSuccessAuth?.(accessToken);
      return;
    }

    this.setIsAuth(false);
    this.tokenService.deleteToken();
    this.setCompleteCheckAuth(true);
    onErrorAuth?.();
  };

  removeTokenUnlessAuthorized = () => {
    this.tokenService.deleteToken();
    window.location.href = '/';
  };

  // Loadings
  setLoading = (value: boolean): void => {
    this.loading = value;
  };

  // Setters
  setCompleteCheckAuth = (value: boolean): void => {
    this.completeCheckAuth = value;
  };

  setIsAuth = (state: boolean): void => {
    this.isAuth = state;
  };

  setErrors = (errors: any | null): void => {
    this.errorMessages = errors;
  };

  removeError = (fieldName: string): void => {
    delete this.errorMessages[fieldName];
  };

  resetStore = (): void => {
    this.loading = false;
    this.errorMessages = {};

    this.isAuth = false;
    this.completeCheckAuth = true;
  };
}
