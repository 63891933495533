import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { CommonHelper } from 'helpers/CommonHelper';
import { useObjectUrls } from 'hooks/useObjectUrls';
import { FileHelper } from 'modules/file/helpers/FileHelper';
import { ILocalFileWithFileUrl } from 'modules/file/interfaces/FileInterfaces';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollConstructorBlockImageFieldFormFields } from 'modules/polls/forms/PollConstructorBlockImageFieldForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { customColors } from 'styles/colors';

import { AttachMediaModal } from '../AttachMediaModal';
import { ImageTitleTextField } from './ImageTitleTextField';

interface IAttachFileWithTitleProps {
  index: number;
  fieldIndex: number;
  disabled?: boolean;
  wrapProps?: BoxProps;
}

// TODO: Нужно добавить модалку для прикрепления файла, после того как будет готово медиатека
export const AttachFileWithTitle: React.FC<IAttachFileWithTitleProps> = observer(props => {
  const { wrapProps, disabled, index, fieldIndex } = props;
  const { fileStore } = useRootStore();
  const { classes, cx } = useStyles();

  const [loading, setLoading] = useState(false);
  const [attachMediaModal, setAttachMediaModal] = useState(false);
  const { getObjectUrl, removeObjectUrl } = useObjectUrls();

  const { control } = useFormContext<PollCreateAndEditForm>();
  const { field: imageController } = useController({
    control,
    name: `${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.images}.${fieldIndex}.${PollConstructorBlockImageFieldFormFields.image}`, // 'blocks.0.fields.0.image'
  });

  const fileUrl = FileHelper.getCurrentFileUrl(
    getObjectUrl(imageController.value?.localFile),
    imageController.value?.fileUrl,
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[] | null) => {
      if (imageController.value?.localFile) {
        removeObjectUrl(imageController.value.localFile);
      }

      if (!acceptedFiles?.length) {
        return;
      }

      const file = acceptedFiles[0];
      const fileObject: ILocalFileWithFileUrl = {
        ...imageController.value,
        fileUrl: imageController.value?.fileUrl ?? null,
        localFile: file,
      };

      imageController.onChange(fileObject);
      setLoading(true);

      fileStore.uploadFile(file, {
        onHandleSuccess: fileUrl => {
          if (fileUrl.length) {
            const newFileObject: ILocalFileWithFileUrl = { ...fileObject, fileUrl };
            imageController.onChange(newFileObject);
          }
        },
        onHandleError: () => {
          if (imageController.value?.localFile) {
            removeObjectUrl(imageController.value.localFile);
          }
          imageController.onChange(null);
        },
        onHandleFinally: () => {
          setLoading(false);
        },
      });
    },
    [imageController.value],
  );

  const fileDropzone = useDropzone({ onDrop, noClick: false, noKeyboard: true, multiple: false });

  const handleClickInputRootProps = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) {
      return;
    }

    if (imageController.value?.fileUrl) {
      return;
    }

    fileDropzone.open();
  };

  const handleClearFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (imageController.value?.fileUrl?.length) {
      removeObjectUrl(imageController.value.localFile);
      imageController.onChange(null);
    }
  };

  const handleWrapClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const handleCloseAttachModal = () => {
    setAttachMediaModal(false);
  };

  // Renders
  const renderTextField = () => {
    return (
      <div
        className={cx(classes.textFieldWrap, { hasFile: CommonHelper.hasLength(fileUrl) })}
        onClick={handleWrapClick}
      >
        <Controller
          name={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.images}.${fieldIndex}.${PollConstructorBlockImageFieldFormFields.text}`}
          control={control}
          render={({ field }) => (
            <ImageTitleTextField {...field} classes={{ root: classes.textFieldRoot }} placeholder="Введите заголовок" />
          )}
        />
      </div>
    );
  };

  const renderFileUploadContent = () => {
    return (
      <>
        <div>
          <input
            disabled={disabled}
            type="file"
            name={`${PollCreateAndEditFormFields.blocks}.${index}.${PollConstructorBlockFormFields.images}.${fieldIndex}.${PollConstructorBlockImageFieldFormFields.image}`}
            className={classes.fileInput}
            {...fileDropzone.getInputProps()}
          />
          <div className={classes.iconWrap}>
            <i className="custom-icon-import-curve" />
          </div>
          <Typography variant="h4Regular" sx={{ mb: 0.5 }}>
            <span className={classes.accent}>Открыть медиатеку</span> <br /> или перетащите изображение сюда
          </Typography>
          <Typography variant="h5Regular" color={customColors.gray}>
            JPG, PNG или GIF максимальный размер 3 MБ
          </Typography>
        </div>
        {renderTextField()}
      </>
    );
  };

  const renderUploadedFile = () => {
    if (!fileUrl?.length) {
      return null;
    }

    return (
      <>
        <div className={classes.imgWrap}>
          <img src={fileUrl} alt="" />
          <IconButton className={classes.clearBtn} onClick={handleClearFile}>
            <i className="custom-icon-add" />
          </IconButton>
        </div>
        {renderTextField()}
      </>
    );
  };

  return (
    <>
      <Box
        {...wrapProps}
        className={cx(classes.wrap, wrapProps?.className, {
          hasFileUpload: !CommonHelper.hasLength(fileUrl),
          isActive: fileDropzone.isDragActive,
        })}
        {...fileDropzone.getRootProps()}
        onClick={handleClickInputRootProps}
      >
        {loading && <Loader zIndex={5} isAbsolute />}
        {CommonHelper.hasLength(fileUrl) ? renderUploadedFile() : renderFileUploadContent()}
      </Box>
      <AttachMediaModal open={attachMediaModal} onHandleCloseModal={handleCloseAttachModal} />
    </>
  );
});

const useStyles = makeStyles<void, 'imgWrap' | 'clearBtn' | 'textFieldRoot'>()((theme, props, classes) => ({
  wrap: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    borderRadius: 10,
    padding: theme.spacing(5, 2),
    border: `1px solid ${theme.palette.customColors.darkGrey}`,
    minHeight: 250,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '&.isActive': {
      borderColor: theme.palette.customColors.purple,
    },
    '&.hasFileUpload': {
      cursor: 'pointer',
    },
  },
  iconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 28,
    marginBottom: theme.spacing(1),
  },
  accent: {
    fontWeight: 700,
    color: theme.palette.customColors.purple,
  },
  fileInput: {
    display: 'none',
  },
  imgWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    '&:hover': {
      '&:after': {
        opacity: 1,
        visibility: 'visible',
      },
      [`& .${classes.clearBtn}`]: {
        opacity: 1,
        visibility: 'visible',
      },
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '101%',
      height: '101%',
      zIndex: 0,
      opacity: 0,
      visibility: 'hidden',
      background: 'rgba(0, 0, 0, 54%)',
      transition: theme.transitions.create(['opacity', 'visibility']),
      transform: 'translate(-50%, -50%)',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  clearBtn: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    padding: 0,
    color: theme.palette.customColors.white,
    opacity: 0,
    visibility: 'hidden',
    transition: theme.transitions.create(['opacity', 'visibility']),
    zIndex: 2,
    '& > i': {
      transform: 'rotate(-45deg)',
    },
  },
  textFieldWrap: {
    position: 'absolute',
    left: -1,
    bottom: -1,
    width: 'calc(100% + 2px)',
    zIndex: 3,
    transition: theme.transitions.create('background'),
    '&.hasFile': {
      [`& .${classes.textFieldRoot}`]: {
        color: theme.palette.customColors.white,
        background: theme.palette.customColors.black,
        borderColor: theme.palette.customColors.black,
      },
    },
  },
  textFieldRoot: {},
}));
