import { Box, Button, Divider, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { authRoutes } from 'routes/routes';
import { customColors } from 'styles/colors';

import { LoginWrapper } from './components/LoginWrapper';

interface IForgotPasswordSuccessScreenProps {}

export const ForgotPasswordSuccessScreen: React.FC<IForgotPasswordSuccessScreenProps> = observer(() => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.email?.length) {
      navigate(authRoutes.ForgotPasswordScreen.path);
    }
  }, [location]);

  // Handlers
  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigate(authRoutes.ForgotPasswordScreen.path, { state: { email: location.state?.email ?? null } });
  };

  // Renders
  return (
    <LoginWrapper>
      {authStore.loading && <Loader isAbsolute minHeight="100vh" />}
      <Box sx={{ mb: 2.5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2.5 }}>
          <Typography variant="h2Bold" color={customColors.purple}>
            Восстановление пароля
          </Typography>
        </Box>
        <Divider />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', maxWidth: 480, mx: 'auto' }}>
        <Typography sx={{ mb: 2 }} textAlign="center" variant="h3Medium">
          Проверьте Вашу электронную почту. Вам придёт уведомление о замене пароля с дальнейшими инструкциями.
        </Typography>
        <Typography sx={{ mb: 2.5 }} textAlign="center" variant="h3Medium">
          Если письмо не пришло в течение 15 минут, проверьте папку «Спам».Если же письма нет, попробуйте ещё раз.
          Возможно, Вы ошиблись при вводе адреса.
        </Typography>

        <Button sx={{ mb: 2.5 }} variant="contained" color="primary" onClick={handleGoBack}>
          Вернуться назад
        </Button>
      </Box>
    </LoginWrapper>
  );
});
