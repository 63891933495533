import { AxiosProgressEvent } from 'axios';

import { ObjectToFormDataHelper } from 'helpers/ObjectToFormDataHelper';

import { FileApiRepository } from './FileApiRepository';
import { ILocalFileWithFileUrl } from './interfaces/FileInterfaces';

export class FileService {
  private fileApi: FileApiRepository;

  constructor() {
    this.fileApi = new FileApiRepository();
  }

  tryUploadFile = async (file: File): Promise<string | null> => {
    let savedFile = null;

    try {
      savedFile = await this.uploadFile(file);
    } catch (err) {}

    return savedFile;
  };

  tryUploadFiles = async (file: File[]): Promise<string[] | null> => {
    let savedFiles = null;

    try {
      savedFiles = await this.uploadFiles(file);
    } catch (err) {}

    return savedFiles;
  };

  uploadFile = async (file: File): Promise<string | null> => {
    const formData = ObjectToFormDataHelper.getSingleFileFormData(file);
    const { data } = await this.fileApi.uploadFile(formData);

    return data?.data ?? null;
  };

  uploadFiles = async (
    files: File[],
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<string[] | null> => {
    const formData = ObjectToFormDataHelper.getMultipleFilesFormData(files);
    const { data } = await this.fileApi.uploadFiles(formData, onUploadProgress);

    return data.data ?? null;
  };

  createLocalFileWithFileUrl = (fileUrl: string | null): ILocalFileWithFileUrl | null => {
    if (!fileUrl) {
      return null;
    }

    return { fileUrl, localFile: null };
  };
}
