import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import {
  OrganizationCreateForm,
  OrganizationCreateFormFields,
} from 'modules/organizations/forms/OrganizationCreateForm';
import { organizationCreateFormScheme } from 'modules/organizations/schemes/organizationCreateFormScheme';
import { OrganizationCreateModalContextKeys } from 'modules/organizations/types/OrganizationTypes';
import { customColors } from 'styles/colors';

interface IOrganizationCreateModalProps extends Partial<IQModalProps> {
  modalContextKey: OrganizationCreateModalContextKeys;
  onHandleSuccess?: () => void;
}

export const OrganizationCreateModal: React.FC<IOrganizationCreateModalProps> = observer(props => {
  const { organizationsStore } = useRootStore();
  const { modalContextKey, onHandleSuccess, ...rest } = props;

  const organizationCreateForm = useForm<OrganizationCreateForm>({
    mode: 'onChange',
    resolver: yupResolver(organizationCreateFormScheme),
    defaultValues: OrganizationCreateForm.create(),
  });

  // Handlers
  const handleCloseModal = () => {
    organizationsStore.setOrganizationCreateModal(modalContextKey, false);
  };

  const handleSubmit = (form: OrganizationCreateForm) => {
    organizationsStore.createOrganization(form, () => {
      handleCloseModal();
      onHandleSuccess?.();

      organizationCreateForm.reset(OrganizationCreateForm.create());

      if (!organizationsStore.selectedOrganization) {
        organizationsStore.setSelectedOrganization(organizationsStore.membershipOrganizations?.[0] ?? null);
      }
    });
  };

  // Renders
  return (
    <QModal
      onClose={handleCloseModal}
      open={!!organizationsStore.organizationCreateModal?.[modalContextKey]}
      {...rest}
      modalMaxWidthValue={610}
    >
      {organizationsStore.organizationCreateLoading && <Loader isAbsolute />}
      <Box
        component="form"
        onSubmit={organizationCreateForm.handleSubmit(handleSubmit)}
        sx={{ textAlign: 'center', maxWidth: 350, mx: 'auto' }}
      >
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 2 }}>
          Создание организации
        </Typography>

        <QOutlinedTextFieldWithController
          sx={{ mb: 2.5 }}
          placeholder="Придумайте название организации"
          name={OrganizationCreateFormFields.organizationName}
          hookFormProps={{ control: organizationCreateForm.control }}
        />
        <Button fullWidth variant="contained" color="primary" sx={{ mb: 2 }} type="submit">
          Добавить
        </Button>
      </Box>
    </QModal>
  );
});
