import { AttachFile } from 'components/UI/AttachFile';

interface IChooseHelpFormFileProps {
  value: File | null;
  onChange: (file: File | null) => void;
}

export const ChooseHelpFormFile: React.FC<IChooseHelpFormFileProps> = props => {
  const { value, onChange } = props;

  const handleClearFile = () => {
    onChange(null);
  };

  const handleDropFile = (acceptedFiles: File[]) => {
    onChange(acceptedFiles?.[0] ?? null);
  };

  return <AttachFile file={value} label="Вложение" onHandleClearFile={handleClearFile} onDrop={handleDropFile} />;
};
