import { computed, makeObservable } from 'mobx';

import { PollsService } from 'modules/polls/PollsService';
import { PollsFilters, PollsViewType } from 'modules/polls/types/PollsTypes';
import { TableForm } from 'modules/tables/forms/TableForm/TableForm';

export enum PollsListFormFields {
  filter = 'filter',
  viewType = 'viewType',
  search = 'search',
  organizationId = 'organizationId',
}

export class PollsListForm extends TableForm {
  limit: number = PollsService.POLLS_GRID_TYPE_LIST_LIMIT;
  filter: PollsFilters | null = null;
  viewType: PollsViewType = PollsViewType.grid;
  search: string = '';
  organizationId: number | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createFormObservable() {
    const context = new this();
    context.createTableFormObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(PollsListFormFields),
      pageCount: computed,
      showPagination: computed,
      currentContextLimit: computed,
    });
  }

  get currentContextLimit() {
    return this.viewType === PollsViewType.grid
      ? PollsService.POLLS_GRID_TYPE_LIST_LIMIT
      : PollsService.POLLS_TABLE_TYPE_LIST_LIMIT;
  }

  get pageCount() {
    return Math.ceil(this.total / this.currentContextLimit);
  }

  get showPagination() {
    return this.total > this.currentContextLimit;
  }
}
