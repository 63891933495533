import { AbstractApiRepository } from 'base/api/AbstractApiRepository';

import { ReportsDeleteDto } from './dto/ReportsDeleteDto';

export default class ReportsApiRepository extends AbstractApiRepository {
  getPollReports = (params: string) => {
    return this.apiClient.get({ url: `/report/polls${params}` });
  };

  getPollReportAnswers = (params: string) => {
    return this.apiClient.get({ url: `/report/answers${params}` });
  };

  getUsersNotCompletedPolls = (pollId: number, params: string) => {
    return this.apiClient.get({ url: `/report/${pollId}/ignore${params}` });
  };

  getUsersVote = (
    reportId: number | null,
    pollQuestionId: number | null,
    pollQuestionAnswerId: number | null,
    params: string,
  ) => {
    return this.apiClient.get({ url: `/report/${reportId}/${pollQuestionId}/${pollQuestionAnswerId}/votes${params}` });
  };

  deleteReports = (data: ReportsDeleteDto) => {
    return this.apiClient.delete({ url: '/report/remove', data });
  };

  exportReport = (pollId: string) => {
    return this.apiClient.get({ url: `/report/export/${pollId}`, config: { responseType: 'blob' } });
  };
}
