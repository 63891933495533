import { AbstractApiRepository } from 'base/api/AbstractApiRepository';

import { ProfileChangePasswordFormDto } from './dto/ProfileChangePasswordFormDto';
import { ProfileFormDto } from './dto/ProfileFormDto';

export class UsersApiRepository extends AbstractApiRepository {
  getCurrentUserInfo = () => {
    return this.apiClient.get({ url: '/user/me' });
  };

  editUser = (data: ProfileFormDto) => {
    return this.apiClient.put({ url: '/user', data });
  };

  sendVerify = () => {
    return this.apiClient.post({ url: '/user/email-verify' });
  };

  changeThePasswordWithTheCurrent = (data: ProfileChangePasswordFormDto) => {
    return this.apiClient.post({ url: '/user/change-password', data });
  };

  changeUserStatus = () => {
    return this.apiClient.post({ url: '/user/change-status' });
  };

  sendHelpMail = (data: FormData) => {
    return this.apiClient.post({ url: '/user/help', data });
  };
}
