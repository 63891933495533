import { NotificationMessages } from 'types/CommonTypes';

export enum UserNotificationMessagesKeys {
  helpMail = 'helpMail',
}

export const usersNotificationMessages: NotificationMessages<UserNotificationMessagesKeys> = {
  helpMail: {
    success: 'Обращение отправлено успешно! С Вами свяжутся в ближайшее время',
    error: 'Что-то пошло не так',
  },
};
