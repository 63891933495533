import { observer } from 'mobx-react-lite';

import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';

interface IAttachMediaModalProps extends IQModalProps {
  onHandleCloseModal: () => void;
}

export const AttachMediaModal: React.FC<IAttachMediaModalProps> = observer(props => {
  // const { pollsStore } = useRootStore();
  const { onHandleCloseModal, ...rest } = props;

  // Handlers
  const handleCloseModal = () => {
    onHandleCloseModal();
  };

  // Renders
  return (
    <QModal {...rest} onClose={handleCloseModal} modalMaxWidthValue={1450}>
      Медиатека
    </QModal>
  );
});
