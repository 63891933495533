import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Notification } from 'base/ui/Notification';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { Loader } from 'components/UI/Loader';
import { QModal } from 'components/UI/QModal';
import { QSelectOutlinedWithController } from 'components/UI/Selects/QSelectOutlinedWithController';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { ClipboardHelper } from 'helpers/ClipboardHelper';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { organizationNotificationMessages } from 'messages/organization';
import {
  InviteUserInOrganizationForm,
  InviteUserInOrganizationFormFields,
} from 'modules/organizations/forms/InviteUserInOrganizationForm';
import { inviteUserInOrganizationFormScheme } from 'modules/organizations/schemes/inviteUserInOrganizationFormScheme';
import { customColors } from 'styles/colors';

interface IMembersInvitationModalProps extends Partial<IQModalProps> {}

export const MembersInvitationModal: React.FC<IMembersInvitationModalProps> = observer(props => {
  const { organizationsStore } = useRootStore();
  const { ...rest } = props;
  const { classes } = useStyles();

  const inviteUserInOrganizationForm = useForm<InviteUserInOrganizationForm>({
    mode: 'onChange',
    resolver: yupResolver(inviteUserInOrganizationFormScheme),
    defaultValues: InviteUserInOrganizationForm.create(),
  });

  const defaultSelectedOrganizationId = organizationsStore.selectedOrganization?.id
    ? organizationsStore.selectedOrganization?.id?.toString()
    : undefined;

  const defaultOwnershipOrganization = organizationsStore.ownershipOrganizations?.length
    ? organizationsStore.ownershipOrganizations?.[0]?.id?.toString()
    : undefined;
  const loading = organizationsStore.inviteUserInOrganizationLoading || organizationsStore.generateInviteLinkLoading;

  // Effects
  useEffect(() => {
    organizationsStore.getOwnershipOrganizations();
  }, []);

  // Handlers
  const handleSendInvite = (form: InviteUserInOrganizationForm) => {
    if (!organizationsStore.selectedOrganization?.id) {
      return;
    }

    organizationsStore.inviteUserInOrganization(form, () => {
      handleCloseModal();
      inviteUserInOrganizationForm.reset(InviteUserInOrganizationForm.create());
    });
  };

  const handleCloseModal = () => {
    organizationsStore.setMembersInvitationModal(false);
  };

  const handleGenerateInviteLink = () => {
    if (!organizationsStore.selectedOrganization?.id) {
      return;
    }

    organizationsStore.generateInviteLink(organizationsStore.selectedOrganization.id, (url: string) => {
      ClipboardHelper.copyToClipboard(url).then(() => {
        Notification.showSuccess(organizationNotificationMessages.generateInviteLink.success);
      });
    });
  };

  // Renders
  return (
    <QModal
      {...rest}
      open={organizationsStore.membersInvitationModal}
      onClose={handleCloseModal}
      modalMaxWidthValue={610}
    >
      {loading && <Loader isAbsolute />}
      <Box
        component="form"
        sx={{ maxWidth: 380, width: '100%', mx: 'auto' }}
        onSubmit={inviteUserInOrganizationForm.handleSubmit(handleSendInvite)}
      >
        <Typography variant="h1Bold" color={customColors.purple} sx={{ textAlign: 'center', mb: 2 }}>
          Пригласить участника
        </Typography>

        <QSelectOutlinedWithController
          sx={{ mb: 1.5 }}
          fullWidth
          singleDefaultSelectedValue={
            defaultSelectedOrganizationId?.length ? defaultSelectedOrganizationId : defaultOwnershipOrganization
          }
          placeholder="Выбрать организацию"
          name={InviteUserInOrganizationFormFields.choosenOrganization}
          hookFormProps={{ control: inviteUserInOrganizationForm.control }}
        >
          {organizationsStore.ownershipOrganizations?.map(
            item =>
              !!item.id && (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ),
          )}
        </QSelectOutlinedWithController>

        <QOutlinedTextFieldWithController
          sx={{ mb: 1.5 }}
          placeholder="Введите электронную почту"
          name={InviteUserInOrganizationFormFields.userEmail}
          hookFormProps={{ control: inviteUserInOrganizationForm.control }}
        />

        <Box sx={{ mb: 2, mx: 'auto', maxWidth: '380px' }}>
          <Button fullWidth variant="contained" color="primary" sx={{ mb: 2 }} type="submit">
            Отправить приглашение
          </Button>
        </Box>
        <Typography variant="h4Regular" className={classes.desc}>
          Или просто{' '}
          <TextButton primaryUnderLine color="primary" className={classes.linkBtn} onClick={handleGenerateInviteLink}>
            нажмите сюда
          </TextButton>
          , чтобы скопировать ссылку, <br />и отправьте ее пользователю
        </Typography>
      </Box>
    </QModal>
  );
});

const useStyles = makeStyles()(theme => ({
  desc: {
    textAlign: 'center',
  },
  linkBtn: {
    fontWeight: 400,
  },
}));
