import { BaseForm } from 'base/BaseForm';

export enum ProfileChangePasswordFormFields {
  currentPassword = 'currentPassword',
  newPassword = 'newPassword',
  newPasswordConfirm = 'newPasswordConfirm',
}

export class ProfileChangePasswordForm extends BaseForm {
  currentPassword: string = '';
  newPassword: string = '';
  newPasswordConfirm: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
