import { PollBaseLayout } from './components/PollBaseLayout';
import { PollSettingsContent } from './components/pollSettings/PollSettingsContent';

interface IPollSettingsScreenProps {}

export const PollSettingsScreen: React.FC<IPollSettingsScreenProps> = () => {
  return (
    <PollBaseLayout>
      <PollSettingsContent />
    </PollBaseLayout>
  );
};
