import { Typography } from '@mui/material';

import { CommonContainer } from 'components/Common/CommonContainer';

import { SupportFormComponent } from './components/SupportFormComponent';

interface ISupportScreenProps {}

export const SupportScreen: React.FC<ISupportScreenProps> = () => {
  return (
    <CommonContainer sx={{ pt: 8.75, pl: 9 }}>
      <Typography variant="h1Bold" sx={{ mb: 3 }}>
        Помощь
      </Typography>

      <SupportFormComponent />
    </CommonContainer>
  );
};
