import { makeAutoObservable } from 'mobx';

import { MediaLibraryService } from './MediaLibraryService';

export class MediaLibraryStore {
  loading: boolean = false;

  attachFilesModal: boolean = false;

  private mediaLibraryService: MediaLibraryService;

  constructor() {
    makeAutoObservable(this);
    this.mediaLibraryService = new MediaLibraryService();
  }

  setAttachFilesModal = (value: boolean) => {
    this.attachFilesModal = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };
}
