import { PaginationModel } from 'base/modules/pagination/models/PaginationModel';
import { ResponseWithPagination } from 'base/modules/pagination/types/PaginationTypes';
import { SortService } from 'base/modules/sort/SortService';
import { SortValue } from 'base/modules/sort/models/SortValue';
import { SortOrders } from 'base/modules/sort/types/SortTypes';
import { ArrayHelper } from 'helpers/ArrayHelper';
import { ParamsGenerationHelper } from 'helpers/ParamsGenerationHelper';
import { RoleMatrixItem } from 'modules/organizations/types/OrganizationTypes';

import { PollsDataSource } from './PollsDataSource';
import { PollsFactory } from './PollsFactory';
import { PollBlockSettingsForm } from './forms/PollBlockSettingsForm';
import { PollConstructorBlockFieldForm } from './forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockForm } from './forms/PollConstructorBlockForm';
import { PollConstructorBlockImageFieldForm } from './forms/PollConstructorBlockImageFieldForm';
import { PollCreateAndEditForm } from './forms/PollCreateAndEditForm';
import { PollOthersTemplatesAnswerForm } from './forms/PollOthersTemplatesAnswerForm';
import { PollQuestionsForm } from './forms/PollQuestionsForm';
import { PollsListForm } from './forms/PollsListForm/PollsListForm';
import { IPollActionMenuItem } from './interfaces/PollsInterfaces';
import { PollModel } from './models/PollModel';
import { PollProgressModel } from './models/PollProgressModel';
import { PollQuestionModel } from './models/PollQuestionModel';
import { PollVoteDefaultTemplateModel } from './models/PollVoteDefaultTemplateModel';
import { PollVoteOthersTemplateModel } from './models/PollVoteOthersTemplateModel';
import { PollsApiRepository } from './repositories/PollsApiRepository';
import { PollsLocalRepository } from './repositories/PollsLocalRepository';
import { PollActionMenuTypes, PollBlockTypes, PollTypes } from './types/PollsTypes';

export class PollsService {
  static readonly POLLS_TABLE_TYPE_LIST_LIMIT: number = 5;
  static readonly POLLS_GRID_TYPE_LIST_LIMIT: number = 12;

  private pollsApi: PollsApiRepository;
  private pollsLocalApi: PollsLocalRepository;
  private pollsDataSource: PollsDataSource;
  private pollsFactory: PollsFactory;
  private sortService: SortService;

  constructor() {
    this.pollsApi = new PollsApiRepository();
    this.pollsLocalApi = new PollsLocalRepository();
    this.pollsDataSource = new PollsDataSource();
    this.pollsFactory = new PollsFactory();
    this.sortService = new SortService();
  }

  createPoll = async (organizationId: number, form: PollCreateAndEditForm): Promise<PollModel> => {
    const dto = this.pollsDataSource.preparePollCreateFormDto(organizationId, form);
    const { data } = await this.pollsApi.createPoll(dto);

    return this.pollsFactory.createPollModel(data.data);
  };

  editPoll = async (organizationId: number, pollId: string, form: PollCreateAndEditForm): Promise<PollModel> => {
    const dto = this.pollsDataSource.preparePollCreateFormDto(organizationId, form);
    const { data } = await this.pollsApi.editPoll(pollId, dto);

    return this.pollsFactory.createPollModel(data.data);
  };

  getPollById = async (pollId: string, organizationId: number): Promise<PollModel> => {
    const params = ParamsGenerationHelper.getSerializedParams({ organization_id: organizationId });
    const { data } = await this.pollsApi.getPollById(pollId, params);

    return this.pollsFactory.createPollModel(data.data);
  };

  getPollVoteById = async (pollId: string, organizationId: number): Promise<PollVoteOthersTemplateModel> => {
    const params = ParamsGenerationHelper.getSerializedParams({ organization_id: organizationId });
    const { data } = await this.pollsApi.getPollVoteById(pollId, params);

    return this.pollsFactory.create<PollVoteOthersTemplateModel>(PollVoteOthersTemplateModel, data.data);
  };

  getPollVoteByIdDefaultTemplate = async (
    pollId: string,
    organizationId: number,
  ): Promise<PollVoteDefaultTemplateModel> => {
    const params = ParamsGenerationHelper.getSerializedParams({ organization_id: organizationId });
    const { data } = await this.pollsApi.getPollVoteById(pollId, params);

    return this.pollsFactory.create<PollVoteDefaultTemplateModel>(PollVoteDefaultTemplateModel, data.data);
  };

  setAnswersTheQuestions = async (
    pollId: string,
    organizationId: number,
    form: PollQuestionsForm,
  ): Promise<PollVoteDefaultTemplateModel> => {
    const params = ParamsGenerationHelper.getSerializedParams({ organization_id: organizationId });
    const dto = this.pollsDataSource.prepareAnswersTheQuestionsDto(form);
    const { data } = await this.pollsApi.setAnswersTheQuestions(pollId, params, dto);

    return this.pollsFactory.create<PollVoteDefaultTemplateModel>(PollVoteDefaultTemplateModel, data.data);
  };

  setAnswerTheQuestionOtherTemplates = async (
    pollId: string,
    organizationId: number,
    form: PollOthersTemplatesAnswerForm,
  ): Promise<PollVoteOthersTemplateModel> => {
    const params = ParamsGenerationHelper.getSerializedParams({ organization_id: organizationId });
    const dto = this.pollsDataSource.prepareAnswerTheQuestionOtherTemplatesDto(form);
    const { data } = await this.pollsApi.setAnswerTheQuestionOtherTemplates(pollId, params, dto);

    return this.pollsFactory.create<PollVoteOthersTemplateModel>(PollVoteOthersTemplateModel, data.data);
  };

  revertPrevQuestion = async (pollId: string): Promise<PollVoteOthersTemplateModel> => {
    const { data } = await this.pollsApi.revertPrevQuestion(pollId);

    return this.pollsFactory.create<PollVoteOthersTemplateModel>(PollVoteOthersTemplateModel, data.data);
  };

  getOrganizationPolls = async (form: PollsListForm): ResponseWithPagination<PollModel[]> => {
    const dto = this.pollsDataSource.prepareOrganizationPollsDto(form);
    const params = ParamsGenerationHelper.getSerializedParams(dto);

    const { data } = await this.pollsApi.getOrganizationPolls(params);

    const list = this.pollsFactory.createPollsList(data.data.items);
    const paginationMeta = this.pollsFactory.create<PaginationModel>(PaginationModel, data.data.paginationMeta);

    return { items: list, paginationMeta };
  };

  deletePoll = (pollId: number): Promise<void> => {
    return this.pollsApi.deletePoll(pollId);
  };

  getPollProgress = async (pollId: number): Promise<PollProgressModel> => {
    const { data } = await this.pollsApi.getPollProgress(pollId);

    return this.pollsFactory.create<PollProgressModel>(PollProgressModel, data?.progress ?? {});
  };

  startPollById = async (pollId: number, organizationId: number): Promise<void> => {
    const params = ParamsGenerationHelper.getSerializedParams({ organization_id: organizationId });
    return this.pollsApi.startPollById(pollId, params);
  };

  stopPollById = async (pollId: number, organizationId: number): Promise<void> => {
    const params = ParamsGenerationHelper.getSerializedParams({ organization_id: organizationId });
    return this.pollsApi.stopPollById(pollId, params);
  };

  replicatePollById = async (pollId: number): Promise<PollModel> => {
    const { data } = await this.pollsApi.replicatePollById(pollId);

    return this.pollsFactory.createPollModel(data.data);
  };

  // Getters
  getCurrentGettedPollQuestionsHashMap = (
    currentGettedPollInfo: PollModel | null,
  ): Record<string, PollQuestionModel> | null => {
    if (!currentGettedPollInfo?.questions?.length) {
      return null;
    }

    const result: Record<string, PollQuestionModel> = {};

    for (let i = 0; i < currentGettedPollInfo.questions.length; i++) {
      const element = currentGettedPollInfo.questions[i];

      if (element.id) {
        result[element.id] = element;
      }
    }

    return result;
  };

  getPollActionTypesList = (list: IPollActionMenuItem[], pollInfo: PollModel | null): IPollActionMenuItem[] => {
    return list.filter(item => {
      const isActive = !!pollInfo?.is_active;

      switch (item.id) {
        case PollActionMenuTypes.startPoll:
          return !isActive;
        case PollActionMenuTypes.stopPoll:
          return isActive;
        default:
          return true;
      }
    });
  };

  getPollActionTypesListState = (
    item: IPollActionMenuItem,
    pollLocalInfo: PollModel | null,
    currentRoleMatrix: RoleMatrixItem | null,
  ): boolean => {
    switch (item.id) {
      case PollActionMenuTypes.startPoll:
        return !currentRoleMatrix?.pollEdit;
      case PollActionMenuTypes.stopPoll:
        return !currentRoleMatrix?.pollEdit;
      case PollActionMenuTypes.edit:
        return !!pollLocalInfo?.is_active || !currentRoleMatrix?.pollEdit;
      case PollActionMenuTypes.report:
        return !pollLocalInfo?.is_votable || !currentRoleMatrix?.pollResults;
      case PollActionMenuTypes.duplicate:
        return !currentRoleMatrix?.pollCreate;
      case PollActionMenuTypes.rename:
        return !currentRoleMatrix?.pollEdit;
      case PollActionMenuTypes.delete:
        return !currentRoleMatrix?.pollDelete;
      default:
        return false;
    }
  };

  getSortValue = (currentOrderBy: string, sortValue: SortValue | null, withClear?: boolean): SortValue => {
    return this.sortService.getSortValue(currentOrderBy, sortValue, withClear);
  };

  getCurrentSortValue(currentSort: string, sortDirection: SortOrders): SortValue {
    return this.sortService.getCurrentSortValue(currentSort, sortDirection);
  }

  getPollBlockDefaultImages = (
    imagesCount: number,
    data?: PollConstructorBlockImageFieldForm[],
  ): PollConstructorBlockImageFieldForm | PollConstructorBlockImageFieldForm[] => {
    const array: PollConstructorBlockImageFieldForm[] = [];

    for (let i = 0; i < imagesCount; i++) {
      const currentData = data?.[i] ?? null;
      if (currentData) {
        array.push(PollConstructorBlockImageFieldForm.create(currentData));
      } else {
        array.push(PollConstructorBlockImageFieldForm.create());
      }
    }

    return array;
  };

  // getPollConstructorBlockFormFromTheForm
  getPollConstructorBlockFormFromTheForm = (form: PollConstructorBlockForm): PollConstructorBlockForm => {
    return PollConstructorBlockForm.create({
      ...form,
      settings: PollBlockSettingsForm.create(form?.settings),
      fields: form.fields?.length ? form.fields.map(item => PollConstructorBlockFieldForm.create(item)) : [],
      images: form.images?.length ? form.images.map(item => PollConstructorBlockImageFieldForm.create(item)) : [],
    });
  };

  // PollCreateAndEditForm start
  getUpdatedPollModel = (prevPollModel: PollModel | null, data: any) => {
    return this.pollsFactory.createPollModel({ ...prevPollModel, ...data });
  };

  getPollCreateAndEditForm = (pollModel: PollModel, settedData?: Record<string, unknown>): PollCreateAndEditForm => {
    return this.pollsDataSource.getPollCreateAndEditForm(pollModel, settedData);
  };

  getPollCreateAndEditCurrentContextDefaultForm = (pollType: PollTypes): PollCreateAndEditForm => {
    return this.pollsDataSource.getPollCreateAndEditCurrentContextDefaultForm(pollType);
  };

  getCurrentPollTemplateDefaultBlockForm = (pollType: PollTypes): PollConstructorBlockForm => {
    return this.pollsDataSource.getCurrentPollTemplateDefaultBlockForm(pollType);
  };

  getPollContructorBlocksForm = (
    type: PollTypes,
    uploadedFilesList: string[],
    imagesCount: number,
  ): PollConstructorBlockForm[] => {
    const pollConstructorBlockForm = this.getCurrentPollTemplateDefaultBlockForm(type);
    const imagesList = ArrayHelper.splitArrayIntoSubarrays(uploadedFilesList, imagesCount);
    const blocksListArray: PollConstructorBlockForm[] = [];

    imagesList.forEach(item => {
      const block = PollConstructorBlockForm.create<PollConstructorBlockForm>({
        ...pollConstructorBlockForm,
        settings: PollBlockSettingsForm.create({
          ...pollConstructorBlockForm.settings,
          blockType: PollBlockTypes.media,
          imagesCount,
        }),
        images: pollConstructorBlockForm.images.map((imageFieldForm, imageFieldFormIndex) =>
          PollConstructorBlockImageFieldForm.create({
            ...imageFieldForm,
            image: { fileUrl: item?.[imageFieldFormIndex] ?? null, localFile: null },
          }),
        ),
      });

      blocksListArray.push(block);
    });

    return blocksListArray;
  };

  transformJson = (data: any) => {
    if (!data.questions?.length) {
      return [];
    }

    const result = data.questions.map((question: any) => {
      return {
        id: question.id,
        title: question.title,
        answers: question.answers.map((answer: any) => {
          return {
            id: answer.id,
            poll_question_id: answer.poll_question_id,
            text: answer.text,
            image: answer.image,
            order: answer.order,
          };
        }),
      };
    });

    return { next_match: result };
  };
}
