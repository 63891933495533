import { makeAutoObservable, runInAction } from 'mobx';

import { SortOrders } from 'base/modules/sort/types/SortTypes';
import { FormFieldSetter, LoadingState } from 'base/types/BaseTypes';
import { Notification } from 'base/ui/Notification';
import { pollsNotificationMessages } from 'messages/polls';
import { RoleMatrixItem } from 'modules/organizations/types/OrganizationTypes';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';

import { PollsService } from './PollsService';
import { PollConstructorBlockForm } from './forms/PollConstructorBlockForm';
import { PollConstructorBlockImageFieldForm } from './forms/PollConstructorBlockImageFieldForm';
import { PollCreateAndEditForm } from './forms/PollCreateAndEditForm';
import { PollOthersTemplatesAnswerForm } from './forms/PollOthersTemplatesAnswerForm';
import { PollQuestionsForm } from './forms/PollQuestionsForm';
import { PollsListForm, PollsListFormFields } from './forms/PollsListForm/PollsListForm';
import { IPollActionMenuItem } from './interfaces/PollsInterfaces';
import { PollModel } from './models/PollModel';
import { PollProgressModel } from './models/PollProgressModel';
import { PollVoteDefaultTemplateModel } from './models/PollVoteDefaultTemplateModel';
import { PollVoteOthersTemplateModel } from './models/PollVoteOthersTemplateModel';
import { PollTypes } from './types/PollsTypes';

export class PollsStore {
  loading: boolean = false;
  pollLoadingState: LoadingState = LoadingState.notLoaded;
  organizationPollsListLoading: boolean = false;
  deletePollLoading: boolean = false;
  changePollStatusLoading: boolean = false;
  pollVoteLoading: boolean = false;

  pollLocalInfo: PollModel | null = null;
  currentGettedPollInfo: PollModel | null = null;
  organizationPollsList: PollModel[] | null = null;
  pollVoteModel: PollVoteOthersTemplateModel | null = null;
  pollVoteDefatulTemplateModel: PollVoteDefaultTemplateModel | null = null;

  pollsListForm: PollsListForm = PollsListForm.createFormObservable();

  deletePollModalOpen: boolean = false;
  pollStatusModalOpen: boolean = false;
  pollNameModalOpen: boolean = false;
  pollSuccessfullyModalOpen: boolean = false;

  pollName: string = '';

  private pollsService: PollsService;

  constructor() {
    makeAutoObservable(this);
    this.pollsService = new PollsService();
  }

  get currentGettedPollQuestionsHashMap() {
    return this.pollsService.getCurrentGettedPollQuestionsHashMap(this.currentGettedPollInfo);
  }

  createPoll = (
    organizationId: number,
    form: PollCreateAndEditForm,
    onHandleSuccess?: (pollModel: PollModel) => void,
  ) => {
    this.setLoading(true);

    this.pollsService
      .createPoll(organizationId, form)
      .then(pollModel => {
        this.setPollName(pollModel.name ?? '');
        onHandleSuccess?.(pollModel);
        Notification.showSuccess(pollsNotificationMessages.pollCreate.success);
      })
      .catch(() => {
        Notification.showError(pollsNotificationMessages.pollCreate.error);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  duplicatePoll = (
    organizationId: number,
    form: PollCreateAndEditForm,
    onHandleSuccess?: (pollModel: PollModel) => void,
  ) => {
    this.setLoading(true);

    this.pollsService
      .createPoll(organizationId, form)
      .then(pollModel => {
        onHandleSuccess?.(pollModel);
        Notification.showSuccess(pollsNotificationMessages.duplicatePoll.success);
      })
      .catch(() => {
        Notification.showError(pollsNotificationMessages.duplicatePoll.error);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  editPoll = (
    organizationdId: number,
    pollId: string,
    form: PollCreateAndEditForm,
    onHandleSuccess?: (pollModel: PollModel) => void,
  ) => {
    this.setLoading(true);

    this.pollsService
      .editPoll(organizationdId, pollId, form)
      .then(pollModel => {
        this.setCurrentGettedPollInfo(pollModel);
        Notification.showSuccess(pollsNotificationMessages.pollEdit.success);
        onHandleSuccess?.(pollModel);
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getPollById = (
    pollId: string,
    organizationId: number,
    onHandleSuccess?: (pollModel: PollModel) => void,
    onHandleError?: () => void,
  ) => {
    this.setLoading(true);
    this.setPollLoadingState(LoadingState.loading);

    this.pollsService
      .getPollById(pollId, organizationId)
      .then(pollModel => {
        this.setPollName(pollModel.name ?? '');
        this.setCurrentGettedPollInfo(pollModel);
        onHandleSuccess?.(pollModel);
        this.setPollLoadingState(LoadingState.loadingFinished);
      })
      .catch(e => {
        onHandleError?.();
        this.setPollLoadingState(LoadingState.error);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  getPollVoteById = (
    pollId: string,
    organizationId: number,
    onHandleSuccess?: (pollVoteModel: PollVoteOthersTemplateModel) => void,
    onHandleError?: () => void,
  ) => {
    this.setPollVoteLoading(true);

    this.pollsService
      .getPollVoteById(pollId, organizationId)
      .then(pollVoteModel => {
        this.setPollVoteModel(pollVoteModel);
        onHandleSuccess?.(pollVoteModel);
      })
      .catch(e => {
        onHandleError?.();
      })
      .finally(() => {
        this.setPollVoteLoading(false);
      });
  };

  getPollVoteByIdDefaultTemplate = (
    pollId: string,
    organizationId: number,
    onHandleSuccess?: (pollVoteDefaultTemplateModel: PollVoteDefaultTemplateModel) => void,
    onHandleError?: () => void,
  ) => {
    this.setPollVoteLoading(true);

    this.pollsService
      .getPollVoteByIdDefaultTemplate(pollId, organizationId)
      .then(pollVoteDefaultTemplateModel => {
        this.setPollVoteDefaultTemplateModel(pollVoteDefaultTemplateModel);
        onHandleSuccess?.(pollVoteDefaultTemplateModel);
      })
      .catch(e => {
        onHandleError?.();
      })
      .finally(() => {
        this.setPollVoteLoading(false);
      });
  };

  setAnswersTheQuestions = (
    pollId: string,
    organizationId: number,
    form: PollQuestionsForm,
    onHandleSuccess?: () => void,
  ) => {
    this.setPollVoteLoading(true);

    this.pollsService
      .setAnswersTheQuestions(pollId, organizationId, form)
      .then(pollVoteDefatulTemplateModel => {
        onHandleSuccess?.();
        this.setPollVoteDefaultTemplateModel(pollVoteDefatulTemplateModel);
      })
      .catch(() => {})
      .finally(() => {
        this.setPollVoteLoading(false);
      });
  };

  setAnswerTheQuestionOtherTemplates = (
    pollId: string,
    organizationId: number,
    form: PollOthersTemplatesAnswerForm,
    onHandleSuccess?: () => void,
  ) => {
    this.setPollVoteLoading(true);

    this.pollsService
      .setAnswerTheQuestionOtherTemplates(pollId, organizationId, form)
      .then(pollVoteModel => {
        this.setPollVoteModel(pollVoteModel);
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPollVoteLoading(false);
      });
  };

  revertPrevQuestion = (pollId: string, onHandleSuccess?: (pollVoteModel: PollVoteOthersTemplateModel) => void) => {
    this.setPollVoteLoading(true);

    this.pollsService
      .revertPrevQuestion(pollId)
      .then(pollVoteModel => {
        this.setPollVoteModel(pollVoteModel);
        onHandleSuccess?.(pollVoteModel);
      })
      .catch(() => {})
      .finally(() => {
        this.setPollVoteLoading(false);
      });
  };

  getOrganizationPolls = () => {
    this.setOrganizationPollsListLoading(true);

    this.pollsService
      .getOrganizationPolls(this.pollsListForm)
      .then(({ items, paginationMeta }) => {
        runInAction(() => {
          this.organizationPollsList = items;
          this.pollsListForm.setValues(paginationMeta);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setOrganizationPollsListLoading(false);
      });
  };

  deletePoll = (pollId: number, onHandleSuccess?: () => void) => {
    this.setDeletePollLoading(true);

    this.pollsService
      .deletePoll(pollId)
      .then(() => {
        Notification.showSuccess(pollsNotificationMessages.deletePoll.success);
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setDeletePollLoading(false);
      });
  };

  getPollProgress = (pollId: number, onHandleSuccess?: (pollProgressModel: PollProgressModel) => void) => {
    this.pollsService
      .getPollProgress(pollId)
      .then(pollProgressModel => {
        if (this.currentGettedPollInfo) {
          const updatePollModel = this.pollsService.getUpdatedPollModel(this.currentGettedPollInfo, {
            progress: pollProgressModel,
          });

          this.setCurrentGettedPollInfo(updatePollModel);
          onHandleSuccess?.(pollProgressModel);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  startPollById = (pollId: number, organizationId: number, onHandleSuccess?: () => void) => {
    this.setChangePollStatusLoading(true);

    this.pollsService
      .startPollById(pollId, organizationId)
      .then(() => {
        Notification.showSuccess(pollsNotificationMessages.pollStatusStarted.success);
        onHandleSuccess?.();
      })
      .catch(() => {
        Notification.showError(pollsNotificationMessages.pollStatusStarted.error);
      })
      .finally(() => {
        this.setChangePollStatusLoading(false);
      });
  };

  stopPollById = (pollId: number, organizationId: number, onHandleSuccess?: () => void) => {
    this.setChangePollStatusLoading(true);

    this.pollsService
      .stopPollById(pollId, organizationId)
      .then(() => {
        Notification.showSuccess(pollsNotificationMessages.pollStatusEnded.success);
        onHandleSuccess?.();
      })
      .catch(() => {
        Notification.showError(pollsNotificationMessages.pollStatusEnded.error);
      })
      .finally(() => {
        this.setChangePollStatusLoading(false);
      });
  };

  replicatePollById = (pollId: number, onHandleSuccess?: (pollModel: PollModel) => void) => {
    this.setLoading(true);

    this.pollsService
      .replicatePollById(pollId)
      .then(pollModel => {
        onHandleSuccess?.(pollModel);
      })
      .catch(() => {
        Notification.showError(pollsNotificationMessages.pollStatusEnded.error);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  pollsListFormSetValueAndGetList: FormFieldSetter<`${PollsListFormFields}` | `${TableFormFields}`, PollsListForm> = (
    key,
    value,
  ) => {
    this.pollsListForm.setValue(key, value);
    this.getOrganizationPolls();
  };

  pollsListFormSetValuesAndGetList = (props: Partial<PollsListForm>) => {
    this.pollsListForm.setValues(props);
    this.getOrganizationPolls();
  };

  pollsListFormSetSortValueAndGetList = (currentOrderBy: string, withClear?: boolean) => {
    const sortValue = this.pollsService.getSortValue(currentOrderBy, this.pollsListForm.sortValue, withClear);
    this.pollsListFormSetValueAndGetList(TableFormFields.sortValue, sortValue);
  };

  pollsListFormSetSortValue = (currentOrderBy: string, withClear?: boolean) => {
    const sortValue = this.pollsService.getSortValue(currentOrderBy, this.pollsListForm.sortValue, withClear);
    this.pollsListForm.setValue(TableFormFields.sortValue, sortValue);
  };

  pollsListFormSetSortValueDefaultDesc = (currentOrderBy: string, withClear?: boolean) => {
    const sortValue = this.pollsService.getSortValue(currentOrderBy, this.pollsListForm.sortValue, withClear);
    this.pollsListForm.setValue(TableFormFields.sortValue, sortValue);
  };

  setCurrentSortValue(currentSort: string, sortDirection: SortOrders) {
    const sortValue = this.pollsService.getCurrentSortValue(currentSort, sortDirection);
    this.pollsListForm.setValue(TableFormFields.sortValue, sortValue);
  }

  // Setters
  setPollLocalInfo = (pollInfo: PollModel | null) => {
    this.pollLocalInfo = pollInfo;
  };

  setDeletePollModalOpen = (value: boolean) => {
    this.deletePollModalOpen = value;
  };

  setPollStatusModalOpen = (value: boolean) => {
    this.pollStatusModalOpen = value;
  };

  setPollNameModalOpen = (value: boolean) => {
    this.pollNameModalOpen = value;
  };

  setPollName = (pollName: string) => {
    this.pollName = pollName;
  };

  setCurrentGettedPollInfo = (pollModel: PollModel | null) => {
    this.currentGettedPollInfo = pollModel;
  };

  setPollSuccessfullyModalOpen = (value: boolean) => {
    this.pollSuccessfullyModalOpen = value;
  };

  setPollVoteModel = (pollVoteModel: PollVoteOthersTemplateModel | null) => {
    this.pollVoteModel = pollVoteModel;
  };

  setPollVoteDefaultTemplateModel = (pollVoteDefatulTemplateModel: PollVoteDefaultTemplateModel | null) => {
    this.pollVoteDefatulTemplateModel = pollVoteDefatulTemplateModel;
  };

  // Getters
  getPollActionTypesList = (list: IPollActionMenuItem[]): IPollActionMenuItem[] => {
    return this.pollsService.getPollActionTypesList(list, this.pollLocalInfo);
  };

  getPollActionTypesListState = (item: IPollActionMenuItem, currentRoleMatrix: RoleMatrixItem | null): boolean => {
    return this.pollsService.getPollActionTypesListState(item, this.pollLocalInfo, currentRoleMatrix);
  };

  getPollConstructorBlockFormFromTheForm = (data: PollConstructorBlockForm): PollConstructorBlockForm => {
    return this.pollsService.getPollConstructorBlockFormFromTheForm(data);
  };

  getPollBlockDefaultImages = (imagesCount: number, data?: PollConstructorBlockImageFieldForm[]) => {
    return this.pollsService.getPollBlockDefaultImages(imagesCount, data);
  };

  getPollCreateAndEditForm = (pollModel: PollModel, settedData?: Record<string, unknown>): PollCreateAndEditForm => {
    return this.pollsService.getPollCreateAndEditForm(pollModel, settedData);
  };

  getPollCreateAndEditCurrentContextDefaultForm = (pollType: PollTypes) => {
    return this.pollsService.getPollCreateAndEditCurrentContextDefaultForm(pollType);
  };

  getCurrentPollTemplateDefaultBlockForm = (pollType: PollTypes) => {
    return this.pollsService.getCurrentPollTemplateDefaultBlockForm(pollType);
  };

  getPollContructorBlocksForm = (type: PollTypes, uploadedFilesList: string[], imagesCount: number) => {
    return this.pollsService.getPollContructorBlocksForm(type, uploadedFilesList, imagesCount);
  };

  // Loadings
  setDeletePollLoading = (value: boolean) => {
    this.deletePollLoading = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setPollLoadingState = (value: LoadingState) => {
    this.pollLoadingState = value;
  };

  setOrganizationPollsListLoading = (value: boolean) => {
    this.organizationPollsListLoading = value;
  };

  setChangePollStatusLoading = (value: boolean) => {
    this.changePollStatusLoading = value;
  };

  setPollVoteLoading = (value: boolean) => {
    this.pollVoteLoading = value;
  };

  // Resets
  resetPollInfoAndForm = () => {
    this.setCurrentGettedPollInfo(null);
    this.setPollName('');
  };
}
