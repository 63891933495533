import { Theme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Header } from 'components/Common/Header/Header';
import { Sidebar } from 'components/Common/Sidebar/Sidebar';
import { UrlHelper } from 'helpers/UrlHelper';

interface IBaseLayoutProps {}

export const BaseLayout: React.FC<PropsWithChildren<IBaseLayoutProps>> = props => {
  const { classes, cx } = useStyles();

  const location = useLocation();
  const isPollScreens = UrlHelper.isValidRoute(location.pathname, 'poll');

  return (
    <div className={cx(classes.mainWrap, { fullWidth: isPollScreens })}>
      {!isPollScreens && <Sidebar />}
      <Header />
      <main className={classes.content}>{props.children}</main>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  mainWrap: {
    '&:not(.fullWidth)': {
      display: 'grid',
      gridTemplateColumns: '250px 1fr',
      gridTemplateAreas: `
      "sidebar header"
      "sidebar content"
      `,
    },
  },
  content: {
    gridArea: 'content',
    height: 'calc(100vh - 85px)',
    overflow: 'auto',
  },
}));
