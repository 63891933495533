import { useParams } from 'react-router-dom';

import { IPollCreateAndEditScreensParams } from 'modules/polls/interfaces/PollsInterfaces';

export const useGetPollCreateAndEditParams = (): Record<keyof IPollCreateAndEditScreensParams, string> => {
  const params = useParams();
  const action = params.action ?? '';
  const templateId = params.templateId ?? '';
  const type = params.type ?? '';
  const pollId = params.pollId ?? '';

  return { action, templateId, type, pollId };
};
