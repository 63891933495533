import { UserInOrganizationModel } from 'modules/users/models/UserInOrganizationModel';

import { ROLE_MATRIX } from '../consts/consts';
import { RoleModel } from '../models/RoleModel';
import { OrganizationRoles, RoleMatrixItem } from '../types/OrganizationTypes';

export class OrganizationHelper {
  static getCurrentRoleMatrix = (
    currentUserInOrganizationRole: UserInOrganizationModel | null | undefined,
  ): RoleMatrixItem | null => {
    if (!currentUserInOrganizationRole?.role?.name) {
      return null;
    }

    return ROLE_MATRIX[currentUserInOrganizationRole.role.name];
  };

  static getFilteredRoles = (
    rolesList: RoleModel[] | null,
    currentRole: OrganizationRoles | null,
    isCurrentSelectedOrganizationAdmin: boolean,
  ) => {
    return rolesList?.filter(role => {
      const isCurrentRoleNotAdmin = currentRole !== OrganizationRoles.admin;
      const roleAdmin = role.name === OrganizationRoles.admin;

      return isCurrentRoleNotAdmin && !isCurrentSelectedOrganizationAdmin && roleAdmin ? false : true;
    });
  };
}
