import { Grid, Typography } from '@mui/material';
import { FormProvider, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';

import { PollSettingsInterfaceBlock } from './Blocks/PollSettingsInterfaceBlock';
import { PollSettingsNotificationBlock } from './Blocks/PollSettingsNotificationBlock';

interface IPollSettingsContentProps {}

export const PollSettingsContent: React.FC<IPollSettingsContentProps> = () => {
  const { classes } = useStyles();

  const pollCreateAndEditForm = useFormContext<PollCreateAndEditForm>();

  // Renders
  return (
    <FormProvider {...pollCreateAndEditForm}>
      <div className={classes.wrap}>
        <Grid container sx={{ justifyContent: 'space-around' }} columnSpacing={8}>
          <Grid item xs={5}>
            <Typography sx={{ mb: 1 }} variant="h1Bold">
              Интерфейс
            </Typography>
            <PollSettingsInterfaceBlock />
          </Grid>
          <Grid item xs={5}>
            <Typography sx={{ mb: 1 }} variant="h1Bold">
              Уведомления
            </Typography>
            <PollSettingsNotificationBlock />
          </Grid>
        </Grid>
      </div>
    </FormProvider>
  );
};

const useStyles = makeStyles()(theme => ({
  wrap: {
    padding: theme.spacing(5.75, 4),
  },
}));
