import { BaseForm } from 'base/BaseForm';

export enum LoginFormFields {
  email = 'email',
  password = 'password',
  needRemember = 'needRemember',
}

export class LoginForm extends BaseForm {
  email: string = '';
  password: string = '';
  needRemember: boolean = false;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
