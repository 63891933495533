import { makeAutoObservable, runInAction } from 'mobx';

import { FormFieldSetter } from 'base/types/BaseTypes';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';
import { UserModel } from 'modules/users/models/UserModel';

import ReportsService from './ReportsService';
import { ReportAnswersListForm, ReportAnswersListFormFields } from './forms/ReportAnswersListForm';
import { ReportsListForm, ReportsListFormFields } from './forms/ReportsListForm';
import {
  UsersNotCompletedPollListForm,
  UsersNotCompletedPollListFormFields,
} from './forms/UsersNotCompletedPollListForm';
import { UsersVoteListForm, UsersVoteListFormFields } from './forms/UsersVoteListForm';
import { ReportAnswerModel } from './models/ReportAnswerModel';
import { ReportModel } from './models/ReportModel';

export class ReportsStore {
  loading: boolean = true;
  deleteReportsLoading: boolean = false;
  exportReportLoading: boolean = false;
  usersNotCompletedListLoading: boolean = false;
  reportAnswersListLoading: boolean = false;
  usersVoteListLoading: boolean = false;

  reportsList: ReportModel[] | null = null;
  reportsListForm: ReportsListForm = ReportsListForm.createFormObservable();
  deletedReportsSelection: Record<string, boolean> = {};

  reportAnswersList: ReportAnswerModel[] | null = null;
  reportAnswersListForm: ReportAnswersListForm = ReportAnswersListForm.createFormObservable();

  openUsersNotCompletedPollListFormModal: boolean = false;
  currentReport: ReportModel | null = null;
  usersNotCompletedPollListForm: UsersNotCompletedPollListForm = UsersNotCompletedPollListForm.createFormObservable();
  usersNotCompletedPollList: UserModel[] | null = null;

  openUsersVoteListModal: boolean = false;
  usersVoteListForm: UsersVoteListForm = UsersVoteListForm.createFormObservable();
  usersVoteList: UserModel[] | null = null;

  deleteReportsModalOpen: boolean = false;
  openUsersModal: boolean = false;

  private reportsService: ReportsService;

  constructor() {
    makeAutoObservable(this);
    this.reportsService = new ReportsService();
  }

  get deletedReportsSelectionList() {
    return this.reportsService.getDeletedReportsSelectionCount(this.deletedReportsSelection);
  }

  getPollReports = () => {
    this.setLoading(true);

    this.reportsService
      .getPollReports(this.reportsListForm)
      .then(({ items, paginationMeta }) => {
        runInAction(() => {
          this.reportsList = items;
          this.reportsListForm.setValues(paginationMeta);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getPollReportAnswers = () => {
    this.setReportAnswersListLoading(true);

    this.reportsService
      .getPollReportAnswers(this.reportAnswersListForm)
      .then(({ items, paginationMeta }) => {
        runInAction(() => {
          this.reportAnswersList = items;
          this.reportAnswersListForm.setValues(paginationMeta);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setReportAnswersListLoading(false);
      });
  };

  getUsersNotCompletedPolls = () => {
    if (!this.currentReport?.poll?.id) {
      return;
    }

    this.setUsersNotCompletedListLoading(true);

    this.reportsService
      .getUsersNotCompletedPolls(this.currentReport.poll.id, this.usersNotCompletedPollListForm)
      .then(({ items, paginationMeta }) => {
        runInAction(() => {
          this.usersNotCompletedPollList = items;
          this.usersNotCompletedPollListForm.setValues(paginationMeta);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setUsersNotCompletedListLoading(false);
      });
  };

  getUsersVote = () => {
    this.setUsersVoteListLoading(true);

    this.reportsService
      .getUsersVote(this.usersVoteListForm)
      .then(({ items, paginationMeta }) => {
        runInAction(() => {
          this.usersVoteList = items;
          this.usersVoteListForm.setValues(paginationMeta);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setUsersVoteListLoading(false);
      });
  };

  deleteReports = (onHandleSuccess?: () => void) => {
    this.setDeleteReportsLoading(true);

    this.reportsService
      .deleteReports(this.deletedReportsSelectionList)
      .then(() => {
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => this.setDeleteReportsLoading(false));
  };

  exportReport = (pollId: string, onHandleSuccess?: () => void) => {
    this.setExportReportLoading(true);

    this.reportsService
      .exportReport(pollId)
      .then(() => {
        onHandleSuccess?.();
      })
      .catch(() => {})
      .finally(() => this.setExportReportLoading(false));
  };

  // ReportsList
  reportsListFormSetValueAndGetList: FormFieldSetter<
    `${ReportsListFormFields}` | `${TableFormFields}`,
    ReportsListForm
  > = (key, value) => {
    this.reportsListForm.setValue(key, value);
    this.getPollReports();
  };

  reportsListFormSetSortValueAndGetList = (currentOrderBy: string) => {
    const sortValue = this.reportsService.getSortValue(currentOrderBy, this.reportsListForm.sortValue);
    this.reportsListFormSetValueAndGetList(TableFormFields.sortValue, sortValue);
  };

  // ReportAnswersList
  reportAnswersListFormSetValueAndGetList: FormFieldSetter<
    `${ReportAnswersListFormFields}` | `${TableFormFields}`,
    ReportAnswersListForm
  > = (key, value) => {
    this.reportAnswersListForm.setValue(key, value);
    this.getPollReportAnswers();
  };

  reportAnswersListFormSetSortValueAndGetList = (currentOrderBy: string) => {
    const sortValue = this.reportsService.getSortValue(currentOrderBy, this.reportAnswersListForm.sortValue);
    this.reportAnswersListFormSetValueAndGetList(TableFormFields.sortValue, sortValue);
  };

  // UsersNotCompletedPollListForm
  usersNotCompletedPollListFormSetValueAndGetList: FormFieldSetter<
    `${UsersNotCompletedPollListFormFields}` | `${TableFormFields}`,
    UsersNotCompletedPollListForm
  > = (key, value) => {
    this.usersNotCompletedPollListForm.setValue(key, value);
    this.getUsersNotCompletedPolls();
  };

  usersNotCompletedPollListFormSetSortValueAndGetList = (currentOrderBy: string) => {
    const sortValue = this.reportsService.getSortValue(currentOrderBy, this.usersNotCompletedPollListForm.sortValue);
    this.usersNotCompletedPollListFormSetValueAndGetList(TableFormFields.sortValue, sortValue);
  };

  // UsersVoteListForm
  usersVoteListFormSetValueAndGetList: FormFieldSetter<
    `${UsersVoteListFormFields}` | `${TableFormFields}`,
    UsersVoteListForm
  > = (key, value) => {
    this.usersVoteListForm.setValue(key, value);
    this.getUsersVote();
  };

  usersVoteListFormSetSortValueAndGetList = (currentOrderBy: string) => {
    const sortValue = this.reportsService.getSortValue(currentOrderBy, this.usersVoteListForm.sortValue);
    this.usersVoteListFormSetValueAndGetList(TableFormFields.sortValue, sortValue);
  };

  // Setters
  setDeleteReportsModalOpen = (value: boolean) => {
    this.deleteReportsModalOpen = value;
  };

  setDeletedReportsSelection = (value: Record<string, boolean>) => {
    this.deletedReportsSelection = value;
  };

  toggleDeletedReportSelection = (key: number, value: boolean) => {
    if (!value) {
      delete this.deletedReportsSelection[key];

      return;
    }

    this.deletedReportsSelection[key] = value;
  };

  setOpenUsersModal = (value: boolean) => {
    this.openUsersModal = value;
  };

  setOpenUsersNotCompletedListFormModal = (value: boolean) => {
    this.openUsersNotCompletedPollListFormModal = value;
  };

  setOpenUsersVoteListModal = (value: boolean) => {
    this.openUsersVoteListModal = value;
  };

  setCurrentReport = (report: ReportModel | null) => {
    this.currentReport = report;
  };

  // Loadings
  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setReportAnswersListLoading = (value: boolean) => {
    this.reportAnswersListLoading = value;
  };

  setDeleteReportsLoading = (value: boolean) => {
    this.deleteReportsLoading = value;
  };

  setExportReportLoading = (value: boolean) => {
    this.exportReportLoading = value;
  };

  setUsersNotCompletedListLoading = (value: boolean) => {
    this.usersNotCompletedListLoading = value;
  };

  setUsersVoteListLoading = (value: boolean) => {
    this.usersVoteListLoading = value;
  };
}
