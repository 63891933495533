import { TableCell } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { UsersVoteListFormFields } from 'modules/reports/forms/UsersVoteListForm';
import { ReportAnswerModel } from 'modules/reports/models/ReportAnswerModel';
import { ReportAnswersTableColumns } from 'modules/reports/types/ReportsTypes';

interface IReportDetailTableBodyElementsProps {
  data: ReportAnswerModel;
  elementId: ReportAnswersTableColumns;
}

export const ReportDetailTableBodyElements: React.FC<IReportDetailTableBodyElementsProps> = observer(props => {
  const { data, elementId } = props;
  const { reportsStore } = useRootStore();
  const { classes } = useStyles();
  const params = useParams<{ reportId?: string }>();

  // Handlers
  const handleGetUsersVote = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    reportsStore.setOpenUsersVoteListModal(true);
    if (params?.reportId && data.answer && data.answer.id && data.answer.poll_question_id) {
      reportsStore.usersVoteListForm.setValues({
        [UsersVoteListFormFields.reportId]: Number(params.reportId),
        [UsersVoteListFormFields.pollQuestionAnswerId]: data.answer.id,
        [UsersVoteListFormFields.pollQuestionId]: data.answer.poll_question_id,
      });
      reportsStore.getUsersVote();
    }
  };

  // Renders
  const elements = useMemo<Record<ReportAnswersTableColumns, ReactNode>>(() => {
    return {
      id: <TableCell align="left">{data.answer?.id}</TableCell>,
      image: (
        <TableCell align="left">
          <div className={classes.content}>
            {data.answer?.image && (
              <div className={classes.imgWrap}>
                <img src={data.answer?.image} alt="" />
              </div>
            )}
          </div>
        </TableCell>
      ),
      text: <TableCell align="left">{data.answer?.text}</TableCell>,
      score: <TableCell align="center">{data.score} баллов</TableCell>,
      user_answers: (
        <TableCell align="center">
          <TextButton className={classes.link} color="secondary" onClick={handleGetUsersVote}>
            {data.user_answers} шт.
          </TextButton>
        </TableCell>
      ),
    };
  }, [data, reportsStore.deletedReportsSelection]);

  return <>{elements?.[elementId] ? elements?.[elementId] : null}</>;
});

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 315,
  },
  imgWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 53,
    height: 53,
    overflow: 'hidden',
    borderRadius: 3,
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(0deg, rgba(57, 57, 57, 0.4), rgba(57, 57, 57, 0.4))',
      zIndex: -1,
    },
  },
  link: {
    textDecoration: 'underline',
  },
}));
