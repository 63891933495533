import { BaseForm } from 'base/BaseForm';

export enum PollSettingsFormFields {
  shuffle = 'shuffle',
  descriptionVisible = 'descriptionVisible',
  progressBar = 'progressBar',
  showOneBlock = 'showOneBlock',
  sendEmailToOwner = 'sendEmailToOwner',
  newRepliesNotify = 'newRepliesNotify',
}

export class PollSettingsForm extends BaseForm {
  shuffle: boolean = false;
  descriptionVisible: boolean = true;
  progressBar: boolean = false;
  showOneBlock: boolean = false;
  sendEmailToOwner: boolean = false;
  newRepliesNotify: boolean = false;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
