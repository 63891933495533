import { ChooseOrganization } from 'widgets/ChooseOrganization/ChooseOrganization';

import { HeaderRightPanel } from './HeaderRightPanel';

interface IHeaderMainProps {}

export const HeaderMain: React.FC<IHeaderMainProps> = () => {
  return (
    <>
      <ChooseOrganization />
      <HeaderRightPanel />
    </>
  );
};
