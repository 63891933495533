import { BaseForm } from 'base/BaseForm';
import { ILocalFileWithFileUrl } from 'modules/file/interfaces/FileInterfaces';

import { PollConstructorBlockFieldsTypes } from '../types/PollsTypes';

export enum PollConstructorBlockFieldFormFields {
  type = 'type',
  text = 'text',
  image = 'image',
}

export class PollConstructorBlockFieldForm extends BaseForm {
  type: PollConstructorBlockFieldsTypes = PollConstructorBlockFieldsTypes.imageWithRadio;
  text: string = '';
  image?: ILocalFileWithFileUrl | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
