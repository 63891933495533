import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { QRadio } from 'components/UI/Radio/QRadio';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { PollConstructorBlockFieldFormFields } from 'modules/polls/forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockFormFields } from 'modules/polls/forms/PollConstructorBlockForm';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';

import { AttachFile } from '../AttachFile';

interface IConstructorImageWithRadioFieldProps {
  fieldName: `${PollCreateAndEditFormFields.blocks}.${number}.${PollConstructorBlockFormFields.fields}.${number}`;
  actionButtons: ReactNode;
}

export const ConstructorImageWithRadioField: React.FC<IConstructorImageWithRadioFieldProps> = props => {
  const { fieldName, actionButtons } = props;
  const { classes } = useStyles();
  const { control } = useFormContext<PollCreateAndEditForm>();

  return (
    <div className={classes.wrap}>
      <AttachFile fieldName={fieldName} />
      <div className={classes.fieldWrap}>
        <QRadio disabled />
        <QOutlinedTextFieldWithController
          fullWidth
          placeholder="Введите текст"
          name={`${fieldName}.${PollConstructorBlockFieldFormFields.text}`}
          hookFormProps={{ control }}
        />
        {actionButtons}
      </div>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  fieldWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
