import { Button, MenuItem, Theme, buttonClasses, menuItemClasses } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { TextButton } from 'components/UI/Buttons/TextButton';
import { Loader } from 'components/UI/Loader';
import { QMenu } from 'components/UI/QMenu/QMenu';
import { OrganizationModel } from 'modules/organizations/models/OrganizationModel';
import { OrganizationCreateModalContextKeys } from 'modules/organizations/types/OrganizationTypes';
import { routes } from 'routes/routes';
import { OrganizationCreateModal } from 'widgets/OrganizationCreateModal/OrganizationCreateModal';

interface IChooseOrganizationProps {}

export const ChooseOrganization: React.FC<IChooseOrganizationProps> = observer(props => {
  const { organizationsStore } = useRootStore();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();

  const isMembersScreen = !!RouteHelper.matchPath(routes.MembersScreen.path, location.pathname);

  // Effects
  useEffect(() => {
    const savedInviteLinkId = organizationsStore.getLocalJoinLink();

    if (savedInviteLinkId) {
      organizationsStore.joinOrganizationByLink(savedInviteLinkId, () => {
        organizationsStore.removeLocalJoinLink();

        if (!organizationsStore.membershipOrganizations?.length) {
          handleGetMembershipOrganizations();
        }
      });
    }
  }, [organizationsStore.membershipOrganizations, organizationsStore.selectedOrganization, location.pathname]);

  useEffect(() => {
    handleGetMembershipOrganizations();
  }, [organizationsStore.selectedOrganization, organizationsStore.membershipOrganizations, location.pathname]);

  useEffect(() => {
    if (!organizationsStore.selectedOrganization?.id) {
      return;
    }

    organizationsStore.getCurrentUserInOrganizationRole(organizationsStore.selectedOrganization.id);
  }, [organizationsStore.selectedOrganization]);

  // Handlers
  const handleGetMembershipOrganizations = () => {
    if (organizationsStore.membershipOrganizations?.length || isMembersScreen) {
      return;
    }

    organizationsStore.getMembershipOrganizations('ChooseOrganization', organizations => {
      if (organizationsStore.selectedOrganization?.id) {
        return;
      }

      organizationsStore.setSelectedOrganization(organizations?.[0] ?? null);
    });
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChooseOrganization = (organization: OrganizationModel) => (e: React.MouseEvent<HTMLLIElement>) => {
    organizationsStore.setSelectedOrganization(organization);
    handleCloseMenu();
  };

  const handleAddOrganization = (e: React.MouseEvent<HTMLButtonElement>) => {
    organizationsStore.setOrganizationCreateModal(OrganizationCreateModalContextKeys.header, true);
  };

  const handleSuccessCreateOrganization = () => {
    handleCloseMenu();
  };

  // Renders
  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        endIcon={<i className="custom-icon-triangle-owal-down" />}
        classes={{ root: classes.button, endIcon: classes.endIcon }}
        onClick={handleOpenMenu}
      >
        {organizationsStore.membershipOrganizationsLoading && <Loader isAbsolute size={20} />}
        {organizationsStore.selectedOrganization?.name
          ? organizationsStore.selectedOrganization?.name
          : 'Выберите организацию'}
      </Button>

      <QMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseMenu}
        variant="selectedMenu"
      >
        {organizationsStore.membershipOrganizations?.map(organization => (
          <MenuItem
            key={organization.id}
            selected={organization.id === organizationsStore.selectedOrganization?.id}
            className={classes.menuItem}
            onClick={handleChooseOrganization(organization)}
          >
            {organization.name}
          </MenuItem>
        ))}

        <MenuItem disableRipple disableTouchRipple className={classes.btnMenuItem}>
          <TextButton onClick={handleAddOrganization} color="primary">
            Добавить организацию
          </TextButton>
        </MenuItem>
      </QMenu>

      <OrganizationCreateModal
        modalContextKey={OrganizationCreateModalContextKeys.header}
        onHandleSuccess={handleSuccessCreateOrganization}
      />
    </>
  );
});

const useStyles = makeStyles()((theme: Theme) => ({
  menuItem: {
    [`&.${menuItemClasses.selected}`]: {
      backgroundColor: theme.palette.customColors.white,
      fontWeight: 700,
    },
  },
  button: {
    padding: theme.spacing(1.75, 2.875),
    borderRadius: 10,
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 400,
  },
  endIcon: {
    [`&.${buttonClasses.endIcon} > i`]: {
      fontSize: 10,
    },
  },
  btnMenuItem: {
    '&:hover': {
      background: theme.palette.customColors.white,
    },
  },
}));
