import { RadioGroup } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { QRadio } from 'components/UI/Radio/QRadio';
import { QOutlinedTextField } from 'components/UI/TextFields/QOutlinedTextField';
import { PollAnswersFormFields } from 'modules/polls/forms/PollAnswersForm';
import { PollQuestionsForm, PollQuestionsFormFields } from 'modules/polls/forms/PollQuestionsForm';
import { PollMatchDefaultTemplateModel } from 'modules/polls/models/PollMatchDefaultTemplateModel';
import { PollQuestionModel } from 'modules/polls/models/PollQuestionModel';

interface IPollTextBlockRadioGroupProps {
  question: PollQuestionModel;
  pollMatchDefaultTemplateModel: PollMatchDefaultTemplateModel;
}

export const PollTextBlockRadioGroup: React.FC<IPollTextBlockRadioGroupProps> = props => {
  const { question } = props;
  const { control } = useFormContext<PollQuestionsForm>();
  const { classes } = useStyles();

  const questionId = question?.id ?? '';

  const { field: answersController } = useController({
    control,
    name: `${PollQuestionsFormFields.questions}.${questionId}.${PollAnswersFormFields.answer}`,
  });

  // Handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    answersController.onChange(value);
  };

  // Renders
  return (
    <>
      <RadioGroup value={answersController.value ?? ''} onChange={handleChange}>
        {question?.answers?.map(answer => (
          <div key={answer.id} className={classes.answerItem}>
            {!!answer?.image?.length && (
              <div className={classes.imgWrap}>
                <img src={answer?.image} alt="" />
              </div>
            )}
            <div className={classes.radioWrap}>
              <QRadio color="secondary" value={answer.id} />
              <QOutlinedTextField
                value={answer?.text ?? ''}
                InputProps={{ readOnly: true }}
                containerProps={{ sx: { width: 239 } }}
              />
            </div>
          </div>
        ))}
      </RadioGroup>
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  radioWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  answerItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.25),
    },
  },
  imgWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 273,
    height: 273,
    overflow: 'hidden',
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}));
