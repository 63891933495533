import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { TextButton } from 'components/UI/Buttons/TextButton';
import { customColors } from 'styles/colors';

interface INotificationItemProps {
  title: string;
  message: string;
  withLink: boolean;
}

export const NotificationItem: React.FC<INotificationItemProps> = props => {
  const { title, message, withLink } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2Bold" color={customColors.purple}>
          {title}
        </Typography>
        <TextButton className={classes.closeBtn}>
          <i className="custom-icon-add" />
        </TextButton>
      </Box>
      <Box sx={{ display: 'flex', mt: 1.25 }}>
        <Typography variant="h3Regular">{message}</Typography>
        {withLink && (
          <Link to="">
            <Typography variant="h3Bold">Перейти к результатам</Typography>
          </Link>
        )}
      </Box>
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2),
    borderRadius: '10px',
    backgroundColor: theme.palette.customColors.lightBlue,
  },
  closeBtn: {
    color: theme.palette.customColors.black,
    ':hover': {
      color: theme.palette.customColors.black,
    },
    '& > i': {
      transform: 'rotate(-45deg)',
    },
  },
}));
