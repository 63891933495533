import { Avatar, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { UserModel } from 'modules/users/models/UserModel';
import { MemberKickButton } from 'widgets/MemberKickButton/MemberKickButton';
import { MembersRoleChangeSelect } from 'widgets/MembersRoleChangeSelect/MembersRoleChangeSelect';

interface IMemberEditModalProps extends IQModalProps {
  currentMember: UserModel | null;
  onHandleCloseModal: () => void;
}

export const MemberEditModal: React.FC<IMemberEditModalProps> = observer(props => {
  const { currentMember, onHandleCloseModal, ...rest } = props;
  const { classes } = useStyles();

  // Handlers
  const handleCloseModal = () => {
    onHandleCloseModal();
  };

  // Renders
  return (
    <QModal onClose={handleCloseModal} {...rest} modalMaxWidthValue={610}>
      <div className={classes.wrap}>
        <Avatar className={classes.avatar} src={currentMember?.avatar ?? undefined} />
        {currentMember?.name && (
          <Typography sx={{ mb: 1 }} variant="h3Bold">
            {currentMember.name}
          </Typography>
        )}
        {currentMember?.city && (
          <Typography sx={{ mb: 1 }} variant="h4Medium">
            {currentMember.city}
          </Typography>
        )}
        {currentMember?.birthday?.formatted && (
          <Typography sx={{ mb: 1 }} variant="h4Medium">
            {currentMember.birthday.formatted}
          </Typography>
        )}
        {currentMember?.email && (
          <Typography sx={{ mb: 2 }} variant="h4Medium">
            {currentMember.email}
          </Typography>
        )}

        <div className={classes.selectWrap}>
          <MembersRoleChangeSelect
            currentMember={currentMember}
            selectProps={{ formControlProps: { sx: { minWidth: 250 } } }}
          />

          <MemberKickButton currentMember={currentMember} />
        </div>
      </div>
    </QModal>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  avatar: {
    width: 63,
    height: 63,
    marginBottom: theme.spacing(1.75),
  },
  selectWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
