import { Controller } from 'react-hook-form';

import { IQCheckboxProps, IControllerProps } from 'interfaces/CommonComponentsInterfaces';

import { QCheckbox } from './QCheckbox';

export interface IQCheckboxWithControllerProps extends Omit<IQCheckboxProps, 'name'>, IControllerProps {}

export const QCheckboxWithController: React.FC<IQCheckboxWithControllerProps> = ({ name, hookFormProps, ...rest }) => {
  return (
    <Controller
      name={name}
      control={hookFormProps.control}
      render={({ field }) => (
        <QCheckbox checked={field.value} onChange={e => field.onChange(e.target.checked)} {...rest} />
      )}
    />
  );
};
