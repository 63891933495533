import { CommonContainer } from 'components/Common/CommonContainer';

import { ReportDetailContainer } from './components/reportDetail/ReportDetailContainer';

interface IReportDetailScreenProps {}

export const ReportDetailScreen: React.FC<IReportDetailScreenProps> = () => {
  return (
    <CommonContainer sx={{ pt: 6.5, pr: 3.75 }}>
      <ReportDetailContainer />
    </CommonContainer>
  );
};
