import { NotificationMessages } from 'types/CommonTypes';

export enum FileNotificationMessagesKeys {
  uploadFile = 'uploadFile',
  cancelRequest = 'cancelRequest',
}

export const fileNotificationMessages: NotificationMessages<FileNotificationMessagesKeys> = {
  uploadFile: {
    success: 'Форма отправлена успешно, с вами свяжутся в ближайшее время!',
    error: 'Что-то пошло не так',
  },
  cancelRequest: {
    error: 'Загрузка файлов отменена',
  },
};
