import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { QSwitchWithController } from 'components/UI/Switches/QSwitchWithController';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';
import { PollSettingsFormFields } from 'modules/polls/forms/PollSettingsForm';

import { BlockPaper } from './BlockPaper';

interface IPollSettingsNotificationBlockProps {}

export const PollSettingsNotificationBlock: React.FC<IPollSettingsNotificationBlockProps> = () => {
  const { control } = useFormContext<PollCreateAndEditForm>();

  return (
    <BlockPaper>
      <Box sx={{ mb: 1.5 }}>
        <QSwitchWithController
          label="Электронное письмо владельцу при завершении опроса"
          labelProps={{ variant: 'h3Regular' }}
          name={`${PollCreateAndEditFormFields.settings}.${PollSettingsFormFields.sendEmailToOwner}`}
          hookFormProps={{ control }}
        />
      </Box>
      <Box sx={{ mb: 1.5 }}>
        <QSwitchWithController
          label="Уведомлять о новых ответах"
          labelProps={{ variant: 'h3Regular' }}
          name={`${PollCreateAndEditFormFields.settings}.${PollSettingsFormFields.newRepliesNotify}`}
          hookFormProps={{ control }}
        />
      </Box>
    </BlockPaper>
  );
};
