import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { UseFieldArrayReturn, useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { PollCreateAndEditForm, PollCreateAndEditFormFields } from 'modules/polls/forms/PollCreateAndEditForm';

interface IConstructorBlockSettingsPanelProps {
  index: number;
  blocksListFieldArray: UseFieldArrayReturn<PollCreateAndEditForm, 'blocks', 'id'>;
}

export const ConstructorBlockSettingsPanel: React.FC<IConstructorBlockSettingsPanelProps> = observer(props => {
  const { index, blocksListFieldArray } = props;
  const { pollsStore } = useRootStore();

  const { classes, cx } = useStyles();

  const { control } = useFormContext<PollCreateAndEditForm>();
  const { field: currentBlock } = useController({ name: `${PollCreateAndEditFormFields.blocks}.${index}`, control });

  const nextIndex = index + 1;
  const previousIndex = index - 1;

  // Handlers
  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    const currentBlockForm = pollsStore.getPollConstructorBlockFormFromTheForm(currentBlock.value);

    blocksListFieldArray.insert(nextIndex, currentBlockForm);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (blocksListFieldArray.fields.length > 1) {
      blocksListFieldArray.remove(index);
    }
  };

  const handleMoveDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    const maxIndex = blocksListFieldArray.fields.length - 1;

    if (index < maxIndex) {
      blocksListFieldArray.swap(index, nextIndex);
    }
  };

  const handleMoveUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (index > 0) {
      blocksListFieldArray.swap(index, previousIndex);
    }
  };

  return (
    <ButtonGroup variant="contained" color="info" disableElevation sx={{ mr: 1 }}>
      <Tooltip title="Дублировать блок" placement="top-start">
        <Button onClick={handleCopy}>
          <i className="custom-icon-copy" />
        </Button>
      </Tooltip>
      <Tooltip title="Удалить блок" placement="top-start">
        <Button onClick={handleDelete}>
          <i className="custom-icon-trushsquare" />
        </Button>
      </Tooltip>
      <Tooltip title="Переместить блок вниз" placement="top-start">
        <Button onClick={handleMoveDown}>
          <i className="custom-icon-triangle-owal-down" />
        </Button>
      </Tooltip>
      <Tooltip title="Переместить блок вверх" placement="top-start">
        <Button onClick={handleMoveUp}>
          <i className={cx('custom-icon-triangle-owal-down', classes.triangleUp)} />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
});

const useStyles = makeStyles()(theme => ({
  triangleUp: {
    transform: 'rotate(-180deg)',
  },
}));
