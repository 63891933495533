import { Box, Button, Typography } from '@mui/material';

import { QModal } from 'components/UI/QModal';
import { IQModalProps } from 'interfaces/CommonComponentsInterfaces';
import { customColors } from 'styles/colors';

interface IBatchImageUploadCancelConfirmationModalProps extends Omit<IQModalProps, 'onClose'> {
  onHandleCancelIt: () => void;
  onHandleContinueUploading: () => void;
}

export const BatchImageUploadCancelConfirmationModal: React.FC<
  IBatchImageUploadCancelConfirmationModalProps
> = props => {
  const { onHandleCancelIt, onHandleContinueUploading, ...rest } = props;

  // Renders
  return (
    <QModal {...rest} modalMaxWidthValue={610}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1Bold" color={customColors.purple} sx={{ mb: 1 }}>
          Отмена загрузки
        </Typography>

        <Typography variant="h2Medium" sx={{ mb: 3 }}>
          Вы уверены, что хотите отменить загрузку? <br />
          Все изображения будут сброшены.
        </Typography>

        <Box sx={{ mx: 'auto', maxWidth: '330px' }}>
          <Button fullWidth variant="contained" color="error" sx={{ mb: 1 }} onClick={onHandleCancelIt}>
            Да, отменить
          </Button>
          <Button fullWidth variant="contained" color="secondary" onClick={onHandleContinueUploading}>
            Нет, продолжить загрузку
          </Button>
        </Box>
      </Box>
    </QModal>
  );
};
