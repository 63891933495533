import { useMemo } from 'react';

import { SidebarVisualTypesEnum } from 'modules/sidebar/types/SidebarTypes';

import { FolderSidebar } from './FolderSidebar';
import { MainSideBar } from './MainSideBar';

interface ISidebarTypesComponentProps {
  type: SidebarVisualTypesEnum;
}

export const SidebarTypesComponent: React.FC<ISidebarTypesComponentProps> = props => {
  const { type } = props;

  const sidebarTypes = useMemo(
    () => ({
      [SidebarVisualTypesEnum.folder]: <FolderSidebar />,
      [SidebarVisualTypesEnum.main]: <MainSideBar />,
    }),
    [type],
  );

  return <>{sidebarTypes?.[type] ?? null}</>;
};
