import { Avatar, TableCell } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { MembersModalTableColumns } from 'modules/organizations/types/OrganizationTypes';
import { UserModel } from 'modules/users/models/UserModel';

interface IMembersTableBodyElementsProps {
  member: UserModel;
  elementId: MembersModalTableColumns;
}

export const MembersTableBodyElements: React.FC<IMembersTableBodyElementsProps> = props => {
  const { member, elementId } = props;

  const { classes } = useStyles();

  const elements = useMemo<Record<MembersModalTableColumns, ReactNode>>(() => {
    return {
      full_name: (
        <TableCell>
          <div className={classes.content}>
            <Avatar className={classes.avatar} src={member.avatar ?? undefined} />
            <div className={classes.nameWrap}>{member.name}</div>
          </div>
        </TableCell>
      ),
      email: <TableCell>{member.email}</TableCell>,
      role: <TableCell>{member.role?.title}</TableCell>,
      status: <TableCell>-</TableCell>,
    };
  }, [member]);

  return <>{elements?.[elementId] ? elements?.[elementId] : null}</>;
};

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 200,
  },
  nameWrap: {
    marginLeft: theme.spacing(1.5),
    maxWidth: 150,
  },
  avatar: {
    width: 26,
    height: 26,
    marginRight: theme.spacing(1),
  },
}));
