import { Button, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { PollCreateAndEditForm } from 'modules/polls/forms/PollCreateAndEditForm';

import { PollPreviewBlockItem } from './PollPreviewBlockItem';
import { PollPreviewShowOneBlockContent } from './PollPreviewShowOneBlockContent';

interface IPollPreviewDefaultViewTypeProps {}

export const PollPreviewDefaultViewType: React.FC<IPollPreviewDefaultViewTypeProps> = () => {
  const { getValues } = useFormContext<PollCreateAndEditForm>();
  const formValues = getValues();
  const { classes } = useStyles();

  return (
    <div className={classes.wrap}>
      <Typography variant="h1Bold" sx={{ textAlign: 'center', mb: 2 }}>
        {formValues.name}
      </Typography>
      {formValues.settings.descriptionVisible && (
        <Typography component="div" variant="h2Medium" sx={{ textAlign: 'center', mb: 4 }}>
          {formValues.description}
        </Typography>
      )}

      {formValues.settings.showOneBlock ? (
        <PollPreviewShowOneBlockContent />
      ) : (
        <div className={classes.blocksWrap}>
          {formValues.blocks.map((item, idx) => (
            <PollPreviewBlockItem key={idx} index={idx} data={item} />
          ))}
        </div>
      )}
      {!formValues.settings.showOneBlock && (
        <div className={classes.btnWrap}>
          <Button fullWidth variant="contained" color="secondary" type="submit">
            Сохранить
          </Button>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  wrap: {
    color: theme.palette.customColors.white,
    margin: theme.spacing(0, 'auto'),
  },
  blocksWrap: {},
  btnWrap: {
    maxWidth: 273,
    margin: theme.spacing(4, 'auto', 0),
  },
}));
