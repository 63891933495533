import { BaseForm } from 'base/BaseForm';

import { PollBlockTypes } from '../types/PollsTypes';

export enum PollBlockSettingsFormFields {
  blockType = 'blockType',
  titleVisible = 'titleVisible',
  manyAnswers = 'manyAnswers',
  imagesCount = 'imagesCount',
}

export class PollBlockSettingsForm extends BaseForm {
  blockType: PollBlockTypes = PollBlockTypes.text;
  titleVisible: boolean = true;
  manyAnswers: boolean = true;
  imagesCount: number = 4;
  background: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
