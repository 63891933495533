import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BoxProps, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { PasswordTextField } from 'components/UI/TextFields/PasswordTextField';
import { NewPasswordForm, NewPasswordFormFields } from 'modules/auth/forms/auth/NewPasswordForm';
import { newPasswordScheme } from 'modules/auth/schemes/newPasswordScheme';
import { authRoutes } from 'routes/routes';

interface INewPasswordFormComponentProps extends BoxProps {}

export const NewPasswordFormComponent: React.FC<INewPasswordFormComponentProps> = observer(props => {
  const { authStore } = useRootStore();
  const params = useParams<{ id: string; hash: string }>();
  const navigate = useNavigate();

  const newPasswordForm = useForm<NewPasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(newPasswordScheme),
    defaultValues: NewPasswordForm.create(),
  });

  // Handlers
  const handleSubmit = (form: NewPasswordForm) => {
    if (!params.id || !params.hash) {
      return;
    }

    authStore.setNewPassword(params.id, params.hash, form, () => {
      navigate(authRoutes.LoginScreen.path);
    });
  };

  return (
    <Box sx={{ mx: 'auto', maxWidth: '315px' }}>
      <Box component="form" {...props} onSubmit={newPasswordForm.handleSubmit(handleSubmit)}>
        <Box sx={{ mb: 2.5 }}>
          <PasswordTextField
            name={NewPasswordFormFields.password}
            label="Новый пароль"
            placeholder="Придумайте новый пароль"
            autoComplete="new-password"
            errors={authStore.errorMessages?.password}
            hookFormProps={{ control: newPasswordForm.control }}
          />
        </Box>
        <Box sx={{ mb: 2.5 }}>
          <PasswordTextField
            name={NewPasswordFormFields.passwordConfirm}
            label="Повторите пароль"
            placeholder="Повторите пароль"
            autoComplete="off"
            errors={authStore.errorMessages?.password}
            hookFormProps={{ control: newPasswordForm.control }}
          />
        </Box>

        <Button fullWidth type="submit" variant="contained" color="primary">
          Сохранить
        </Button>
      </Box>
    </Box>
  );
});
