import { Box, Divider, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { customColors } from 'styles/colors';

import { LoginWrapper } from './components/LoginWrapper';
import { NewPasswordFormComponent } from './components/newPassword/NewPasswordFormComponent';

interface INewPasswordScreenProps {}

export const NewPasswordScreen: React.FC<INewPasswordScreenProps> = observer(() => {
  const { authStore } = useRootStore();

  return (
    <LoginWrapper>
      {authStore.loading && <Loader isAbsolute minHeight="100vh" />}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2.5 }}>
          <Typography variant="h2Bold" color={customColors.purple}>
            Восстановление пароля
          </Typography>
        </Box>
        <Divider />
      </Box>

      <NewPasswordFormComponent />
    </LoginWrapper>
  );
});
