import { BaseForm } from 'base/BaseForm';

export enum RegisterFormFields {
  fullname = 'fullname',
  email = 'email',
  password = 'password',
}

export class RegisterForm extends BaseForm {
  fullname: string = '';
  email: string = '';
  password: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
