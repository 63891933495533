import { Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { NotificationsSettingsForm } from 'modules/notifications/forms/NotificationsSettingsForm';

import { NotificationsOptions } from './NotificationsOptions';

interface INotificationsSettingsProps {}

export const NotificationsSettings: React.FC<INotificationsSettingsProps> = () => {
  const optionsForm = useForm<NotificationsSettingsForm>({
    mode: 'onChange',
    defaultValues: NotificationsSettingsForm.create(),
  });

  return (
    <Box sx={{ mt: 8.75, mb: 6.25 }}>
      <Typography variant="h1Bold">Настройки уведомлений</Typography>
      <FormProvider {...optionsForm}>
        <NotificationsOptions />
      </FormProvider>
    </Box>
  );
};
