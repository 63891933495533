import { CommonHelper } from 'helpers/CommonHelper';
import { NumberHelper } from 'helpers/NumberHelper';

import { PollConstructorBlockFieldForm } from '../forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockForm } from '../forms/PollConstructorBlockForm';
import { IPollCreateAndEditScreensParams } from '../interfaces/PollsInterfaces';
import { PollAnswerModel } from '../models/PollAnswerModel';
import { PollProgressModel } from '../models/PollProgressModel';
import { PollQuestionModel } from '../models/PollQuestionModel';
import { PollVoteOthersTemplateModel } from '../models/PollVoteOthersTemplateModel';
import {
  PollConstructorBlockFieldsTypes,
  PollRouteActionTypes,
  PollTypes,
  PollsChildScreenKeys,
} from '../types/PollsTypes';

export class PollsHelper {
  static getValidPollConstructorBlockFieldsType = (
    currentType: PollConstructorBlockFieldsTypes,
  ): PollConstructorBlockFieldsTypes => {
    const typesMatrix = {
      [PollConstructorBlockFieldsTypes.radio]: PollConstructorBlockFieldsTypes.imageWithRadio,
      [PollConstructorBlockFieldsTypes.checkbox]: PollConstructorBlockFieldsTypes.imageWithCheckbox,
      [PollConstructorBlockFieldsTypes.imageWithRadio]: PollConstructorBlockFieldsTypes.radio,
      [PollConstructorBlockFieldsTypes.imageWithCheckbox]: PollConstructorBlockFieldsTypes.checkbox,
    };

    return typesMatrix?.[currentType] ?? PollConstructorBlockFieldsTypes.radio;
  };

  static getPollContructorBlockFieldOppositeType = (
    currentType: PollConstructorBlockFieldsTypes,
  ): PollConstructorBlockFieldsTypes | null => {
    const typesMatrix = {
      [PollConstructorBlockFieldsTypes.radio]: PollConstructorBlockFieldsTypes.checkbox,
      [PollConstructorBlockFieldsTypes.checkbox]: PollConstructorBlockFieldsTypes.radio,
      [PollConstructorBlockFieldsTypes.imageWithRadio]: PollConstructorBlockFieldsTypes.imageWithCheckbox,
      [PollConstructorBlockFieldsTypes.imageWithCheckbox]: PollConstructorBlockFieldsTypes.imageWithRadio,
    };

    if (!typesMatrix[currentType]) {
      return null;
    }

    return typesMatrix[currentType];
  };

  static сhangeTheTypeOfFieldsDependingOnTheSetting = (
    fields: PollConstructorBlockFieldForm[],
    manyAnswers: boolean,
  ) => {
    if (manyAnswers) {
      return fields.map(field => {
        if (field.type === PollConstructorBlockFieldsTypes.radio) {
          field.type = PollConstructorBlockFieldsTypes.checkbox;
        }

        if (field.type === PollConstructorBlockFieldsTypes.imageWithRadio) {
          field.type = PollConstructorBlockFieldsTypes.imageWithCheckbox;
        }

        return field;
      });
    }

    return fields.map(field => {
      if (field.type === PollConstructorBlockFieldsTypes.checkbox) {
        field.type = PollConstructorBlockFieldsTypes.radio;
      }

      if (field.type === PollConstructorBlockFieldsTypes.imageWithCheckbox) {
        field.type = PollConstructorBlockFieldsTypes.imageWithRadio;
      }

      return field;
    });
  };

  static getValidFieldType = (
    pollAnswerModel: PollAnswerModel,
    pollQuestionModel: PollQuestionModel | null,
  ): PollConstructorBlockFieldsTypes => {
    const manyAnswers = pollQuestionModel?.has_many_answers ?? false;
    const hasImage = CommonHelper.isNotNullOrUndefined(pollAnswerModel.image);

    if (!manyAnswers) {
      return !hasImage ? PollConstructorBlockFieldsTypes.radio : PollConstructorBlockFieldsTypes.imageWithRadio;
    }

    return !hasImage ? PollConstructorBlockFieldsTypes.checkbox : PollConstructorBlockFieldsTypes.imageWithCheckbox;
  };

  static isPollEditScreen = (params: Readonly<Partial<IPollCreateAndEditScreensParams>>) => {
    return params.action === PollRouteActionTypes.edit;
  };

  static getCurrentScreenSlug = (routeSegmentsList: string[] | undefined | null): PollsChildScreenKeys | null => {
    if (!routeSegmentsList?.length) {
      return null;
    }

    return (routeSegmentsList[routeSegmentsList.length - 1] as PollsChildScreenKeys) ?? null;
  };

  static getNotEmptyFields = (fields: PollConstructorBlockFieldForm[]) => {
    return fields.filter(item => CommonHelper.hasLength(item.text?.trim()));
  };

  static getPollProgressPercentage = (progress: PollProgressModel | null | undefined): number => {
    if (!progress?.all || !progress?.completed) {
      return 0;
    }

    const percentage = NumberHelper.parseFloat(progress.completed / progress.all) * 100;

    return percentage > 100 ? 100 : NumberHelper.round(percentage);
  };

  static getPollCurrentStepsFlugs = (pollProgressModel: PollProgressModel | null | undefined) => {
    const firstStep = pollProgressModel?.completed === 0 && (pollProgressModel?.all ?? 0) > 0;
    const lastStep = pollProgressModel?.all === pollProgressModel?.completed;

    return { firstStep, lastStep };
  };

  static isValidPollTemplateType = (type: PollTypes): boolean => {
    const values = Object.values(PollTypes);

    return values.includes(type);
  };

  static isBeforeTheLastRound = (pollVoteModel: PollVoteOthersTemplateModel | null): boolean => {
    if (!pollVoteModel) {
      return false;
    }

    const current = pollVoteModel.current ?? 0;
    const total = pollVoteModel.total ?? 0;

    return current === total;
  };

  static isFirstRound = (pollVoteModel: PollVoteOthersTemplateModel | null): boolean => {
    if (!pollVoteModel) {
      return false;
    }

    const current = pollVoteModel.current ?? 0;

    return current === 1;
  };

  static isFormBlocksEmpty = (blocks: PollConstructorBlockForm[]): boolean => {
    const images: { image: string; text: string }[] = [];
    const titleList: string[] = [];

    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];

      if (block.title.length) {
        titleList.push(block.title);
      }

      block.fields.forEach(item => {
        if (item.image?.fileUrl?.length || item.text.length) {
          images.push({ image: item.image?.fileUrl ?? '', text: item.text });
        }
      });

      block.images.forEach(item => {
        if (item.image?.fileUrl?.length || item.text.length) {
          images.push({ image: item.image?.fileUrl ?? '', text: item.text });
        }
      });
    }

    return !titleList.length && !images.length;
  };
}
