import { computed, makeObservable } from 'mobx';

import { TableForm } from 'modules/tables/forms/TableForm/TableForm';

import ReportsService from '../ReportsService';
import { ReportDetailFilters } from '../types/ReportsTypes';

export enum ReportAnswersListFormFields {
  pollId = 'pollId',
  filter = 'filter',
}

export class ReportAnswersListForm extends TableForm {
  limit: number = ReportsService.REPORT_ANSWERS_LIST_LIMIT;
  pollId: string | null = null;
  filter: ReportDetailFilters | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createFormObservable() {
    const context = new this();
    context.createTableFormObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(ReportAnswersListFormFields),
      pageCount: computed,
      showPagination: computed,
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.limit);
  }

  get showPagination() {
    return this.total > this.limit;
  }

  resetForm = () => {
    this.total = 0;
    this.limit = ReportsService.REPORT_ANSWERS_LIST_LIMIT;
    this.offset = 0;
    this.page = 1;
    this.sortValue = null;
  };
}
