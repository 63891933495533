import { OtherPollsMatches } from '../Common/OtherPollsMatches';
import { PollTopLine } from '../Common/PollTopLine';

interface IPollOneToOneProps {}

export const PollOneToOne: React.FC<IPollOneToOneProps> = () => {
  return (
    <>
      <PollTopLine sx={{ mb: 4 }} />
      <OtherPollsMatches />
    </>
  );
};
