export const appConfig = {
  apiUrl: {
    dev: process.env.REACT_APP_DEV_API_URL,
    prod: process.env.REACT_APP_API_URL,
  },
  tokenStorageKey: '_yourChoiceAccessToken', // _[projectName]AccessToken
  imageBaseUrl: process.env?.REACT_APP_BASE_IMAGE_URL ?? null,
  siteName: 'Test Pool',
  sentrySettings: {
    dsn: 'project_dsn_link',
  },
  projectName: 'yourChoice',
};
