import { PaginationModel } from 'base/modules/pagination/models/PaginationModel';
import { ResponseWithPagination } from 'base/modules/pagination/types/PaginationTypes';
import { SortService } from 'base/modules/sort/SortService';
import { SortValue } from 'base/modules/sort/models/SortValue';
import { DateHelper } from 'helpers/DateHelper';
import { ParamsGenerationHelper } from 'helpers/ParamsGenerationHelper';
import { FileHelper } from 'modules/file/helpers/FileHelper';
import { UserModel } from 'modules/users/models/UserModel';

import ReportsApiRepository from './ReportsApiRepository';
import { ReportsDataSource } from './ReportsDataSource';
import { ReportsFactory } from './ReportsFactory';
import { ReportAnswersListForm } from './forms/ReportAnswersListForm';
import { ReportsListForm } from './forms/ReportsListForm';
import { UsersNotCompletedPollListForm } from './forms/UsersNotCompletedPollListForm';
import { UsersVoteListForm } from './forms/UsersVoteListForm';
import { ReportAnswerModel } from './models/ReportAnswerModel';
import { ReportModel } from './models/ReportModel';

export default class ReportsService {
  static readonly REPORTS_LIST_LIMIT: number = 8;
  static readonly REPORT_ANSWERS_LIST_LIMIT: number = 8;
  static readonly USERS_NOT_COMPLETED_LIST_LIMIT: number = 10;
  static readonly USERS_VOTE_LIST_LIMIT: number = 10;

  private reportsApi: ReportsApiRepository;
  private reportsFactory: ReportsFactory;
  private reportsDataSource: ReportsDataSource;
  private sortService: SortService;

  constructor() {
    this.reportsApi = new ReportsApiRepository();
    this.reportsFactory = new ReportsFactory();
    this.reportsDataSource = new ReportsDataSource();
    this.sortService = new SortService();
  }

  getPollReports = async (form: ReportsListForm): ResponseWithPagination<ReportModel[]> => {
    const dto = this.reportsDataSource.prepareReportsListDto(form);
    const params = ParamsGenerationHelper.getSerializedParams(dto);
    const { data } = await this.reportsApi.getPollReports(params);

    const items = this.reportsFactory.createReportModelList(data.data.items);
    const paginationMeta = this.reportsFactory.create<PaginationModel>(PaginationModel, data.data.paginationMeta);

    return { items, paginationMeta };
  };

  getPollReportAnswers = async (form: ReportAnswersListForm): ResponseWithPagination<ReportAnswerModel[]> => {
    const dto = this.reportsDataSource.prepareReportAnswersListFormDto(form);
    const params = ParamsGenerationHelper.getSerializedParams(dto);
    const { data } = await this.reportsApi.getPollReportAnswers(params);

    const items = this.reportsFactory.createReportAnswerModelList(data.data.items);
    const paginationMeta = this.reportsFactory.create<PaginationModel>(PaginationModel, data.data.paginationMeta);

    return { items, paginationMeta };
  };

  getUsersNotCompletedPolls = async (
    pollId: number,
    form: UsersNotCompletedPollListForm,
  ): ResponseWithPagination<UserModel[]> => {
    const dto = this.reportsDataSource.prepareUsersNotCompletedPollListFormDto(form);
    const params = ParamsGenerationHelper.getSerializedParams(dto);
    const { data } = await this.reportsApi.getUsersNotCompletedPolls(pollId, params);

    const items = this.reportsFactory.createUsersList(data.data.items);
    const paginationMeta = this.reportsFactory.create<PaginationModel>(PaginationModel, data.data.paginationMeta);

    return { items, paginationMeta };
  };

  getUsersVote = async (form: UsersVoteListForm): ResponseWithPagination<UserModel[]> => {
    const dto = this.reportsDataSource.prepareUsersVoteListFormDto(form);
    const params = ParamsGenerationHelper.getSerializedParams(dto);
    const { data } = await this.reportsApi.getUsersVote(
      form.reportId,
      form.pollQuestionId,
      form.pollQuestionAnswerId,
      params,
    );

    const items = this.reportsFactory.createUsersList(data.data.items);
    const paginationMeta = this.reportsFactory.create<PaginationModel>(PaginationModel, data.data.paginationMeta);

    return { items, paginationMeta };
  };

  deleteReports = async (pollIdList: string[]): Promise<void> => {
    const dto = this.reportsDataSource.prepareReportsDeleteDto(pollIdList);

    return this.reportsApi.deleteReports(dto);
  };

  exportReport = async (pollId: string): Promise<void> => {
    const { data } = await this.reportsApi.exportReport(pollId);
    this.createAndDownloadExportedReport(data as Blob);
  };

  // Getters
  getSortValue = (currentOrderBy: string, sortValue: SortValue | null): SortValue => {
    return this.sortService.getSortValue(currentOrderBy, sortValue);
  };

  getDeletedReportsSelectionCount = (deletedReportsSelection: Record<string, boolean>): string[] => {
    return Object.keys(deletedReportsSelection);
  };

  private createAndDownloadExportedReport = (blob: Blob) => {
    const fileUrl = FileHelper.createFileUrl(blob as Blob);
    const fileName = DateHelper.formatDate(new Date(), 'yyyyMMdd-HHmmss') ?? 'report';
    FileHelper.downloadFile(fileUrl, `${fileName}.xls`);
    FileHelper.removeFileUrl(fileUrl);
  };
}
