import { TextField, inputBaseClasses } from '@mui/material';
import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IQStandardTextFieldProps } from 'interfaces/CommonComponentsInterfaces';

interface IFieldTitleTextFieldProps extends IQStandardTextFieldProps {}

export const FieldTitleTextField: React.FC<IFieldTitleTextFieldProps> = forwardRef((props, ref) => {
  const { classes } = useStyles({ ...props });

  return <TextField variant="standard" classes={{ root: classes.root }} ref={ref} {...props} />;
});

const useStyles = makeStyles<IFieldTitleTextFieldProps>()(theme => {
  return {
    root: {
      [`& > .${inputBaseClasses.root}`]: {
        color: theme.palette.customColors.black,
        ...theme.typography.h1Medium,
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
        '& > input': {
          padding: 0,
          height: '100%',
          '&::placeholder': {
            color: theme.palette.customColors.darkGrey,
          },
        },
      },
    },
  };
});
