import { Dto } from 'base/Dto';
import { PaginationService } from 'base/modules/pagination/PaginationService';
import { SortService } from 'base/modules/sort/SortService';

import { ReportAnswersListFormDto } from './dto/ReportAnswersListFormDto';
import { ReportsDeleteDto } from './dto/ReportsDeleteDto';
import { ReportsListFormDto } from './dto/ReportsListFormDto';
import { UsersNotCompletedPollListFormDto } from './dto/UsersNotCompletedPollListFormDto';
import { UsersVoteListFormDto } from './dto/UsersVoteListFormDto';
import { ReportAnswersListForm } from './forms/ReportAnswersListForm';
import { ReportsListForm } from './forms/ReportsListForm';
import { UsersNotCompletedPollListForm } from './forms/UsersNotCompletedPollListForm';
import { UsersVoteListForm } from './forms/UsersVoteListForm';

export class ReportsDataSource {
  private paginationService: PaginationService;
  private sortService: SortService;

  constructor() {
    this.paginationService = new PaginationService();
    this.sortService = new SortService();
  }

  public prepareReportsListDto = (form: ReportsListForm): ReportsListFormDto => {
    const paginationData = this.paginationService.getPaginationData(form.page, form.limit);

    const sortParam = this.sortService.getSort(form?.sortValue ?? null);
    const preDto = { orderBy: sortParam.orderBy, organization_id: form.organizationId };

    return Dto.populate(ReportsListFormDto, { ...form, ...preDto, ...paginationData });
  };

  public prepareReportAnswersListFormDto = (form: ReportAnswersListForm): ReportAnswersListFormDto => {
    const paginationData = this.paginationService.getPaginationData(form.page, form.limit);

    const sortParam = this.sortService.getSort(form?.sortValue ?? null);
    const preDto = { orderBy: sortParam.orderBy, poll_id: form.pollId };

    return Dto.populate(ReportAnswersListFormDto, { ...form, ...preDto, ...paginationData });
  };

  public prepareUsersNotCompletedPollListFormDto = (
    form: UsersNotCompletedPollListForm,
  ): UsersNotCompletedPollListFormDto => {
    const paginationData = this.paginationService.getPaginationData(form.page, form.limit);

    const sortParam = this.sortService.getSort(form?.sortValue ?? null);
    const preDto = { orderBy: sortParam.orderBy, organization_id: form.organizationId };

    return Dto.populate(UsersNotCompletedPollListFormDto, { ...form, ...preDto, ...paginationData });
  };

  public prepareUsersVoteListFormDto = (form: UsersVoteListForm): UsersVoteListFormDto => {
    const paginationData = this.paginationService.getPaginationData(form.page, form.limit);

    const sortParam = this.sortService.getSort(form?.sortValue ?? null);
    const preDto = { orderBy: sortParam.orderBy };

    return Dto.populate(UsersVoteListFormDto, { ...form, ...preDto, ...paginationData });
  };

  public prepareReportsDeleteDto = (pollIdsList: string[]): ReportsDeleteDto => {
    return Dto.populate(ReportsDeleteDto, { poll_id: pollIdsList });
  };
}
