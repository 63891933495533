import { ModelFactory } from 'base/ModelFactory';
import { DateHelper } from 'helpers/DateHelper';
import { IDate } from 'interfaces/CommonInterfaces';
import { OrganizationModel } from 'modules/organizations/models/OrganizationModel';
import { USER_DISPLAY_DATE_FORMAT } from 'modules/users/consts/consts';
import { UserInOrganizationModel } from 'modules/users/models/UserInOrganizationModel';
import { UserModel } from 'modules/users/models/UserModel';

import { BASE_DISPLAY_DATE_FORMAT, BASE_DISPLAY_DATE_SHORT_FORMAT } from './consts/consts';
import { DateModel } from './models/DateModel';

export class BaseFactory extends ModelFactory {
  createDateModel(
    date: any | null | undefined | string,
    format: string = BASE_DISPLAY_DATE_FORMAT,
    shortFormat: string = BASE_DISPLAY_DATE_SHORT_FORMAT,
  ): DateModel | null {
    if (date?.length) {
      const dateObject: IDate = { date: null, formattedShort: null, formatted: null, raw: null };
      dateObject.date = DateHelper.stringToDate(date as string);

      if (format) {
        dateObject.formatted = DateHelper.formatDate(dateObject.date, format);
      }

      if (shortFormat) {
        dateObject.formattedShort = DateHelper.formatDate(dateObject.date, shortFormat);
      }

      dateObject.raw = date;

      return this.create<DateModel>(DateModel, dateObject);
    }

    return null;
  }

  createUsersList = (items: any[], dateFormat?: string): UserModel[] => {
    return items?.length ? items.map(item => this.createUserModel(item, dateFormat)) : [];
  };

  createUserModel = (json: any, dateFormat: string = USER_DISPLAY_DATE_FORMAT): UserModel => {
    const userModelData: Record<string, unknown> = {};

    if (json?.created_at && !(json.created_at instanceof DateModel)) {
      userModelData.created_at = this.createDateModel(json.created_at, dateFormat);
    }

    if (json?.birthday && !(json.birthday instanceof DateModel)) {
      userModelData.birthday = this.createDateModel(json.birthday, dateFormat);
    }

    if (json?.updated_at && !(json.updated_at instanceof DateModel)) {
      userModelData.updated_at = this.createDateModel(json.updated_at, dateFormat);
    }

    if (json?.email_verified_at && !(json.email_verified_at instanceof DateModel)) {
      userModelData.email_verified_at = this.createDateModel(json.email_verified_at, dateFormat);
    }

    if (json?.organization) {
      userModelData.organization = this.createOrganizationModel(json.organization);
    }

    return this.create<UserModel>(UserModel, { ...json, ...userModelData });
  };

  createUsersInOrganizationsModelList = (items: any[], dateFormat?: string): UserModel[] => {
    return items?.length ? items.map(item => this.createUsersInOrganizationsModel(item, dateFormat)) : [];
  };

  createUsersInOrganizationsModel = (
    json: any,
    dateFormat: string = USER_DISPLAY_DATE_FORMAT,
  ): UserInOrganizationModel => {
    const userInOrganizationModelData: Record<string, unknown> = {};

    if (json?.created_at && !(json.created_at instanceof DateModel)) {
      userInOrganizationModelData.created_at = this.createDateModel(json.created_at, dateFormat);
    }

    if (json?.birthday && !(json.birthday instanceof DateModel)) {
      userInOrganizationModelData.birthday = this.createDateModel(json.birthday, dateFormat);
    }

    if (json?.updated_at && !(json.updated_at instanceof DateModel)) {
      userInOrganizationModelData.updated_at = this.createDateModel(json.updated_at, dateFormat);
    }

    if (json?.email_verified_at && !(json.email_verified_at instanceof DateModel)) {
      userInOrganizationModelData.email_verified_at = this.createDateModel(json.email_verified_at, dateFormat);
    }

    return this.create<UserInOrganizationModel>(UserInOrganizationModel, { ...json, ...userInOrganizationModelData });
  };

  createOrganizationModelList = (items: any[], dateFormat?: string): OrganizationModel[] => {
    return items?.length ? items.map(item => this.createOrganizationModel(item, dateFormat)) : [];
  };

  createOrganizationModel = (json: any, dateFormat?: string): OrganizationModel => {
    const organizationModelData: Record<string, unknown> = {};

    if (json?.owner && !(json.owner instanceof UserInOrganizationModel)) {
      organizationModelData.owner = this.createUsersInOrganizationsModel(json.owner, dateFormat);
    }

    return this.create<OrganizationModel>(OrganizationModel, { ...json, ...organizationModelData });
  };
}
