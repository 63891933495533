import { OtherPollsMatches } from '../Common/OtherPollsMatches';
import { PollTopLine } from '../Common/PollTopLine';

interface IPollDoubleEliminationProps {}

export const PollDoubleElimination: React.FC<IPollDoubleEliminationProps> = () => {
  return (
    <>
      <PollTopLine sx={{ mb: 4 }} />
      <OtherPollsMatches />
    </>
  );
};
