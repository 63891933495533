import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Logo } from 'components/UI/Logo';
import { CommonHelper } from 'helpers/CommonHelper';
import { UrlHelper } from 'helpers/UrlHelper';
import { routes } from 'routes/routes';
import { customColors } from 'styles/colors';

import { HeaderRightPanel } from './HeaderRightPanel';

interface IPollsHeaderProps {}

export const PollsHeader: React.FC<IPollsHeaderProps> = observer(() => {
  const { organizationsStore, pollsStore } = useRootStore();
  const { classes } = useStyles();

  const location = useLocation();
  const isTemplateScreen = UrlHelper.isValidRoute(location.pathname, routes.PollTemplatesScreen.path.split('/'));

  // Effects
  useEffect(() => {
    if (organizationsStore.membershipOrganizations?.length) {
      return;
    }

    organizationsStore.getMembershipOrganizations('PollsHeader', organizations => {
      if (organizationsStore.selectedOrganization) {
        return;
      }

      organizationsStore.setSelectedOrganization(organizations?.[0] ?? null);
    });
  }, [organizationsStore.selectedOrganization, organizationsStore.membershipOrganizations]);

  // Renders
  return (
    <>
      <div className={classes.wrap}>
        <Box sx={{ mr: 3.75 }}>
          <Logo linkProps={{ to: routes.MainScreen.path }} />
        </Box>
        {!isTemplateScreen && (
          <>
            {!!organizationsStore.selectedOrganization && (
              <Typography variant="h4Regular" color={customColors.darkGrey}>
                Организация “{organizationsStore.selectedOrganization.name}”
              </Typography>
            )}
            {CommonHelper.hasLength(pollsStore.pollName) && (
              <>
                <div className={classes.seperator} />
                <Typography variant="h4Bold">{pollsStore.pollName}</Typography>
              </>
            )}
          </>
        )}
      </div>
      <HeaderRightPanel />
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  seperator: {
    width: 24,
    height: 1,
    background: theme.palette.customColors.gray,
    margin: theme.spacing(0, 1.5),
  },
}));
