import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { CommonHelper } from 'helpers/CommonHelper';
import {
  PollOthersTemplatesAnswerForm,
  PollOthersTemplatesAnswerFormFields,
} from 'modules/polls/forms/PollOthersTemplatesAnswerForm';

import { PollMatchItem } from './OtherPollsMatches/PollMatchItem';

interface IOtherPollsMatchesProps {}

export const OtherPollsMatches: React.FC<IOtherPollsMatchesProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();
  const params = useParams<{ pollId?: string }>();
  const { classes, cx } = useStyles();

  const { control } = useFormContext<PollOthersTemplatesAnswerForm>();
  const { field: answerIdController } = useController({ name: PollOthersTemplatesAnswerFormFields.answerId, control });

  // Effects
  useEffect(() => {
    if (params.pollId && organizationsStore.selectedOrganization?.id) {
      pollsStore.getPollVoteById(params.pollId, organizationsStore.selectedOrganization.id);
    }
  }, [params.pollId, organizationsStore.selectedOrganization]);

  return (
    <div
      className={cx(classes.imagesWrap, {
        hasChoosenItem: CommonHelper.isNotNullOrUndefined(answerIdController.value),
      })}
    >
      {CommonHelper.hasLength(pollsStore.pollVoteModel?.next_match) &&
        pollsStore.pollVoteModel?.next_match?.map(pollMatchModel => (
          <PollMatchItem
            key={pollMatchModel.id}
            className={classes.item}
            pollMatchModel={pollMatchModel}
            innerClassName={classes.itemInner}
          />
        ))}
    </div>
  );
});

const useStyles = makeStyles<void, 'item' | 'itemInner'>()((theme, _, classes) => ({
  imagesWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    gap: theme.spacing(4),
    maxWidth: '100%',
    margin: '0 auto',
    '&.hasChoosenItem': {
      [`& .${classes.item}:not(.active) .${classes.itemInner}`]: {
        '&:before': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
  },
  item: {},
  itemInner: {},
}));
