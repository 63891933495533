import { RadioProps, FormControlLabelProps, FormControlLabel, Radio, Typography, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';

interface IQRadioProps extends RadioProps {
  label?: FormControlLabelProps['label'];
  labelTypographyProps?: TypographyProps;
  formControlLabelProps?: Partial<FormControlLabelProps>;
}

export const QRadio: React.FC<IQRadioProps> = forwardRef((props, ref) => {
  const { label, formControlLabelProps, labelTypographyProps, ...rest } = props;

  return (
    <FormControlLabel
      label={
        !!label && (
          <Typography variant="h5Regular" color="inherit" {...labelTypographyProps}>
            {label}
          </Typography>
        )
      }
      control={<Radio ref={ref} color="primary" {...rest} />}
      {...formControlLabelProps}
    />
  );
});
