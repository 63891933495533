import { appConfig } from 'appConfig';

import { IRoute } from 'base/routes/interfaces/RouteInterfaces';
import { pollsRoutes } from 'routes/routes';

import { PollsChildScreenKeys } from '../types/PollsTypes';

export const POLLS_DISPLAY_DATE_LONG_FORMAT: string = 'dd.MM.yyyy HH:mm:ss';
export const POLLS_CONSTRUCTOR_TABLE_NAME: string = `_${appConfig.projectName}ConstructorData`;
export const POLLS_BLOCK_FIELDS_MAX_LENGTH = 10;

export const getPollsChildrendScreens = (): Record<PollsChildScreenKeys, IRoute['path']> => {
  return {
    constructor: pollsRoutes.PollConstructorScreen.path,
    design: pollsRoutes.PollDesignScreen.path,
    settings: pollsRoutes.PollSettingsScreen.path,
    preview: pollsRoutes.PollPreviewScreen.path,
  };
};

export const pollContextsViewMatrix = {
  default: {
    pollViewTypeChangePanel: {
      media: true,
      text: true,
    },
    imagesCount: {
      2: true,
      4: true,
      6: true,
    },
    manyAnswers: true,
    showOneBlock: true,
    shuffle: true,
  },
  one_to_one: {
    pollViewTypeChangePanel: {
      media: true,
      text: false,
    },
    imagesCount: {
      2: true,
      4: true,
      6: false,
    },
    manyAnswers: false,
    showOneBlock: false,
    shuffle: false,
  },
  single_elimination: {
    pollViewTypeChangePanel: {
      media: true,
      text: false,
    },
    imagesCount: {
      2: true,
      4: true,
      6: false,
    },
    manyAnswers: false,
    showOneBlock: false,
    shuffle: false,
  },
  double_elimination: {
    pollViewTypeChangePanel: {
      media: true,
      text: false,
    },
    imagesCount: {
      2: true,
      4: true,
      6: false,
    },
    manyAnswers: false,
    showOneBlock: false,
    shuffle: false,
  },
};
