import { Serializable, jsonProperty } from 'ts-serializable';

export class PollMatchOthersTemplateModel extends Serializable {
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(Boolean) isWinner: boolean = false;
  @jsonProperty(Boolean, null) status: boolean | null = null;
  @jsonProperty(Number, null) name: number | null = null;
  @jsonProperty(String, null) image: string | null = null;
  @jsonProperty(Number, null) question_id: number | null = null;
  @jsonProperty(String, null) text: string | null = null;
}
