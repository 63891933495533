import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { authRoutes, routes } from 'routes/routes';

interface IJoinOrganizationScreenProps {}

export const JoinOrganizationScreen: React.FC<IJoinOrganizationScreenProps> = observer(() => {
  const { authStore, organizationsStore } = useRootStore();
  const params = useParams<{ hash?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params?.hash?.length) {
      return;
    }

    handleJoinInOrganization();
  }, [params, authStore.isAuth]);

  // Handlers
  const handleJoinInOrganization = () => {
    if (!params?.hash?.length) {
      return;
    }

    if (authStore.isAuth) {
      organizationsStore.joinOrganizationByLink(
        params.hash,
        () => {
          organizationsStore.removeLocalJoinLink();
          handleGetMembershipOrganizationsAndNavigate();
        },
        handleNavigate,
      );

      return;
    } else {
      organizationsStore.saveJoinLinkInLocal(params.hash);
      handleNavigate();
    }
  };

  const handleGetMembershipOrganizationsAndNavigate = () => {
    organizationsStore.getMembershipOrganizations('JoinOrganizationScreen', organizations => {
      organizationsStore.setSelectedOrganization(organizations?.[0] ?? null);
      handleNavigate();
    });
  };

  const handleNavigate = () => {
    if (authStore.isAuth) {
      navigate(routes.MainScreen.path);
    } else {
      navigate(authRoutes.RegisterScreen.path);
    }
  };

  return <Loader isFixed />;
});
