import { BaseForm } from 'base/BaseForm';

export enum PollAnswersFormFields {
  answers = 'answers',
  answer = 'answer',
}

export class PollAnswersForm extends BaseForm {
  answers?: Array<string | undefined> = [];
  answer?: string | null | undefined = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
