import { NotificationMessages } from 'types/CommonTypes';

export enum OrganizationNotificationMessagesKeys {
  createOrganization = 'createOrganization',
  inviteUserInOrganization = 'inviteUserInOrganization',
  joinOrganizationByLink = 'joinOrganizationByLink',
  saveJoinLink = 'saveJoinLink',
  generateInviteLink = 'generateInviteLink',
}
export const organizationNotificationMessages: NotificationMessages<OrganizationNotificationMessagesKeys> = {
  createOrganization: {
    success: 'Организация успешно создано!',
    error: 'Что-то пошло не так',
  },
  inviteUserInOrganization: {
    success: 'Приглашение успешно отправлено!',
    error: 'Что-то пошло не так',
  },
  joinOrganizationByLink: {
    success: 'Вы успешно вступили в организацию!',
    error: 'Что-то пошло не так, вы не вступили в организацию!',
  },
  saveJoinLink: {
    success: 'Ваша ссылка сохранена. Войдите в учетную запись или зарегистрируйтесь!',
    error: 'Что-то пошло не так',
  },
  generateInviteLink: {
    success: 'Ссылка скопирована в буфер обмена!',
    error: 'Что-то пошло не так',
  },
};
