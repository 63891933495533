import { ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { QOutlinedTextField } from 'components/UI/TextFields/QOutlinedTextField';
import { PollConstructorBlockFieldForm } from 'modules/polls/forms/PollConstructorBlockFieldForm';
import { PollConstructorBlockFieldsTypes } from 'modules/polls/types/PollsTypes';

import { ReactComponent as UnCheckedCheckboxIcon } from 'assets/img/icons/unchecked-secondary-icon.svg';
import { ReactComponent as UnCheckedRadioIcon } from 'assets/img/icons/unchecked-secondary-radio.svg';

interface IPollPreviewFieldsTypesProps {
  data: PollConstructorBlockFieldForm;
  fieldType: PollConstructorBlockFieldsTypes;
}

export const PollPreviewFieldsTypes: React.FC<IPollPreviewFieldsTypesProps> = props => {
  const { data, fieldType } = props;
  const { classes } = useStyles();

  // Renders
  const FieldTypes: Record<PollConstructorBlockFieldsTypes, ReactNode> = useMemo(() => {
    return {
      radio: (
        <div className={classes.fieldsWrap}>
          <div className={classes.uncheckedIconWrap}>
            <UnCheckedRadioIcon />
          </div>
          <QOutlinedTextField
            value={data.text}
            InputProps={{ readOnly: true }}
            containerProps={{ sx: { width: 239 } }}
          />
        </div>
      ),
      checkbox: (
        <div className={classes.fieldsWrap}>
          <div className={classes.uncheckedIconWrap}>
            <UnCheckedCheckboxIcon />
          </div>
          <QOutlinedTextField
            value={data.text}
            InputProps={{ readOnly: true }}
            containerProps={{ sx: { width: 239 } }}
          />
        </div>
      ),
      imageWithRadio: (
        <>
          {!!data.image?.fileUrl && (
            <div className={classes.imgWrap}>
              <img src={data.image.fileUrl} alt="" />
            </div>
          )}
          <div className={classes.fieldsWrap}>
            <div className={classes.uncheckedIconWrap}>
              <UnCheckedRadioIcon />
            </div>
            <QOutlinedTextField
              value={data.text}
              InputProps={{ readOnly: true }}
              containerProps={{ sx: { width: 239 } }}
            />
          </div>
        </>
      ),
      imageWithCheckbox: (
        <>
          {!!data.image?.fileUrl && (
            <div className={classes.imgWrap}>
              <img src={data.image?.fileUrl} alt="" />
            </div>
          )}
          <div className={classes.fieldsWrap}>
            <div className={classes.uncheckedIconWrap}>
              <UnCheckedCheckboxIcon />
            </div>
            <QOutlinedTextField
              value={data.text}
              InputProps={{ readOnly: true }}
              containerProps={{ sx: { width: 239 } }}
            />
          </div>
        </>
      ),
    };
  }, [fieldType, data]);

  return <>{FieldTypes?.[fieldType] ? <div className={classes.fieldItem}>{FieldTypes[fieldType]}</div> : null}</>;
};

const useStyles = makeStyles()(theme => ({
  fieldItem: {
    // maxWidth: 290,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  fieldsWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  imgWrap: {
    width: 273,
    height: 273,
    borderRadius: 10,
    overflow: 'hidden',
    margin: theme.spacing(0, 'auto', 2),
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  uncheckedIconWrap: {
    width: 22,
    height: 22,
    marginRight: theme.spacing(1.5),
  },
}));
