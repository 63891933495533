import { TableHead, TableCell, TableBody, Box, Table } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { RouteHelper } from 'base/routes/helpers/RouteHelper';
import { Loader } from 'components/UI/Loader';
import { QTableHeadCellWithSortLabel } from 'components/UI/Tables/QTableHeadCellWithSortLabel';
import { QTableRow } from 'components/UI/Tables/QTableRow';
import { reportsTableFields } from 'lists/reports/reportsTableFields';
import { ReportModel } from 'modules/reports/models/ReportModel';
import { routes } from 'routes/routes';
import { useTableStyles } from 'styles/tableStyles';

import { ReportsTableBodyElements } from './ReportsTableBodyElements';

interface IReportsTableListProps {}

export const ReportsTableList: React.FC<IReportsTableListProps> = observer(() => {
  const { reportsStore } = useRootStore();

  const navigate = useNavigate();

  const { tableClasses, cx } = useTableStyles();
  // Handlers
  const handleSort = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    reportsStore.reportsListFormSetSortValueAndGetList(currentSort);
  };

  const handleClickRow = (report: ReportModel) => (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const path = RouteHelper.makePath(routes.ReportDetailScreen.path, {
      reportId: report?.poll?.id ?? '',
    });

    navigate(path);
  };

  // Renders
  return (
    <Box sx={{ pl: 4, pb: 5, position: 'relative' }}>
      {reportsStore.loading && (
        <div className={tableClasses.loaderWrap}>
          <Loader isAbsolute />
        </div>
      )}
      <Table sx={{ borderSpacing: '0 24px' }}>
        <TableHead>
          <QTableRow variant="transparent">
            {reportsTableFields.map((field, index) => (
              <QTableHeadCellWithSortLabel
                key={index}
                field={field}
                sortValue={reportsStore.reportsListForm.sortValue}
                {...field.settings}
                onHandleSort={handleSort}
              />
            ))}
            <TableCell align="right" />
          </QTableRow>
        </TableHead>
        <TableBody className={tableClasses.tableRelative}>
          {reportsStore.reportsList?.map(report => (
            <QTableRow
              key={report?.poll?.id}
              className={cx(tableClasses.tableRowFilledVariant, tableClasses.pointer)}
              onClick={handleClickRow(report)}
            >
              {reportsTableFields.map(field => (
                <ReportsTableBodyElements key={field.column} data={report} elementId={field.column} />
              ))}
            </QTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
});
