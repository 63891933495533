import { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { IQSelectOutlinedProps, IControllerProps } from 'interfaces/CommonComponentsInterfaces';

import { QSelectOutlined } from './QSelectOutlined';

interface IQSelectOutlinedWithControllerProps extends Omit<IQSelectOutlinedProps, 'name'>, IControllerProps {
  singleDefaultSelectedValue?: string | null | undefined;
}

export const QSelectOutlinedWithController: React.FC<IQSelectOutlinedWithControllerProps> = props => {
  const { name, hookFormProps, errors, singleDefaultSelectedValue, ...rest } = props;
  const { field, formState } = useController({ name, ...hookFormProps });

  // Effects
  useEffect(() => {
    if (singleDefaultSelectedValue) {
      field.onChange(singleDefaultSelectedValue);
    }
  }, [singleDefaultSelectedValue]);

  // Renders
  return (
    <QSelectOutlined
      {...rest}
      errors={errors?.length ? errors : (formState.errors?.[name]?.message as string)}
      {...field}
    />
  );
};
