import { alpha } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { PollOthersTemplatesAnswerForm } from 'modules/polls/forms/PollOthersTemplatesAnswerForm';
import { PollQuestionsForm } from 'modules/polls/forms/PollQuestionsForm';
import { PollTypes } from 'modules/polls/types/PollsTypes';
import { routes } from 'routes/routes';

import { PollSuccessfullyPassedModal } from './Modals/PollSuccessfullyPassedModal';
import { PollDetailDefaultTemplateTopLine } from './PollDetailTopLine/PollDetailDefaultTemplateTopLine';
import { PollDetailOthersTemplateTopLine } from './PollDetailTopLine/PollDetailOthersTemplateTopLine';
import { PollsTypesContent } from './PollsTypesContent/PollsTypesContent';

interface IPollDetailContentProps {}

export const PollDetailContent: React.FC<IPollDetailContentProps> = observer(() => {
  const { pollsStore, organizationsStore } = useRootStore();
  const { classes } = useStyles();

  const params = useParams<{ pollId?: string }>();
  const navigate = useNavigate();
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  const pollDefaultTemplateAnswerForm = useForm<PollQuestionsForm>({
    mode: 'onChange',
    defaultValues: PollQuestionsForm.create(),
  });

  const pollOthersTemplatesAnswerForm = useForm<PollOthersTemplatesAnswerForm>({
    mode: 'onChange',
    defaultValues: PollOthersTemplatesAnswerForm.create(),
  });

  // Effects
  useEffect(() => {
    if (!organizationsStore.currentUserInOrganizationRole) {
      return;
    }

    if (!roleMatrix?.pollCompletion) {
      navigate(routes.MainScreen.path);
    }
  }, [organizationsStore.currentUserInOrganizationRole]);

  useEffect(() => {
    if (params?.pollId?.length && !pollsStore.currentGettedPollInfo && organizationsStore.selectedOrganization?.id) {
      pollsStore.getPollById(params.pollId, organizationsStore.selectedOrganization?.id);
    }
  }, [pollsStore.currentGettedPollInfo, params.pollId, organizationsStore.selectedOrganization]);

  useEffect(() => {
    return () => {
      pollsStore.setCurrentGettedPollInfo(null);
      pollsStore.setPollVoteModel(null);
      pollsStore.setPollVoteDefaultTemplateModel(null);
      pollsStore.setPollSuccessfullyModalOpen(false);
    };
  }, []);

  // Renders
  return (
    <>
      <div className={classes.wrap}>
        {(pollsStore.pollVoteLoading || pollsStore.loading) && <Loader isFixed />}

        {pollsStore.currentGettedPollInfo?.template === PollTypes.default ? (
          <FormProvider {...pollDefaultTemplateAnswerForm}>
            <PollDetailDefaultTemplateTopLine />
            <PollsTypesContent />
          </FormProvider>
        ) : (
          <FormProvider {...pollOthersTemplatesAnswerForm}>
            <PollDetailOthersTemplateTopLine />
            <PollsTypesContent />
          </FormProvider>
        )}

        <PollSuccessfullyPassedModal />
      </div>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  wrap: {
    position: 'relative',
    background: alpha(theme.palette.customColors.black, 0.8),
    minHeight: 'calc(100vh - 85px)',
    padding: theme.spacing(0, 4, 4),
    borderRadius: 10,
    overflow: 'clip',
  },
}));
