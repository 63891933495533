import { Pagination, PaginationItem, PaginationProps, PaginationRenderItemParams, Typography } from '@mui/material';

import { TextButton } from './Buttons/TextButton';

interface IQPaginationProps extends PaginationProps {}

export const QPagination: React.FC<IQPaginationProps> = props => {
  // Renders
  const renderPaginationItem = (item: PaginationRenderItemParams) => {
    switch (item.type) {
      case 'previous':
        return (
          <TextButton sx={{ mr: 0.5 }} color="secondary" onClick={item.onClick} disabled={item.disabled}>
            <Typography variant="h4Medium">Пред.</Typography>
          </TextButton>
        );
      case 'next':
        return (
          <TextButton sx={{ ml: 0.5 }} color="secondary" onClick={item.onClick} disabled={item.disabled}>
            След.
          </TextButton>
        );
      default:
        return <PaginationItem {...item} />;
    }
  };

  return (
    <>
      <Pagination
        renderItem={renderPaginationItem}
        count={10}
        variant="outlined"
        color="primary"
        shape="rounded"
        {...props}
      />
    </>
  );
};
