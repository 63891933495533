import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Paper } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { QOutlinedTextFieldWithController } from 'components/UI/TextFields/QOutlinedTextFieldWithController';
import { HelpMailForm, HelpMailFormFields } from 'modules/users/forms/HelpMailForm';
import { helpMailFormScheme } from 'modules/users/schemes/helpMailFormScheme';

import { ChooseHelpFormFile } from './ChooseHelpFormFile';

import { ReactComponent as IllustrationIcon } from 'assets/img/icons/illustration-support.svg';

interface ISupportFormComponentProps {}

export const SupportFormComponent: React.FC<ISupportFormComponentProps> = observer(() => {
  const { usersStore } = useRootStore();
  const { classes } = useStyles();

  const helpMailForm = useForm<HelpMailForm>({
    mode: 'onChange',
    resolver: yupResolver(helpMailFormScheme),
    defaultValues: HelpMailForm.create(),
  });

  const handleSubmit = (data: HelpMailForm) => {
    usersStore.sendHelpMail(data, () => {
      helpMailForm.reset(HelpMailForm.create());
    });
  };

  return (
    <>
      <Paper className={classes.paper}>
        {usersStore.sendMailLoading && <Loader isAbsolute />}
        <form onSubmit={helpMailForm.handleSubmit(handleSubmit)}>
          <div className={classes.wrap}>
            <div className={classes.leftCol}>
              <QOutlinedTextFieldWithController
                placeholder="Введите имя"
                name={HelpMailFormFields.name}
                hookFormProps={{ control: helpMailForm.control }}
              />
            </div>
            <div className={classes.rightCol}>
              <QOutlinedTextFieldWithController
                placeholder="Введите почту"
                name={HelpMailFormFields.email}
                hookFormProps={{ control: helpMailForm.control }}
              />
            </div>
            <Box sx={{ width: '100%' }}>
              <QOutlinedTextFieldWithController
                multiline
                fullWidth
                rows={7}
                placeholder="Что у Вас случилось?"
                name={HelpMailFormFields.text}
                hookFormProps={{ control: helpMailForm.control }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Controller
                control={helpMailForm.control}
                name={HelpMailFormFields.attachment}
                render={({ field }) => <ChooseHelpFormFile value={field.value} onChange={field.onChange} />}
              />
              <Box sx={{ maxWidth: 240, width: '100%' }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={usersStore.sendMailLoading}
                >
                  Отправить
                </Button>
              </Box>
            </Box>
          </div>
        </form>
      </Paper>
      <div className={classes.illustrationIconWrap}>
        <IllustrationIcon />
      </div>
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  paper: {
    position: 'relative',
    marginBottom: theme.spacing(2.75),
    padding: theme.spacing(5, 5.5),
    background: theme.palette.customColors.white,
    boxShadow: '0px 4px 4px rgba(169, 169, 169, 0.12)',
    border: `1px solid ${theme.palette.customColors.grayDivider}`,
    borderRadius: '10px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2.5),
  },
  leftCol: {
    flexBasis: `calc(66.71% - ${theme.spacing(1.25)})`,
    maxWidth: `calc(66.71% - ${theme.spacing(1.25)})`,
  },
  rightCol: {
    flexBasis: `calc(33.291% - ${theme.spacing(1.25)})`,
    maxWidth: `calc(33.29% - ${theme.spacing(1.25)})`,
  },
  illustrationIconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 365,
    margin: '0 auto',
  },
}));
