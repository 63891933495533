import { BaseForm } from 'base/BaseForm';

export enum OrganizationCreateFormFields {
  organizationName = 'organizationName',
}

export class OrganizationCreateForm extends BaseForm {
  organizationName: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
