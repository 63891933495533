import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import { customColors } from 'styles/colors';

import { AuthNavigation } from './components/AuthNavigation';
import { LoginWrapper } from './components/LoginWrapper';
import { LoginFormComponent } from './components/login/LoginFormComponent';

interface ILoginScreenProps {}

export const LoginScreen: React.FC<ILoginScreenProps> = observer(() => {
  const { authStore } = useRootStore();

  return (
    <LoginWrapper>
      {authStore.loading && <Loader isAbsolute minHeight="100vh" />}
      <AuthNavigation />

      <Box sx={{ pt: 2.5, mb: 4.25 }}>
        <Typography sx={{ textAlign: 'center' }} variant="h3Regular" color={customColors.black}>
          Пожалуйста, авторизируйтесь
        </Typography>
      </Box>

      <LoginFormComponent />
    </LoginWrapper>
  );
});
