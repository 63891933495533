import { Box, Button, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useRootStore } from 'base/hooks/useRootStore';
import { membersModalTableFields } from 'lists/organizations/membersModalTableFields';
import { OrganizationHelper } from 'modules/organizations/helpers/OrganizationHelper';
import { ReportsListFormFields } from 'modules/reports/forms/ReportsListForm';
import { TableFormFields } from 'modules/tables/forms/TableForm/TableForm';
import { routes } from 'routes/routes';

import { MembersModal } from '../MembersModal/MembersModal';
import { ReportDeleteModal } from './Modals/ReportDeleteModal';
import { ReportsPagination } from './ReportsPagination';
import { ReportsSearchForm } from './ReportsSearchForm';
import { ReportsTableList } from './ReportsTable/ReportsTableList';

interface IReportsContainerProps {}

export const ReportsContainer: React.FC<IReportsContainerProps> = observer(() => {
  const { classes } = useStyles();
  const { reportsStore, organizationsStore } = useRootStore();
  const navigate = useNavigate();
  const roleMatrix = OrganizationHelper.getCurrentRoleMatrix(organizationsStore.currentUserInOrganizationRole);

  // Effects
  useEffect(() => {
    if (!organizationsStore.currentUserInOrganizationRole) {
      return;
    }

    if (!roleMatrix?.pollResults) {
      navigate(routes.MainScreen.path);
    }
  }, [organizationsStore.currentUserInOrganizationRole]);

  useEffect(() => {
    if (!organizationsStore.selectedOrganization?.id) {
      return;
    }

    reportsStore.reportsListFormSetValueAndGetList(
      ReportsListFormFields.organizationId,
      organizationsStore.selectedOrganization.id,
    );
  }, [organizationsStore.selectedOrganization]);

  // Handlers
  const handleOpenDeleteModal = () => {
    reportsStore.setDeleteReportsModalOpen(true);
    organizationsStore.membersListForm.resetForm();
  };

  // UsersModal
  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    organizationsStore.membersListFormSetValueAndGetList(TableFormFields.page, page);
  };

  const handleCloseUsersModal = () => {
    reportsStore.setOpenUsersModal(false);
  };

  const handleSort = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    organizationsStore.membersListFormSetSortValueAndGetList(currentSort);
  };

  // UsersNotCompletedListFormModal
  const handleCloseUsersNotCompletedListFormModal = () => {
    reportsStore.setOpenUsersNotCompletedListFormModal(false);
    reportsStore.setCurrentReport(null);
    reportsStore.usersNotCompletedPollListForm.resetForm();
  };

  const handleChangePageUsersNotCompletedList = (event: React.ChangeEvent<unknown>, page: number) => {
    reportsStore.usersNotCompletedPollListFormSetValueAndGetList(TableFormFields.page, page);
  };

  const handleSortUsersNotCompletedList = (currentSort: string) => (e: React.MouseEvent<HTMLElement>) => {
    reportsStore.reportsListFormSetSortValueAndGetList(currentSort);
  };

  // Renders
  return (
    <>
      <div className={classes.topLine}>
        <Box sx={{ width: '100%', mr: 15 }}>
          <ReportsSearchForm />
        </Box>
        <Box sx={{ maxWidth: 190 }}>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={handleOpenDeleteModal}
            disabled={!reportsStore.deletedReportsSelectionList.length}
          >
            Удалить
          </Button>
        </Box>
      </div>
      <Divider className={classes.divider} />

      <ReportsTableList />
      {reportsStore.reportsListForm.showPagination && <ReportsPagination />}

      <ReportDeleteModal />

      <MembersModal
        open={reportsStore.openUsersModal}
        sortValue={organizationsStore.membersListForm.sortValue}
        tableLoading={organizationsStore.organizationMembersListLoading}
        membersList={organizationsStore.organizationMembersList}
        tableFields={membersModalTableFields}
        paginationProps={{
          showPagination: organizationsStore.membersListForm.showPagination,
          page: organizationsStore.membersListForm.page,
          pageCount: organizationsStore.membersListForm.pageCount,
          onHandleChangePage: handleChangePage,
        }}
        onHandleClose={handleCloseUsersModal}
        onHandleSort={handleSort}
      />

      <MembersModal
        open={reportsStore.openUsersNotCompletedPollListFormModal}
        sortValue={reportsStore.usersNotCompletedPollListForm.sortValue}
        tableLoading={reportsStore.usersNotCompletedListLoading}
        membersList={reportsStore.usersNotCompletedPollList}
        tableFields={membersModalTableFields}
        paginationProps={{
          showPagination: reportsStore.usersNotCompletedPollListForm.showPagination,
          page: reportsStore.usersNotCompletedPollListForm.page,
          pageCount: reportsStore.usersNotCompletedPollListForm.pageCount,
          onHandleChangePage: handleChangePageUsersNotCompletedList,
        }}
        onHandleClose={handleCloseUsersNotCompletedListFormModal}
        onHandleSort={handleSortUsersNotCompletedList}
      />
    </>
  );
});

const useStyles = makeStyles()(theme => ({
  topLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(0, -3.75, -1.5, 4),
  },
}));
