import { FormLabel, FormControl, Select, FormHelperText } from '@mui/material';
import React, { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IQSelectOutlinedProps } from 'interfaces/CommonComponentsInterfaces';

import { Loader } from '../Loader';
import { QHelperText } from '../QHelperText';

export const QSelectOutlined: React.FC<IQSelectOutlinedProps> = forwardRef((props, ref) => {
  const { loading, label, fullWidth, labelProps, errors, formControlProps, ...rest } = props;

  const { classes, cx } = useStyles({ fullWidth });

  // Renders
  const renderIcon = (props: { className: string }) => (
    <i className={cx('custom-icon-triangle-owal-down', props.className)} />
  );

  return (
    <div className={classes.wrap}>
      {loading && <Loader isAbsolute />}
      {label && (
        <FormLabel color="secondary" {...labelProps}>
          <div className={classes.labelWrap}>{label}</div>
        </FormLabel>
      )}
      <FormControl fullWidth={fullWidth} error={!!errors} {...formControlProps}>
        <Select
          ref={ref}
          autoComplete="off"
          className={classes.select}
          MenuProps={{
            classes: { paper: classes.paper },
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          }}
          IconComponent={renderIcon}
          fullWidth={fullWidth}
          displayEmpty
          {...rest}
        />

        {!!errors?.length && (
          <FormHelperText>
            <QHelperText errors={errors} />
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
});

export const useStyles = makeStyles<{ fullWidth?: boolean }>()((theme, props) => ({
  wrap: {
    position: 'relative',
    width: !!props?.fullWidth ? '100%' : undefined,
  },
  select: {
    ...theme.typography.h3Regular,
  },
  paper: {
    background: theme.palette.customColors.white,
    border: `0.5px solid ${theme.palette.customColors.grayDivider}`,
    boxShadow: '0px 4px 4px rgba(169, 169, 169, 0.12)',
    borderRadius: 4,
    marginTop: theme.spacing(1),
  },
  labelWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));
